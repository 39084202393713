import React from 'react';
import cn from 'classnames';

import PlayIcon from '../PlayIcon';
import { FullScreen, Muted, UnMuted } from './Icons';
import { useOnScreen } from 'src/routes/client/hooks';
import { getVideoFileName } from 'src/routes/client/utils/video-file-name';

import css from './VideoJsPlayer.module.scss';

interface Props {
  id: string;
  videoSRC: string;
  cover?: string;
  startFrom?: number; // in seconds
  getPosition?: (value: { pauseTime: number; persents: number }) => void;
  onStatisticUpdate?: (value: { fileName: string; progress: number; duration: string }) => void;
  onComplete?: () => void;
  onStartPlaying?: (videoSrc: string) => void;
  onReady?: () => void;
  bindFullscreenClick?: () => void;
  smallButtons?: boolean;
  isRepeated?: boolean;
  isDefaultControls?: boolean;
  isCenteredPlayBtn?: boolean;
  onExternalPause?: boolean;
  playButtonClassName?: string;
}

export const VideoJS = (props: Props) => {
  const {
    onStartPlaying,
    videoSRC,
    onComplete,
    cover,
    id,
    getPosition,
    startFrom,
    smallButtons = false,
    isRepeated = false,
    isDefaultControls = false,
    isCenteredPlayBtn = false,
    onStatisticUpdate,
    onReady,
    bindFullscreenClick,
    onExternalPause = false,
    playButtonClassName = '',
  } = props;
  const videoRef = React.useRef(null);
  const playerRef = React.useRef<any>(null);
  const [isReady, setIsReady] = React.useState(false);
  const [isMuted, setIsMuted] = React.useState(true);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [statisticPersents, setStatisticPersents] = React.useState<
    0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100
  >(0);
  const isVisible = useOnScreen(videoRef as any, 0.5);
  const fileName = getVideoFileName(videoSRC);
  const [isDefaultUIControls, setIsDefaultUIControls] = React.useState(isDefaultControls);

  React.useEffect(() => {
    const options = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      muted: typeof (window as any).isUserAction === 'undefined' ? true : (window as any).isUserAction,
      experimentalSvgIcons: true,
      loop: isRepeated,
      sources: [
        {
          src: videoSRC,
          type: 'application/x-mpegURL',
        },
      ],
    };

    setIsMuted(typeof (window as any).isUserAction === 'undefined' ? true : (window as any).isUserAction);

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');
      videoElement.setAttribute('id', id);

      videoElement.classList.add('vjs-big-play-centered');
      const videoElem = document.getElementById(id);
      (videoElem as any).appendChild(videoElement);

      const player: any = ((playerRef as any).current = (window as any).videojs(videoElement, options, () => {
        // console.log('player is ready');
        handlePlayerEvents(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player: any = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [videoSRC, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !(player as any).isDisposed()) {
        (player as any).dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  React.useEffect(() => {
    const player: any = playerRef.current;

    if ((isPlaying && !isVisible) || onExternalPause) {
      player.pause();
      setIsPlaying(false);
    } else if (isReady && isVisible && !onExternalPause) {
      const playPromise = player.play();

      // prevent DevTools error
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            player.play();
          })
          .catch((error: string) => {
            console.info(error);
          });
      }
    }
  }, [isReady, isVisible, onExternalPause]);

  React.useEffect(() => {
    if (onStatisticUpdate && statisticPersents > 0) {
      onStatisticUpdate({
        fileName,
        progress: statisticPersents,
        duration: playerRef.current ? parseFloat(playerRef.current.duration()).toFixed() : '0',
      });
    }
  }, [statisticPersents]);

  const handlePlayerEvents = (player: any) => {
    playerRef.current = player;

    setIsReady(true);

    if (startFrom && !isPlaying) {
      player.currentTime(startFrom);
    }

    // You can handle player events here, for example:
    player.on('waiting', () => {
      setIsPlaying(true);
    });

    player.on('playing', () => {
      setIsPlaying(true);
    });

    player.on('play', () => {
      onStartPlaying?.(videoSRC);
    });

    player.on('pause', () => {
      getVideoPosition();
      setIsPlaying(false);
    });

    player.on('fullscreenchange', () => {
      if (!isDefaultControls) {
        onFullScreenChange();
      }
    });

    if (onStatisticUpdate) {
      player.on('timeupdate', () => {
        videoStatistic(player.duration(), player.currentTime());
      });
    }

    player.on('ended', () => {
      if (onStatisticUpdate) {
        // in case video can't reach 100% on timeupdate
        onStatisticUpdate({ fileName, progress: 100, duration: parseFloat(player.duration()).toFixed() });
      }
      if (onComplete) {
        onComplete();
        setIsPlaying(false);
      }
    });

    player.on('dispose', () => {
      getVideoPosition();
    });

    if (onReady) {
      onReady();
    }
  };

  const handleMute = () => {
    const player: any = playerRef.current;

    if (player) {
      const isMuted = player.muted();

      player.muted(!isMuted);
      setIsMuted(!isMuted);

      /** UX: Global mute flag */
      (window as any).isUserAction = !isMuted;
    }
  };

  const onFullScreenChange = () => {
    // this function handle UI controls
    const player: any = playerRef.current;
    const isFullScreen = (player as any).isFullscreen();

    setIsDefaultUIControls(isFullScreen);
  };

  const handleFullScreen = () => {
    if (bindFullscreenClick) {
      bindFullscreenClick();
      return;
    }

    const player: any = playerRef.current;

    if (player) {
      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.webkitRequestFullscreen) {
        /* Safari */
        player.webkitRequestFullscreen();
      } else if (player.msRequestFullscreen) {
        /* IE11 */
        player.msRequestFullscreen();
      }
    }
  };

  const onPlayClick = () => {
    const player: any = playerRef.current;

    if (player) {
      if (isPlaying) {
        player.pause();
      } else {
        player.play();
      }
    }
  };

  const getVideoPosition = () => {
    const player: any = playerRef.current;

    if (getPosition) {
      const pos = player.currentTime();
      const duration = player.duration();
      if (pos && duration) {
        const persents = (pos / duration) * 100;
        getPosition({ pauseTime: pos, persents: persents });
      }
    }
  };

  const videoStatistic = (duration: number, currentTime: number) => {
    const currentProgress = Math.floor((currentTime / duration) * 100);

    if (currentProgress >= 10 && currentProgress <= 20) {
      setStatisticPersents(10);
    } else if (currentProgress >= 20 && currentProgress <= 30) {
      setStatisticPersents(20);
    } else if (currentProgress >= 30 && currentProgress <= 40) {
      setStatisticPersents(30);
    } else if (currentProgress >= 40 && currentProgress <= 50) {
      setStatisticPersents(40);
    } else if (currentProgress >= 50 && currentProgress <= 60) {
      setStatisticPersents(50);
    } else if (currentProgress >= 60 && currentProgress <= 70) {
      setStatisticPersents(60);
    } else if (currentProgress >= 70 && currentProgress <= 80) {
      setStatisticPersents(70);
    } else if (currentProgress >= 80 && currentProgress <= 90) {
      setStatisticPersents(80);
    } else if (currentProgress >= 90 && currentProgress <= 100) {
      setStatisticPersents(90);
    }
    // 100% - on ended video
  };

  return (
    <div data-vjs-player className={cn(css.player, isDefaultUIControls && css.isCustomControls)}>
      <div ref={videoRef} id={id} />
      {!isPlaying && (
        <div className={css.cover} onClick={onPlayClick} style={cover ? { backgroundImage: `url(${cover})` } : {}}>
          <PlayIcon
            className={cn(
              css.playButton,
              playButtonClassName,
              isCenteredPlayBtn && css.centered,
              smallButtons && css.small,
            )}
          />
        </div>
      )}
      {!isDefaultUIControls && (
        <div className={css.castomControls}>
          <button type="button" className={cn(css.controlButton, smallButtons && css.small)} onClick={handleMute}>
            {isMuted ? <Muted /> : <UnMuted />}
          </button>
          <button type="button" className={cn(css.controlButton, smallButtons && css.small)} onClick={handleFullScreen}>
            <FullScreen />
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoJS;
