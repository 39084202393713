import React from 'react';
import cn from 'classnames';

import logoUrl from 'client/assets/header/cic-logo-v2.svg';
import css from './Logo.module.scss';

interface Props {
  className?: string;
}

const Logo = (props: Props) => {
  const { className = '' } = props;

  return (
    <div className={cn(css.logo, className)}>
      <img src={logoUrl} alt="Catalyst Investors' Club" />
    </div>
  );
};

export { Logo };
export default Logo;
