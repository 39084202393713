export function numberToCurrency(value: number): string {
  const billion = 1_000_000_000; // who knows...
  const million = 1_000_000;
  const thousand = 1_000;

  if (value >= billion) {
    const formattedValue = (value / billion).toFixed(1).replace('.0', '');
    return `$${formattedValue}B`;
  } else if (value >= million) {
    const formattedValue = (value / million).toFixed(1).replace('.0', '');
    return `$${formattedValue}M`;
  } else if (value >= thousand) {
    const formattedValue = (value / thousand).toFixed(1).replace('.0', '');
    return `$${formattedValue}K`;
  } else {
    return `$${value}`;
  }
}
