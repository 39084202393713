import React from 'react';
import cn from 'classnames';

import { getVideoFileName } from 'src/routes/client/utils/video-file-name';
import playBtnIconUrl from 'client/assets/player/icon-play.svg';

import { FullScreen, Muted, UnMuted } from './Icons';
import { Button } from '../Button';

import css from './VideoJsPlayer.module.scss';

interface Props {
  id: string;
  videoSRC: string;
  onStatisticUpdate?: (value: { fileName: string; progress: number; duration: string }) => void;
  onComplete?: () => void;
  onStartPlaying?: (videoSrc: string) => void;
  bindFullscreenClick?: () => void;
  className?: string;
}

export const VideoJS = (props: Props) => {
  const { videoSRC, onComplete, onStartPlaying, id, onStatisticUpdate, bindFullscreenClick, className = '' } = props;
  const videoRef = React.useRef(null);
  const playerRef = React.useRef<any>(null);
  const [isMuted, setIsMuted] = React.useState(true);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [statisticPersents, setStatisticPersents] = React.useState<
    0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100
  >(0);
  const fileName = getVideoFileName(videoSRC);
  React.useEffect(() => {
    const options = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      muted: true,
      experimentalSvgIcons: true,
      sources: [
        {
          src: videoSRC,
          type: 'application/x-mpegURL',
        },
      ],
    };

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');
      videoElement.setAttribute('id', id);

      videoElement.classList.add('vjs-big-play-centered');
      const videoElem = document.getElementById(id);
      (videoElem as any).appendChild(videoElement);

      const player: any = ((playerRef as any).current = (window as any).videojs(videoElement, options, () => {
        player.playsinline(true);
        handlePlayerEvents(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player: any = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [videoSRC, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !(player as any).isDisposed()) {
        (player as any).dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  React.useEffect(() => {
    if (onStatisticUpdate && statisticPersents > 0) {
      onStatisticUpdate({
        fileName,
        progress: statisticPersents,
        duration: playerRef.current ? parseFloat(playerRef.current.duration()).toFixed() : '0',
      });
    }
  }, [statisticPersents]);

  const handlePlayerEvents = (player: any) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      setIsPlaying(true);
    });

    player.on('playing', () => {
      setIsPlaying(true);
    });

    player.on('play', () => {
      onStartPlaying?.(videoSRC);
    });

    player.on('pause', () => {
      setIsPlaying(false);
    });

    if (onStatisticUpdate) {
      player.on('timeupdate', () => {
        videoStatistic(player.duration(), player.currentTime());
      });
    }

    player.on('ended', () => {
      if (onStatisticUpdate) {
        // in case video can't reach 100% on timeupdate
        onStatisticUpdate({ fileName, progress: 100, duration: parseFloat(player.duration()).toFixed() });
      }
      if (onComplete) {
        onComplete();
        setIsPlaying(false);
      }
    });

    // player.on('dispose', () => {
    //   console.info('player dispose', statisticPersents);
    // });
  };

  const onSoundToggleClick = () => {
    const player: any = playerRef.current;

    if (player) {
      const isMuted = player.muted();

      player.muted(!isMuted);
      setIsMuted(!isMuted);
    }
  };

  const onFullscreenToggleClick = () => {
    if (bindFullscreenClick) {
      bindFullscreenClick();
      return;
    }

    const player: any = playerRef.current;

    if (player) {
      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.webkitRequestFullscreen) {
        /* Safari */
        player.webkitRequestFullscreen();
      } else if (player.msRequestFullscreen) {
        /* IE11 */
        player.msRequestFullscreen();
      }
    }
  };

  const onPlayToggleClick = () => {
    const player: any = playerRef.current;

    if (player) {
      if (isPlaying) {
        player.pause();
      } else {
        player.play();
      }
    }
  };

  const videoStatistic = (duration: number, currentTime: number) => {
    const currentProgress = Math.floor((currentTime / duration) * 100);

    if (currentProgress >= 10 && currentProgress <= 20) {
      setStatisticPersents(10);
    } else if (currentProgress >= 20 && currentProgress <= 30) {
      setStatisticPersents(20);
    } else if (currentProgress >= 30 && currentProgress <= 40) {
      setStatisticPersents(30);
    } else if (currentProgress >= 40 && currentProgress <= 50) {
      setStatisticPersents(40);
    } else if (currentProgress >= 50 && currentProgress <= 60) {
      setStatisticPersents(50);
    } else if (currentProgress >= 60 && currentProgress <= 70) {
      setStatisticPersents(60);
    } else if (currentProgress >= 70 && currentProgress <= 80) {
      setStatisticPersents(70);
    } else if (currentProgress >= 80 && currentProgress <= 90) {
      setStatisticPersents(80);
    } else if (currentProgress >= 90 && currentProgress <= 100) {
      setStatisticPersents(90);
    }
    // 100% - on ended video
  };

  return (
    <div data-vjs-player className={cn(css.player, className)}>
      <div ref={videoRef} id={id} />
      {!isPlaying && (
        <div className={css.playIcon}>
          <img src={playBtnIconUrl} alt="play" />
        </div>
      )}
      <button className={css.playToggleBtn} type="button" onClick={onPlayToggleClick} />
      <div className={css.playerControls}>
        <Button
          className={css.playerControlsBtn}
          size="large"
          variant="blured"
          noPaddings
          onClick={onFullscreenToggleClick}
        >
          <FullScreen />
        </Button>
        <Button className={css.playerControlsBtn} size="large" variant="blured" noPaddings onClick={onSoundToggleClick}>
          {isMuted ? <Muted /> : <UnMuted />}
        </Button>
      </div>
    </div>
  );
};

export default VideoJS;
