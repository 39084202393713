import React from 'react';
import cn from 'classnames';

import { InputValue, Onboarding } from 'models/user/onboarding';
import { useTranslation } from 'client/hooks';
import { onboarding as onboardingApi } from 'client/redux/api/onboarding';
import { SKIPPABLE_TOKEN, getStageStep } from 'utils/onboarding/stage';
import Button from '../../../Button';

import css from './NavigationButtons.module.scss';

interface Props {
  clickOnBack: () => void;
  clickOnSkip: (value: Record<string, InputValue>) => void;
  isHiddenBack: boolean | null;
  isSkippable: boolean;
  currentStep: ReturnType<typeof getStageStep>;
}
const NavigationButtons = ({ clickOnBack, clickOnSkip, isHiddenBack, isSkippable, currentStep }: Props) => {
  const { translate } = useTranslation();
  // const [quesstionare, quesstionareState] = onboardingApi.endpoints.setQuesstionare.useMutation();

  const onBackHandler = () => {
    clickOnBack();
  };

  const onSkipHandler = () => {
    if (currentStep?.current) {
      const currentStepVal = { [`${currentStep?.current.name}`]: SKIPPABLE_TOKEN };
      clickOnSkip(currentStepVal);
    }
  };

  return (
    <div className={cn(css.buttonsWrapper, isSkippable && css.skippable)}>
      <Button onClick={onBackHandler} className={cn(css.back, isHiddenBack && css.hide)}>
        {translate('questionnaire.navigationButtons.back')}
      </Button>
      <Button onClick={onSkipHandler} className={cn(css.skip, isSkippable && css.show)}>
        {translate('questionnaire.navigationButtons.skip')}
      </Button>
    </div>
  );
};

export default NavigationButtons;
