import React, { useState } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import DOMPurify from 'dompurify';

import Error from '../../Error';
import _ from 'lodash';

import css from './TextArea.module.scss';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  valuePattern?: RegExp;
  label?: string | (() => React.ReactNode);
}

const TextArea: React.FC<Props> = (props) => {
  const { className = '', name = '', valuePattern, label = '', ...inputProps } = props;
  const [field, meta, helpers] = useField(name);
  const errorMsg = !!(meta.touched && meta.error) ? meta.error : '';
  const [isFocused, setIsFocused] = useState(false);
  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (valuePattern) {
      if (e.target.value === '' || valuePattern.test(e.target.value.toLocaleLowerCase())) {
        const value = DOMPurify.sanitize(e.target.value);
        helpers.setValue(value);
      }
    } else {
      const value = DOMPurify.sanitize(e.target.value);
      helpers.setValue(value);
    }
  };

  const fieldProps = {
    ...field,
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChangeHandler(event);
    },
    onFocus: () => {
      setIsFocused(true);
    },
    onReset: () => {
      console.info('reset');
    },
    onBlur: () => {
      if (!field.value) {
        setIsFocused(false);
      }
    },
  };

  return (
    <div className={classNames(css.container, meta.error && meta.touched && css.isError, className)}>
      <div className={classNames(css.textAreaWrapper, isFocused && css.focused)}>
        {label ? <p className={css.label}>{typeof label === 'string' ? label : label()}</p> : null}
        <div className={css.warapper}>
          <textarea className={css.formTextArea} {...inputProps} {...fieldProps}></textarea>
        </div>
      </div>

      {errorMsg && meta.touched && <Error name={name} error={errorMsg} />}
    </div>
  );
};

export default TextArea;
