import * as yup from 'yup';

import * as routes from 'api/routes';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = {
  target: 'favorite' | 'voted' | 'all';
};

export type Result = {
  voted: Array<string>;
  favorite: Array<string>;
};

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape({
  target: yup.mixed<Params['target']>().oneOf(['favorite', 'voted', 'all']).required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.USERS_FAVORITE_VOTED_INFO_GET;
