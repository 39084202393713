import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import ContentWrap from '../ContentWrap';
import css from './PageNavigation.module.scss';

interface Props {
  className?: string;
  stickyMode?: boolean;
  anchorMode?: boolean;
  navigation: {
    label: string;
    path: string;
    hasPendingAction?: boolean;
    icon?: React.ReactNode;
    highlighted?: boolean;
  }[];
  logo?: React.ReactNode;
}

export const PageNavigation = (props: Props) => {
  const { navigation, className = '', logo = null, stickyMode = false, anchorMode = false } = props;

  return (
    <nav className={cn(css.pageNavigation, stickyMode && css.stickyMode, className)}>
      <ContentWrap className={css.pageNavigationContentWrap}>
        <div className={css.pageNavigationContent}>
          <ul className={css.pageNavigationList}>
            {navigation.map((item) => (
              <li className={cn(css.pageNavigationItem, item.highlighted && css.highlighted)} key={item.path}>
                {item.hasPendingAction && <div className={css.pendingAction}></div>}
                {!anchorMode ? (
                  <NavLink
                    exact
                    to={item.path}
                    className={(isActive) => cn(css.pageNavigationLink, isActive && css.active)}
                  >
                    {Boolean(item.icon) && <span className={css.pageNavigationLinkIcon}>{item.icon}</span>}
                    <span>{item.label}</span>
                  </NavLink>
                ) : (
                  <a href={item.path} className={cn(css.pageNavigationLink)}>
                    {Boolean(item.icon) && <span className={css.pageNavigationLinkIcon}>{item.icon}</span>}
                    <span>{item.label}</span>
                  </a>
                )}
              </li>
            ))}
          </ul>
          {logo && <div className={css.pageNavigationLogo}>{logo}</div>}
        </div>
      </ContentWrap>
    </nav>
  );
};

export default PageNavigation;
