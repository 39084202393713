import React from 'react';

import SuccessLogin from './SuccessLogin';
import SuccessEmailSend from './SuccessEmailSend';
import SuccessResetPassword from './SuccessResetPassword';
import { State, useAuthDispatch } from '../state';

type Props = {
  action: State['authType'];
  onSentEmailAgainClick: () => unknown;
  onSignInClickAfterRestore: () => unknown;
  onCloseClick: () => unknown;
  email?: string;
};
const SuccessMessage: React.FC<Props> = ({
  action,
  onSentEmailAgainClick,
  onSignInClickAfterRestore,
  onCloseClick,
  email,
}) => {
  const authDispatch = useAuthDispatch();

  const onTryAgainClick = () => {
    onSentEmailAgainClick();
    authDispatch({ type: 'setAuthType', payload: 'forgot-password' });
  };

  const onSignInClick = () => {
    onSignInClickAfterRestore();
    authDispatch({ type: 'setAuthType', payload: 'sign-in' });
  };

  return (
    <>
      {action === 'sign-up' && <SuccessLogin onStarBrowsingBtnClick={onCloseClick} />}
      {action === 'forgot-password' && (
        <SuccessEmailSend onCloseClick={onCloseClick} onTryAgainClick={onTryAgainClick} email={email} />
      )}
      {action === 'restore-password' && (
        <SuccessResetPassword onCloseClick={onCloseClick} onSignInClick={onSignInClick} />
      )}
    </>
  );
};

export default SuccessMessage;
