import * as yup from 'yup';

import * as routes from 'api/routes';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params =
  | {
      provider: 'google';
      email: string;
      pictureUrl?: string;
      name?: string;
    }
  | {
      provider: 'linkedin';
      email: string;
      pictureUrl?: string;
      name?: string;
    }
  | {
      provider: 'emailAndPassword';
      email: string;
      password: string;
    };

export const emailAndPassParamsSchema = yup.object().shape({
  email: yup.string().email(VALIDATION_ERROR.INVALID_EMAIL).required(VALIDATION_ERROR.REQUIRED),
  password: yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const paramsSchema = yup.object().shape({
  provider: yup.string().oneOf(['google', 'linkedin', 'emailAndPassword']).required(VALIDATION_ERROR.REQUIRED),
  email: yup.string().email(VALIDATION_ERROR.INVALID_EMAIL).required(VALIDATION_ERROR.REQUIRED),
  pictureUrl: yup.string().optional(),
  name: yup.string().optional(),
  password: yup
    .string()
    .optional()
    .when(['provider'], {
      is: 'emailAndPassword',
      then: yup.string().required(VALIDATION_ERROR.REQUIRED),
    }),
});

export const ROUTE = routes.AUTH_USER;
