import React from 'react';
import { useTranslation } from 'client/hooks';
import css from './ExplanationSubtitle.module.scss';

interface Props {
  text: string;
}

const ExplanationSubtitle = ({ text }: Props) => {
  const { translate } = useTranslation();
  return <p className={css.text}>{text}</p>;
};

export default ExplanationSubtitle;
