import { useMemo } from 'react';

import { selectAuth, selectUserId } from 'client/redux/user/selectors';

import { useAppSelector } from './redux';

export const useAnalytics = () => {
  const userId = useAppSelector(selectUserId);
  const auth = useAppSelector(selectAuth);

  const analytics = useMemo(() => {
    return {
      gtag: {
        event: (action: string, params?: Record<string, string>) => {
          // @ts-expect-error gtag is added in html.head:
          const { gtag } = window;

          if (gtag) {
            console.log('gtag:', action, params);
            gtag('event', action, {
              send_to: ['G-GP0RDENJVT', 'G-16YC3NL8HN'],
              ...(auth.isLoggedIn && { user_id_dimension: userId }),
              ...params,
            });
          } else {
            console.log('%cgtag is undefined', 'color: red');
          }
        },
      },
    };
  }, [userId, auth.isLoggedIn]);

  return analytics;
};
