import * as yup from 'yup';

import * as routes from 'api/routes';
import { VALIDATION_ERROR } from 'endpoints/index';
import { ENVELOPE_TYPE, EnvelopeType } from 'api/utils/docuSign/types';

export type Params = { type: EnvelopeType };
export type Result = { url: string; envelopeId: string };

export const paramsSchema = yup.object().shape({
  type: yup
    .mixed()
    .oneOf(Object.values(ENVELOPE_TYPE), VALIDATION_ERROR.INVALID_VALUE)
    .required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.DS_GET_ENVELOPE;
