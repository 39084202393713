import { ObjectValues } from 'types/object-values';

export interface IModel {
  _id: TargetValues;
  title: string;
  body: string;
}

export const TARGET_VALUES = {
  TERMS_OF_USE: 'terms_of_use',
  DISCLAIMERS: 'disclaimers',
  PRIVACY_POLICY: 'privacy_policy',
  CYBER_SECURITY: 'cyber_security',
  CONSENT_FORM: 'consent_form',
  ACCESSIBILITY_STATEMENT: 'accessibility_statement',
  FOOTER_DISCLAIMER: 'footer_disclaimer',
} as const;

export type TargetValues = ObjectValues<typeof TARGET_VALUES>;
