import React, { createContext, useContext } from 'react';

export const StateContext = createContext<State | null>(null);
export const DispatchContext = createContext<React.Dispatch<Action> | null>(null);

export interface State {
  authType: 'sign-in' | 'sign-up' | 'restore-password' | 'forgot-password';
  currentView: 'default' | 'googleProvider' | 'linkedinProvider' | 'emailProvider' | 'successView';
  isBusy: boolean;
  isActiveNewUserData: boolean;
}

type Action =
  | {
      type: 'setCurrentView';
      payload: State['currentView'];
    }
  | {
      type: 'setAuthType';
      payload: State['authType'];
    }
  | {
      type: 'setIsBusy';
      payload: boolean;
    }
  | {
      type: 'setIsActiveNewUserData';
      payload: boolean;
    };

export const initialState: State = {
  authType: 'sign-in',
  currentView: 'default',
  isBusy: false,
  isActiveNewUserData: false,
};

export function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'setCurrentView': {
      const isBusy =
        state.isBusy ||
        action.payload === 'googleProvider' ||
        action.payload === 'linkedinProvider' ||
        action.payload === 'emailProvider';
      return { ...state, currentView: action.payload, isBusy };
    }

    case 'setIsBusy':
      return { ...state, isBusy: action.payload };

    case 'setAuthType':
      return { ...state, authType: action.payload };

    case 'setIsActiveNewUserData':
      return { ...state, isActiveNewUserData: action.payload };

    default:
      throw new Error('Unknown action');
  }
}

export function useAuthState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useAuthState must be used within a AppContextProvider');
  }

  return context as { [K in keyof State]: NonNullable<State[K]> };
}

export function useAuthDispatch() {
  const dispatch = useContext(DispatchContext);

  if (!dispatch) {
    throw new Error('useAuthDispatch must be used within a AppContextProvider');
  }

  return dispatch;
}
