import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import SwiperCore, { Virtual, Mousewheel, Navigation } from 'swiper';
import SwiperClass from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams } from 'react-router-dom';

import { MainPageWebsiteSliderItem } from 'api/main_page/public/get/params';
import { Company } from 'api/companies/public/paginate/params';
import { Loader, CompanyVideoSlide } from 'client/components/common';

import css from './CompaniesSlider.module.scss';

// install Virtual module
SwiperCore.use([Virtual, Mousewheel, Navigation]);

interface Props {
  title: string | (() => React.ReactNode);
  companies?: MainPageWebsiteSliderItem[] | Company[];
  scrollTop?: boolean;
  isLoading: boolean;
  isOnCompanyPage?: boolean;
}

export const SLIDES_PER_GROUP = 4;

const CompaniesSlider: React.FC<Props> = ({
  title,
  companies,
  scrollTop = false,
  isLoading,
  isOnCompanyPage = false,
}) => {
  const [activeSlide, setActiveSlide] = useState<string>('');
  const sliderRef = React.useRef<SwiperClass>();
  const [isHideNext, setIsHideNext] = useState<boolean>(false);
  const [isHidePrev, setIsHidePrev] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const routeParams = useParams<{ id: string }>();

  useEffect(() => {
    if (isOnCompanyPage) {
      sliderRef.current?.slideTo(0);
    }
  }, [routeParams.id]);

  return (
    <div className={css.slider}>
      <div className={css.sliderTitle}>{typeof title === 'string' ? title : title()}</div>
      {isLoading ? (
        <Loader position="absolute" />
      ) : (
        <div className={css.sliderWrapper}>
          <Swiper
            className={css.swiper}
            spaceBetween={20}
            slidesPerView={4}
            virtual
            mousewheel={false}
            onSwiper={(swiper) => (sliderRef.current = swiper)}
            onSlideChange={(s) => {
              if (companies) {
                if (s.activeIndex === 0) {
                  setIsHidePrev(true);
                } else {
                  setIsHidePrev(false);
                }
                if (s.activeIndex + SLIDES_PER_GROUP >= companies.length) {
                  setIsHideNext(true);
                } else {
                  setIsHideNext(false);
                }
              }
            }}
            onTransitionStart={(s) => {
              if (s.isEnd) {
                setIsEnd(true);
              }

              if (!s.isEnd && isEnd) {
                setIsEnd(false);
              }
            }}
            slidesPerGroup={SLIDES_PER_GROUP}
            speed={300}
            allowTouchMove={false}
            noSwiping={false}
          >
            {companies &&
              companies.map((slideContent, index) => (
                <SwiperSlide
                  className={cn(css.swiperSlide, slideContent._id === activeSlide && css.active)}
                  key={slideContent._id}
                  virtualIndex={index}
                >
                  <CompanyVideoSlide
                    activeId={activeSlide}
                    data={slideContent}
                    setActiveSlide={(id) => setActiveSlide(id)}
                    index={index + 1}
                    companiesLength={companies.length}
                    isEnd={isEnd}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
          {companies && companies.length > SLIDES_PER_GROUP && (
            <>
              <div
                className={cn(css.navBtn, css.next, isHideNext && css.hide)}
                onClick={() => sliderRef.current?.slideNext()}
              >
                <div className={css.arrow}></div>
              </div>

              <div
                className={cn(css.navBtn, css.prev, isHidePrev && css.hide)}
                onClick={() => sliderRef.current?.slidePrev()}
              >
                <div className={css.arrow}></div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CompaniesSlider;
