import yup, { AnySchema } from 'yup';

export enum ERROR {
  REQUIRED = 'REQUIRED',
  TOO_SHORT = 'TOO_SHORT',
  TOO_LONG = 'TOO_LONG',
  PASSWORDS_MISMATCH = 'PASSWORDS_MISMATCH',
  TOO_WEAK = 'TOO_WEAK',
  INVALID_FORMAT = 'INVALID_FORMAT',
  INVALID_VALUE = 'INVALID_VALUE',
  SLUG_REGEXP_ERROR = 'SLUG_REGEXP_ERROR',
  ONLY_HEBREW = 'ONLY_HEBREW',
  AT_LEAST_ONE_UPPER_LETTER = 'AT_LEAST_ONE_UPPER_LETTER',
  AT_LEAST_ONE_LOWER_LETTER = 'AT_LEAST_ONE_LOWER_LETTER',
  AT_LEAST_ONE_NUMBER = 'AT_LEAST_ONE_NUMBER',
  MIN_DIGITS = 'MIN_DIGITS',
  MAX_DIGITS = 'MAX_DIGITS',
  FILE_IS_TOO_LARGE = 'FILE_IS_TOO_LARGE',
  FILE_IS_TOO_SMALL = 'FILE_IS_TOO_SMALL',
  FILE_UNSUPPORTED_FORMAT = 'FILE_UNSUPPORTED_FORMAT',
  MAX_CHARACTERS = 'MAX_CHARACTERS',
  MIN_CHARACTERS = 'MIN_CHARACTERS',
  INVALID_URL = 'INVALID_URL',
  INVALID_EMAIL = 'INVALID_EMAIL',
  MIN_TWO_WORDS = 'MIN_TWO_WORDS',
  INVALID_CHARACTERS = 'INVALID_CHARACTERS',
  INVALID_SIZE = 'INVALID_SIZE',
  TAXID_INVALID_VALUE = 'TAXID_INVALID_VALUE',
  TAXID_REQUIRED = 'TAXID_REQUIRED',
  TAXCOUNTRY_REQUIRED = 'TAXCOUNTRY_REQUIRED',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  AT_LEAST_SOME_NUMBER_OF_DIGITS = 'AT_LEAST_SOME_NUMBER_OF_DIGITS',
}

export type RawData = Record<string, ERROR[]>;
export type ReadableData = Record<string, string[]>;
export type ValidationResult = { rawErrors: RawData; errors: ReadableData };
export type ValidationShape<P> = { [K in keyof P]: AnySchema };

export const formatValidation = (error: yup.ValidationError): RawData => {
  return error.inner.reduce((acc: Record<string, ERROR[]>, current) => {
    // if (!(Object.values(ERROR) as string[]).includes(current.message)) {
    //   throw new Error(`Failed to format validation data: ${current.message} is not listed in VALIDATION_ERROR enum`);
    // }

    const path = current.path?.replace('[', '.').replace(']', '');
    const code = current.message as ERROR;

    if (path) {
      if (acc[path] === undefined) acc[path] = [];
      acc[path].push(code);
    }

    return acc;
  }, {});
};

export type ValidationTranslation<FormValues = Record<string, unknown>> = Record<
  keyof FormValues,
  Partial<Record<ERROR, string>>
>;

const generalErrors = {
  [ERROR.REQUIRED]: 'Required',
  [ERROR.TOO_SHORT]: 'Too short',
  [ERROR.TOO_LONG]: 'Too long',
  [ERROR.TOO_WEAK]: 'Too weak',
  [ERROR.INVALID_FORMAT]: 'Invalid format',
  [ERROR.PASSWORDS_MISMATCH]: 'Passwords does not match',
  [ERROR.AT_LEAST_ONE_LOWER_LETTER]: 'Password must contain at least 1 lower case letter',
  [ERROR.AT_LEAST_ONE_UPPER_LETTER]: 'Password must contain at least 1 upper case letter',
  [ERROR.AT_LEAST_ONE_NUMBER]: 'Password must contain at least 1 number',
  [ERROR.INVALID_VALUE]: 'Invalid value',
  [ERROR.ONLY_HEBREW]: 'Only hebrew characters are supported',
  [ERROR.SLUG_REGEXP_ERROR]: 'slug field allows only lower case letters and next symbols: _,-',
  [ERROR.MIN_DIGITS]: 'Values must be not less than 10',
  [ERROR.MAX_DIGITS]: 'Values must be not more than 10',
  [ERROR.MAX_CHARACTERS]: 'Values mast be not more than 15',
  [ERROR.MIN_CHARACTERS]: 'Values mast be not less than 2',
  [ERROR.FILE_UNSUPPORTED_FORMAT]: 'Unsupported file type',
  [ERROR.FILE_IS_TOO_LARGE]: 'File is too large',
  [ERROR.FILE_IS_TOO_SMALL]: 'File is too small',
  [ERROR.INVALID_URL]: 'Invalid url',
  [ERROR.INVALID_EMAIL]: 'Invalid email',
  [ERROR.MIN_TWO_WORDS]: 'Value must have minimum two words',
  [ERROR.INVALID_CHARACTERS]: 'Value have invalid characters',
  [ERROR.INVALID_SIZE]: 'Invalid size',
  [ERROR.TAXID_INVALID_VALUE]: 'Tax id invalid value',
  [ERROR.TAXID_REQUIRED]: 'Tax id is required',
  [ERROR.TAXCOUNTRY_REQUIRED]: 'Tax country is required',
  [ERROR.INVALID_PHONE_NUMBER]: 'Invalid phone number',
  [ERROR.AT_LEAST_SOME_NUMBER_OF_DIGITS]: '',
} as const;

const errorPriority = (validationError: ERROR) => {
  const errorPriorities = {
    [ERROR.REQUIRED]: 0,
    [ERROR.TOO_SHORT]: 1,
    [ERROR.TOO_LONG]: 1,
    [ERROR.TOO_WEAK]: 2,
    [ERROR.INVALID_FORMAT]: 3,
    [ERROR.INVALID_VALUE]: 4,
    [ERROR.INVALID_URL]: 4,
    [ERROR.INVALID_EMAIL]: 4,
    [ERROR.ONLY_HEBREW]: 5,
    [ERROR.SLUG_REGEXP_ERROR]: 5,
    [ERROR.PASSWORDS_MISMATCH]: 5,
    [ERROR.AT_LEAST_ONE_UPPER_LETTER]: 5,
    [ERROR.AT_LEAST_ONE_LOWER_LETTER]: 5,
    [ERROR.AT_LEAST_ONE_NUMBER]: 5,
    [ERROR.AT_LEAST_SOME_NUMBER_OF_DIGITS]: 5,
    [ERROR.MAX_CHARACTERS]: 6,
    [ERROR.MIN_CHARACTERS]: 6,
    [ERROR.FILE_UNSUPPORTED_FORMAT]: 6,
    [ERROR.FILE_IS_TOO_LARGE]: 6,
    [ERROR.FILE_IS_TOO_SMALL]: 6,
    [ERROR.MIN_DIGITS]: 6,
    [ERROR.MAX_DIGITS]: 6,
    [ERROR.MIN_TWO_WORDS]: 6,
    [ERROR.INVALID_CHARACTERS]: 6,
    [ERROR.INVALID_SIZE]: 6,
    [ERROR.TAXID_INVALID_VALUE]: 6,
    [ERROR.TAXID_REQUIRED]: 6,
    [ERROR.TAXCOUNTRY_REQUIRED]: 6,
    [ERROR.INVALID_PHONE_NUMBER]: 6,
  } as const;

  return errorPriorities[validationError] || Number.MAX_VALUE;
};

export const translateValidation = (data: RawData, translations?: ValidationTranslation): ReadableData => {
  const result: Record<string, string[]> = {};

  Object.entries(data).forEach(([key, value]) => {
    const sortedErrors = value.sort((a, b) => errorPriority(a) - errorPriority(b));
    result[key] = sortedErrors.map((v: ERROR) => {
      return translations ? translations[key][v] || generalErrors[v] : generalErrors[v];
    });
  });

  return result;
};

export const validate = async (
  values: Record<string, string | unknown>,
  schema: yup.AnySchema,
  translations?: ValidationTranslation,
): Promise<ValidationResult | null> => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    const rawValidation = formatValidation(error as yup.ValidationError);

    return {
      rawErrors: rawValidation,
      errors: translateValidation(rawValidation, translations),
    };
  }
  return null;
};
