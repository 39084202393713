import * as yup from 'yup';
import YupPassword from 'yup-password';

import * as routes from 'api/routes';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = { token: string; userId: string; password: string; confirmPassword: string };
export type Result = undefined;

YupPassword(yup);

export const paramsSchema = yup.object().shape({
  token: yup.string().required(VALIDATION_ERROR.REQUIRED),
  userId: yup.string().required(VALIDATION_ERROR.REQUIRED),
  password: yup
    .string()
    .min(8, VALIDATION_ERROR.TOO_SHORT)
    .required(VALIDATION_ERROR.REQUIRED)
    .minLowercase(1, VALIDATION_ERROR.AT_LEAST_ONE_LOWER_LETTER)
    .minUppercase(1, VALIDATION_ERROR.AT_LEAST_ONE_UPPER_LETTER)
    .minNumbers(1, VALIDATION_ERROR.AT_LEAST_ONE_NUMBER),
  confirmPassword: yup
    .string()
    .min(8, VALIDATION_ERROR.TOO_SHORT)
    .required(VALIDATION_ERROR.REQUIRED)
    .minLowercase(1, VALIDATION_ERROR.AT_LEAST_ONE_LOWER_LETTER)
    .minUppercase(1, VALIDATION_ERROR.AT_LEAST_ONE_UPPER_LETTER)
    .minNumbers(1, VALIDATION_ERROR.AT_LEAST_ONE_NUMBER)
    .oneOf([yup.ref('password')], VALIDATION_ERROR.PASSWORDS_MISMATCH),
});

export const ROUTE = routes.AUTH_RESTORE_PASSWORD;
