import React from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { IModel } from 'models/company_news/interface';
import css from './NewsItem.module.scss';

interface Props {
  className?: string;
  data: IModel['linksInfo'][number];
}

function NewsItem(props: Props) {
  const { data, className = '' } = props;

  if (data.success === false) {
    return null;
  }

  return (
    <div className={cn(css.newsItem, className)}>
      <a href={data.link} target="_blank" rel="noopener noreferrer" className={css.newsItemLink}>
        {data.image && <div className={css.newsItemPhoto} style={{ backgroundImage: `url(${data.image})` }} />}
        <div className={css.newsItemInfo}>
          <p className={css.newsItemTitle}>
            News {data.published && <span>{`${format(new Date(data.published), 'MMM , yyyy')}`}</span>}
          </p>
          <p className={css.newsItemDescription}>
            {data.author && <span>{data.author} - </span>}
            {data.title}
          </p>
        </div>
      </a>
    </div>
  );
}

export { NewsItem };
export default NewsItem;
