import React, { FC, useMemo } from 'react';

import { useTranslation, useAppDispatch, useAnalytics } from 'client/hooks';
import { Button, Founder, Icon } from 'client/components/common';
import { setPopup } from 'client/redux/ui/index';
import { IModel } from 'models/company/interface';

import css from './Founders.module.scss';

type PersonsInfo = IModel['team'];

interface Props {
  team: PersonsInfo;
}

const Founders: FC<Props> = ({ team }) => {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();
  const analytics = useAnalytics();
  const teamArr = useMemo(() => {
    if (team) {
      return team;
    }
    return [];
  }, [team]);

  return (
    <div className={css.founders}>
      <div className={css.foundersTitle}>{translate('companyPage.foundersTitle')}</div>
      <div className={css.foundersWrapper}>
        {teamArr.slice(0, 3).map((personInfo) => {
          return <Founder key={personInfo.name} personInfo={personInfo} />;
        })}
      </div>
      {teamArr.length > 3 && (
        <Button
          onClick={() => {
            analytics.gtag.event('key_executives_view_all');
            dispatch(setPopup({ type: 'founders', data: teamArr }));
          }}
          size="small"
          className={css.btn}
        >
          {translate('commonButtons.viewAll')}
          <Icon type="left-white-arrow" />
        </Button>
      )}
    </div>
  );
};

export default Founders;
