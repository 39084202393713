import React from 'react';
import cn from 'classnames';

import css from './ScrollableBlock.module.scss';

interface Props {
  className?: string;
  title: string;
  children: React.ReactNode;
}

export function ScrollableBlock(props: Props) {
  const { className = '', title, children } = props;

  return (
    <div className={cn(css.scrollableBlock, className)}>
      <div className={css.title}>{title}</div>
      <div className={css.content}>{children}</div>
    </div>
  );
}

export default ScrollableBlock;
