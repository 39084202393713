import React from 'react';
import css from './ConfirmationDialog.module.scss';

type ConfirmationDialogProps = {
  message: React.ReactNode;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ message, onConfirm, onCancel }) => {
  const handleConfirm = async () => {
    await onConfirm();
    handleClose();
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <div className={css.confirmationDialog}>
      <div className={css.confirmationOverlay} onClick={handleClose} />
      <div className={css.confirmationContent}>
        <div className={css.confirmationMessage}>{message}</div>
        <div className={css.confirmationButtons}>
          <button type="button" onClick={handleConfirm}>
            Confirm
          </button>
          <button type="button" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
