import { initializeApp } from 'firebase/app';
import { AppCheck, initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

import { CONFIG } from 'constants/index';

export const app = initializeApp(CONFIG.firebase);

export const getAppCheck = () => {
  let appCheck: AppCheck | null = null;
  // Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
  // site key and pass it to initializeAppCheck().
  if (typeof document !== 'undefined') {
    appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(CONFIG.reCaptchaEnterpriseKey),
      isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
    });
  }

  return appCheck;
};
