import * as yup from 'yup';

import * as routes from 'api/routes';

export type Params = { idToken: string; csrfToken: string };
export type Result = { message: string };

export const paramsSchema = yup.object().shape({
  idToken: yup.string().required(),
  csrfToken: yup.string().required(),
});

export const ROUTE = routes.AUTH_SESSION_IN;
