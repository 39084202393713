import {
  ROUTE as GET_PORTFOLIO_ROUTE,
  Result as GetPortfolioResult,
} from 'api/users/website/get-portfolio/params';
import { ApiResponse } from 'types/api-response';

import api from '..';

export const user = api.injectEndpoints({
  endpoints: (builder) => ({
    getPortfolio: builder.query<ApiResponse<GetPortfolioResult>, null>({
      query: () => ({
        method: 'GET',
        url: GET_PORTFOLIO_ROUTE,
      }),
      providesTags: (result) =>
        result?.success ? [{ type: 'PORTFOLIO', id: 'ALL' }] : [{ type: 'PORTFOLIO', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
