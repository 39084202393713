import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useAppSelector } from 'client/hooks';
import { selectAuth } from 'client/redux/user/selectors';

interface UTMParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
}
// utm_term?: string;
// utm_content?: string;

/**
 * Extract UTM parameters from the URL search string.
 */
const extractUTMParams = (search: string): UTMParams => {
  const params = new URLSearchParams(search);
  return {
    utm_source: params.get('utm_source') || undefined,
    utm_medium: params.get('utm_medium') || undefined,
    utm_campaign: params.get('utm_campaign') || undefined,
  };
};

/**
 * Persist UTM parameters in a single cookie object on first entry.
 */
const persistUTMParamsOnce = (utm: UTMParams): void => {
  const existingUTM = Cookies.get('utmParams');

  if (!existingUTM || Object.keys(JSON.parse(existingUTM || '{}')).length === 0) {
    Cookies.set('utmParams', JSON.stringify(utm), { path: '/' }); // Session
  }
};

/**
 * Get UTM parameters from the cookie object.
 */
const getPersistedUTMParams = (): UTMParams => {
  const storedParams = Cookies.get('utmParams');
  return storedParams ? JSON.parse(storedParams) : {};
};

/**
 * Middleware component to ensure UTM parameters persist across route changes.
 */
const UTMMiddleware: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const auth = useAppSelector(selectAuth);

  useEffect(() => {
    if (auth.isLoggedIn) {
      Cookies.remove('utmParams', { path: '/' });
      return;
    }
    // Extract UTM parameters from the current URL
    const currentUTM = extractUTMParams(location.search);

    // Persist the UTM parameters in a cookie on first entry
    persistUTMParamsOnce(currentUTM);

    // Retrieve all persisted UTM parameters
    const persistedUTM = getPersistedUTMParams();

    // Append UTM parameters to the URL if they're not already present
    const currentSearchParams = new URLSearchParams(location.search);
    let isModified = false;

    Object.entries(persistedUTM).forEach(([key, value]) => {
      if (value && !currentSearchParams.has(key)) {
        currentSearchParams.set(key, value);
        isModified = true;
      }
    });

    if (isModified) {
      history.replace(`${location.pathname}?${currentSearchParams.toString()}`);
    }
  }, [location, history, auth.isLoggedIn]);

  return <>{children}</>;
};

export default UTMMiddleware;
