import { ROUTE as GET_ROUTE, Result as GetResult } from 'api/news/website/get/params';
import { ApiResponse } from 'src/types/api-response';
import api from '..';

export const news = api.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query<ApiResponse<GetResult>, void>({
      query: () => ({
        method: 'GET',
        url: `${GET_ROUTE}`,
      }),
      providesTags: (result) =>
        result?.success && result.data ? [{ type: 'NEWS', id: 'ALL' }] : [{ type: 'NEWS', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
