import { createAsyncThunk } from '@reduxjs/toolkit';

import { ROUTE as AUTH_USER_ME, Params as MeParams } from 'api/auth/user/me/params';
import { SignInSignUpResult as AuthResult } from 'api/auth/user/types';
import { ApiResponse } from 'src/types/api-response';

import { api } from 'utils/api';
import reduxApi from '../../api';

export const me = createAsyncThunk<ApiResponse<AuthResult>, MeParams, { rejectValue: string }>(
  'user/me',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.sendRequest<AuthResult>({ url: AUTH_USER_ME, method: 'GET' });

      if (response.success) {
        dispatch(reduxApi.util.invalidateTags(['MANDATE']));

        return response;
      }

      throw new Error(response.error.message as string);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
