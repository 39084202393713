import React from 'react';
import { prettifyValue } from './helpers';
import { useTranslation } from 'client/hooks';

import css from './OffersPopup.module.scss';

interface Props {
  minAmount: number;
  onBackClick: () => unknown;
  getAmount: (am: number) => void;
}

export const InputSection: React.FC<Props> = (props) => {
  const { getAmount, onBackClick, minAmount } = props;
  const [inputValue, setInputValue] = React.useState('');
  const { translate: _t } = useTranslation('popups');

  React.useEffect(() => {
    if (+inputValue > minAmount || inputValue === '') getAmount(+inputValue);
  }, [inputValue]);

  return (
    <div className={css.inputSection}>
      <button type="button" className={css.backButton} onClick={onBackClick}>
        {_t('offersPopup.backBtn')}
      </button>
      <p className={css.subTitle}>{_t('offersPopup.inputTitle')}</p>
      <div className={css.inputWrap}>
        <input type="number" pattern="\d+" onChange={(e) => setInputValue(e.target.value)} />
        {!inputValue && (
          <span className={css.placeholder}>
            {`${prettifyValue(minAmount)}`}
            <span className={css.smaller}>{_t('offersPopup.inputPlaceholder')}</span>
          </span>
        )}
        {inputValue && +inputValue < minAmount && <p className={css.error}>{_t('offersPopup.inputError')}</p>}
      </div>
    </div>
  );
};
