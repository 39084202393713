import * as yup from 'yup';
import { StepInput } from 'utils/onboarding/stage';
import { InputType } from 'utils/onboarding/input-types';
import { InputValue, Onboarding } from 'models/user/onboarding';

export const initialValues = (fieldsInfo: Array<StepInput<keyof Onboarding['form']>>) => {
  const initialValues = fieldsInfo.reduce((acc, step) => {
    acc[step.name] = getInitialValue(step);

    return acc;
  }, {} as Record<string, InputValue>);

  return initialValues;

  function getInitialValue(input: StepInput<keyof Onboarding['form']>) {
    if (input.type === InputType.TEXT || input.type === InputType.TEXT_AREA || input.type === InputType.DATE) {
      return (input.defaultValue || input.value) ?? null;
    }

    if (input.type === InputType.CHECKBOX) {
      return input.value ?? null;
    }

    if (input.type === InputType.AUTO_COMPLETE) {
      if (Array.isArray(input.value) && input && input?.value.length) {
        return input.value ?? null;
      } else {
        return input.defaultValue ?? null;
      }
    }

    if (input.type === InputType.MEDIA) {
      return input.value ?? null;
    }

    if (input.type === InputType.ARRAY) {
      return input.value ?? input.defaultValue ?? null;
    }

    return null;
  }
};
