import React from 'react';
import cn from 'classnames';
import Select from 'react-select';
import { useTranslation, useAppDispatch } from 'client/hooks';
import Icon from '../../../Icon';
import Button from '../../../Button';
import countries from 'utils/countries.json';
import { Step } from '../index';
import { setPopup } from 'client/redux/ui/index';

import css from './SelectStep.module.scss';

interface Props {
  setCountry: (country: string) => void;
  stepHandler: (step: Step) => void;
}

const SelectStep = ({ setCountry, stepHandler }: Props) => {
  const { translate: popupT } = useTranslation('popups');
  const [selectedCountry, setSelectedCountry] = React.useState<string>();
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleSelect = (country: string) => {
    setSelectedCountry(country);
    setIsActive(false);
  };

  const handeleClickSubmit = () => {
    if (selectedCountry) {
      setCountry(selectedCountry);
      stepHandler('confirmation');
    }
  };

  const clickOnClose = () => {
    dispatch(setPopup(null));
  };

  return (
    <div className={css.stepWrapper}>
      <div className={css.closeBtn} onClick={clickOnClose}></div>
      <div className={css.warpper}>
        <div className={css.topText}>
          <p>{popupT('joinAsInvestor.selectStep.title')}</p>
          <p>{popupT('joinAsInvestor.selectStep.subtitle')}</p>
        </div>
        <div className={css.selectWrapper}>
          <div className={css.title}>
            <Icon type="globe" className={css.globeIcon} />
            {popupT('joinAsInvestor.selectStep.selectTitle')}
          </div>
          <div className={cn(css.select, isActive && css.active)}>
            <Select
              options={countries}
              id="countries-select"
              instanceId="countries-select"
              placeholder={popupT('joinAsInvestor.selectStep.selectPlaceholder')}
              components={{ DropdownIndicator: () => <div className={css.dropdownIndicator}></div> }}
              onChange={(e) => {
                if (e && e.value) {
                  handleSelect(e.value);
                }
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  flexWrap: 'nowrap',
                  height: '48px',
                  border: state.isFocused ? 'none' : 'none',
                  borderColor: state.isFocused ? '#transparent' : '#transparent',
                  boxShadow: 'none',
                  borderBottom: `solid 1px rgba(255, 255, 255, 0.4)`,
                  backgroundColor: `rgba(255, 255, 255, 0.07)`,
                  borderRadius: '0px',
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                }),
                valueContainer: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: '0 20px 0 12px',
                  fontSize: '16px',
                  letterSpacing: '-0.18px',
                  fontWeight: 400,
                  color: 'rgba(255, 255, 255, 0.87)',
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '16px',
                  letterSpacing: '-0.18px',
                  fontWeight: 400,
                  color: 'rgba(255, 255, 255, 0.87)',
                }),
                input: (baseStyles, state) => ({
                  ...baseStyles,
                  color: '#fff',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '150px',
                  backgroundColor: 'rgba(17, 17, 17, 0.97)',
                  overflow: 'auto',
                }),
                menuList: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '150px',
                  backgroundColor: '#transparent',
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: state.isFocused || state.isSelected ? '#444' : '#transparent',
                  fontSize: '16px',
                  letterSpacing: '-0.18px',
                  fontWeight: 400,
                  color: '#fff',
                }),
                noOptionsMessage: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '16px',
                  letterSpacing: '-0.18px',
                  fontWeight: 400,
                  color: '#fff',
                }),
              }}
            />
          </div>
        </div>

        <Button className={css.joinButton} size="large" disabled={!selectedCountry} onClick={handeleClickSubmit}>
          {popupT('joinAsInvestor.selectStep.joinButton')}
        </Button>

        <div className={css.disclaimer}>{popupT('joinAsInvestor.selectStep.disclaimer')}</div>
      </div>
    </div>
  );
};

export default SelectStep;
