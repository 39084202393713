import * as yup from 'yup';

import { IModel } from 'models/company/interface';
import { Params as PaginationParams, Result as PaginationResult } from 'models/pagination';
import { VALIDATION_ERROR } from 'endpoints/index';
import * as routes from 'api/routes';

export type Company = Pick<
  IModel,
  | 'name'
  | 'mainImage'
  | 'decorImage'
  | 'rating'
  | 'avatarVideo'
  | 'subTitle'
  | 'shortDescription'
  | 'order'
  | 'team'
  | 'categoryIds'
> & {
  _id: string;
  categories: Array<{ _id: string; slug: string; name: string }>;
};

export type Params = PaginationParams<Company>;
export type Result = PaginationResult<Company>;

export const paramsSchema = yup.object().shape({
  search: yup.string().optional(),
  limit: yup.number().min(1).required(VALIDATION_ERROR.REQUIRED),
  offset: yup.number().min(0).required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.COMPANY_WEBSITE_PAGINATE;
