import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { useAnalytics, useResponsive } from 'client/hooks';
import { Icon, VideoJSPlayer } from 'src/routes/client/components/common';

import VideoListItem from './VideoListItem';

import css from './VideoGallery.module.scss';

interface Props {
  className?: string;
  videos: { title: string; file: string }[];
  mandateId?: string;
  onClose?: () => unknown;
}

export const VideoGallery: React.FC<Props> = ({ onClose, mandateId, videos }) => {
  const mainTitleRef = React.useRef<HTMLParagraphElement>();
  const playerRef = React.useRef<HTMLDivElement>();
  const analytics = useAnalytics();
  const [currentVideo, setCurrentVideo] = React.useState<{ file: string; title: string }>({
    file: videos[0].file,
    title: videos[0].title,
  });
  const [playerH, setPlayerH] = React.useState(0);
  const [titleH, setTitleH] = React.useState(0);
  const [readyVideos, setReadyVideos] = React.useState<string[]>([]);
  const [isMainPlayerReady, setIsMainPlayerReady] = React.useState(false);
  const [mobileListH, setMobileListHeight] = React.useState(0);
  const [minPlayertH, setMinPlayertH] = React.useState(0);
  const [isMobile] = useResponsive(['MOBILE']);

  React.useEffect(() => {
    document.getElementsByTagName('body')[0].style.height = '100vh';
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';

    setTimeout(() => window.scrollTo(0, 0), 200);
    // TODO: something with resize
    // window.addEventListener('resize', onWindowResize);
    return () => {
      document.getElementsByTagName('body')[0].style.height = '100%';
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
      // window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  // const onWindowResize = () => {
  //   setTimeout(() => {
  //     if (mainTitleRef.current !== null && playerRef.current !== null) {
  //       const titleHeight = mainTitleRef?.current?.getBoundingClientRect().height as number;
  //       const playerHeight = playerRef.current?.children[0].children[0].children[0].clientHeight as number;
  //       console.info('resize', playerHeight, titleHeight);

  //       setTitleH(titleHeight);
  //       setPlayerH(playerHeight);
  //     }
  //   }, 1000);
  // };

  React.useEffect(() => {
    if (mainTitleRef !== null && playerRef !== null && !playerH && !titleH) {
      const titleHeight = mainTitleRef?.current?.getBoundingClientRect().height as number;
      const playerHeight = playerRef.current?.children[0].children[0].children[0].children[0].clientHeight as number;

      setPlayerH(playerHeight);
      setTitleH(titleHeight);

      setMobileListHeight(window.outerHeight - playerHeight - titleHeight - 60 - 56);
      // onWindowResize();
    }
  }, [mainTitleRef, playerRef]);

  React.useEffect(() => {
    if (playerH > 0 && titleH > 0) {
      setMinPlayertH(playerH - titleH);
    }
  }, [playerH, titleH]);

  const onCloseBtnClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={css.popup}>
      <div className={css.popupOverlay} />
      {/* {!isMobile && <div className={css.popupBg} style={{ backgroundImage: `url(${galleryBg})` }} />} */}
      <div className={css.popupContent}>
        <div
          className={cn(css.mainPlayerWrap, !isMainPlayerReady && css.isLoading)}
          style={!isMobile && minPlayertH > 0 ? { maxHeight: `${minPlayertH}px` } : {}}
        >
          <div className={css.mainPlayer} ref={(el: HTMLDivElement) => (playerRef.current = el)}>
            <VideoJSPlayer
              id={'gallery-player'}
              videoSRC={currentVideo.file}
              isDefaultControls
              onReady={() => setIsMainPlayerReady(true)}
              playButtonClassName={css.mainPlayerPlayIcon}
            />
          </div>
          <p
            ref={(el: HTMLParagraphElement) => (mainTitleRef.current = el)}
            className={css.currentTitle}
            style={{ top: minPlayertH > 0 ? `${minPlayertH}px` : '' }}
          >
            {currentVideo.title}
          </p>
        </div>
        <div
          className={css.list}
          data-top={`${playerH + titleH}px`}
          style={isMobile ? { height: `${mobileListH}px` } : {}}
        >
          {_.map(videos, (vid) => {
            return (
              <div
                key={vid.file}
                className={cn(css.listItem, !readyVideos.includes(vid.file) && css.isLoading)}
                onClick={() => setCurrentVideo(vid)}
              >
                <VideoListItem
                  videoSRC={vid.file}
                  id={vid.file}
                  isPlaying={vid.file === currentVideo.file}
                  onReady={(id) => setReadyVideos((state) => [...state, id])}
                />
                <p className={css.listTitle}>{vid.title}</p>
              </div>
            );
          })}
        </div>
      </div>

      <button className={css.closeBtn} onClick={onCloseBtnClick}>
        <Icon type="close" />
      </button>
    </div>
  );
};
