import {
  ROUTE as UPDATE_ROUTE,
  Params as UpdateParams,
  Result as UpdateResult,
} from 'api/video_statistics/update/params';
import { ApiResponse } from 'src/types/api-response';

import api from '..';

export const videoStatistics = api.injectEndpoints({
  endpoints: (builder) => ({
    updateVideoStatistics: builder.mutation<ApiResponse<UpdateResult>, UpdateParams>({
      query: (params) => ({
        method: 'POST',
        url: UPDATE_ROUTE,
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});
