import * as yup from 'yup';

import { Onboarding } from 'models/user/onboarding';

import { configureStep, filterDependencies, getStageCurrentStepName, getStageStep } from '../stage';
import { InputType } from '../input-types';
import { VALIDATION_ERROR } from 'endpoints/index';

/**
 * Returns accreditation questionnaire NDA form fields
 */
export function getNDA(data: Onboarding) {
  const t = (path: string) => `questionnaire.accreditationQuestionnaire.${path}`;

  return {
    steps: filterDependencies(
      [
        configureStep(
          {
            type: InputType.TEXT,
            name: 'signatureId',
            label: t('nda.signatureId.label'),
            schema: yup.string().required(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'signatureStatus',
            label: t('nda.signatureStatus.label'),
            schema: yup.string().required(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.DATE,
            name: 'signatureDate',
            label: t('nda.signatureDate.label'),
            schema: yup.date().typeError(`${VALIDATION_ERROR.REQUIRED}`).required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
      ],
      data,
    ),
  };
}

export function getCurrentStep(data: Onboarding) {
  const currentStepName = getStageCurrentStepName(getNDA(data).steps);
  return currentStepName ? getStep(data, currentStepName) : null;
}

export function getStep(data: Onboarding, targetStep: string) {
  return getStageStep(getNDA(data).steps, targetStep);
}
