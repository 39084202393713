import React, { useState, useRef, useEffect } from 'react';
import { useField } from 'formik';
import cn from 'classnames';

import { MediaType } from 'client/types';
import Icon from 'client/components/common/Icon';
import Error from '../../../Error';

import css from './UploadMedia.module.scss';
interface Props {
  name: string;
  accept?: string;
  value?: MediaType;
  fakeBtn: string;
  acceptText: string;
}

const UploadMedia = (props: Props) => {
  const { name, accept = '', fakeBtn, acceptText } = props;
  const [media, setMedia] = useState<MediaType>(props.value);
  const inputRef = useRef<HTMLInputElement>(null);
  const [field, meta, helpers] = useField(name);
  const hasError = meta.error && meta.touched;
  const errorMsg = !!(meta.touched && meta.error) ? meta.error : '';
  const mediaType = getMediaType(media, inputRef.current);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    if (e.currentTarget.files?.[0]) {
      reader.onloadend = () => setMedia(reader.result);
      reader.readAsDataURL(e.currentTarget.files[0]);

      helpers.setValue(e.currentTarget.files[0]);
    }
  };

  useEffect(() => {
    setMedia(props.value);
  }, [props.value]);

  const mediaName = () => {
    let name = '';

    if (typeof field.value === 'string') {
      name = field.value.split('?')[0].split('/').pop() || '';
    } else {
      name = field.value.name || '';
    }

    return name;
  };

  const onClearBtnClick = () => {
    setMedia(null);
    helpers.setValue(null);
    helpers.setTouched(true);
  };

  return (
    <div className={cn(css.mediaWrapper, hasError && css.error)}>
      <label>
        <input type="file" name={name} onChange={onChange} ref={inputRef} accept={accept} />
        <div className={css.fakeBtn}>{fakeBtn}</div>
        <div className={css.acceptFilesText} dangerouslySetInnerHTML={{ __html: acceptText }}></div>
      </label>
      {media && (
        <div className={css.file}>
          <div className={css.fileWrapper}>
            {mediaName()}
            <div className={css.trashBtn} onClick={onClearBtnClick}>
              <Icon type="trash" />
            </div>
          </div>
          <div className={css.bluredBlock}></div>
        </div>
      )}
      {errorMsg && meta.touched && (
        <div className={css.errorWrapper}>
          <Error name={name} error={errorMsg} />
        </div>
      )}
    </div>
  );
};

const getMediaType = (media: MediaType, ref: HTMLInputElement | null) => {
  let result: 'video' | 'image' | 'file' = 'image';

  if (typeof media === 'string') {
    if (media.includes('.mp4')) {
      result = 'video';
    }
    if (media.includes('.jpeg') || media.includes('.jpg') || media.includes('.png')) {
      result = 'image';
    }
    if (media.includes('.pdf')) {
      result = 'file';
    }
  }

  if (ref?.files && ref.files[0]) {
    const fileName = ref.files[0].name.toLowerCase();

    if (fileName.includes('.mp4') || fileName.includes('.mov')) {
      result = 'video';
    }

    if (fileName.includes('.jpeg') || fileName.includes('.jpg') || fileName.includes('.png')) {
      result = 'image';
    }

    if (fileName.includes('.pdf')) {
      result = 'file';
    }
  }
  return result;
};

export default UploadMedia;
