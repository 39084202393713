import cn from 'classnames';
import React from 'react';

import { Icon } from 'client/components/common';
import { update } from 'client/redux/user/actions';
import { selectUserId } from 'client/redux/user/selectors';
import { useAppDispatch, useAppSelector, useTranslation } from 'client/hooks';

import css from './AvatarUploader.module.scss';

interface Props {
  className?: string;
  imageSrc: string;
}

export const AvatarUploader: React.FC<Props> = (props) => {
  const { className = '', imageSrc } = props;
  const dispatch = useAppDispatch();
  const { translate } = useTranslation();
  const userId = useAppSelector(selectUserId);
  const [media, setMedia] = React.useState<string>(imageSrc);
  const [error, setError] = React.useState<string | null>(null);

  const onAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError(null);

    const file = e.currentTarget.files?.[0];
    const reader = new FileReader();
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1Mb

    if (file) {
      if (file.size <= MAX_FILE_SIZE) {
        reader.onloadend = () => setMedia(reader.result as string);
        reader.readAsDataURL(file);

        dispatch(update({ pictureUrl: file, id: userId }));
      } else {
        setError(translate('errors.fileIsToBigWithValue', { maxSize: '1Mb' }));
      }
    }
  };

  return (
    <label className={cn(css.avatarUploader, className)}>
      <div className={css.content}>
        <img src={media} />
        <Icon className={css.icon} type="edit" />
        <div className={css.overlay} />

        <input accept="image/png, image/jpeg, image/jpg" type="file" onChange={onAvatarChange} />
      </div>
      {error && <div className={css.error}>{error}</div>}
    </label>
  );
};
