import React from 'react';
import cn from 'classnames';
import css from './Button.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'medium' | 'large';
  width?: number;
  className?: string;
  isLoading?: boolean;
}

const Button = (props: Props) => {
  const { className = '', children, size = 'small', width, onClick, ...rest } = props;

  const sizeClassName = (size: 'small' | 'medium' | 'large') => {
    switch (size) {
      case 'small':
        return css.small;
      case 'medium':
        return css.medium;
      case 'large':
        return css.large;
    }
  };

  return (
    <button
      className={cn(css.button, sizeClassName(size), className)}
      style={width ? { width: `${width}px` } : {}}
      onClick={onClick}
      {...rest}
    >
      <span className={css.buttonInner}>{children}</span>
    </button>
  );
};

export default Button;
