import React from 'react';
import cn from 'classnames';
import { FormikProvider, Form, useFormik } from 'formik';
import * as yup from 'yup';
import DOMPurify from 'dompurify';
import { useTranslation } from 'client/hooks';

import { InputValue } from 'models/user/onboarding';
import { TextAreaInput } from 'utils/onboarding/input-types';

import Title from '../../Title';
import ExplanationSubtitle from '../../ExplanationSubtitle';
import ContinueButton from '../../ContinueButton';
import css from './LongTextField.module.scss';

interface Props {
  info: TextAreaInput;
  onSubmitHandler: (value: Record<string, InputValue>) => void;
  isSkippable?: boolean;
}
const LongTextField = ({ info, onSubmitHandler, isSkippable = false }: Props) => {
  const { translate } = useTranslation();
  const [shouldRender, setShouldRender] = React.useState(false);
  const [isSubmiting, setIsSubmiting] = React.useState(false);
  const initialValues = {
    [`${info.name}`]: (info.defaultValue as string) || (info.value as string) || '',
  };

  React.useEffect(() => {
    setShouldRender(true);
  }, []);

  const formikContext = useFormik<Record<string, string>>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({ [`${info.name}`]: info.schema }),
    onSubmit: (values) => {
      setIsSubmiting(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSubmitHandler({ [`${info.name}`]: values[`${info.name}`] });
    },
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // if (valuePattern) {
    //   if (e.target.value === '' || valuePattern.test(e.target.value.toLocaleLowerCase())) {
    //     helpers.setValue(e.target.value);
    //   }
    // } else {
    //   helpers.setValue(e.target.value);
    // }
    const value = DOMPurify.sanitize(e.target.value);
    formikContext.setFieldValue(info.name, value);
  };

  if (!shouldRender) return null;

  return (
    <FormikProvider value={formikContext}>
      <Form className={css.container}>
        <div className={css.containerI}>
          {isSkippable && <p className={css.nonMandatory}>{translate('questionnaire.nonMandatoryText')}</p>}
          <Title text={translate(info.label)} size="m" />
          {info.explanation && <ExplanationSubtitle text={translate(info.explanation)} />}
          <div className={css.textarea}>
            <textarea
              className={cn(css.formTextarea)}
              placeholder={translate('questionnaire.placeholders.longText')}
              value={formikContext.values[`${info.name}`]}
              onChange={onChangeHandler}
            ></textarea>
          </div>
        </div>
        <div className={css.continueBtnWrapper}>
          <ContinueButton disabled={!formikContext.values[`${info.name}`] || isSubmiting} />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default LongTextField;
