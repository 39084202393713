import * as yup from 'yup';

import * as routes from 'api/routes';
import { IModel } from 'models/hubspot/interface';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = Omit<IModel, 'started_the_questionnaire' | 'lastname' | 'date_of_birth' | 'country' | 'phone'>;
export type Result = void;

export const paramsSchema = yup.object().shape({
  email: yup.string().required(VALIDATION_ERROR.REQUIRED),
  firstname: yup.string().required(VALIDATION_ERROR.REQUIRED),
  cic_platform_user_id: yup.string().required(VALIDATION_ERROR.REQUIRED),
  self_declare_residency: yup.string().required(VALIDATION_ERROR.REQUIRED),
  self_declare_date: yup.string().required(VALIDATION_ERROR.REQUIRED),
  investor_status_level: yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.HUBSPOT_CONTACT_WEBSITE_CREATE;
