import React, { useState } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import DOMPurify from 'dompurify';

import Error from '../../Error';
import _ from 'lodash';

import css from './TextField.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  valuePattern?: RegExp;
  label?: string | (() => React.ReactNode);
  autoComplete?: string;
  isDimBorder?: boolean;
}

const TextFieldInput: React.FC<Props> = (props) => {
  const {
    className = '',
    name = '',
    type = 'text',
    valuePattern,
    label = '',
    autoComplete = '',
    isDimBorder = false,
    ...inputProps
  } = props;
  const [field, meta, helpers] = useField(name);
  const [currentType, setCurrentType] = React.useState(type);
  const errorMsg = !!(meta.touched && meta.error) ? meta.error : '';
  const [isFocused, setIsFocused] = useState(false);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (valuePattern) {
      if (e.target.value === '' || valuePattern.test(e.target.value.toLocaleLowerCase())) {
        const value = DOMPurify.sanitize(e.target.value);
        helpers.setValue(value);
      }
    } else {
      const value = DOMPurify.sanitize(e.target.value);
      helpers.setValue(value);
    }
  };

  const fieldProps = {
    ...field,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeHandler(event);
    },
    onFocus: () => {
      setIsFocused(true);
    },
    onReset: () => {
      console.info('reset');
    },
    onBlur: () => {
      if (!field.value) {
        setIsFocused(false);
      }
    },
  };

  const onToggleBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCurrentType((prev) => (prev === 'text' ? 'password' : 'text'));
  };

  return (
    <div className={classNames(css.container, meta.error && meta.touched && css.isError, className)}>
      <div className={classNames(css.inputWrapper, isFocused && css.focused)}>
        {label ? <p className={css.label}>{typeof label === 'string' ? label : label()}</p> : null}
        <div className={css.input}>
          <input
            type={currentType}
            autoCapitalize="none"
            className={classNames(css.formInput, type === 'password' && css.password, isDimBorder && css.dimBorder)}
            {...inputProps}
            {...fieldProps}
            onChange={onChangeHandler}
            autoComplete={autoComplete || 'off'}
          />
          {type === 'password' && field.value && (
            <button type="button" onClick={onToggleBtnClick} className={css.toggleTypeBtn}>
              {currentType === 'password' ? 'Show' : 'Hide'}
            </button>
          )}
        </div>
      </div>

      {errorMsg && meta.touched && <Error name={name} error={errorMsg} />}
    </div>
  );
};

export default TextFieldInput;
