import { WEBSITE_ROUTE as GET_ROUTE, Result as GetResult } from 'api/faq/get/params';
import { ApiResponse } from 'src/types/api-response';
import api from '..';

export const faq = api.injectEndpoints({
  endpoints: (builder) => ({
    getFaq: builder.query<ApiResponse<GetResult>, void>({
      query: () => ({
        method: 'GET',
        url: `${GET_ROUTE}`,
      }),
      providesTags: (result) =>
        result?.success && result.data ? [{ type: 'FAQ', id: 'ALL' }] : [{ type: 'FAQ', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
