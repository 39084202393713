import * as yup from 'yup';

import * as routes from 'api/routes';
import { IModel } from 'models/contact_us/interface';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = Omit<IModel, '_id'>;
export type Result = void;

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape(
  {
    name: yup.string().required(VALIDATION_ERROR.REQUIRED),
    message: yup.string().max(150).required(VALIDATION_ERROR.REQUIRED),
    email: yup.string().email(VALIDATION_ERROR.INVALID_EMAIL).required(VALIDATION_ERROR.REQUIRED),
    contactingAs: yup.string().required(VALIDATION_ERROR.REQUIRED),
    phone: yup.string().min(8, VALIDATION_ERROR.AT_LEAST_SOME_NUMBER_OF_DIGITS).required(VALIDATION_ERROR.REQUIRED),
  },
  [['phone', 'phone']],
);

export const ROUTE = routes.CONTACT_US_CREATE;
