import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { useTranslation } from 'client/hooks';
import css from './OffersPopup.module.scss';

interface Props {
  options: { amount: string; label: string }[];
  onInputSectionOpen: () => unknown;
  getAmount: (am: number) => void;
}

export const OptionsSection: React.FC<Props> = (props) => {
  const { options, getAmount, onInputSectionOpen } = props;
  const [selectedOption, setSelectedOption] = React.useState('');

  const { translate: _t } = useTranslation('popups');

  React.useEffect(() => {
    getAmount(+selectedOption);
  }, [selectedOption]);

  const onOtherOptionClick = () => {
    onInputSectionOpen();
    setSelectedOption('');
  };

  return (
    <>
      <p className={css.subTitle}>{_t('offersPopup.optionsTitle')}</p>
      <div className={css.investOptions}>
        {_.map(options, (opt) => {
          return (
            <button
              type="button"
              key={opt.label}
              className={cn(css.option, selectedOption === opt.amount && css.selected)}
              onClick={() => setSelectedOption(opt.amount)}
            >
              {opt.label}
            </button>
          );
        })}
        <button type="button" className={css.option} onClick={onOtherOptionClick}>
          {_t('offersPopup.otherBtn')}
        </button>
      </div>
    </>
  );
};
