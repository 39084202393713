import * as yup from 'yup';

import * as routes from 'api/routes';
import { IModel } from 'models/user/interface';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = Partial<
  Pick<IModel, 'name'> & {
    pictureUrl: File | string;
  }
>;
export type Result = IModel | null;

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape(
  {
    name: yup.string().when('name', {
      is: (value: string) => !Boolean(value),
      then: (schema) => schema.optional(),
      otherwise: (schema) => schema.min(3, VALIDATION_ERROR.TOO_SHORT).required(VALIDATION_ERROR.REQUIRED),
    }),
    pictureUrl: yup
      .mixed()
      .test('mimetype', 'UNSUPPORTED_FILE_FORMAT', (value) => {
        return value && typeof value === 'object' && value.size !== undefined
          ? ['image/png', 'image/jpeg', 'image/jpg'].includes(value.mimetype || value.type)
          : true;
      })
      .test('size', 'FILE_IS_TOO_LARGE', (value) => {
        return value && typeof value === 'object' ? value.size <= 1 * 1024 * 1024 : true;
      }),
  },
  [['name', 'name']],
);

export const ROUTE = routes.USERS_WEBSITE_UPDATE;
