import React from 'react';
import _ from 'lodash';
import DOMPurify from 'dompurify';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'client/hooks';
import { Company } from 'api/companies/public/paginate/params';
import { companies as companiesApi } from 'client/redux/api/companies';
import { CompanyVideoSlide, Loader, Button } from 'client/components/common';

import css from './SearchResults.module.scss';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ITEMS_PER_PAGE = 20;

const SearchResults = () => {
  const history = useHistory();
  const location = useLocation();
  const { translate } = useTranslation();
  const query = useQuery();
  const [page, setPage] = React.useState<number>(0);
  const [activeSlide, setActiveSlide] = React.useState<string>('');
  const [foundItems, setFoundItems] = React.useState<Company[]>([]);
  const isRendered = React.useRef(false);

  const {
    data,
    isFetching: isFetchingCompanies,
    isLoading: isLoadingCompanies,
    status,
  } = companiesApi.endpoints.paginateCompanies.useQuery({
    sort: undefined,
    limit: ITEMS_PER_PAGE,
    search: DOMPurify.sanitize(query.get('search') || ''),
    offset: page * ITEMS_PER_PAGE,
  });

  const clickOnViewMore = () => {
    setPage(page + 1);
  };

  React.useEffect(() => {
    if (data?.success) {
      if (!data.data.page.total) {
        setFoundItems([]);
        setPage(0);
      } else {
        setFoundItems([...foundItems, ...data.data.items]);
      }
    }
  }, [data]);

  React.useEffect(() => {
    setPage(0);
    if (isRendered.current) {
      setFoundItems([]);
    }
    if (!isRendered.current) {
      isRendered.current = true;
    }
  }, [location]);

  return (
    <div className={css.searchResults}>
      <div className={css.contentWrapper}>
        <div className={css.backWrapper}>
          <button type="button" className={css.backButton} onClick={() => history.goBack()}>
            {`←${translate('searchResultsPage.backBtn')}`}
          </button>
        </div>
        {isLoadingCompanies ? (
          <div className={css.loader}>
            <Loader position="absolute" className={css.mainLoader} />
          </div>
        ) : (
          <>
            <div className={css.searchedWrapper}>
              <div className={css.infoWrapper}>
                <div className={css.serchString}>{`“${DOMPurify.sanitize(query.get('search') || '')}”`}</div>
                {data?.success && data.data.page.total && (
                  <div className={css.totalResults}>
                    {data?.success && data.data.page.total} {translate('searchResultsPage.totalResults')}
                  </div>
                )}
              </div>
              <div className={css.resultsWrapper}>
                {data?.success && data.data.page.total && (
                  <div className={css.companies}>
                    {_.map(foundItems, (company: Company, index) => (
                      <div className={css.company} key={company._id}>
                        <CompanyVideoSlide
                          activeId={activeSlide}
                          data={company}
                          setActiveSlide={(id) => setActiveSlide(id)}
                          index={index + 1}
                          companiesLength={foundItems.length}
                        />
                      </div>
                    ))}
                  </div>
                )}

                {data?.success && !data.data.page.total && (
                  <p
                    className={css.noResultsText}
                    dangerouslySetInnerHTML={{
                      __html: translate('searchResultsPage.noResults.title', {
                        name: `“${DOMPurify.sanitize(query.get('search') || '')}”`,
                      }),
                    }}
                  ></p>
                )}
              </div>
              {data?.success && foundItems.length < data.data.page.total && status === 'fulfilled' && (
                <div className={css.viewMoreWrapper}>
                  <Button onClick={clickOnViewMore} className={css.viewMoreBtn}>
                    {isFetchingCompanies && <Loader position="absolute" className={css.companiesLoader} />}
                    <span>{translate('commonButtons.loadMore')}</span>
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
