import * as yup from 'yup';
import YupPassword from 'yup-password';

import * as routes from 'api/routes';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = { name: string; email: string; password: string; confirmPassword: string };

YupPassword(yup);

const commonSchema = yup.object().shape({
  email: yup.string().email(VALIDATION_ERROR.INVALID_EMAIL).required(VALIDATION_ERROR.REQUIRED),
  password: yup
    .string()
    .min(8, VALIDATION_ERROR.TOO_SHORT)
    .required(VALIDATION_ERROR.REQUIRED)
    .minLowercase(1, VALIDATION_ERROR.AT_LEAST_ONE_LOWER_LETTER)
    .minUppercase(1, VALIDATION_ERROR.AT_LEAST_ONE_UPPER_LETTER)
    .minNumbers(1, VALIDATION_ERROR.AT_LEAST_ONE_NUMBER),
  confirmPassword: yup
    .string()
    .min(8, VALIDATION_ERROR.TOO_SHORT)
    .required(VALIDATION_ERROR.REQUIRED)
    .minLowercase(1, VALIDATION_ERROR.AT_LEAST_ONE_LOWER_LETTER)
    .minUppercase(1, VALIDATION_ERROR.AT_LEAST_ONE_UPPER_LETTER)
    .minNumbers(1, VALIDATION_ERROR.AT_LEAST_ONE_NUMBER)
    .oneOf([yup.ref('password')], VALIDATION_ERROR.PASSWORDS_MISMATCH),
});

export const paramsSchema = commonSchema.shape({
  name: yup
    .string()
    .matches(/^[a-zA-Z\xC0-\uFFFF]+([ \-']{0,1}[a-zA-Z\xC0-\uFFFF]+){0,2}[.]{0,1}$/, {
      message: VALIDATION_ERROR.INVALID_VALUE,
      excludeEmptyString: true,
    })
    .required(VALIDATION_ERROR.REQUIRED),
});

export const emailAndPassSignUpParamsSchema = commonSchema.shape({
  firstName: yup
    .string()
    .matches(/^[a-zA-Z \xC0-\uFFFF]+([ \-']{0,1}[a-zA-Z \xC0-\uFFFF]+){0,2}[.]{0,1}$/, {
      message: VALIDATION_ERROR.INVALID_VALUE,
      excludeEmptyString: true,
    })
    .required(VALIDATION_ERROR.REQUIRED),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z \xC0-\uFFFF]+([ \-']{0,1}[a-zA-Z \xC0-\uFFFF]+){0,2}[.]{0,1}$/, {
      message: VALIDATION_ERROR.INVALID_VALUE,
      excludeEmptyString: true,
    })
    .required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.SIGN_UP_USER_WITH_EMAIL;
