import qs from 'qs';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { ROUTE as GET_ROUTE, Params as GetParams, Result as GetResult } from 'api/companies/public/get/params';
import {
  ROUTE as PAGINATE_ROUTE,
  Params as PaginationParams,
  Result as PaginationResult,
} from 'api/companies/public/paginate/params';
import {
  Params as GetCompanyWithCategoryOrderParams,
  Result as ResultGetCompanyWithCategoryOrder,
  ROUTE as GET_COMPANY_WITH_CATEGORY,
} from 'api/companies/public/paginate-with-cat-order/params';

import {
  Params as UpdateRatingParams,
  Result as UpdateRatingResult,
  ROUTE as UPDATE_RATING,
} from 'api/companies/public/update-rating/params';

import {
  ROUTE as GET_REPORTS_ROUTE,
  Result as GetReportsResult,
} from 'api/companies/reports/website/get-reports-by-user-investments/params';

import { ApiResponse } from 'src/types/api-response';
import { addToVoted } from 'client/redux/user';

import { main as mainPageSlidersApi } from '../main';
import api from '..';

export const companies = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompany: builder.query<ApiResponse<GetResult>, GetParams>({
      query: (params) => ({
        method: 'GET',
        url: GET_ROUTE.replace(':id', params.id),
      }),
      providesTags: (result) => (result?.success ? [{ type: 'COMPANY', id: 'ALL' }] : [{ type: 'COMPANY', id: 'ALL' }]),
    }),
    getCompanyWithCategoryOrder: builder.query<
      ApiResponse<ResultGetCompanyWithCategoryOrder>,
      GetCompanyWithCategoryOrderParams
    >({
      query: (params) => ({
        method: 'GET',
        url: `${GET_COMPANY_WITH_CATEGORY}?${qs.stringify(params)}`,
      }),
      providesTags: (result) =>
        result?.success
          ? [{ type: 'COMPANY_WITH_CATEGORY', id: 'ALL' }]
          : [{ type: 'COMPANY_WITH_CATEGORY', id: 'ALL' }],
    }),
    paginateCompanies: builder.query<ApiResponse<PaginationResult>, PaginationParams>({
      query: (params) => ({
        method: 'GET',
        url: `${PAGINATE_ROUTE}?${qs.stringify(params)}`,
      }),
      providesTags: (result) =>
        result?.success
          ? [
              ...result.data.items.map(({ _id }) => ({ type: 'COMPANY', id: _id.toString() } as const)),
              { type: 'COMPANY', id: 'ALL' },
            ]
          : [{ type: 'COMPANY', id: 'ALL' }],
    }),
    updateRating: builder.mutation<ApiResponse<UpdateRatingResult>, UpdateRatingParams>({
      query: (params) => ({
        method: 'POST',
        url: UPDATE_RATING,
        body: params,
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        dispatch(addToVoted(params.companyId));

        const relatedEndpoints = api.util.selectInvalidatedBy(getState(), [
          'COMPANY',
          'COMPANY_WITH_CATEGORY',
          'MAIN_PAGE_SLIDERS',
        ]);

        optimisticUpdateRating({ params, relatedEndpoints, dispatch });

        try {
          await queryFulfilled;
        } catch {
          dispatch(api.util.invalidateTags([{ type: 'COMPANY', id: 'ALL' }]));
        }
      },
    }),
    getReports: builder.query<ApiResponse<GetReportsResult>, any>({
      query: (params) => ({
        method: 'GET',
        url: `${GET_REPORTS_ROUTE}`,
      }),
      providesTags: (result) =>
        result?.success && result.data
          ? [
              ...result.data.map(({ _id }) => ({ type: 'REPORTS' as const, id: _id.toString() })),
              { type: 'REPORTS', id: 'ALL' },
            ]
          : [{ type: 'REPORTS', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});

type OptimisticUpdateRatingParams = {
  params: UpdateRatingParams;
  relatedEndpoints: {
    endpointName: string;
    originalArgs: any;
    queryCacheKey: string;
  }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, AnyAction>;
};
const optimisticUpdateRating = ({ params, relatedEndpoints, dispatch }: OptimisticUpdateRatingParams) => {
  relatedEndpoints.forEach((endpointInfo) => {
    if (endpointInfo.endpointName === 'paginateCompanies') {
      dispatch(
        companies.util.updateQueryData(endpointInfo.endpointName, endpointInfo.originalArgs, (draft) => {
          if (draft.success) {
            const targetCompanyIndex = draft.data.items.findIndex((c) => c._id === params.companyId);

            if (targetCompanyIndex >= 0) {
              draft.data.items[targetCompanyIndex].rating += 1;
            }
          }
        }),
      );
    }

    if (endpointInfo.endpointName === 'getMainPageSliders') {
      dispatch(
        mainPageSlidersApi.util.updateQueryData(endpointInfo.endpointName, endpointInfo.originalArgs, (draft) => {
          if (draft.success) {
            const { featuredSlider, heroSlider, investNowSlider, topWatchedSlider } = draft.data;
            const compInFeatureIndex = featuredSlider?.findIndex((c) => c._id === params.companyId) || -1;
            const compInHeroIndex = heroSlider?.findIndex((c) => c._id === params.companyId) || -1;
            const compInInvestNowIndex = investNowSlider?.findIndex((c) => c._id === params.companyId) || -1;
            const compInTopWatchedIndex = topWatchedSlider?.findIndex((c) => c._id === params.companyId) || -1;

            if (compInFeatureIndex >= 0 && featuredSlider?.[compInFeatureIndex])
              featuredSlider[compInFeatureIndex].rating += 1;
            if (compInHeroIndex >= 0 && heroSlider?.[compInHeroIndex]) heroSlider[compInHeroIndex].rating += 1;
            if (compInInvestNowIndex >= 0 && investNowSlider?.[compInInvestNowIndex])
              investNowSlider[compInInvestNowIndex].rating += 1;
            if (compInTopWatchedIndex >= 0 && topWatchedSlider?.[compInTopWatchedIndex])
              topWatchedSlider[compInTopWatchedIndex].rating += 1;
          }
        }),
      );
    }

    if (endpointInfo.endpointName === 'getCompany') {
      dispatch(
        companies.util.updateQueryData(endpointInfo.endpointName, endpointInfo.originalArgs, (draft) => {
          if (draft.success && draft.data) {
            draft.data.rating += 1;
          }
        }),
      );
    }

    if (endpointInfo.endpointName === 'getCompanyWithCategoryOrder') {
      dispatch(
        companies.util.updateQueryData(endpointInfo.endpointName, endpointInfo.originalArgs, (draft) => {
          if (draft.success) {
            const targetCompanyIndex = draft.data.items.findIndex((c) => c._id === params.companyId);

            if (targetCompanyIndex >= 0) {
              draft.data.items[targetCompanyIndex].rating += 1;
            }
          }
        }),
      );
    }
  });
};
