import React from 'react';
import cn from 'classnames';
import { useResponsive, useTranslation, useAppSelector } from 'client/hooks';
import { SlideDown } from 'react-slidedown';

import { faq as faqApi } from 'client/redux/api/faq';
import { LOCALE, FAQDATA } from 'client/types';

import css from './FaqSection.module.scss';

const searchRegExp = /&#x27;/gi;

interface Props {
  data: FAQDATA;
}

const FaqSection = (props: Props) => {
  const { data } = props;
  const [isMobile] = useResponsive(['MOBILE']);
  const { translate } = useTranslation();
  const [activeFaq, setActiveFaq] = React.useState<null | number>(null);

  const clickOnFaq = (e: React.MouseEvent<HTMLDivElement>) => {
    const { index } = e.currentTarget.dataset;
    if (index) {
      if (Number(index) === activeFaq) {
        setActiveFaq(null);
      } else {
        setActiveFaq(Number(index));
      }
    }
  };

  return (
    <div className={css.contentWrapper}>
      <div className={css.title}>{translate('faqPage.title')}</div>
      <div className={css.faqs}>
        {data.map((i, index) => {
          const itemData = i.value.find((locale) => locale.locale === LOCALE.EN);
          if (itemData) {
            return (
              <div className={cn(css.faq, index === activeFaq && css.active)} key={index}>
                <div className={css.name} data-index={index} onClick={clickOnFaq}>
                  <span>{itemData.question}</span>{' '}
                  <div className={css.btn} data-index={index} onClick={clickOnFaq}></div>
                </div>
                <SlideDown className={css.descriptionWrapper}>
                  {index === activeFaq ? (
                    <div
                      className={css.description}
                      dangerouslySetInnerHTML={{ __html: itemData.answer.replace(searchRegExp, `'`) }}
                    ></div>
                  ) : null}
                </SlideDown>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default FaqSection;
