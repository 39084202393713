import { ROUTE as GET_ROUTE, Result as GetResult, Params } from 'api/other/static-pages/get/params';
import { ApiResponse } from 'src/types/api-response';
import api from '..';

export const staticPages = api.injectEndpoints({
  endpoints: (builder) => ({
    getStaticPage: builder.query<ApiResponse<GetResult>, Params>({
      query: (params) => ({
        method: 'GET',
        url: `${GET_ROUTE}?target=${params.target}`,
      }),
      providesTags: (result) =>
        result?.success && result.data ? [{ type: 'STATIC_PAGES', id: 'ALL' }] : [{ type: 'STATIC_PAGES', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
