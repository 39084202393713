import React from 'react';
import _ from 'lodash';
import SwiperCore, { EffectFade, Swiper as SwiperType, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { videoStatistics as videoStatisticsApi } from 'client/redux/api/video-statistics';
import { VideoJSPlayer } from 'client/components/common';
import { useAnalytics } from 'client/hooks';

SwiperCore.use([EffectFade, Pagination]);

import css from './VideoSlider.module.scss';

type Props = {
  videos: string[];
  companyId: string;
  companyName: string;
};

const VideoSlider = ({ videos, companyId, companyName }: Props) => {
  const analytics = useAnalytics();
  const [swiperInstance, setSwiperInstance] = React.useState<SwiperType | null>(null);
  const [updateVideoStatistics] = videoStatisticsApi.endpoints.updateVideoStatistics.useMutation();

  const onVideoCompleted = () => {
    if (swiperInstance) {
      if (swiperInstance.activeIndex === videos.length - 1) {
        swiperInstance.slideTo(0);
      } else {
        swiperInstance.slideNext();
      }
    }
  };

  const onStatisticUpdate = async (st: { fileName: string; progress: number; duration: string }) => {
    const { fileName, duration, progress } = st;

    const videoID = decodeURIComponent(fileName).split('/')?.[4]?.split('.')?.[0] ?? 'N/A';

    analytics.gtag.event('video_play', {
      VideoProgress: `${progress}`,
      CompanyID: companyId,
      CompanyName: companyName,
      VideoID: videoID,
      TotalVideoLength: `${duration}`,
    });

    updateVideoStatistics({ fileName, progress });
  };

  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        effect={'fade'}
        allowTouchMove={false}
        noSwiping={false}
        pagination={{ el: '.custom-pagination', clickable: true }}
      >
        {_.map(videos, (video) => (
          <SwiperSlide key={`company_video_${video}`}>
            {({ isActive }) => {
              return (
                isActive && (
                  <div className={css.videoWrapper}>
                    <VideoJSPlayer
                      videoSRC={video}
                      onComplete={onVideoCompleted}
                      id={`company_video_${video}`}
                      onStatisticUpdate={(st) => onStatisticUpdate(st)}
                      isDefaultControls
                      isCenteredPlayBtn
                    />
                  </div>
                )
              );
            }}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="custom-pagination"></div>
    </>
  );
};

export default VideoSlider;
