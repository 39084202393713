import { createSelector } from 'reselect';

import { RootState } from '..';

export const selectUI = (state: RootState) => {
  return state.ui;
};

export const selectMenuState = createSelector(selectUI, (ui) => {
  return ui.menu;
});

export const selectRoutesHistory = createSelector(selectUI, (ui) => {
  return ui.routesHistory;
});

export const selectPopup = createSelector(selectUI, (ui) => {
  return ui.popup;
});
