import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ROUTE as RESTORE_PASS_API_ROUTE,
  Params as RestorePassParams,
  Result as RestorePassResult,
} from 'api/auth/user/restore-password/params';
import { ApiResponse } from 'src/types/api-response';

import { api } from 'utils/api';

export const restorePassword = createAsyncThunk<
  ApiResponse<RestorePassResult>,
  RestorePassParams,
  { rejectValue: string }
>('user/restorePassword', async (data, { rejectWithValue }) => {
  try {
    const response = await api.sendRequest<RestorePassResult>({
      url: RESTORE_PASS_API_ROUTE,
      method: 'POST',
      data,
    });

    if (response.success) {
      return response;
    }

    throw new Error(response.error.message as string);
  } catch (e) {
    console.info('e', (e as Error).message || e);
    return rejectWithValue((e as Error).message);
  }
});
