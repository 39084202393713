import React from 'react';
import { useResponsive } from 'client/hooks';
import { ControlPanel, Header } from 'client/components/common/mobile';
import { HelmetComponent } from 'client/components/common';
import { staticPages as staticPagesApi } from 'client/redux/api/static-pages';
import { TARGET_VALUES } from 'models/other/interface';
import css from './PrivacyPolicy.module.scss';
import rtCSS from 'src/routes/client/styles/RichTextContent.module.scss';

const PrivacyStatement = () => {
  const [isMobile] = useResponsive(['MOBILE']);
  const { data, isFetching } = staticPagesApi.endpoints.getStaticPage.useQuery({
    target: TARGET_VALUES.PRIVACY_POLICY,
  });
  const pageData = data?.success && data.data ? data.data : { title: '', body: '' };

  return (
    <>
      <HelmetComponent
        title="Privacy Statement - Catalyst Investors' Club"
        description="Join Catalyst Investors' Club to invest alongside reputable lead investors. Find out more about the club."
        ogTitle="Privacy Statement - Catalyst Investors' Club"
        ogDescription="Join Catalyst Investors' Club to invest alongside reputable lead investors. Find out more about the club."
      ></HelmetComponent>
      <div className={css.pageWrapper}>
        {isMobile ? <Header className={css.pageHeader} /> : null}
        {isMobile ? <ControlPanel className={css.controlPanel} /> : null}

        {pageData && (
          <div className={css.contentWrapper}>
            <div className={rtCSS.title}>{pageData?.title}</div>
            <div className={rtCSS.richTextContent} dangerouslySetInnerHTML={{ __html: pageData.body }}></div>
          </div>
        )}
      </div>
    </>
  );
};

export default PrivacyStatement;
