import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { IModel, UserRole, UserStatus } from 'models/user/interface';
import { Onboarding } from 'models/user/onboarding';
import { getFullData } from 'utils/onboarding/full-data';
import { Params as QuesstionareDataParams } from 'api/users/website/onboarding/update/params';
import * as actions from './actions';

interface IUser extends Omit<IModel, '_id' | 'password' | 'onboarding' | 'createdAt'> {
  _id: string;
  votedCompanies: Array<string>;
  favoriteCompanies: Array<string>;
  auth: {
    isInProgress: boolean;
    isLoggedIn: boolean;
    isCompleted: boolean;
  };
  createdAt: string;
  onboarding: Onboarding;
  isGAEnabled: boolean;
  intercomHash: string;
}

const initialState: IUser = {
  _id: '',
  role: 'VIEWER',
  status: 'NONE',
  provider: 'emailAndPassword',
  email: '',
  createdAt: new Date().toISOString(),
  votedCompanies: [],
  favoriteCompanies: [],
  auth: {
    isInProgress: false,
    isLoggedIn: false,
    isCompleted: false, // flag for redirects
  },
  onboarding: getFullData(),
  isGAEnabled: false,
  intercomHash: '',
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state._id = action.payload._id;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.name = action.payload.name;
      state.provider = action.payload.provider;
      state.pictureUrl = action.payload.pictureUrl;
      state.auth.isLoggedIn = true;
      state.status = action.payload.status;

      if (action.payload.onboarding) state.onboarding = action.payload.onboarding;
    },
    addToFavorite: (state, action: PayloadAction<string>) => {
      state.favoriteCompanies = [...new Set([...state.favoriteCompanies, action.payload])];
    },
    deleteFromFavorite: (state, action: PayloadAction<string>) => {
      state.favoriteCompanies = state.favoriteCompanies.filter((item) => item !== action.payload);
    },
    addToVoted: (state, action: PayloadAction<string>) => {
      state.votedCompanies = [...new Set([...state.votedCompanies, action.payload])];
    },
    setAuthProgressState: (state, action: PayloadAction<boolean>) => {
      state.auth.isInProgress = action.payload;
    },
    setQuestionnaire: (state, action: PayloadAction<QuesstionareDataParams & { intent: 'questionnaire' }>) => {
      state.onboarding.form = {
        ...state.onboarding.form,
        ...omit(action.payload, 'intent'),
      };
    },
    setPersonalDetails: (state, action: PayloadAction<Partial<Onboarding['form']>>) => {
      state.onboarding.form = {
        ...state.onboarding.form,
        ...action.payload,
      };
    },
    setNDA: (state, action: PayloadAction<Partial<Onboarding['form']>>) => {
      state.onboarding.form = {
        ...state.onboarding.form,
        ...action.payload,
      };
    },
    setKYC: (state, action: PayloadAction<Partial<Onboarding['form']>>) => {
      state.onboarding.form = {
        ...state.onboarding.form,
        ...action.payload,
      };
    },
    setSelfDeclaration: (state, action: PayloadAction<Onboarding['agreement']>) => {
      state.onboarding.agreement = {
        ...state.onboarding.agreement,
        ...action.payload,
      };
    },
    setBankDetails: (state, action: PayloadAction<Partial<Onboarding['form']>>) => {
      state.onboarding.form = {
        ...state.onboarding.form,
        ...action.payload,
      };
    },
    setCertification: (state, action: PayloadAction<Partial<Onboarding['form']>>) => {
      state.onboarding.form = {
        ...state.onboarding.form,
        ...action.payload,
      };
    },
    setTaxDeclaration: (state, action: PayloadAction<Partial<Onboarding['form']>>) => {
      state.onboarding.form = {
        ...state.onboarding.form,
        ...action.payload,
      };
    },
    setContactDetails: (state, action: PayloadAction<Partial<Onboarding['form']>>) => {
      state.onboarding.form = {
        ...state.onboarding.form,
        ...action.payload,
      };
    },
    setRole: (state, action: PayloadAction<UserRole>) => {
      state.role = action.payload;
    },
    setStatus: (state, action: PayloadAction<UserStatus>) => {
      state.status = action.payload;
    },
    enableGA: (state, action: PayloadAction<boolean>) => {
      state.isGAEnabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.me.pending, (state) => {
      state.auth.isInProgress = true;
    });
    builder.addCase(actions.me.fulfilled, (state, action) => {
      state.auth.isInProgress = false;
      state.auth.isLoggedIn = true;
      state.auth.isCompleted = true;

      if (action.payload.success) {
        state._id = action.payload.data._id.toString();
        state.email = action.payload.data.email;
        state.role = action.payload.data.role;
        state.name = action.payload.data.name;
        state.provider = action.payload.data.provider;
        state.pictureUrl = action.payload.data.pictureUrl;
        state.status = action.payload.data.status;
        state.createdAt = action.payload.data.createdAt;

        state.onboarding = { ...state.onboarding, ...action.payload.data.onboarding };

        state.intercomHash = action.payload.data.intercomHash;
      }
    });
    builder.addCase(actions.me.rejected, (state) => {
      state.auth.isInProgress = false;
      state.auth.isLoggedIn = false;
      state.auth.isCompleted = true;
    });
    builder.addCase(actions.signIn.pending, (state) => {
      state.auth.isInProgress = true;
    });
    builder.addCase(actions.signIn.fulfilled, (state, action) => {
      state.auth.isInProgress = false;
      state.auth.isLoggedIn = true;
      state.auth.isCompleted = true;

      if (action.payload.success) {
        state._id = action.payload.data._id.toString();
        state.email = action.payload.data.email;
        state.role = action.payload.data.role;
        state.name = action.payload.data.name;
        state.provider = action.payload.data.provider;
        state.pictureUrl = action.payload.data.pictureUrl;
        state.status = action.payload.data.status;
        state.createdAt = action.payload.data.createdAt;
        state.intercomHash = action.payload.data.intercomHash;

        state.onboarding = { ...state.onboarding, ...action.payload.data.onboarding };
      }
    });
    builder.addCase(actions.signIn.rejected, (state) => {
      state.auth.isInProgress = false;
      state.auth.isCompleted = true;
    });

    builder.addCase(actions.emailSignUp.pending, (state) => {
      state.auth.isInProgress = true;
    });
    builder.addCase(actions.emailSignUp.fulfilled, (state, action) => {
      state.auth.isInProgress = false;
      state.auth.isLoggedIn = true;
      state.auth.isCompleted = true;

      if (action.payload.success) {
        state._id = action.payload.data._id.toString();
        state.email = action.payload.data.email;
        state.role = action.payload.data.role;
        state.name = action.payload.data.name;
        state.provider = action.payload.data.provider;
        state.pictureUrl = action.payload.data.pictureUrl;
        state.status = action.payload.data.status;
        state.createdAt = action.payload.data.createdAt;
        state.onboarding = { ...state.onboarding, ...action.payload.data.onboarding };
        state.intercomHash = action.payload.data.intercomHash;
      }
    });
    builder.addCase(actions.emailSignUp.rejected, (state) => {
      state.auth.isInProgress = false;
      state.auth.isCompleted = true;
    });

    builder.addCase(actions.signOut.pending, (state) => {
      state.auth.isInProgress = true;
    });
    builder.addCase(actions.signOut.fulfilled, (state, action) => {
      state.auth.isInProgress = false;
      state.auth.isLoggedIn = false;
      state.auth.isCompleted = true;

      if (action.payload.success) {
        state._id = initialState._id;
        state.email = initialState.email;
        state.role = initialState.role;
        state.name = initialState.name;
        state.provider = initialState.provider;
        state.pictureUrl = initialState.pictureUrl;
        state.status = initialState.status;
        state.createdAt = new Date().toISOString();
        state.onboarding = initialState.onboarding;
      }
    });
    builder.addCase(actions.signOut.rejected, (state) => {
      state.auth.isInProgress = false;
      state.auth.isCompleted = true;
    });
    builder.addCase(actions.update.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.name = action.payload.data?.name || '';
        state.pictureUrl = action.payload.data?.pictureUrl || '';
      }
    });
    builder.addCase(actions.getUserData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.votedCompanies = action.payload.data.voted;
        state.favoriteCompanies = action.payload.data.favorite;
      }
    });
  },
});

export const {
  init,
  setAuthProgressState,
  addToFavorite,
  addToVoted,
  deleteFromFavorite,
  setQuestionnaire,
  setPersonalDetails,
  setSelfDeclaration,
  setNDA,
  setKYC,
  setBankDetails,
  setCertification,
  setTaxDeclaration,
  setRole,
  setStatus,
  setContactDetails,
  enableGA,
} = user.actions;
export default user.reducer;
