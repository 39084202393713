import React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router';

import { useTranslation, useAppDispatch, useResponsive, useAppSelector, useAnalytics } from 'client/hooks';
import { selectAuth, selectUser } from 'client/redux/user/selectors';
import * as routes from 'client/constants/routes';
import { setPopup } from 'client/redux/ui';

import Icon from '../Icon';
import GoogleProvider from './Providers/GoogleProvider';
import LinkedInProvider from './Providers/LinkedInProvider';
import EmailProvider from './Providers/EmailProvider';
import TermsAndPolicy from './TermsAndPolicy';
import SuccessMessage from './SuccessMessages';

import { DispatchContext, State, StateContext, initialState, reducer } from './state';
import css from './Login.module.scss';

export type Props = {
  initialAuthType?: State['authType'];
  initialCurrentView?: State['currentView'];
  userId?: string;
  token?: string;
  redirectUrl?: string;
};

const points = Array.from(Array(3).keys());

const Login: React.FC<Props> = ({ initialAuthType, initialCurrentView, userId, token, redirectUrl }) => {
  const dispatch = useAppDispatch();

  const analytics = useAnalytics();
  const history = useHistory();
  const isSignInRoute = !!useRouteMatch<{ id: string }>(routes.SIGN_IN_ROUTE);
  const isSignUpRoute = !!useRouteMatch<{ id: string }>(routes.SIGN_UP_ROUTE);
  const urlParams = React.useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const emailProviderFields = React.useMemo(() => {
    if (urlParams.get('f_name') && urlParams.get('l_name') && urlParams.get('email')) {
      return {
        firstName: urlParams.get('f_name') ?? '',
        lastName: urlParams.get('l_name') ?? '',
        email: urlParams.get('email') ?? '',
      };
    }
    return undefined;
  }, [urlParams]);

  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  const [isDesktop] = useResponsive('DESKTOP');
  const { translate: _t } = useTranslation('popups');
  const [state, authDispatch] = React.useReducer(reducer, {
    ...initialState,
    authType: initialAuthType || 'sign-in',
    currentView: initialCurrentView || urlParams.get('f_name') ? 'emailProvider' : 'default',
  });
  const [restoredEmail, setRestoredEmail] = React.useState('');
  const translatePath = state.authType === 'sign-in' ? 'signIn' : 'signUp';

  React.useEffect(() => {
    if (isDesktop) {
      document.body.style.overflow = 'hidden';
    }
  }, [isDesktop]);

  /**
   * close popup if user logged in but stay open if current view
   * is about success (after login via google or linkedIn after signInRedirect)
   */
  React.useEffect(() => {
    if (auth.isLoggedIn && state.currentView !== 'successView') {
      if (redirectUrl && user.role !== 'VIEWER') {
        window.open(redirectUrl, '_self');
      }

      dispatch(setPopup(null));
      if (isDesktop) {
        document.body.style.overflow = 'auto';
      }
    }
  }, [dispatch, auth.isLoggedIn, state.currentView]);

  /**
   * show self declaration popup after user signed up and if user role viewer
   * if user signed up and his role not equal viewer will close popup
   */
  React.useEffect(() => {
    if (auth.isLoggedIn && state.authType === 'sign-up') {
      if (auth.isLoggedIn && user.role === 'VIEWER') {
        dispatch(setPopup({ type: 'join-as-investor', data: { link: '', isExternalLink: false } }));
      } else {
        dispatch(setPopup(null));
      }
    }
  }, [dispatch, auth.isLoggedIn]);

  React.useEffect(() => {
    if (userId && token) {
      authDispatch({ type: 'setCurrentView', payload: 'emailProvider' });
    }
  }, [userId, token]);

  const onProviderBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = e.currentTarget.dataset;
    switch (id) {
      case 'GOOGLE':
        analytics.gtag.event('login', {
          method: `Google`,
        });
        authDispatch({ type: 'setCurrentView', payload: 'googleProvider' });
        break;

      case 'LINKEDIN':
        analytics.gtag.event('login', {
          method: `LinkedIn`,
        });
        authDispatch({ type: 'setCurrentView', payload: 'linkedinProvider' });
        break;

      case 'EMAIL':
        analytics.gtag.event('login', {
          method: `Email`,
        });
        authDispatch({ type: 'setCurrentView', payload: 'emailProvider' });
        break;

      default:
        break;
    }
  };

  const onLoginSuccess = (email?: string) => {
    const isFromForgotPassView = !!email;

    if (email) {
      setRestoredEmail(email);
    }

    if (isSignInRoute || isSignUpRoute) {
      history.replace(routes.ROOT);
    }

    if ((initialAuthType === 'sign-up' || isFromForgotPassView) && state.authType !== 'sign-up') {
      authDispatch({ type: 'setCurrentView', payload: 'successView' });
    }
  };

  const renderDefaultView = (renderProvider?: () => React.JSX.Element) => {
    return (
      <>
        <div className={css.providers}>
          <button type="button" data-id="GOOGLE" className={css.google} onClick={onProviderBtnClick}>
            <Icon type="login-google" className={css.icon} />
            <span className={css.label}>{_t(`login.${translatePath}.googleLabel`)}</span>
          </button>
          {/* <button type="button" className={css.linkedin} data-id="LINKEDIN" onClick={onProviderBtnClick}>
            <Icon type="login-linkedin" className={css.icon} />
            <span className={css.label}>{_t(`login.${translatePath}.linkedinLabel`)}</span>
          </button> */}

          <div className={css.divider}>
            <span className={css.line} />
            <span className={css.dividerText}>{_t('login.divider')}</span>
            <span className={css.line} />
          </div>

          <button type="button" className={css.email} data-id="EMAIL" onClick={onProviderBtnClick}>
            <span className={css.label}>{_t(`login.${translatePath}.emailLabel`)}</span>
          </button>
        </div>

        {renderProvider?.()}
      </>
    );
  };

  const onBackToDefaultState = () => {
    authDispatch({ type: 'setCurrentView', payload: 'default' });
  };

  const onBackToEmailProviderState = () => {
    authDispatch({ type: 'setCurrentView', payload: 'emailProvider' });
  };

  const renderCurrentView = () => {
    switch (state.currentView) {
      case 'default':
        return renderDefaultView();

      case 'googleProvider':
        return renderDefaultView(() => <GoogleProvider action={state.authType} onSuccess={onLoginSuccess} />);

      case 'linkedinProvider':
        return renderDefaultView(() => <LinkedInProvider onSuccess={onLoginSuccess} />);

      case 'emailProvider':
        return (
          <EmailProvider
            onBackClick={onBackToDefaultState}
            fields={emailProviderFields}
            action={state.authType}
            onSuccess={onLoginSuccess}
          />
        );

      default:
        return null;
    }
  };

  const onCloseBtnClick = () => {
    dispatch(setPopup(null));

    if (isSignInRoute || isSignUpRoute) {
      history.replace(routes.ROOT);
    }

    if (isDesktop) {
      document.body.style.overflow = 'auto';
    }
  };

  const onBackBtnClick = () => {
    if (state.currentView === 'emailProvider') {
      authDispatch({ type: 'setCurrentView', payload: 'default' });
    } else {
      onCloseBtnClick();
    }
  };

  const renderBottom = () => {
    return state.authType === 'sign-in' ? (
      <div className={css.bottom}>
        {_t(`login.${translatePath}.bottomText`)}{' '}
        <span className={css.link} onClick={() => authDispatch({ type: 'setAuthType', payload: 'sign-up' })}>
          {_t(`login.${translatePath}.linkLabel`)}
        </span>
      </div>
    ) : (
      <TermsAndPolicy />
    );
  };

  const renderCloseButton = () => {
    if (state.currentView === 'emailProvider') {
      return null;
    }
    return (
      <button className={css.closeBtn} onClick={onCloseBtnClick}>
        <Icon type="close" />
      </button>
    );
  };

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={authDispatch}>
        <div className={cn(css.popup, css[state.authType])}>
          {isDesktop && <div className={css.popupOverlay} />}
          {!isDesktop && state.currentView !== 'successView' && renderCloseButton()}

          {state.currentView === 'successView' ? (
            <SuccessMessage
              action={state.authType}
              onSentEmailAgainClick={onBackToEmailProviderState}
              onSignInClickAfterRestore={onBackToEmailProviderState}
              onCloseClick={onCloseBtnClick}
              email={restoredEmail}
            />
          ) : (
            <div className={css.loginWrapper}>
              <div className={css.login}>
                <>
                  {/** render general signIn or signUp view */}
                  {state.currentView !== 'emailProvider' && (
                    <>
                      {isDesktop && renderCloseButton()}
                      <div className={css.title}>{_t(`login.${translatePath}.title`)}</div>
                    </>
                  )}

                  {renderCurrentView()}

                  {state.currentView !== 'emailProvider' && renderBottom()}
                </>
              </div>
              <div className={css.desktopAdditional}>
                <div className={css.title}>{_t(`login.additionalInfo.title`)}</div>
                <ul>
                  {points.map((point) => {
                    return (
                      <li key={point}>
                        <Icon type="bullet-arrow" className={css.icon} />
                        <span>{_t(`login.additionalInfo.points.${point}`)}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export default Login;
