import qs from 'qs';
import React from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { setPopup } from 'client/redux/ui';
import { selectPopup } from 'client/redux/ui/selectors';
import { useAppDispatch, useAppSelector } from 'client/hooks';
import { ContentWrap } from 'client/components/common/mobile/ContentWrap';
import { transitionClassNames } from 'client/utils/css-transition-classnames';

import KYC from './KYC';
import Login from '../Login';
import JoinAsInvestor from './JoinAsInvestor';
import AccreditationQuestionnaire from './AccreditationQuestionnaire';
import BankDetails from './AccreditationFinalization/BankDetails';
import AccreditationCertification from './AccreditationFinalization/AccreditationCertification';
import TaxDeclare from './AccreditationFinalization/TaxDeclare';
import ContactDetails from './AccreditationFinalization/ContactDetails';
import NewsPopup from '../NewsPopup';
import FoundersPopup from '../FoundersPopup';
import { EditInfoPopUp } from 'client/components/pages/UserProfile/EditInfoPopUp';
import QuestionnaireAnswers from './QuestionnaireAnswers';

import css from './PopUpManager.module.scss';

interface Props {
  className?: string;
}

function PopUpManager(props: Props) {
  const { className = '' } = props;
  const popup = useAppSelector(selectPopup);
  const dispatch = useAppDispatch();
  const isCloseBtnVisible = getIsCloseBtnVisible(popup?.type);
  const isBgVisible = getIsBgVisible(popup?.type);
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const onCloseClick = () => {
    dispatch(setPopup(null));
  };

  const renderContent = () => {
    switch (popup?.type) {
      case 'bank-details':
        return <BankDetails />;

      case 'accrediatation-certification':
        return <AccreditationCertification />;

      case 'tax-declare':
        return <TaxDeclare />;

      case 'kyc':
        return <KYC />;

      case 'join-as-investor':
        return <JoinAsInvestor data={popup.data} />;

      case 'auth':
        return (
          <Login
            initialAuthType={popup?.type === 'auth' ? popup?.data.initialAuthType : 'sign-in'}
            userId={queryParams.userId as string}
            token={queryParams.token as string}
            redirectUrl={popup?.data.redirectUrl}
          />
        );

      case 'accreditation-questionnaire':
        return <AccreditationQuestionnaire />;

      case 'contact-details':
        return <ContactDetails />;

      case 'founders':
        return <FoundersPopup team={popup.data} />;

      case 'news':
        return <NewsPopup news={popup.data} />;

      case 'profile-edit':
        return <EditInfoPopUp view={popup.data.view} />;

      case 'questionnaire-answers':
        return <QuestionnaireAnswers />;

      default:
        return null;
    }
  };

  //make 100vh for body and prevent scroll
  React.useEffect(() => {
    if (Boolean(popup)) {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('height');
      document.body.style.removeProperty('overflow');
    }
  }, [popup]);

  return (
    <CSSTransition in={!!popup} timeout={650} classNames={containerTransitionClassNames} mountOnEnter unmountOnExit>
      <div className={cn(css.popUpManager, className)}>
        {isBgVisible && <div className={css.bg} />}
        <ContentWrap className={css.contentWrap}>
          {isCloseBtnVisible && <button className={css.closeBtn} type="button" onClick={onCloseClick} />}

          <SwitchTransition mode="out-in">
            <CSSTransition
              key={popup?.type}
              classNames={contentTransitionClassNames}
              timeout={300}
              appear
              unmountOnExit
            >
              <div className={css.content}>{renderContent()}</div>
            </CSSTransition>
          </SwitchTransition>
        </ContentWrap>
      </div>
    </CSSTransition>
  );
}

function getIsBgVisible(type?: string) {
  switch (type) {
    case 'bank-details':
    case 'accrediatation-certification':
    case 'tax-declare':
    case 'contact-details':
    case 'questionnaire-answers':
      return false;
    default:
      return true;
  }
}

function getIsCloseBtnVisible(type?: string) {
  switch (type) {
    case 'kyc':
    case 'nda':
    case 'accreditation-questionnaire':
      return true;

    case 'join-as-investor':
    default:
      return false;
  }
}

const containerTransitionClassNames = transitionClassNames(css, 'containerTransition');
const contentTransitionClassNames = transitionClassNames(css, 'contentTransition');

export { PopUpManager };
export default PopUpManager;
