import { createSelector } from 'reselect';
import _ from 'lodash';

import { RootState } from '..';

export const selectSettings = (state: RootState) => {
  return state.settings;
};

export const selectLang = (state: RootState) => {
  return state.settings.lang;
};

export const selectTexts = createSelector(selectSettings, selectLang, (settings, lang) => {
  return settings.texts[lang];
});

export const selectGeneralTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'general', {});
});

export const selectHeaderTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'header', {});
});

export const selectCompanyPageTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'companyPage', {});
});

export const selectUserProfileTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'userProfile', {});
});

export const selectPopupsTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'popups', {});
});

export const selectNotFoundTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'notFound', {});
});
