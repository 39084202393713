import { useAppSelector } from 'client/hooks';
import { getCurrentStep as questionnaireStep } from 'utils/onboarding/accreditation-questionnaire/questionnaire';
import { getCurrentStep as personalDetailsStep } from 'utils/onboarding/accreditation-questionnaire/personal-details';
import { getCurrentStep as ndaStep } from 'utils/onboarding/accreditation-questionnaire/nda';
import { selectUserOnboarding } from 'client/redux/user/selectors';
import { STAGES } from 'client/types';

export const useGetActiveStage = (): STAGES => {
  const onBoarding = useAppSelector(selectUserOnboarding);
  const stepQuestionnaire = questionnaireStep(onBoarding);
  const stepPersonalDetails = personalDetailsStep(onBoarding);
  const stepNda = ndaStep(onBoarding);
  const signatureStatus = onBoarding.form.signatureStatus;

  if (stepPersonalDetails) {
    return 'personalDetails';
  }

  if (stepQuestionnaire) {
    return 'questionnaire';
  }

  if (stepNda || signatureStatus === 'in-progress') {
    return 'nda';
  }

  return 'personalDetails';
};
