import React from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { useResponsive } from 'client/hooks';
import crossIcon from '../assets/cross.svg';
import css from './InvestorDetails.module.scss';

export interface InvestorData {
  name: string;
  logo: string;
  description: string;
}

interface Props {
  className?: string;
  data: InvestorData | null;
  onClose: () => unknown;
}

export function InvestorDetails(props: Props) {
  const { className = '' } = props;
  const [data, setData] = React.useState<InvestorData | null>(props.data);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isMobile] = useResponsive(['MOBILE']);

  React.useEffect(() => {
    if (props.data) setData(props.data);
  }, [props.data]);

  return (
    <CSSTransition
      in={!!props.data}
      timeout={parseInt(css.transitionDuration, 10) || 500}
      classNames={{
        appear: css.appear,
        appearActive: css.appearActive,
        appearDone: css.appearDone,
        enter: css.enter,
        enterActive: css.enterActive,
        exit: css.exit,
        exitActive: css.exitActive,
        exitDone: css.exitDone,
      }}
      onExited={() => setData(null)}
      unmountOnExit
    >
      <div className={cn(css.investorDetails, className)} ref={containerRef}>
        <div className={css.investorDetailsBg} onClick={props.onClose} />
        {isMobile && <div className={css.closeBtn} onClick={props.onClose}></div>}
        <div className={css.investorDetailsPopUp}>
          {!isMobile && (
            <button className={css.closeBtn} onClick={props.onClose}>
              <img src={crossIcon} alt="close" />
            </button>
          )}
          <div className={css.header}>
            <div className={css.logo} style={{ backgroundImage: `url(${data?.logo})` }} />
            <p className={css.title}>{data?.name}</p>
          </div>
          <div className={css.investorDetailsPopUpContent}>
            <div className={css.description}>
              <div dangerouslySetInnerHTML={{ __html: data?.description ?? '' }} />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default InvestorDetails;
