import * as yup from 'yup';

import { IModel as CategoryModel } from 'models/category/interface';
import { IModel as CompanyNewsModel } from 'models/company_news/interface';
import { IModel } from 'models/company/interface';
import { VALIDATION_ERROR } from 'endpoints/index';
import * as routes from 'api/routes';

export type CompanyWithCategoryInfo = Omit<IModel, '_id' | 'categoryIds'> & {
  _id: string;
  news: CompanyNewsModel['linksInfo'];
  mandateId?: string;
  categories: Array<Pick<CategoryModel, 'name' | 'slug'> & { _id: string }>;
};

export type Params = { id: string };
export type Result = CompanyWithCategoryInfo | undefined;

export const paramsSchema = yup.object().shape({
  id: yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.COMPANY_GET;
