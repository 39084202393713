import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Swiper as SwiperType } from 'swiper';
import { useAppSelector, useAppDispatch, useAnalytics } from 'client/hooks';
import { SIZE_TYPE } from 'client/types';
import { COMPANY_PAGE_ROUTE } from 'client/constants/routes';
import { companies as companiesApi } from 'client/redux/api/companies';
import { MainPageWebsiteSliderItem } from 'api/main_page/public/get/params';

import { selectAuth, selectUser } from 'client/redux/user/selectors';
import { setPopup } from 'client/redux/ui';

import { LinkButton, VideoJSPlayer, Loader } from 'client/components/common';

import css from './HeroSlider.module.scss';

SwiperCore.use([EffectFade]);

type Props = {
  items?: MainPageWebsiteSliderItem[];
  isLoading: boolean;
};

const HeroSlider: React.FC<Props> = ({ items, isLoading }) => {
  const analytics = useAnalytics();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [updateRating] = companiesApi.endpoints.updateRating.useMutation();
  const [swiperInstance, setSwiperInstance] = React.useState<SwiperType | null>(null);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [isCompleted, setCompleted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isCompleted && swiperInstance && items) {
      if (swiperInstance.activeIndex === items.length - 1) {
        swiperInstance.slideTo(0);
      } else {
        swiperInstance.slideNext();
      }

      setCompleted(false);
    }
  }, [isCompleted, items]);

  const onUpRatingBtnClick = (id: string) => {
    if (!user.auth.isLoggedIn) {
      dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in' } }));
      return;
    }

    updateRating({ companyId: id });
  };

  return (
    <section className={css.heroSlider}>
      {isLoading ? (
        <Loader position="absolute" />
      ) : (
        <div className={css.swiperWrapper}>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            effect={'fade'}
            allowTouchMove={false}
            noSwiping={false}
          >
            {_.map(items, (slide, index) => {
              const isAlreadyVoted = user.votedCompanies.includes(slide._id);

              return (
                <SwiperSlide key={slide._id}>
                  {({ isActive }) => (
                    <div className={css.slide}>
                      <div className={css.info}>
                        <h2 className={css.title}>{slide.name}</h2>
                        <p className={css.description}>{slide.shortDescription}</p>
                        <div className={css.actions}>
                          <LinkButton link={COMPANY_PAGE_ROUTE.replace(':id', slide._id)} size={SIZE_TYPE.L} />
                          {/* <div
                            className={cn(css.upvote, isAlreadyVoted && css.voted)}
                            onClick={() => onUpRatingBtnClick(slide._id)}
                          >
                            <span>{slide.rating}</span>
                            <i className={css.icon} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
      {items && (
        <div className={css.videoWrap}>
          <VideoJSPlayer
            videoSRC={items[activeIndex].avatarVideo}
            cover={items[activeIndex].mainImage}
            onComplete={() => setCompleted(true)}
            isRepeated={items.length === 1}
            id={'hero-player'}
          />
        </div>
      )}
    </section>
  );
};

export default HeroSlider;
