import React from 'react';
import _ from 'lodash';

import {
  selectGeneralTexts,
  selectTexts,
  selectHeaderTexts,
  selectCompanyPageTexts,
  selectPopupsTexts,
  selectUserProfileTexts,
  selectNotFoundTexts,
} from 'routes/client/redux/settings/selectors';
import { useAppSelector } from './redux';
import { RootState } from 'routes/client/redux';

type TranslationSource = 'general' | 'header' | 'companyPage' | 'popups' | 'userProfile' | 'notFound';

/**
 * Usage:
 * const { translate } = useTranslation('logIn'); // will use store.settings.texts.{lang}.logIn
 * // assuming: store.settings.texts.{lang}.logIn.hello = 'hello ${name}';
 * const str = translate('hello', { name: 'Dear User' });
 * // -> str = 'hello Dear User
 *
 * If source is not provided translate function will use store.setting.texts.{lang} as base
 */
export const useTranslation = (source?: TranslationSource) => {
  const selectorsMap = {
    general: selectGeneralTexts,
    header: selectHeaderTexts,
    companyPage: selectCompanyPageTexts,
    popups: selectPopupsTexts,
    userProfile: selectUserProfileTexts,
    notFound: selectNotFoundTexts,
  };
  const texts = useAppSelector(source ? selectorsMap[source] : selectTexts);
  const t = React.useCallback(
    (path: string, options?: Record<string, string>) => {
      return translate(texts, path, options);
    },
    [texts],
  );

  const createPrefixedTranslationFunc =
    (prefix: string): typeof t =>
    (path, options) =>
      t(`${prefix}.${path}`, options);

  return {
    translate: t,
    createPrefixedTranslationFunc,
  };
};

export function translate(
  texts: RootState['settings']['texts'][string],
  path: string,
  options?: Record<string, string>,
) {
  let result = _.get(texts, path, path);
  if (options) {
    const compiled = _.template(result);
    result = compiled(options);
  }

  return result;
}
