// @function vw_d($val, $base: 1920) {
//   @return calc($val / $base * 100) + vw;
// }

export function vw_d(value: number, base = 1920) {
  if (typeof window === 'undefined') {
    return value;
  }

  return (value / base) * window.innerWidth;
}
