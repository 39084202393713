import React from 'react';
import { FieldArray } from 'formik';

import { useTranslation, useResponsive } from 'client/hooks';

import countries from 'utils/countries.json';
import { InputValue } from 'models/user/onboarding';
import Icon from 'client/components/common/Icon';
import SelectField from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/Form/Select';
import TextField from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/Form/TextField';
import css from './TaxDeclare.module.scss';

interface Props {
  name: string;
  items: InputValue;
}

const TaxInfoFieldArray = (props: Props) => {
  const { name, items } = props;
  const { translate } = useTranslation();
  const [isMobile] = useResponsive(['MOBILE']);

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div className={css.fieldArrayWrapper}>
          {Array.isArray(items) &&
            items.map((item, index) => {
              return (
                <div key={index} className={css.row}>
                  <div className={css.fields}>
                    <div className={css.fieldWrapper}>
                      <SelectField
                        name={`${name}[${index}].country`}
                        label={translate('onboardingFinalization.taxDeclaration.form.country.label')}
                        options={countries}
                        stringValue
                      />
                    </div>
                    <div className={css.fieldWrapper}>
                      <TextField
                        name={`${name}[${index}].taxId`}
                        label={translate('onboardingFinalization.taxDeclaration.form.taxId.label')}
                        withUpperLabel={false}
                      />
                    </div>
                  </div>

                  {index !== 0 && (
                    <div className={css.deleteBtn} onClick={() => arrayHelpers.remove(index)}>
                      <Icon type="trash" className={css.trashIcon} />
                    </div>
                  )}
                </div>
              );
            })}

          {Array.isArray(items) && items.length < 3 && (
            <div className={css.addBtnWrapper}>
              <div className={css.addBtn} onClick={() => arrayHelpers.push({ country: '', taxId: '' })}>
                <div className={css.plus}></div>
                {isMobile
                  ? translate('onboardingFinalization.taxDeclaration.addAdditionalBtnMobileText')
                  : translate('onboardingFinalization.taxDeclaration.addAdditionalBtnText')}
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default TaxInfoFieldArray;
