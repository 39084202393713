import React from 'react';
import snsWebSdk from '@sumsub/websdk';
import SnsWebSdk from '@sumsub/websdk/types/SnsWebSdk';

import { onboarding as onboardingApi } from 'client/redux/api/onboarding';
import { selectUserOnboarding } from 'client/redux/user/selectors';
import { useAppDispatch, useAppSelector } from 'client/hooks';
import { ApplicantStatusChangePayload } from 'utils/kyc/types';
import { generateKYCToken } from 'client/redux/user/actions';
import { getReviewStatus } from 'utils/kyc';
import { setPopup } from 'client/redux/ui';

import css from './KYC.module.scss';

const KYC = () => {
  const dispatch = useAppDispatch();
  const onboarding = useAppSelector(selectUserOnboarding);
  const closePopupTimeoutRef = React.useRef<NodeJS.Timeout | number | null>(null);
  const applicantRef = React.useRef<{ id: string; isCompleted: boolean; status: string } | null>(
    Boolean(onboarding.form.kycId)
      ? {
          id: (onboarding.form.kycId as string) || '',
          status: (onboarding.form.kycStatus as string) || '',
          isCompleted: Boolean(onboarding.form.kycId) && onboarding.form.kycStatus === 'completed',
        }
      : null,
  );
  const [setKYC] = onboardingApi.endpoints.setKYC.useMutation();

  const onApplicantStatusChanged = (payload: ApplicantStatusChangePayload) => {
    console.info('onApplicantStatusChanged', payload);

    const status = getReviewStatus(payload.reviewStatus, payload.reviewResult);

    // if user already passed verification earlier and it's status 'complete'
    // and onApplicantStatusChanged event payload.status === 'complete' - we need
    // close the pop up after 3sec
    if (applicantRef.current?.status === status && status === 'completed') {
      closePopupTimeoutRef.current = setTimeout(() => {
        dispatch(setPopup(null));
      }, 3000);
      // when user is done with all verification steps and seeing sumsub's message like
      // "We are processing your data"
      // user's kycStatus needs to be updated to "pending"
    } else if (status === 'pending')
      if (applicantRef.current) {
        setKYC({
          kycId: applicantRef.current.id,
          kycStatus: status,
          intent: 'kyc',
        });
      } else {
        console.info('applicant is empty');
      }
  };

  React.useEffect(() => {
    let snsWebSdkInstance: SnsWebSdk | null = null;
    let accessToken = '';

    init();

    async function getToken() {
      const result = await dispatch(generateKYCToken({})).unwrap();

      return result.success ? result.data.token : '';
    }

    async function init() {
      try {
        accessToken = await getToken();
        launchWebSdk(accessToken);
      } catch (e) {
        console.info('e', e);
      }
    }

    function launchWebSdk(accessToken: string) {
      snsWebSdkInstance = snsWebSdk
        .init(
          accessToken,
          // token update callback, must return Promise
          // Access token expired
          // get a new one and pass it to the callback to re-initiate the WebSDK
          () => getToken(),
        )
        .withConf({
          lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
          theme: 'dark',
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        // see below what kind of messages WebSDK generates
        // @ts-expect-error due the lib types and real payload conflict
        .on('idCheck.onApplicantStatusChanged', onApplicantStatusChanged)
        .on('idCheck.onApplicantLoaded', (payload) => {
          if (!applicantRef.current) {
            applicantRef.current = { isCompleted: false, status: 'init', id: payload.applicantId || '' };
          }
        })
        .on('idCheck.onError', (error) => {
          console.log('onError', error);
        })
        .build();

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch('#sumsub-websdk-container');
    }

    return () => {
      snsWebSdkInstance?.destroy();

      if (closePopupTimeoutRef.current) clearTimeout(closePopupTimeoutRef.current);
    };
  }, []);

  const closeClickHandler = () => {
    dispatch(setPopup(null));
  };

  return (
    <>
      <div className={css.kycWrapper}>
        <div className={css.closeBtnMobile} onClick={closeClickHandler}></div>
        <div className={css.contentWrapper}>
          <div className={css.kycContent} id="sumsub-websdk-container"></div>
        </div>
      </div>
    </>
  );
};

export default KYC;
