import React from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import { FormikProvider, Form, useFormik } from 'formik';

import { useAppSelector, useTranslation, useAppDispatch } from 'client/hooks';
import { selectUserOnboarding } from 'client/redux/user/selectors';
import { getCertification } from 'utils/onboarding/accreditation-finalization/certification';
import { setPopup } from 'client/redux/ui';
import { onboarding as onboardingApi } from 'client/redux/api/onboarding';
import { Onboarding } from 'models/user/onboarding';
import { StepInput } from 'utils/onboarding/stage';
import { initialValues, validationSchema } from 'utils/onboarding';
import { MediaType } from 'client/types';

import UploadMedia from 'client/components/common/form/Onboarding/UploadMedia';
import Button from 'client/components/common/Button';

import css from './AccreditationCertification.module.scss';

const AccreditationCertification = () => {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();
  const onBoarding = useAppSelector(selectUserOnboarding);
  const certificationData = getCertification(onBoarding);
  const [certification, certificationState] = onboardingApi.endpoints.setCertification.useMutation();

  const formikContext = useFormik<Onboarding['form']>({
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    initialValues: initialValues(certificationData.steps),
    validate: async (values: Onboarding['form']) => {
      const errors: Record<string, string> = {};
      const schema = validationSchema(certificationData.steps as Array<StepInput>);

      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const { inner } = error as yup.ValidationError;
        inner.forEach((e) => {
          if (e.path && e.type) errors[e.path] = e.errors.join(', ');
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      await certification({ ...values, intent: 'certification' });
    },
  });

  React.useEffect(() => {
    if (certificationState.data?.success) {
      dispatch(setPopup(null));
    }
  }, [certificationState]);

  const closeClickHandler = () => {
    if (formikContext.dirty) {
      const result = confirm(translate('onboardingFinalization.accreditationCertification.closeWindowConfirm'));
      if (result) {
        dispatch(setPopup(null));
      }
    } else {
      dispatch(setPopup(null));
    }
  };
  return (
    <FormikProvider value={formikContext}>
      <Form autoComplete="off">
        <div className={css.closeBtnMobile} onClick={closeClickHandler}></div>
        <div className={css.formWrapper}>
          <div className={css.closeBtn} onClick={closeClickHandler}></div>
          <div className={css.formWrapperI}>
            <div className={css.contentWrapper}>
              <h1
                dangerouslySetInnerHTML={{
                  __html: translate('onboardingFinalization.accreditationCertification.title'),
                }}
              ></h1>
              <h3 className={css.subtitle}>
                {translate('onboardingFinalization.accreditationCertification.subtitle')}
              </h3>
              <div className={css.form}>
                <UploadMedia
                  name="certificationId"
                  accept=".jpg, .jpeg, .pdf"
                  acceptText={translate('onboardingFinalization.accreditationCertification.acceptFiles')}
                  fakeBtn={translate('onboardingFinalization.accreditationCertification.fakeUploadBtn')}
                  value={formikContext.values.certificationId as MediaType}
                />
              </div>
              <div className={css.submitWrapper}>
                <Button type="submit" disabled={!formikContext.dirty} className={css.submitButton}>
                  <span className={cn(css.btnText, certificationState.isLoading && css.loading)}>
                    {translate('onboardingFinalization.submitBtn')}
                  </span>
                  <div className={cn(css.loaderWrapper, certificationState.isLoading && css.loading)}>
                    <div className={css.loader}></div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={css.blurredBlock}></div>
      </Form>
    </FormikProvider>
  );
};

export default AccreditationCertification;
