import React from 'react';
import { useAppSelector } from 'client/hooks';
import { selectDisclaimer } from 'client/redux/footerDisclaimer/selectors';

import css from './FooterDisclaimer.module.scss';

const FooterDisclaimer = () => {
  const disclaimerData = useAppSelector(selectDisclaimer);

  return (
    <div className={css.footerDisclaimer}>
      <h3 className={css.title}>{disclaimerData.title}</h3>
      <div className={css.text} dangerouslySetInnerHTML={{ __html: disclaimerData.body }}></div>
    </div>
  );
};

export default FooterDisclaimer;
