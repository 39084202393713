import React from 'react';
import { useAppDispatch, useAppState } from '../Context';
import { useTranslation } from 'client/hooks';

import Button from '../../../Button';

import css from './ContinueButton.module.scss';

interface Props {
  disabled: boolean;
}
const ContinueButton = ({ disabled }: Props) => {
  const { translate } = useTranslation();

  return (
    <div className={css.buttonWrapper}>
      <Button type="submit" disabled={disabled} className={css.continueButton}>
        {translate('questionnaire.navigationButtons.continue')}
      </Button>
    </div>
  );
};

export default ContinueButton;
