import React from 'react';
import cn from 'classnames';
import { FormikProvider, Form, useFormik } from 'formik';
import * as yup from 'yup';
import DOMPurify from 'dompurify';

import { useTranslation } from 'client/hooks';

import { InputValue } from 'models/user/onboarding';
import { SelectInput } from 'utils/onboarding/input-types';

import Title from '../../Title';
import ContinueButton from '../../ContinueButton';
import ExplanationSubtitle from '../../ExplanationSubtitle';

import css from './SingleChoice.module.scss';

interface Props {
  info: SelectInput;
  onSubmitHandler: (value: Record<string, InputValue>) => void;
  isSkippable?: boolean;
}

const SingleChoice = ({ info, onSubmitHandler, isSkippable = false }: Props) => {
  const [isSubmiting, setIsSubmiting] = React.useState(false);
  const { translate } = useTranslation();
  const [shouldRender, setShouldRender] = React.useState(false);
  const [isActiveOther, setIsActiveOther] = React.useState<boolean>(false);
  const initialValues = {
    [`${info.name}`]: (info.defaultValue as Array<string>) || (info.value as Array<string>) || '',
  };

  React.useEffect(() => {
    setShouldRender(true);
  }, []);

  const formikContext = useFormik<Record<string, Array<string>>>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({ [`${info.name}`]: info.schema }),
    onSubmit: (values) => {
      setIsSubmiting(true);
      onSubmitHandler({ [`${info.name}`]: values[`${info.name}`] });
    },
  });

  const clickOnOtherHandler = () => {
    if (!isActiveOther) {
      formikContext.setFieldValue(info.name, '');
      setIsActiveOther(true);
    }
  };

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const cleanedValue = DOMPurify.sanitize(value);
    formikContext.setFieldValue(info.name, [cleanedValue]);
  };

  const radioChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isActiveOther) setIsActiveOther(false);
    formikContext.setFieldValue(info.name, [value]);
  };

  React.useEffect(() => {
    if (formikContext.values[`${info.name}`]) {
      const value = formikContext.values[`${info.name}`][0];
      const isActiveOther = info.options.find((item) => item.value === value);

      if (!isActiveOther) {
        setIsActiveOther(true);
      }
    }
  }, []);

  if (!shouldRender) return null;

  return (
    <FormikProvider value={formikContext}>
      <Form className={css.container}>
        <div className={css.containerI}>
          {isSkippable && <p className={css.nonMandatory}>{translate('questionnaire.nonMandatoryText')}</p>}
          <Title text={translate(info.label)} size="m" />
          {info.explanation && <ExplanationSubtitle text={translate(info.explanation)} />}
          <div className={css.fieldWrapper}>
            {info.options.map((item, index) => {
              if (index === info.options.length - 1 && info.withOther) {
                return (
                  <div
                    className={cn(css.other, isActiveOther && css.active)}
                    key={item.value}
                    onClick={clickOnOtherHandler}
                  >
                    <div className={css.answer}>
                      <div className={css.letter}>{item.value}</div>
                      <div className={css.text}>
                        <span>{translate(item.label)}</span>
                        {isActiveOther ? (
                          <input
                            type="text"
                            name={info.name}
                            autoFocus
                            value={formikContext.values[`${info.name}`]}
                            onChange={inputChangeHandler}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <label key={item.value}>
                  <input
                    name={info.name}
                    type="radio"
                    value={item.value}
                    checked={
                      formikContext.values[`${info.name}`]
                        ? formikContext.values[`${info.name}`].includes(item.value)
                        : false
                    }
                    onChange={radioChangeHandler}
                  />
                  <div className={css.answer}>
                    <div className={css.letter}>{item.value}</div>
                    <div className={css.text}>{translate(item.label)}</div>
                  </div>
                </label>
              );
            })}
          </div>
        </div>

        <div className={css.continueBtnWrapper}>
          <ContinueButton disabled={!formikContext.values[`${info.name}`] || isSubmiting} />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default SingleChoice;
