import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import css from './OpacityTransitionComponent.module.scss';

interface Props extends React.PropsWithChildren {
  visible: boolean;
}

const classNames = {
  enter: css.enter,
  enterActive: css.enterActive,
  exit: css.exit,
  exitActive: css.exitActive,
};

const OpacityTransitionComponent = ({ visible, children }: Props) => {
  return (
    <CSSTransition timeout={parseInt(css.transitionOpacity, 10)} in={visible} classNames={classNames} unmountOnExit>
      <SwitchTransition mode="out-in">
        <CSSTransition key={'key'} classNames={classNames} timeout={parseInt(css.transitionOpacity, 10)} unmountOnExit>
          {children}
        </CSSTransition>
      </SwitchTransition>
    </CSSTransition>
  );
};

export default OpacityTransitionComponent;
