import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { selectAuth, selectUserNameOrEmail, selectUserPictureUrl } from 'client/redux/user/selectors';
import { useAppDispatch, useAppSelector, useTranslation } from 'client/hooks';
import { USER_PROFILE_ROUTE } from 'client/constants/routes';
import { signOut } from 'client/redux/user/actions';
import defaultAvatar from 'client/assets/default-avatar.svg';

import css from './User.module.scss';

type Props = {
  className?: string;
};

const User: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const userName = useAppSelector(selectUserNameOrEmail);
  const avatarUrl = useAppSelector(selectUserPictureUrl);
  const auth = useAppSelector(selectAuth);
  const { translate: _t } = useTranslation('header');

  const onLogoutClick = () => {
    dispatch(signOut());
    (window as any).Intercom('shutdown');
  };

  return (
    <div className={cn(css.user, className)}>
      <div className={css.info}>
        <Link to={USER_PROFILE_ROUTE} className={css.name}>
          {userName}
        </Link>
        <button className={css.logoutBtn} onClick={onLogoutClick}>
          {_t('logout')}
        </button>
      </div>
      <Link to={USER_PROFILE_ROUTE}>
        <img src={avatarUrl || defaultAvatar} alt={`${userName}`} />
      </Link>
    </div>
  );
};

export default User;
