import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModel as CompanyModel } from 'models/company/interface';
import { LinkInfoItem } from 'models/company_news/interface';
import { Props as LoginPopupProps } from 'client/components/common/Login';

export type FounderPopUpData = CompanyModel['team'];
export type NewsPopUpData = LinkInfoItem[];
export type ProfileEditPopUpData = { view: 'edit-name'; value: string } | { view: 'edit-password' };
export type JoinAsInvestorPopuData = { link: string; isExternalLink: boolean };

type UI = {
  menu: 'navigation' | 'categories' | 'hidden';
  footer: 'visible' | 'hidden';
  popup:
    | {
        type: 'founders';
        data: FounderPopUpData;
      }
    | {
        type: 'news';
        data: NewsPopUpData;
      }
    | {
        type: 'profile-edit';
        data: ProfileEditPopUpData;
      }
    | {
        type: 'auth';
        data: LoginPopupProps;
      }
    | {
        type: 'join-as-investor';
        data: JoinAsInvestorPopuData;
      }
    | {
        type: 'accreditation-questionnaire';
      }
    | {
        type: 'kyc';
      }
    | {
        type: 'bank-details';
      }
    | {
        type: 'accrediatation-certification';
      }
    | {
        type: 'tax-declare';
      }
    | {
        type: 'contact-details';
      }
    | {
        type: 'questionnaire-answers';
      }
    | null;

  routesHistory: { route: string; pathname: string; search: string }[];
  bannerJoin: 'visible' | 'hidden';
};

const initialState: UI = {
  menu: 'hidden',
  footer: 'hidden',
  popup: null,
  routesHistory: [],
  bannerJoin: 'visible',
};

export const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setMenuState: (state, action: PayloadAction<UI['menu']>) => {
      state.menu = action.payload;
    },
    setFooterState: (state, action: PayloadAction<UI['footer']>) => {
      state.footer = action.payload;
    },
    pushToRoutesHistory: (state, action: PayloadAction<UI['routesHistory'][number]>) => {
      if (state.routesHistory.at(-1)?.route !== action.payload.route) {
        state.routesHistory.push(action.payload);
      }
    },
    setPopup: (state, action: PayloadAction<UI['popup']>) => {
      state.popup = action.payload;
    },
    setBannerJoinState: (state, action: PayloadAction<UI['bannerJoin']>) => {
      state.bannerJoin = action.payload;
    },
  },
});

export const { setMenuState, setFooterState, pushToRoutesHistory, setPopup, setBannerJoinState } = ui.actions;
export default ui.reducer;
