import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IModel } from 'models/other/interface';
import * as actions from './actions';

type DisclaimerModel = Omit<IModel, '_id'>;

const initialState: DisclaimerModel = {
  title: '',
  body: '',
};

export const footerDisclaimer = createSlice({
  name: 'footerDisclaimer',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.title = action.payload.title;
      state.body = action.payload.body;
    },
  },
});

export const { init } = footerDisclaimer.actions;
export default footerDisclaimer.reducer;
