import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import cn from 'classnames';
import { useHistory } from 'react-router';
import _ from 'lodash';

import { news as newsApi } from 'client/redux/api/news';
import { user as userApi } from 'client/redux/api/user';
import { companies as companiesApi } from 'client/redux/api/companies';
import { CONFIG } from 'constants/index';
import { COMPANY_REPORTS_GET_DOC_FILE } from 'api/routes';

import fileIcon from 'client/assets/icons/file.svg';
import downloadIcon from 'client/assets/icons/download.svg';
import chartImg from 'client/assets/chart-default.png';

import { ROOT, MANDATES_PAGE_ROUTE, USER_PROFILE_INVESTOR_ROUTE, USER_PROFILE_ROUTE } from 'client/constants/routes';
import { Button, ContentWrap, DonutChart, Loader } from 'client/components/common';
import { useTranslation, useResponsive, useAppSelector, useAnalytics } from 'client/hooks';

import { ScrollableBlock } from './ScrollableBlock';
import { selectUserGAStatus, selectUserRole } from 'client/redux/user/selectors';

import css from './Home.module.scss';

interface Props {
  className?: string;
}

const DONUT_CHART_PALLETE = [
  '#0757D3',
  '#FEFEFE',
  '#B4B4B4',
  '#E5AC73',
  '#FF4D79',
  '#D55EED',
  '#D32F2F',
  '#FBC02D',
  '#512DA8',
  '#00796B',
  '#586D79',
  '#C2185B',
  '#0288D1',
  '#303F9F',
  '#0097A7',
];

export function Home(props: Props) {
  const { className = '' } = props;
  const { translate: _t } = useTranslation('userProfile');
  const [isMobile] = useResponsive(['MOBILE']);
  const analytics = useAnalytics();
  const userRole = useAppSelector(selectUserRole);
  const userGAStatus = useAppSelector(selectUserGAStatus);
  const { data, isFetching } = newsApi.endpoints.getNews.useQuery(undefined);
  const { data: reportsData, isFetching: isFetchingReports } = companiesApi.endpoints.getReports.useQuery(undefined);
  const news = React.useMemo(() => (data?.success ? data.data : []), [data]);
  const reports = React.useMemo(() => (reportsData?.success ? reportsData.data : []), [reportsData]);
  const { data: portfolioData, isFetching: isFetchingPortfolio } = userApi.endpoints.getPortfolio.useQuery(null);
  const portfolio: any = React.useMemo(() => (portfolioData?.success ? portfolioData.data : []), [portfolioData]);
  const history = useHistory();

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `Investor Profile - Home`,
        page_location: `${CONFIG.domainUrl}${USER_PROFILE_ROUTE}`,
      });
    }
  }, [userGAStatus]);

  const onContinueOnboardingClick = () => {
    history.push(USER_PROFILE_INVESTOR_ROUTE);
  };

  const renderPendingActions = () => {
    return (
      <div className={cn(css.pendingAction, className)}>
        <div className={css.message}>
          <p className={css.title}>{_t('investor.pendingActions.title')}</p>
          <p className={css.text}>{_t('investor.pendingActions.text')}</p>
        </div>
        <Button className={css.button} onClick={onContinueOnboardingClick}>
          {_t('investor.pendingActions.btnLabel')}
        </Button>
      </div>
    );
  };

  const renderNewsBlock = () => {
    return (
      <ScrollableBlock className={css.news} title={_t('investor.newsTitle')}>
        {isFetching ? (
          <Loader position="absolute" />
        ) : (
          <>
            {news.length > 0 ? (
              <div className={css.newsList}>
                {news.map((item, index) => (
                  <div key={index} className={css.newsItem}>
                    <p className={css.date}>{format(new Date(item.createdAt), 'dd/MM/yy')}</p>
                    <div className={css.content}>
                      <p className={css.newsTitle}>{item.title}</p>
                      <div className={css.newsText} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={css.noDataMessage}>
                <p>{_t('investor.newsNoData')}</p>
              </div>
            )}
          </>
        )}
      </ScrollableBlock>
    );
  };

  const renderPieChart = () => {
    const total = _.sumBy(portfolio, 'amount');

    const chartData =
      isFetchingPortfolio || _.isEmpty(portfolio)
        ? []
        : _.map(portfolio, (p, i: number) => {
            return {
              color:
                portfolio.length > DONUT_CHART_PALLETE.length
                  ? (_.sample(DONUT_CHART_PALLETE) as string)
                  : DONUT_CHART_PALLETE[i],
              tooltip: `${p.name} ${((p.amount / total) * 100).toFixed(2)}%`,
              progress: p.amount / total,
            };
          });

    return (
      <ScrollableBlock className={css.pieChart} title={_t('investor.chart.title')}>
        {isFetchingPortfolio ? (
          <Loader position="absolute" />
        ) : (
          <>
            {chartData.length > 0 ? (
              <DonutChart className={css.chart} thickness={0.5} values={chartData} enableTooltips />
            ) : (
              <div className={css.noChartData}>
                <img src={chartImg} className={css.chartImgDefault} />

                <div className={css.noChartDataTop}>
                  <p>{_t('investor.chart.noDataMessage')}</p>
                  <Link to={isMobile ? MANDATES_PAGE_ROUTE : ROOT} className={css.button}>
                    {_t('investor.chart.noDataBtnLabel')}
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </ScrollableBlock>
    );
  };

  const renderReports = () => {
    const reportsDesktopData = !isMobile && !isFetchingReports ? reports : null;
    const reportsMobileData = isMobile && !isFetchingReports ? reportDataMobile(reports) : null;

    return (
      <ScrollableBlock className={css.reports} title={_t('investor.reportsTitle')}>
        {isFetchingReports ? (
          <Loader position="absolute" />
        ) : (
          <>
            {!_.isEmpty(reportsMobileData) || !_.isEmpty(reportsDesktopData) ? (
              <div className={css.reportsList}>
                {isMobile &&
                  reportsMobileData?.map((reportGroup, index: number) => (
                    <div key={index} className={css.reportItem}>
                      {reportGroup.map((report, index: number) => (
                        <>
                          {index === 0 && <p className={css.reportDate}>{report.createdAt}</p>}
                          <div className={css.reportMobileRow}>
                            <div className={css.filenameWrap}>
                              <p className={css.reportFilename}>{report.companyName}</p>
                            </div>
                            <p className={css.reportTitle}>{report.title}</p>
                            <a
                              className={css.downloadBtn}
                              href={`${CONFIG.domainUrl}${COMPANY_REPORTS_GET_DOC_FILE.replace(
                                ':id',
                                report.companyId,
                              )}?fileName=${report.document}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={downloadIcon} />
                            </a>
                          </div>
                        </>
                      ))}
                    </div>
                  ))}
                {!isMobile &&
                  reportsDesktopData?.map((report, index: number) => (
                    <div key={report._id} className={css.reportItem}>
                      <p className={css.reportDate}>{format(new Date(report.createdAt), 'dd/MM/yy')}</p>
                      <p className={css.reportTitle}>{report.companyName}</p>
                      <div className={css.filenameWrap}>
                        <img src={fileIcon} />
                        <p className={css.reportFilename}>{report.title}</p>
                      </div>
                      <a
                        className={css.downloadBtn}
                        href={`${CONFIG.domainUrl}${COMPANY_REPORTS_GET_DOC_FILE.replace(
                          ':id',
                          report.companyId,
                        )}?fileName=${report.document}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={downloadIcon} />
                      </a>
                    </div>
                  ))}
              </div>
            ) : (
              <div className={css.noDataMessage}>
                <p>{_t('investor.reportsNoData.message')}</p>
                <Link to={isMobile ? MANDATES_PAGE_ROUTE : ROOT} className={css.button}>
                  {_t('investor.reportsNoData.button')}
                </Link>
              </div>
            )}
          </>
        )}
      </ScrollableBlock>
    );
  };

  const reportDataMobile = (reports: any) => {
    const goodDateArr = _.map(reports, (r) => {
      return { ...r, createdAt: format(new Date(r.createdAt), 'dd/MM/yy') };
    });
    return _.values(_.groupBy(goodDateArr, 'createdAt'));
  };

  return (
    <div className={cn(css.home, className)}>
      <ContentWrap>
        {userRole !== 'INVESTOR_LEVEL_3' && renderPendingActions()}
        <div className={css.newsAndPieChart}>
          {renderNewsBlock()}
          {renderPieChart()}
        </div>
        {renderReports()}
      </ContentWrap>
    </div>
  );
}
