import React from 'react';
import cn from 'classnames';

import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { transitionClassNames } from 'client/utils/css-transition-classnames';

import SelectStep from './SelectStep';
import ConfirmationStep from './ConfirmationStep';

import css from './JoinAsInvestor.module.scss';

export type Step = 'select' | 'confirmation';

export type CountryInfo = {
  label: string;
  value: string;
};

export type ConfirmationType = '1' | '2';

export const COUNTRIES = [
  { label: 'Austria', value: 'austria' },
  { label: 'France', value: 'france' },
  { label: 'Denmark', value: 'denmark' },
  { label: 'Germany', value: 'germany' },
  { label: 'Japan', value: 'japan' },
  { label: 'China', value: 'china' },
  { label: 'Thailand', value: 'thailand' },
  { label: 'USA', value: 'usa' },
  { label: 'Canada', value: 'canada' },
] as const;

interface Props {
  data: {
    link: string;
    isExternalLink: boolean;
  };
}

const JoinAsInvestor = (props: Props) => {
  const { link, isExternalLink } = props.data;
  const [step, setStep] = React.useState<Step>('select');
  const [selectedCountry, setSelectedCountry] = React.useState<string>('');

  const setCountry = (country: string) => {
    setSelectedCountry(country);
    setStep('confirmation');
  };

  const stepHandler = (step: Step) => {
    setStep(step);
  };

  const renderStep = (step: Step) => {
    switch (step) {
      case 'select':
        return <SelectStep setCountry={setCountry} stepHandler={stepHandler} />;

      case 'confirmation':
        return <ConfirmationStep country={selectedCountry} link={link} isExternalLink={isExternalLink} />;
      default:
        break;
    }
  };

  return (
    <div className={cn(css.popupWrapper, step === 'confirmation' && css.confirmation)}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={step}
          timeout={300}
          classNames={contentTransitionClassNames}
          appear
          mountOnEnter
          unmountOnExit
        >
          {renderStep(step)}
        </CSSTransition>
      </SwitchTransition>

      <div className={cn(css.blurredBlock)} />
    </div>
  );
};

const contentTransitionClassNames = transitionClassNames(css, 'stepTransition');

export default JoinAsInvestor;
