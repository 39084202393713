import React from 'react';
import cn from 'classnames';

import { useTranslation } from 'client/hooks';

import css from './Title.module.scss';

interface Props {
  text: string;
  size?: 'm' | 'l';
}

const Title = ({ text, size = 'm' }: Props) => {
  const { translate } = useTranslation();
  return <h3 className={cn(css.title, css[size])}>{text}</h3>;
};

export default Title;
