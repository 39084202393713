import { Types } from 'mongoose';

import { ObjectValues } from 'types/object-values';

export interface IModel {
  _id: Types.ObjectId;
  companyId: Types.ObjectId;
  legalName: string;
  about: string;
  companySize: string;
  keyInformation: string;
  dealTerms: string;
  shortDescription: string;
  prevRounds: number;
  investmentTerms: string;
  termsOfDeal: string;
  raised: string;
  raisedFieldLabel: string;
  seriesFunding: string;
  investmentStatusTitle: string;
  investmentStatusColor: string;
  valuation: number | string;
  progressBarPercentage: number;
  isProgressBarVisible: boolean;
  clip: string;
  leadInvestorInfo: Array<{ name: string; logo: string; description: string }>;
  documents: Array<{ title: string; file: string }>;
  videoGallery: Array<{ title: string; file: string }>;
  investmentStatus: InvestmentStatus;
  recruitmentTotalAmount: number;
  investmentOffersDeadline: Date;
  isVisible: boolean;
}

export const INVESTMENT_STATUS = {
  OPEN_FOR_INVESTMENTS: 'OPEN_FOR_INVESTMENTS',
  OVER_SUBSCRIBED: 'OVER_SUBSCRIBED',
  INVESTMENT_ENDED: 'INVESTMENT_ENDED',
} as const;

export type InvestmentStatus = ObjectValues<typeof INVESTMENT_STATUS>;
