import { ROUTE as CREATE_ROUTE, Params as CreateParams, Result as CreateResult } from 'api/contact_us/create/params';
import { ApiResponse } from 'src/types/api-response';

import api from '..';

export const contactUs = api.injectEndpoints({
  endpoints: (builder) => ({
    contactUs: builder.mutation<ApiResponse<CreateResult>, CreateParams>({
      query: (params) => ({
        method: 'POST',
        url: CREATE_ROUTE,
        body: params,
      }),
      invalidatesTags: () => [{ type: 'CONTACT_US', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
