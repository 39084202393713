import React from 'react';

import { useTranslation, useAppSelector, useAppDispatch } from 'client/hooks';
import { selectUserNameOrEmail } from 'client/redux/user/selectors';
import { setPopup } from 'client/redux/ui';

import Icon from '../../../Icon';
import Button from '../../../Button';
import css from './Welcome.module.scss';

interface Props {
  setScreen: () => void;
}
const Welcome = ({ setScreen }: Props) => {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();
  const userName = useAppSelector(selectUserNameOrEmail);

  return (
    <div className={css.welcomeWrapper}>
      <div className={css.welcomeWrapperI}>
        <h1 className={css.title}>{translate(`questionnaire.welcome.title`).replace('{name}', userName)}</h1>
        <p className={css.subtitle}>{translate(`questionnaire.welcome.subtitle`)}</p>
        <div className={css.cooperationText}>
          <Icon type="time-left" className={css.timeLeftIcon} />
          <p>{translate(`questionnaire.welcome.cooperationText`)}</p>
        </div>
        <p className={css.additionalText}>{translate(`questionnaire.welcome.additionalText`)}</p>
      </div>

      <div className={css.btnWrapper}>
        <Button type="button" className={css.continueButton} onClick={() => setScreen()}>
          {translate('questionnaire.navigationButtons.continue')}
        </Button>
        <Button type="button" className={css.cancelButton} onClick={() => dispatch(setPopup(null))}>
          {translate('questionnaire.navigationButtons.cancel')}
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
