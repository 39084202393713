import * as yup from 'yup';

import * as routes from 'api/routes';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = { companyId?: string; fundId?: string };
export type Result = void;

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape(
  {
    fundId: yup
      .string()
      .optional()
      .when(['companyId'], {
        is: (companyId: string) => !companyId,
        then: yup.string().required(VALIDATION_ERROR.REQUIRED),
      }),
    companyId: yup
      .string()
      .optional()
      .when(['fundId'], {
        is: (fundId: string) => !fundId,
        then: yup.string().required(VALIDATION_ERROR.REQUIRED),
      }),
    userId: yup.string().required(VALIDATION_ERROR.REQUIRED),
  },
  [['companyId', 'fundId']],
);

export const ROUTE = routes.STATISTICS_UPDATE;
