import { createSelector } from 'reselect';
import { RootState } from '..';

export const selectUser = (state: RootState) => {
  return state.user;
};

export const selectUserRole = (state: RootState) => {
  return state.user.role;
};

export const selectUserPictureUrl = createSelector(selectUser, (user) => {
  return user.pictureUrl;
});

export const selectUserId = createSelector(selectUser, (user) => {
  return user._id;
});

export const selectUserAuthProvider = createSelector(selectUser, (user) => {
  return user.provider;
});

export const selectAuth = createSelector(selectUser, (user) => {
  return user.auth;
});

export const selectData = createSelector(selectUser, (user) => {
  return { favorite: user.favoriteCompanies, voted: user.votedCompanies };
});

export const selectUserNameOrEmail = createSelector(selectUser, (user) => {
  return user.name || user.email || '';
});

export const selectUserName = createSelector(selectUser, (user) => {
  return user.name || '';
});

export const selectUserOnboarding = createSelector(selectUser, (user) => {
  return user.onboarding;
});

export const selectUserEmail = createSelector(selectUser, (user) => {
  return user.email;
});

export const selectUserGAStatus = createSelector(selectUser, (user) => {
  return user.isGAEnabled;
});
