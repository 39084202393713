import React from 'react';
import { getAuth, signInWithPopup, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth';

import { useAppDispatch, useResponsive } from 'client/hooks';
import { app as firebaseApp } from 'src/firebase-client';
import { signIn } from 'client/redux/user/actions';

import { State as AuthState } from '../../state';

type Props = {
  action: AuthState['authType'];
  onSuccess: () => unknown;
};
const GoogleProvider: React.FC<Props> = ({ action, onSuccess }) => {
  const appDispatch = useAppDispatch();
  const [isDesktop] = useResponsive('DESKTOP');

  React.useEffect(() => {
    const init = async () => {
      if (isDesktop === undefined) return;

      try {
        const provider = new GoogleAuthProvider();
        const auth = getAuth(firebaseApp);

        // optional:
        // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

        if (!isDesktop) window.localStorage.setItem('isCheckRedirectSignInRequired', 'true');
        await auth.signOut();

        const result = isDesktop ? await signInWithPopup(auth, provider) : await signInWithRedirect(auth, provider);
        const { user } = result;

        if (!user.email || !user.uid) {
          throw new Error('User email or uid is not provided');
        }

        const signInResult = await appDispatch(
          signIn({
            provider: 'google',
            email: user.email,
            pictureUrl: user.photoURL ?? '',
            name: user.displayName ?? '',
          }),
        ).unwrap();

        if (signInResult.success) {
          onSuccess();
        } else {
          console.info('signInResultError', signInResult);
        }
      } catch (error) {
        console.log('Unable to get user data', (error as Error).message || error);
      }
    };

    init();
  }, [appDispatch, isDesktop]);

  return null;
};

export default GoogleProvider;
