import React from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { LinkInfoItem } from 'models/company_news/interface';

import css from './NewsItem.module.scss';

interface Props {
  className?: string;
  info: LinkInfoItem;
}

const NewsItem = ({ className = '', info }: Props) => {
  if (!info.success) return;
  return (
    <div className={cn(className, css.newsItem)}>
      <a href={info.link} target="_blank" rel="noreferrer">
        <div className={css.image}>
          <img src={info.image} />
        </div>
        <div className={css.content}>
          <div className={css.title}>
            News {info.published && <span>{`${format(new Date(info.published), 'MMM , yyyy')}`}</span>}
          </div>
          <div className={css.description}>
            {info.author && <span>{info.author} - </span>}
            {info.title}
          </div>
        </div>
      </a>
    </div>
  );
};

export default NewsItem;
