import * as yup from 'yup';
import countries from 'utils/countries.json';
import { Onboarding } from 'models/user/onboarding';
import { filterDependencies, configureStep, getStageCurrentStepName, getStageStep } from '../stage';
import { InputType, constants } from '../input-types';
import { getCoutryNameByResidency } from '../self-declaration';
import { VALIDATION_ERROR } from 'endpoints/index';

/**
 * Returns accreditation finalization form fields
 */
export function getContactDetails(data: Onboarding) {
  const t = (path: string) => `onboardingFinalization.contactDetails.${path}`;
  return {
    steps: filterDependencies(
      [
        configureStep(
          {
            type: InputType.TEXT,
            name: 'firstName',
            defaultValue: '',
            label: t('form.firstName.label'),
            shortWidth: true,
            schema: yup
              .string()
              .typeError(`${VALIDATION_ERROR.REQUIRED}`)
              .min(constants.TEXT_MIN_LENGTH)
              .max(constants.TEXT_MAX_LENGTH)
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'lastName',
            defaultValue: '',
            label: t('form.lastName.label'),
            shortWidth: true,
            schema: yup
              .string()
              .typeError(`${VALIDATION_ERROR.REQUIRED}`)
              .min(constants.TEXT_MIN_LENGTH)
              .max(constants.TEXT_MAX_LENGTH)
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.DATE,
            name: 'dateOfBirth',
            shortWidth: true,
            defaultValue: '',
            label: t('form.dateOfBirth.label'),
            schema: yup.date().typeError(`${VALIDATION_ERROR.REQUIRED}`).required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.AUTO_COMPLETE,
            name: 'countryOfBirth',
            shortWidth: true,
            defaultValue: [],
            label: t('form.countryOfBirth.label'),
            options: countries,
            schema: yup.array().of(yup.string()).min(1, VALIDATION_ERROR.REQUIRED).required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.AUTO_COMPLETE,
            name: 'countryOfResidence',
            defaultValue: [],
            label: t('form.countryOfResidence.label'),
            options: countries,
            schema: yup.array().of(yup.string()).min(1, VALIDATION_ERROR.REQUIRED).required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'addressLine_1',
            label: t('form.addressLine1.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.TEXT_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .nullable(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'addressLine_2',
            label: t('form.addressLine2.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.TEXT_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .nullable(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'city',
            label: t('form.city.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.TEXT_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .nullable(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'region',
            label: t('form.region.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.TEXT_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .nullable(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'postalCode',
            label: t('form.postalCode.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.TEXT_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .matches(/^[0-9]*$/, VALIDATION_ERROR.INVALID_CHARACTERS)
              .nullable(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.AUTO_COMPLETE,
            name: 'country',
            label: t('form.country.label'),
            explanation: '',
            defaultValue: [],
            options: countries,
            schema: yup.array().of(yup.string()),
          },
          {
            ...data,
            form: { ...data.form, country: data.form.country ?? getCoutryNameByResidency(data.agreement.residency) },
          },
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'phone',
            label: t('form.phone.label'),
            explanation: '',
            schema: yup
              .string()
              .matches(/^\+[1-9]\d{10,14}$/g, VALIDATION_ERROR.INVALID_PHONE_NUMBER)
              .nullable(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.MEDIA,
            name: 'photoId',
            label: t('form.photoId.label'),
            explanation: '',
            schema: yup
              .mixed()
              .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value) => {
                return value && typeof value === 'object'
                  ? ['image/png', 'image/jpeg', 'image/jpg'].includes(value.mimetype || value.type)
                  : true;
              })
              .test('size', VALIDATION_ERROR.INVALID_SIZE, (value) => {
                return value && typeof value === 'object' ? value.size < 1024 * 1024 * 3 : true;
              }),
          },
          data,
        ),
      ],
      data,
    ),
  };
}

export function getCurrentStep(data: Onboarding) {
  const currentStepName = getStageCurrentStepName(getContactDetails(data).steps);
  return currentStepName ? getStep(data, currentStepName) : null;
}

export function getStep(data: Onboarding, targetStep: string) {
  return getStageStep(getContactDetails(data).steps, targetStep);
}
