import {
  ROUTE as CREATE_ROUTE,
  Params as CreateParams,
  Result as CreateResult,
} from 'api/company_claims/create/params';
import { ApiResponse } from 'src/types/api-response';

import api from '..';

export const companyClaims = api.injectEndpoints({
  endpoints: (builder) => ({
    createClaim: builder.mutation<ApiResponse<CreateResult>, CreateParams>({
      query: (params) => ({
        method: 'POST',
        url: CREATE_ROUTE,
        body: params,
      }),
      invalidatesTags: () => [{ type: 'COMPANY_CLAIMS', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
