import React from 'react';

import { CONFIG } from 'src/constants';

type Props = {
  onSuccess: () => unknown;
};

const LinkedInProvider: React.FC<Props> = () => {
  React.useEffect(() => {
    const init = async () => {
      const url = `https://www.linkedin.com/oauth/v2/authorization`;
      const params = new URLSearchParams({
        response_type: 'code',
        prompt: 'consent',
        client_id: CONFIG.auth.linkedIn.client_id,
        redirect_uri: CONFIG.auth.linkedIn.redirect_uri,
        scope: ['openid', 'profile', 'email'].join(' '),
      });

      window.localStorage.setItem('isCheckRedirectSignInRequired', 'true');
      window.open(`${url}?${params.toString()}`, '_self');
    };

    init();
  }, []);

  return null;
};

export default LinkedInProvider;
