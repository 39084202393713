import React from 'react';
import cn from 'classnames';

import { useTranslation, useAppSelector } from 'client/hooks';
import { selectUserName, selectUserPictureUrl } from 'client/redux/user/selectors';
import defaultAvatar from 'client/assets/default-avatar.svg';

import css from './SuccessMessages.module.scss';

type Props = {
  onStarBrowsingBtnClick: () => unknown;
};

const SuccessLogin: React.FC<Props> = ({ onStarBrowsingBtnClick }) => {
  const { translate: _t } = useTranslation('popups');
  const userName = useAppSelector(selectUserName);
  const avatar = useAppSelector(selectUserPictureUrl);

  return (
    <div className={cn(css.success, css.loginSuccess)}>
      <div className={css.avatar}>
        <img src={avatar || defaultAvatar} alt="avatar" />
      </div>
      <div className={css.name}>
        {_t('login.successMessages.successLogin.title')} {userName}
      </div>
      <div className={css.greetingMessage}>{_t('login.successMessages.successLogin.greetingText')}</div>
      <button onClick={onStarBrowsingBtnClick} type="button" className={css.startButton}>
        {_t('login.successMessages.successLogin.startButton')}
      </button>
    </div>
  );
};

export default SuccessLogin;
