import React from 'react';

import { IModel } from 'models/company/interface';

import { useTranslation } from 'client/hooks';

import Founder from '../Founder';
import { ModalWrapper } from '../ModalWrapper';

import css from './FoundersPopup.module.scss';

interface Props {
  team: IModel['team'];
}

const FoundersPopup = ({ team }: Props) => {
  const { translate } = useTranslation();

  return (
    <ModalWrapper title={translate('popups.founders.title')}>
      <div className={css.personsWrapper}>
        <div className={css.persons}>
          {team.map((teamMember) => {
            return <Founder key={teamMember.name} personInfo={teamMember} className={css.person} />;
          })}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default FoundersPopup;
