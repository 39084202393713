import React from 'react';
import { LinkInfoItem } from 'models/company_news/interface';
import { useTranslation } from 'client/hooks';

import NewsItem from '../NewsItem';
import { ModalWrapper } from '../ModalWrapper';

import css from './NewsPopup.module.scss';

interface Props {
  news: LinkInfoItem[];
}

const NewsPopup = ({ news }: Props) => {
  const { translate } = useTranslation();

  return (
    <ModalWrapper title={translate('popups.news.title')}>
      <div className={css.newsWrapper}>
        <div className={css.news}>
          {news.map((newsItem, index) => {
            if (!newsItem.success) return;
            return <NewsItem info={newsItem} key={`${newsItem.title}${index}`} className={css.newsItem} />;
          })}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default NewsPopup;
