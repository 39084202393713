import * as yup from 'yup';

import * as routes from 'api/routes';
import { IModel } from 'models/category/interface';

export type Params = {
  id: string;
};
export type Result = (Omit<IModel, '_id'> & { _id: string }) | null;

export const paramsSchema = yup.object().shape({
  id: yup.string().required(),
});

export const ROUTE = routes.CATEGORY_GET;
export const WEBSITE_ROUTE = routes.CATEGORY_WEBSITE_GET;
