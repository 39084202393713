import * as yup from 'yup';
import YupPassword from 'yup-password';

import { VALIDATION_ERROR } from 'endpoints/index';
import * as routes from 'api/routes';

export type Params = { oldPassword: string; newPassword: string; confirmNewPassword: string };
export type Result = undefined;

YupPassword(yup);

export const paramsSchema = yup.object().shape({
  oldPassword: yup.string().required(VALIDATION_ERROR.REQUIRED),
  newPassword: yup
    .string()
    .min(8, VALIDATION_ERROR.TOO_SHORT)
    .required(VALIDATION_ERROR.REQUIRED)
    .minLowercase(1, VALIDATION_ERROR.AT_LEAST_ONE_LOWER_LETTER)
    .minUppercase(1, VALIDATION_ERROR.AT_LEAST_ONE_UPPER_LETTER)
    .minNumbers(1, VALIDATION_ERROR.AT_LEAST_ONE_NUMBER),
  confirmNewPassword: yup
    .string()
    .min(8, VALIDATION_ERROR.TOO_SHORT)
    .required(VALIDATION_ERROR.REQUIRED)
    .minLowercase(1, VALIDATION_ERROR.AT_LEAST_ONE_LOWER_LETTER)
    .minUppercase(1, VALIDATION_ERROR.AT_LEAST_ONE_UPPER_LETTER)
    .minNumbers(1, VALIDATION_ERROR.AT_LEAST_ONE_NUMBER)
    .oneOf([yup.ref('newPassword')], VALIDATION_ERROR.PASSWORDS_MISMATCH),
});

export const ROUTE = routes.USERS_CHANGE_PASSWORD;
