import React from 'react';

import { NewsPopUpData } from 'client/redux/ui';
import { NewsItem } from 'client/components/pages/CompanyMobile/NewsItem';
import css from './News.module.scss';

interface Props {
  data: NewsPopUpData;
}

function Founders(props: Props) {
  const { data } = props;

  return (
    <div className={css.news}>
      {data.map((item, index) => (
        <NewsItem className={css.newsItem} key={index} data={item} />
      ))}
    </div>
  );
}

export { Founders };
export default Founders;
