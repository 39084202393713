import * as yup from 'yup';

import * as routes from 'api/routes';
import { IModel, OFFER_STATUS, OfferStatus } from 'models/offer/interface';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = Omit<IModel, '_id' | 'userId' | 'fundId' | 'feePercentage' | 'companyId' | 'createdAt'> & {
  fundId?: string;
  userId: string;
  companyId?: string;
};
export type Result = void;

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape(
  {
    fundId: yup.string().when(['companyId'], {
      is: (companyId: string) => !companyId,
      then: yup.string().required(VALIDATION_ERROR.REQUIRED),
    }),
    companyId: yup.string().when(['fundId'], {
      is: (fundId: string) => !fundId,
      then: yup.string().required(VALIDATION_ERROR.REQUIRED),
    }),
    userId: yup.string().required(VALIDATION_ERROR.REQUIRED),
    status: yup.mixed<OfferStatus>().oneOf(Object.values(OFFER_STATUS)).default('NEW'),
    value: yup.number().required(VALIDATION_ERROR.REQUIRED),
    isFeesIncluded: yup.boolean().required(VALIDATION_ERROR.REQUIRED),
    notes: yup.string().notRequired(),
  },
  [['companyId', 'fundId']],
);

export const ROUTE = routes.OFFER_CREATE;
