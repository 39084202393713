import * as yup from 'yup';

import * as routes from 'api/routes';
import { IModel } from 'models/mandate/interface';
import { VALIDATION_ERROR } from 'endpoints/index';
import { IModel as CompanyModel } from 'models/company/interface';
import { CompanyWithCategoryInfo } from 'api/companies/public/get/params';

type CompanyFields = Pick<
  CompanyModel,
  'logo' | 'name' | 'team' | 'avatarVideo' | 'founded' | 'websiteLink' | 'country' | 'socials' | 'sourceOfInformation'
>;
type MandateFields = Omit<IModel, 'companyId' | 'categoryIds' | 'investmentOffersDeadline' | '_id'> & {
  companyId: string;
  categories: CompanyWithCategoryInfo['categories'];
  _id: string;
  investmentOffersDeadline: string;
};

export type Params = { id: string };
export type Result = {
  mandate: (Partial<CompanyFields> & Partial<MandateFields>) | null;
  seoData: { name: string; description: string };
};

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape({
  id: yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.W_MANDATE_GET;
