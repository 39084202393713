import React from 'react';
import { useTranslation } from 'client/hooks';
import { Icon } from 'client/components/common';
import css from './Thanks.module.scss';

const Thanks = () => {
  const { translate } = useTranslation();
  const [isRender, setIsRender] = React.useState(false);

  React.useEffect(() => {
    setIsRender(true);
  }, []);

  if (!isRender) return null;
  return (
    <div className={css.thanks}>
      <div className={css.iconWrapper}>
        <Icon className={css.icon} type="check-rounded" color="#0757d3" secondaryColor="rgb(104,164, 255)" />
      </div>
      <div className={css.text}>
        <p dangerouslySetInnerHTML={{ __html: translate('contactUsPage.thanks.title') }}></p>
        <p dangerouslySetInnerHTML={{ __html: translate('contactUsPage.thanks.subtitle') }}></p>
      </div>
    </div>
  );
};

export default Thanks;
