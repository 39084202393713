import omit from 'lodash/omit';
import { format } from 'date-fns';
import { AppCheckTokenResult, getToken } from 'firebase/app-check';

import {
  ROUTE as UPDATE_ROUTE,
  Params as UpdateParams,
  Result as UpdateResult,
} from 'api/users/website/onboarding/update/params';

import { ApiResponse } from 'src/types/api-response';
import { API_HOST } from 'api/routes';

import {
  setQuestionnaire,
  setSelfDeclaration,
  setPersonalDetails,
  setBankDetails,
  setNDA,
  setCertification,
  setTaxDeclaration,
  setContactDetails,
  setKYC,
} from 'client/redux/user';

import { renameFile } from 'utils/rename-file';
import api from '..';
import { RootState } from 'client/redux';
import { getAppCheck } from 'src/firebase-client';

export const onboarding = api.injectEndpoints({
  endpoints: (builder) => ({
    setSelfDecalaration: builder.mutation<ApiResponse<UpdateResult>, UpdateParams & { intent: 'self_declaration' }>({
      queryFn: (params) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          formData.append('intent', params.intent);
          if (params.agree) formData.append('agree', params.agree.toString());
          if (params.residency) formData.append('residency', params.residency as string);

          xmlHttpRequest(formData)
            .then((payload) => resolve(payload))
            .catch((e) => reject(e));
        });
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const agreement = state.user.onboarding.agreement;
        dispatch(setSelfDeclaration(params));
        try {
          await queryFulfilled;
          dispatch(api.util.invalidateTags([{ type: 'MANDATE', id: 'ALL' }]));
        } catch {
          dispatch(setSelfDeclaration(agreement));
        }
      },
    }),
    setPersonalDetails: builder.mutation<ApiResponse<UpdateResult>, UpdateParams & { intent: 'personal_details' }>({
      queryFn: (params) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          formData.append('intent', params.intent);
          if (params.countryOfBirth) formData.append('countryOfBirth', JSON.stringify(params.countryOfBirth));
          if (params.countryOfResidence)
            formData.append('countryOfResidence', JSON.stringify(params.countryOfResidence));
          if (params.dateOfBirth) formData.append('dateOfBirth', params.dateOfBirth as string);
          if (params.firstName) formData.append('firstName', params.firstName as string);
          if (params.lastName) formData.append('lastName', params.lastName as string);
          if (params.hasNonResidentalCitizenship === false || params.hasNonResidentalCitizenship)
            formData.append('hasNonResidentalCitizenship', params.hasNonResidentalCitizenship.toString());
          if (params.nonResidentalCitizenships)
            formData.append('nonResidentalCitizenships', JSON.stringify(params.nonResidentalCitizenships));

          xmlHttpRequest(formData)
            .then((payload) => resolve(payload))
            .catch((e) => reject(e));
        });
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const personalDetails = state.user.onboarding.form;
        dispatch(setPersonalDetails(omit(params, 'intent')));
        try {
          await queryFulfilled;
        } catch {
          dispatch(setPersonalDetails(personalDetails));
        }
      },
    }),
    setQuesstionare: builder.mutation<ApiResponse<UpdateResult>, UpdateParams & { intent: 'questionnaire' }>({
      queryFn: (params) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          formData.append('intent', params.intent);
          if (params.USRelation) formData.append('USRelation', JSON.stringify(params.USRelation));
          if (params.appliesTo) formData.append('appliesTo', JSON.stringify(params.appliesTo));
          if (params.experience) formData.append('experience', JSON.stringify(params.experience));
          if (params.experienceExplanation)
            formData.append('experienceExplanation', params.experienceExplanation as string);
          if (params.financeEducation || params.financeEducation === false)
            formData.append('financeEducation', params.financeEducation.toString());
          if (params.investingAs) formData.append('investingAs', JSON.stringify(params.investingAs));
          if (params.investmentPortionOfTotal)
            formData.append('investmentPortionOfTotal', JSON.stringify(params.investmentPortionOfTotal));
          if (params.liquidAssetsDuration)
            formData.append('liquidAssetsDuration', JSON.stringify(params.liquidAssetsDuration));
          if (params.longTermInvestmentLiquidityNeeds || params.longTermInvestmentLiquidityNeeds === false)
            formData.append('longTermInvestmentLiquidityNeeds', params.longTermInvestmentLiquidityNeeds.toString());
          if (params.objectiveDescription)
            formData.append('objectiveDescription', JSON.stringify(params.objectiveDescription));
          if (params.occupation) formData.append('occupation', JSON.stringify(params.occupation));
          if (params.privateEquity) formData.append('privateEquity', params.privateEquity as string);
          if (params.prominentPublicRole || params.prominentPublicRole === false)
            formData.append('prominentPublicRole', params.prominentPublicRole.toString());
          if (params.prominentPublicRoleRelation || params.prominentPublicRoleRelation === false)
            formData.append('prominentPublicRoleRelation', params.prominentPublicRoleRelation.toString());
          if (params.sourceOfFunds) formData.append('sourceOfFunds', JSON.stringify(params.sourceOfFunds));
          if (params.sourceOfSavings) formData.append('sourceOfSavings', params.sourceOfSavings as string);
          if (params.timeHorizon) formData.append('timeHorizon', JSON.stringify(params.timeHorizon));
          if (params.totalLossAcceptance || params.totalLossAcceptance === false)
            formData.append('totalLossAcceptance', JSON.stringify(params.totalLossAcceptance));

          xmlHttpRequest(formData)
            .then((payload) => resolve(payload))
            .catch((e) => reject(e));
        });
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const questionnaire = state.user.onboarding.form;
        dispatch(setQuestionnaire(params));
        try {
          await queryFulfilled;
        } catch {
          dispatch(setQuestionnaire({ ...questionnaire, intent: 'questionnaire' }));
        }
      },
    }),

    setNDA: builder.mutation<ApiResponse<UpdateResult>, UpdateParams & { intent: 'nda' }>({
      queryFn: (params) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          formData.append('intent', params.intent);

          if (params.signatureId) formData.append('signatureId', params.signatureId as string);
          if (params.signatureStatus) formData.append('signatureStatus', params.signatureStatus as string);
          if (params.signatureDate) formData.append('signatureDate', params.signatureDate as string);

          xmlHttpRequest(formData)
            .then((payload) => resolve(payload))
            .catch((e) => reject(e));
        });
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const data = state.user.onboarding.form;
        dispatch(setNDA(omit(params, 'intent')));
        try {
          await queryFulfilled;
        } catch {
          dispatch(setNDA({ ...data }));
        }
      },
    }),
    setKYC: builder.mutation<ApiResponse<UpdateResult>, UpdateParams & { intent: 'kyc' }>({
      queryFn: (params) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          formData.append('intent', params.intent);

          if (params.kycId) formData.append('kycId', params.kycId as string);
          if (params.kycStatus) formData.append('kycStatus', params.kycStatus as string);

          const request = new XMLHttpRequest();

          request.onload = () => {
            try {
              const response = JSON.parse(request.response);
              resolve({ data: response as ApiResponse<UpdateResult> });
            } catch (e) {
              reject(e);
            }
          };

          request.open('POST', `${API_HOST}${UPDATE_ROUTE}`);
          request.onerror = () => {
            reject('Network error');
          };
          request.send(formData);
        });
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const data = state.user.onboarding.form;

        dispatch(setKYC(omit(params, 'intent')));
        try {
          await queryFulfilled;
        } catch {
          dispatch(setKYC({ ...data }));
        }
      },
    }),

    setBankDetails: builder.mutation<ApiResponse<UpdateResult>, UpdateParams & { intent: 'bank_details' }>({
      queryFn: (params) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          formData.append('intent', params.intent);
          if (params.bankCountry) formData.append('bankCountry', JSON.stringify(params.bankCountry));
          if (params.bankName) formData.append('bankName', params.bankName as string);
          if (params.bankCity) formData.append('bankCity', params.bankCity as string);
          if (params.bankPostalCode) formData.append('bankPostalCode', params.bankPostalCode as string);
          if (params.bankAddressLine_1) formData.append('bankAddressLine_1', params.bankAddressLine_1 as string);
          if (params.bankAddressLine_2) formData.append('bankAddressLine_2', params.bankAddressLine_2 as string);
          if (params.bankAccountName) formData.append('bankAccountName', params.bankAccountName as string);
          if (params.bankAccountNumber) formData.append('bankAccountNumber', params.bankAccountNumber as string);
          if (params.bankSwiftCode) formData.append('bankSwiftCode', params.bankSwiftCode as string);

          xmlHttpRequest(formData)
            .then((payload) => resolve(payload))
            .catch((e) => reject(e));
        });
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const bankDetails = state.user.onboarding.form;
        dispatch(setBankDetails(omit(params, 'intent')));
        try {
          await queryFulfilled;
        } catch {
          dispatch(setBankDetails(bankDetails));
        }
      },
    }),
    setCertification: builder.mutation<ApiResponse<UpdateResult>, UpdateParams & { intent: 'certification' }>({
      queryFn: (params) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append('intent', params.intent);

          if (params.certificationId)
            formData.append('certificationId', renameFile(params.certificationId as File, 'certificationId'));

          xmlHttpRequest(formData)
            .then((payload) => resolve(payload))
            .catch((e) => reject(e));
        });
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const certificationDetails = state.user.onboarding.form;

        try {
          await queryFulfilled;
          if (params.certificationId as File) {
            const fileExtension = (params.certificationId as File).name.split('.').pop();
            setTimeout(() => {
              dispatch(
                setCertification({
                  certificationId: `certification-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.${fileExtension}`,
                }),
              );
            }, 300);
          }
        } catch {
          dispatch(setCertification(certificationDetails));
        }
      },
    }),
    setTaxDeclaration: builder.mutation<ApiResponse<UpdateResult>, UpdateParams & { intent: 'tax_declaration' }>({
      queryFn: (params) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          formData.append('intent', params.intent);

          if (params.taxCountryDetails && Array.isArray(params.taxCountryDetails)) {
            params.taxCountryDetails.forEach((item, index) => {
              //eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              formData.append(`taxCountryDetails.${index}.country`, item?.country as string);
              //eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              formData.append(`taxCountryDetails.${index}.taxId`, item?.taxId as string);
            });
          }

          if (params.taxDeclarationId) formData.append('taxDeclarationId', params.taxDeclarationId as string);
          if (params.taxDeclarationStatus)
            formData.append('taxDeclarationStatus', params.taxDeclarationStatus as string);
          if (params.taxDeclarationDate) formData.append('taxDeclarationDate', params.taxDeclarationDate as string);

          xmlHttpRequest(formData)
            .then((payload) => resolve(payload))
            .catch((e) => reject(e));
        });
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const taxDeclarationDetails = state.user.onboarding.form;
        dispatch(setTaxDeclaration(omit(params, 'intent')));
        try {
          await queryFulfilled;
        } catch {
          dispatch(setTaxDeclaration(taxDeclarationDetails));
        }
      },
    }),
    setContactDetails: builder.mutation<ApiResponse<UpdateResult>, UpdateParams & { intent: 'contact_details' }>({
      queryFn: (params) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          formData.append('intent', params.intent);
          if (params.countryOfBirth) formData.append('countryOfBirth', JSON.stringify(params.countryOfBirth));
          if (params.countryOfResidence)
            formData.append('countryOfResidence', JSON.stringify(params.countryOfResidence));
          if (params.dateOfBirth) formData.append('dateOfBirth', params.dateOfBirth as string);
          if (params.firstName) formData.append('firstName', params.firstName as string);
          if (params.lastName) formData.append('lastName', params.lastName as string);
          if (params.addressLine_1) formData.append('addressLine_1', params.addressLine_1 as string);
          if (params.addressLine_2) formData.append('addressLine_2', params.addressLine_2 as string);
          if (params.city) formData.append('city', params.city as string);
          if (params.region) formData.append('region', params.region as string);
          if (params.postalCode) formData.append('postalCode', params.postalCode as string);
          if (params.country) formData.append('country', JSON.stringify(params.country));
          if (params.postalCode) formData.append('postalCode', params.postalCode as string);
          if (params.phone) formData.append('phone', params.phone as string);
          if (params.photoId) {
            if (typeof params.photoId === 'string') {
              formData.append('photoId', params.photoId as string);
            } else {
              formData.append('photoId', renameFile(params.photoId as File, 'photoId'));
            }
          }

          xmlHttpRequest(formData)
            .then((payload) => resolve(payload))
            .catch((e) => reject(e));
        });
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const contactDetails = state.user.onboarding.form;

        try {
          await queryFulfilled;
          if (params.photoId as File) {
            const fileExtension = (params.photoId as File).name.split('.').pop();
            setTimeout(() => {
              dispatch(
                setContactDetails({
                  ...omit(params, 'intent'),
                  photoId: `photoId-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.${fileExtension}`,
                }),
              );
            }, 300);
          } else {
            dispatch(
              setContactDetails({
                ...omit(params, 'intent'),
              }),
            );
          }
        } catch {
          dispatch(setContactDetails(contactDetails));
        }
      },
    }),
  }),
  overrideExisting: false,
});

const xmlHttpRequest = async (
  formData: FormData,
  url = `${API_HOST}${UPDATE_ROUTE}`,
  method = 'POST',
): Promise<{ data: ApiResponse<UpdateResult> }> => {
  let appCheckTokenResponse: AppCheckTokenResult | null = null;

  try {
    const appCheck = getAppCheck();

    if (appCheck) appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
    else throw new Error('appCheck is null');
  } catch (err) {
    console.info('AppCheck getToken error', err);
  }

  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.onload = () => {
      try {
        const response = JSON.parse(request.response);
        resolve({ data: response as ApiResponse<UpdateResult> });
      } catch (e) {
        reject(e as Error);
      }
    };
    request.open(method, url);
    request.setRequestHeader('X-Firebase-AppCheck', appCheckTokenResponse?.token ?? '');
    request.onerror = () => {
      reject('Network error');
    };
    request.send(formData);
  });
};
