import qs from 'qs';
import { CONFIG } from 'constants/index';

const IMGIX_URL = CONFIG.imgixUrl;
const PROJECT_ID = CONFIG.firebase.projectId;

export function imgixUrl(url?: string, params?: Record<string, string | number>) {
  if (!url) return url;
  if (!IMGIX_URL) return url;

  const [src, query] = url.split('?');
  const queryWithParams = { ...qs.parse(query, { ignoreQueryPrefix: true }), ...params };
  const queryStr = qs.stringify(queryWithParams);
  const imgixSrc = src.replace(`https://storage.googleapis.com/${PROJECT_ID}.appspot.com`, IMGIX_URL);

  return `${imgixSrc}${queryStr ? '?' + queryStr : ''}`;
}
