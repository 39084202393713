import * as yup from 'yup';

import * as routes from 'api/routes';
import { IModel } from 'models/hubspot/interface';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = Omit<IModel, 'cic_platform_user_id' | 'self_declare_residency' | 'self_declare_date'>;
export type Result = void;

export const paramsSchema = yup.object().shape({
  email: yup.string().required(VALIDATION_ERROR.REQUIRED),
  started_the_questionnaire: yup.string().nullable(),
  firstname: yup.string().nullable(),
  investor_status_level: yup.string(),
  lastname: yup.string().nullable(),
  date_of_birth: yup.string(),
  country: yup.string(),
  phone: yup.string(),
});

export const ROUTE = routes.HUBSPOT_CONTACT_WEBSITE_UPDATE;
