import { Types } from 'mongoose';

import { ObjectValues } from 'types/object-values';
import { Onboarding, InputValue } from './onboarding';

export interface IModel {
  _id: Types.ObjectId;
  role: UserRole;
  email: string;
  password?: string;
  provider: AuthProvider;
  status: UserStatus;
  createdAt: Date;
  statusLastUpdate?: Date;
  pictureUrl?: string;
  country?: string;
  name?: string;
  onboarding?: Onboarding<InputValue>;
  utmParams?: {
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
  };
}

export const AUTH_PROVIDER = {
  GOOGLE: 'google',
  LINKEDIN: 'linkedin',
  EMAIL: 'emailAndPassword',
} as const;

export const USER_ROLE = {
  VIEWER: 'VIEWER',
  INVESTOR_LEVEL_1: 'INVESTOR_LEVEL_1',
  INVESTOR_LEVEL_2: 'INVESTOR_LEVEL_2',
  INVESTOR_LEVEL_3: 'INVESTOR_LEVEL_3',
} as const;

export const USER_STATUS = {
  NONE: 'NONE',
  WAITING_FOR_APPROVE: 'WAITING_FOR_APPROVE',
  APPROVED: 'APPROVED',
  NOT_APPROVED: 'NOT_APPROVED',
} as const;

export type UserRole = ObjectValues<typeof USER_ROLE>;
export type UserStatus = ObjectValues<typeof USER_STATUS>;
export type AuthProvider = ObjectValues<typeof AUTH_PROVIDER>;
