import { ROUTE as UPDATE_ROUTE, Params as UpdateParams, Result as UpdateResult } from 'api/statistics/update/params';
import { ApiResponse } from 'src/types/api-response';

import api from '..';

export const statistics = api.injectEndpoints({
  endpoints: (builder) => ({
    updateStats: builder.mutation<ApiResponse<UpdateResult>, UpdateParams>({
      query: (params) => ({
        method: 'POST',
        url: UPDATE_ROUTE,
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});
