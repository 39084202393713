import {
  ROUTE as GET_FAVORITE_ROUTE,
  Params as GetFavoriteParams,
  Result as GetFavoriteResult,
} from 'api/users/website/get-favorite-companies/params';
import {
  ROUTE as DELETE_FAVORITE_COMPANY_ROUTE,
  Params as DeleteFavoriteParams,
  Result as DeleteFavoriteResult,
} from 'api/users/website/delete-from-favorite-list/params';

import {
  ROUTE as ADD_FAVORITE_COMPANY_ROUTE,
  Params as AddFavoriteParams,
  Result as AddFavoriteResult,
} from 'api/users/website/add-to-favorite-list/params';
import { ApiResponse } from 'src/types/api-response';

import api from '..';
import { addToFavorite, deleteFromFavorite } from 'client/redux/user';

export const favoriteList = api.injectEndpoints({
  endpoints: (builder) => ({
    getFavoriteList: builder.query<ApiResponse<GetFavoriteResult>, GetFavoriteParams>({
      query: () => ({
        method: 'GET',
        url: GET_FAVORITE_ROUTE,
      }),
      providesTags: (result) =>
        result?.success ? [{ type: 'FAVORITE_LIST', id: 'ALL' }] : [{ type: 'FAVORITE_LIST', id: 'ALL' }],
    }),
    deleteFromFavoriteList: builder.mutation<ApiResponse<DeleteFavoriteResult>, DeleteFavoriteParams>({
      query: (params) => ({
        method: 'DELETE',
        url: DELETE_FAVORITE_COMPANY_ROUTE,
        body: params,
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        dispatch(deleteFromFavorite(params.companyId));

        try {
          await queryFulfilled;
        } catch {
          dispatch(api.util.invalidateTags([{ type: 'COMPANY', id: 'ALL' }]));
        }
      },
      invalidatesTags: () => [{ type: 'FAVORITE_LIST', id: 'ALL' }],
    }),
    addToFavoriteList: builder.mutation<ApiResponse<AddFavoriteResult>, AddFavoriteParams>({
      query: (params) => ({
        method: 'POST',
        url: ADD_FAVORITE_COMPANY_ROUTE,
        body: params,
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        dispatch(addToFavorite(params.companyId));

        try {
          await queryFulfilled;
        } catch {
          dispatch(api.util.invalidateTags([{ type: 'COMPANY', id: 'ALL' }]));
        }
      },
      invalidatesTags: () => [{ type: 'FAVORITE_LIST', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
