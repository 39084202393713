import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router';

import 'client/styles/index.scss';

import { useAppDispatch, useAppSelector, useResponsive } from 'client/hooks';
import { checkRedirectSignIn, getUserData, me } from 'client/redux/user/actions';
import { selectAuth, selectUserId, selectUser } from 'client/redux/user/selectors';
import { usePrev, useAppcues } from 'client/hooks';
import * as routes from 'client/constants/routes';
import { enableGA } from 'client/redux/user';
import { INTERCOM_APP_ID } from 'client/constants';

import DesktopApp from './Desktop';
import MobileApp from './Mobile';

const App: React.FC = () => {
  const [isMobile] = useResponsive(['MOBILE']);
  const appDispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);
  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  const prevUserId = usePrev(userId);

  const history = useHistory();
  const isSignInRoute = !!useRouteMatch<{ id: string }>(routes.SIGN_IN_ROUTE);
  const isSignUpRoute = !!useRouteMatch<{ id: string }>(routes.SIGN_UP_ROUTE);

  const removePreloaderTimeoutRef = React.useRef<NodeJS.Timeout | number | null>(null);

  useAppcues();

  React.useEffect(() => {
    document.documentElement.classList.add('cc--darkmode');

    (window as any).CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: 'cloud',
          position: 'bottom left',
          equalWeightButtons: true,
          flipButtons: false,
        },
        preferencesModal: {
          layout: 'box',
          position: 'right',
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          readOnly: true,
        },
        analytics: {},
        marketing: {},
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'This site uses cookies',
              description:
                'We use cookies to remember log in details, provide secure log in, improve site functionality, and deliver personalized content.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Allow necessary',
              showPreferencesBtn: 'Manage preferences',
              footer: '<a href="/privacy-policy">Privacy Policy</a>\n<a href="/terms-of-use">Terms and Conditions</a>',
            },
            preferencesModal: {
              title: 'Cookie Preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Allow necessary',
              savePreferencesBtn: 'Confirm preferences',
              closeIconLabel: 'Close',
              serviceCounterLabel: 'Service|Services',
              sections: [
                {
                  title: 'Cookie Usage',
                  description:
                    'We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.',
                },
                {
                  title: 'Necessary <span class="pm__badge">Always Enabled</span>',
                  description:
                    'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Statistics',
                  description:
                    'Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.',
                  linkedCategory: 'analytics',
                },
                {
                  title: 'Marketing',
                  description:
                    'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                  linkedCategory: 'marketing',
                },
              ],
            },
          },
        },
      },
      disablePageInteraction: true,
      onConsent: function () {
        if ((window as any).CookieConsent.acceptedCategory('analytics')) {
          // Analytics category enabled

          appDispatch(enableGA(true));
        }
      },
    });
  }, []);

  React.useEffect(() => {
    if (auth.isLoggedIn && (isSignInRoute || isSignUpRoute)) {
      history.replace(routes.ROOT);
    }
    if (auth.isLoggedIn && user) {
      (window as any).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: INTERCOM_APP_ID,
        user_id: user._id,
        user_hash: user.intercomHash,
      });
    }
  }, [auth.isLoggedIn]);

  React.useEffect(() => {
    const checkSignIn = async () => {
      if (isMobile === undefined) {
        return;
      }

      const urlParams = new URLSearchParams(window.location.search);
      const { provider } = JSON.parse(decodeURIComponent(urlParams.get('user') ?? '{}'));
      const isCheckRedirectSignInRequired = window.localStorage.getItem('isCheckRedirectSignInRequired') === 'true';
      let isSignedInByRedirect = false;

      if (isCheckRedirectSignInRequired && (isMobile || provider === 'linkedin') && !userId) {
        isSignedInByRedirect = !!(await appDispatch(checkRedirectSignIn())).payload;
        window.localStorage.setItem('isCheckRedirectSignInRequired', 'false');

        if (isSignedInByRedirect && (isSignInRoute || isSignUpRoute)) {
          history.replace(routes.ROOT);
        }

        if (provider === 'linkedin') {
          const urlParams = new URLSearchParams(history.location.search);
          urlParams.delete('user');
          history.replace(history.location.pathname + (urlParams.toString() ? `?${urlParams.toString()}` : ''));
        }
      }

      if (!isSignedInByRedirect && !userId && !prevUserId) {
        const meResult = await appDispatch(me());

        if (meResult.meta.requestStatus === 'rejected') {
          console.log('Client is unauthorized...');
        } else {
          appDispatch(getUserData({ target: 'all' }));
        }
      } else if (!isSignedInByRedirect && userId) {
        appDispatch(getUserData({ target: 'all' }));
      }

      removeRootPreloader();
    };

    checkSignIn();
  }, [appDispatch, userId, isMobile]);

  const removeRootPreloader = () => {
    const preloadingScreen: HTMLElement | null = document.querySelector('.main-preloader');

    if (preloadingScreen) {
      setTimeout(() => {
        preloadingScreen.style.opacity = '0';
        preloadingScreen.style.visibility = 'hidden';
        preloadingScreen.style.pointerEvents = 'none';
      }, 500);

      if (removePreloaderTimeoutRef.current) clearTimeout(removePreloaderTimeoutRef.current);
      removePreloaderTimeoutRef.current = setTimeout(() => {
        if (document && document.body) {
          document.body.removeChild(preloadingScreen);
        }
      }, 1500);
    }
  };

  return isMobile ? <MobileApp /> : <DesktopApp />;
};

export default App;
