import { IModel } from 'models/reports/interface';
import * as routes from 'api/routes';

export type PaginateItemClient = Omit<IModel, '_id' | 'companyId' | 'createdAt'> & {
  companyId: string;
  companyName: string;
  _id: string;
  createdAt: string;
};

export type Result = PaginateItemClient[];

export const ROUTE = routes.COMPANY_REPORTS_GET_BY_USER_INVESTMENTS;
