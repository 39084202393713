import * as yup from 'yup';

import * as routes from 'api/routes';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = { email: string };
export type Result = { link: string };

export const paramsSchema = yup.object().shape({
  email: yup.string().email(VALIDATION_ERROR.INVALID_EMAIL).required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.AUTH_FORGOT_PASSWORD;
