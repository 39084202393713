import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  preventToSearch?: boolean;
}

const defaultData = {
  title: '',
  description: '',
  ogTitle: '',
  ogDescription: '',
  ogImage: '',
};

const HelmetComponent: React.FC<Props> = (props) => {
  const { title, description, ogTitle, ogDescription, ogImage, preventToSearch } = props;

  return (
    <Helmet>
      <title>{title ? title : defaultData.title}</title>

      <meta name="description" content={description ? description : defaultData.description} />
      <meta property="og:title" content={ogTitle ? ogTitle : defaultData.ogTitle} />
      <meta property="og:description" content={ogDescription ? ogDescription : defaultData.ogDescription} />
      {ogImage && <meta property="og:image" content={ogImage} />}
      {preventToSearch && <meta name="robots" content="noindex" />}
      {preventToSearch && <meta name="googlebot" content="noindex" />}
    </Helmet>
  );
};

export default HelmetComponent;
