import cookies from 'js-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

import { ROUTE as SIGN_UP_USER_WITH_EMAIL, Params as EmailSignUpParams } from 'api/auth/user/email-sign-up/params';
import { SignInSignUpResult } from 'api/auth/user/types';
import { ROUTE as SESSION_IN_ROUTE } from 'api/auth/session-in/params';
import { app as firebaseApp } from 'src/firebase-client';
import { ApiResponse } from 'src/types/api-response';
import { CSRF_TOKEN } from 'api/constants';
import { api } from 'utils/api';

import reduxApi from '../../api';

export const emailSignUp = createAsyncThunk<
  ApiResponse<SignInSignUpResult>,
  EmailSignUpParams,
  { rejectValue: string }
>('user/emailSignUp', async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.sendRequest<SignInSignUpResult>({
      url: SIGN_UP_USER_WITH_EMAIL,
      method: 'POST',
      data,
    });

    if (response.success) {
      const firebaseAuth = getAuth(firebaseApp);

      await signInWithCustomToken(firebaseAuth, response.data.token);

      const idToken = await firebaseAuth.currentUser?.getIdToken(/* forceRefresh */ true);
      const csrfToken = cookies.get(CSRF_TOKEN);

      if (idToken && csrfToken) {
        await api.sendRequest({ url: SESSION_IN_ROUTE, method: 'POST', data: { idToken, csrfToken } });

        dispatch(
          reduxApi.util.invalidateTags(['COMPANY', 'COMPANY_WITH_CATEGORY', 'MAIN_PAGE_SLIDERS', 'COMPANY_RELATED']),
        );

        return response;
      }
    } else {
      throw new Error(response.error.message as string);
    }
    throw new Error('SignInError');
  } catch (e) {
    return rejectWithValue((e as Error).message);
  }
});
