import * as yup from 'yup';

import { IModel } from 'models/offer/interface';
import { Params as PaginationParams, Result as PaginationResult } from 'models/pagination';
import { VALIDATION_ERROR } from 'endpoints/index';
import * as routes from 'api/routes';

export type Offer = Omit<IModel, '_id' | 'notes' | 'userId' | 'createdAt'> & {
  _id: string;
  companyLogo: string;
  companyName: string;
  createdAt: string;
};

export type Params = PaginationParams<Offer>;
export type Result = PaginationResult<Offer>;

export const paramsSchema = yup.object().shape({
  limit: yup.number().min(1).required(VALIDATION_ERROR.REQUIRED),
  offset: yup.number().min(0).required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.OFFER_PAGINATE_WEBSITE;
