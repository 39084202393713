import React from 'react';
import { useHistory } from 'react-router';

import { useAppSelector } from 'client/hooks';
import { selectAuth, selectUserOnboarding } from 'client/redux/user/selectors';

import { MAIN_PAGE_ROUTE } from 'client/constants/routes';
import GeneralProfile from './GeneralProfile';
import InvestorProfile from './InverstorProfile';

export const UserProfile = () => {
  const onboarding = useAppSelector(selectUserOnboarding);
  const auth = useAppSelector(selectAuth);
  const history = useHistory();

  React.useEffect(() => {
    if (!auth.isLoggedIn && auth.isCompleted) {
      history.push(MAIN_PAGE_ROUTE);
    }
  }, [auth.isLoggedIn, auth.isCompleted]);

  return !onboarding.agreement.agree ? <GeneralProfile /> : <InvestorProfile />;
};

export default UserProfile;
