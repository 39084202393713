import React from 'react';
import { useHistory } from 'react-router';
import format from 'date-fns/format';

import { selfDeclaration } from 'utils/onboarding';
import { statusTranslation } from 'utils/hubspot-investor-status';
import { useTranslation, useAppDispatch, useAppSelector } from 'client/hooks';
import { setPopup } from 'client/redux/ui/index';
import { setRole } from 'client/redux/user/index';
import { selectUser } from 'client/redux/user/selectors';
import { onboarding as onboardingApi } from 'client/redux/api/onboarding';
import { hubspot as hubspotApi } from 'client/redux/api/hubspot';
import countries from 'utils/countries.json';
import { MANDATE_PAGE_ROUTE } from 'client/constants/routes';

import Icon from '../../../Icon';
import Button from '../../../Button';
import css from './ConfirmationStep.module.scss';

interface Props {
  country: string;
  link: string;
  isExternalLink: boolean;
}

const ConfirmationStep = ({ country, link, isExternalLink }: Props) => {
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const countryInfo = countries.find((item) => item.value === country);
  const { translate: popupT } = useTranslation('popups');
  const { translate: commonT } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [selfDecalaration, selfDecalarationResult] = onboardingApi.endpoints.setSelfDecalaration.useMutation();
  const [createContact, createContactResult] = hubspotApi.endpoints.createContact.useMutation();
  const contentRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();

  const formConfig = React.useMemo(() => {
    return selfDeclaration.getSelfDeclaration(country);
  }, [country]);

  React.useEffect(() => {
    if (selfDecalarationResult.data?.success) {
      dispatch(setPopup(null));
      dispatch(setRole('INVESTOR_LEVEL_1'));
      createContact({
        email: user.email,
        firstname: user.name || '',
        cic_platform_user_id: user._id,
        investor_status_level: statusTranslation('INVESTOR_LEVEL_1'),
        self_declare_residency: country,
        self_declare_date: `${format(new Date(), 'yyyy-MM-dd')}`,
      });

      if (isExternalLink) {
        window.open(link, '_self');
      }

      if (!isExternalLink) {
        const id = link.split('/').pop();
        if (id) {
          history.push(MANDATE_PAGE_ROUTE.replace(':id', id));
        }
      }
    }
  }, [selfDecalarationResult]);

  const clickOnClose = () => {
    dispatch(setPopup(null));
  };

  const clickOnConfirmation = () => {
    if (isDisabled) return;
    selfDecalaration({ agree: true, residency: formConfig.form.residency, intent: 'self_declaration' });
  };

  const onScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      const isNearBottom = scrollTop + clientHeight + 5 >= scrollHeight;

      if (isNearBottom && isDisabled) {
        setIsDisabled(false);
      }
    }
  };

  React.useEffect(() => {
    const contentElement = contentRef.current;

    if (contentElement) {
      contentElement.addEventListener('scroll', onScroll);

      // Clean-up
      return () => {
        contentElement.removeEventListener('scroll', onScroll);
      };
    }
  }, [isDisabled]);

  React.useEffect(() => {
    if (contentRef.current) {
      const { scrollHeight, clientHeight } = contentRef.current;
      if (scrollHeight === clientHeight) {
        setIsDisabled(false);
      }
    }
  }, [contentRef.current]);

  return (
    <div className={css.stepWrapper}>
      <div className={css.closeBtn} onClick={clickOnClose}></div>
      <div className={css.country}>
        <Icon type="globe" className={css.globeIcon} />
        {countryInfo && countryInfo?.label}
      </div>
      <div
        className={css.title}
        dangerouslySetInnerHTML={{ __html: popupT('joinAsInvestor.confirmationStep.title') }}
      ></div>
      <div className={css.content}>
        <div className={css.contentI} ref={contentRef}>
          <div
            className={css.text}
            dangerouslySetInnerHTML={{
              __html: formConfig ? commonT(formConfig.text) : '',
            }}
          ></div>
        </div>
      </div>
      <div className={css.buttons}>
        <Button className={css.confirmButton} onClick={clickOnConfirmation} size="medium" disabled={isDisabled}>
          {popupT('joinAsInvestor.confirmationStep.confirmButton')}
          {isDisabled && <div className={css.tooltip}>{popupT('joinAsInvestor.confirmationStep.btnTooltip')}</div>}
        </Button>
        <Button className={css.cancelButton} disabled={isDisabled} size="medium" onClick={clickOnClose}>
          {popupT('joinAsInvestor.confirmationStep.cancelButton')}
          {isDisabled && <div className={css.tooltip}>{popupT('joinAsInvestor.confirmationStep.btnTooltip')}</div>}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationStep;
