import * as yup from 'yup';

import * as routes from 'api/routes';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = {
  fileName: string;
  progress: number;
};
export type Result = { status: 'OK' };

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape({
  fileName: yup.string().required(VALIDATION_ERROR.REQUIRED),
  progress: yup.number().oneOf([10, 20, 30, 40, 50, 60, 70, 80, 90, 100]).required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.VIDEO_STATS_UPDATE;
