import React from 'react';

import { useAppDispatch } from 'client/hooks';
import { useAppSelector, useTranslation } from 'client/hooks';
import { onboarding as onBoardingApi } from 'client/redux/api/onboarding';
import { setPopup } from 'client/redux/ui';
import { selectUserOnboarding } from 'client/redux/user/selectors';

import { EnvelopeType } from 'api/utils/docuSign/types';

import Envelope from 'client/components/common/Envelope';

import css from './TaxDocument.module.scss';

interface Props {
  docType: EnvelopeType;
}
const TaxDocument = ({ docType }: Props) => {
  const dispatch = useAppDispatch();
  const userOnboarding = useAppSelector(selectUserOnboarding);
  const isAlreadySigned =
    Boolean(userOnboarding.form.taxDeclarationId) && userOnboarding.form.taxDeclarationStatus === 'completed';
  const [taxDeclaration, taxDeclarationState] = onBoardingApi.endpoints.setTaxDeclaration.useMutation();
  const { translate } = useTranslation();

  const onSuccess = (envelopeId: string) => {
    taxDeclaration({
      taxDeclarationId: envelopeId,
      taxDeclarationStatus: 'completed',
      intent: 'tax_declaration',
      taxDeclarationDate: new Date().toISOString(),
    });
    dispatch(setPopup(null));
  };

  const onReady = (envelopeId: string) => {
    taxDeclaration({
      taxDeclarationId: envelopeId,
      taxDeclarationStatus: 'in-progress',
      intent: 'tax_declaration',
      taxDeclarationDate: new Date().toISOString(),
    });
  };

  if (isAlreadySigned) {
    return <p>Document is already signed</p>;
  }

  return (
    <Envelope
      type={docType}
      onSignComplete={onSuccess}
      onUIReady={onReady}
      className={css.envelopeWrapper}
      loaderText={translate('onboardingFinalization.taxDeclaration.docusignLoaderText')}
    />
  );
};

export default TaxDocument;
