import React from 'react';

import { useTranslation, useAppSelector, useAppDispatch } from 'client/hooks';
import { selectUserNameOrEmail } from 'client/redux/user/selectors';
import { setPopup } from 'client/redux/ui';

import Icon from '../../../Icon';
import Button from '../../../Button';
import css from './Thanks.module.scss';

const Thanks = () => {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();
  const userName = useAppSelector(selectUserNameOrEmail);

  return (
    <div className={css.thanksWrapper}>
      <div className={css.thanksWrapperI}>
        <h1 className={css.title}>{translate(`questionnaire.thanks.title`).replace('{name}', userName)}</h1>
        <p className={css.subtitle}>{translate(`questionnaire.thanks.subtitle`)}</p>
        <div className={css.cooperationText}>
          <Icon type="review" className={css.timeLeftIcon} />
          <p dangerouslySetInnerHTML={{ __html: translate(`questionnaire.thanks.submissionText`) }}></p>
        </div>
        <p
          className={css.additionalText}
          dangerouslySetInnerHTML={{ __html: translate(`questionnaire.thanks.additionalText`) }}
        ></p>
      </div>
      <div className={css.btnWrapper}>
        <Button type="button" className={css.finishButton} onClick={() => dispatch(setPopup(null))}>
          {translate('questionnaire.navigationButtons.finish')}
        </Button>
      </div>
    </div>
  );
};

export default Thanks;
