import { Onboarding } from 'models/user/onboarding';
import * as routes from 'api/routes';

export type Params =
  | ({
      intent: 'self_declaration';
    } & Onboarding['agreement'])
  | ({
      intent: 'personal_details';
    } & Pick<
      Onboarding['form'],
      | 'firstName'
      | 'lastName'
      | 'dateOfBirth'
      | 'countryOfBirth'
      | 'countryOfResidence'
      | 'hasNonResidentalCitizenship'
      | 'nonResidentalCitizenships'
    >)
  | ({
      intent: 'questionnaire';
    } & Partial<
      Pick<
        Onboarding['form'],
        | 'occupation'
        | 'appliesTo'
        | 'experience'
        | 'experienceExplanation'
        | 'financeEducation'
        | 'privateEquity'
        | 'objectiveDescription'
        | 'totalLossAcceptance'
        | 'investmentPortionOfTotal'
        | 'liquidAssetsDuration'
        | 'timeHorizon'
        | 'longTermInvestmentLiquidityNeeds'
        | 'investingAs'
        | 'sourceOfFunds'
        | 'sourceOfSavings'
        | 'prominentPublicRole'
        | 'prominentPublicRoleRelation'
        | 'USRelation'
      >
    >)
  | ({
      intent: 'nda';
    } & Pick<Onboarding['form'], 'signatureId' | 'signatureStatus' | 'signatureDate'>)
  | ({
      intent: 'contact_details';
    } & Pick<
      Onboarding['form'],
      | 'firstName'
      | 'lastName'
      | 'dateOfBirth'
      | 'countryOfBirth'
      | 'countryOfResidence'
      | 'hasNonResidentalCitizenship'
      | 'nonResidentalCitizenships'
      | 'city'
      | 'addressLine_1'
      | 'addressLine_2'
      | 'region'
      | 'postalCode'
      | 'country'
      | 'phone'
      | 'photoId'
    >)
  | ({
      intent: 'bank_details';
    } & Pick<
      Onboarding['form'],
      | 'bankName'
      | 'bankCountry'
      | 'bankCity'
      | 'bankPostalCode'
      | 'bankAddressLine_1'
      | 'bankAddressLine_2'
      | 'bankAccountName'
      | 'bankAccountNumber'
      | 'bankSwiftCode'
    >)
  | ({
      intent: 'certification';
    } & Partial<Pick<Onboarding['form'], 'certificationId'>>)
  | ({
      intent: 'kyc';
    } & Partial<Pick<Onboarding['form'], 'kycId' | 'kycStatus'>>)
  | ({
      intent: 'tax_declaration';
    } & Partial<
      Pick<Onboarding['form'], 'taxCountryDetails' | 'taxDeclarationId' | 'taxDeclarationStatus' | 'taxDeclarationDate'>
    >);

export type Result = null;
export const ROUTE = routes.USER_ONBOARDING_UPDATE;
