import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { AppCheckTokenResult, getToken } from 'firebase/app-check';
import fetch from 'isomorphic-fetch';

import { getAppCheck } from 'src/firebase-client';
import { CONFIG } from 'constants/index';

const api = createApi({
  reducerPath: 'api',
  tagTypes: [
    'COMPANY',
    'COMPANY_RELATED',
    'MAIN_PAGE_SLIDERS',
    'FAQ',
    'CATEGORIES',
    'CATEGORY',
    'FAVORITE_LIST',
    'COMPANY_WITH_CATEGORY',
    'COMPANY_CLAIMS',
    'STATIC_PAGES',
    'MANDATE',
    'NEWS',
    'OFFER',
    'REPORTS',
    'PORTFOLIO',
    'FAKE_MANDATES_SLIDES',
    'CONTACT_US',
    'FAKE_COMING_SOON_SLIDES',
  ],
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: CONFIG.domainUrl,
      fetchFn: fetch,
      prepareHeaders: async (headers) => {
        let appCheckTokenResponse: AppCheckTokenResult | null = null;

        try {
          const appCheck = getAppCheck();

          if (appCheck) appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
          else throw new Error('appCheck is null');
        } catch (err) {
          console.info('AppCheck getToken error', err);
        }

        // Include the App Check token with requests to your server.
        headers.set('X-Firebase-AppCheck', appCheckTokenResponse?.token ?? '');
      },
    }),
    { maxRetries: 1 },
  ),
  extractRehydrationInfo(action) {
    if (action.type === 'HYDRATE') {
      return action.payload;
    }
  },
  endpoints: () => ({}),
});

export default api;
