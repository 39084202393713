import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { LINK_TYPE, SIZE_TYPE } from 'client/types';
import { PlayIcon, Icon } from 'client/components/common';
import { useTranslation } from 'client/hooks/translation';

import css from './Link.module.scss';

type Props = {
  link: string;
  type?: LINK_TYPE;
  size?: SIZE_TYPE;
  className?: string;
  onClick?: () => void;
};

const LinkButton: React.FC<Props> = ({
  link,
  type = LINK_TYPE.SEE_MORE,
  size = SIZE_TYPE.SM,
  className = '',
  onClick,
}) => {
  const { translate } = useTranslation();

  const renderContent = () => {
    switch (type) {
      case LINK_TYPE.WATCH: {
        return (
          <>
            <PlayIcon size={size} className={css.iconPlay} />
            <span>{translate('mainPageDesktop.watchButton')}</span>
          </>
        );
      }
      case LINK_TYPE.SEE_MORE: {
        return (
          <>
            <Icon type="eye" className={css.iconEye} />
            <span>{translate('mainPageDesktop.seeMoreButton')}</span>
          </>
        );
      }

      default:
        <></>;
    }
  };

  const onLinkClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Link to={link} onClick={onLinkClick} className={cn(className, css.link, css[type], css[size])}>
      {renderContent()}
    </Link>
  );
};

export default LinkButton;
