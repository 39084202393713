import React from 'react';
import cn from 'classnames';

import css from './Content.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
}

function ContentWrap(props: Props) {
  const { className = '', containerRef, children } = props;

  return (
    <div className={cn(css.contentWrap, className)} ref={containerRef}>
      {children}
    </div>
  );
}

export { ContentWrap };
export default ContentWrap;
