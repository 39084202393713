import React from 'react';
import cn from 'classnames';

import { Loader } from 'client/components/common';
import css from './Skeleton.module.scss';

type Props = {
  className?: string;
};

export function Skeleton(props: Props) {
  const { className = '' } = props;

  return (
    <div className={cn(css.skeleton, className)}>
      <Loader className={css.loader} position="relative" />
    </div>
  );
}

export default Skeleton;
