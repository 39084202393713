import * as yup from 'yup';

import { Onboarding } from 'models/user/onboarding';
import { filterDependencies, configureStep, getStageCurrentStepName, getStageStep } from '../stage';
import { InputType, constants } from '../input-types';

/**
 * Returns accreditation finalization form fields
 */
export function getKYC(data: Onboarding) {
  const t = (path: string) => `onboardingFinalization.kyc.${path}`;
  return {
    steps: filterDependencies(
      [
        configureStep(
          {
            type: InputType.TEXT,
            name: 'kycId',
            label: t('kycId'),
            explanation: '',
            schema: yup.string().min(constants.TEXT_MIN_LENGTH).max(constants.TEXT_MAX_LENGTH).required(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'kycStatus',
            label: t('kycStatus'),
            explanation: '',
            schema: yup.string().oneOf(['rejected', 'init', 'pending', 'completed']).required(),
          },
          data,
        ),
      ],
      data,
    ),
  };
}

export function getCurrentStep(data: Onboarding) {
  const currentStepName = getStageCurrentStepName(getKYC(data).steps);
  return currentStepName ? getStep(data, currentStepName) : null;
}

export function getStep(data: Onboarding, targetStep: string) {
  return getStageStep(getKYC(data).steps, targetStep);
}
