import React from 'react';
import cn from 'classnames';

import { useTranslation } from 'client/hooks';
import Icon from 'client/components/common/Icon';

import css from './SuccessMessages.module.scss';

type Props = {
  onCloseClick: () => unknown;
  onSignInClick: () => unknown;
};

const SuccessResetPassword: React.FC<Props> = ({ onCloseClick, onSignInClick }) => {
  const { translate: _t } = useTranslation('popups');

  return (
    <div className={cn(css.success, css.resetPasswordSuccess)}>
      <h6 className={css.title}>{_t('login.successMessages.successResetPassword.title')}</h6>
      <p className={css.info}>{_t('login.successMessages.successResetPassword.info')}</p>
      <button onClick={onSignInClick} type="button" className={css.button}>
        {_t('login.successMessages.successResetPassword.buttonLabel')}
      </button>

      <button className={css.closeButton} onClick={onCloseClick}>
        <Icon type="close" />
      </button>
    </div>
  );
};

export default SuccessResetPassword;
