import * as yup from 'yup';
import { Onboarding, AgreementBase, CountryName, CountryGroup, Residency } from 'models/user/onboarding';
import { Input, InputType } from 'utils/onboarding/input-types';
import { VALIDATION_ERROR } from 'endpoints/index';

export function getCountryGroup(country: CountryName): CountryGroup {
  const EU_COUNTRIES = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
  ];

  const UK_COUNTRIES = ['GB', 'IE'];

  const IL_COUNTRY = 'IL';

  const US_COUNTRY = 'US';

  if (EU_COUNTRIES.includes(country)) {
    return CountryGroup.EU as const;
  }
  if (UK_COUNTRIES.includes(country)) {
    return CountryGroup.UK as const;
  }
  if (IL_COUNTRY === country) {
    return CountryGroup.IL as const;
  }
  if (US_COUNTRY === country) {
    return CountryGroup.US as const;
  }

  return CountryGroup.OTHER as const;
}

export function getResidency(country: CountryName): Residency {
  const group = getCountryGroup(country);

  return `${group}_${country}` as const;
}

export function getCoutryNameByResidency(residency: Residency): CountryName {
  return residency.split('_')[1] as CountryName;
}

export type SelfDeclaration = {
  form: Onboarding<Input>['agreement'];
  text: string;
};

/**
 * Returns the confirmation form for the given country
 */
export function getSelfDeclaration(country: CountryName): SelfDeclaration {
  const residency = getResidency(country);
  const t = (path: string) => `popups.joinAsInvestor.confirmationStep.info.${path}`;

  switch (residency) {
    case `${CountryGroup.EU}_${country}` as const: {
      return {
        text: t('eu.agreementText'),
        form: {
          ...getCommonForm(residency),
        },
      };
    }
    case `${CountryGroup.UK}_${country}` as const: {
      return {
        text: t('uk.agreementText'),
        form: {
          ...getCommonForm(residency),
        },
      };
    }
    case `${CountryGroup.IL}_${country}` as const: {
      return {
        text: t('il.agreementText'),
        form: {
          ...getCommonForm(residency),
        },
      };
    }
    case `${CountryGroup.US}_${country}` as const: {
      return {
        text: t('us.agreementText'),
        form: {
          ...getCommonForm(residency),
        },
      };
    }

    case `${CountryGroup.OTHER}_${country}` as const: {
      return {
        text: t('other.agreementText'),
        form: {
          ...getCommonForm(residency),
        },
      };
    }

    default: {
      throw 'Unknown residency';
    }
  }

  function getCommonForm<R extends Residency>(residency: R): AgreementBase<Input> & { residency: R } {
    return {
      residency,
      agree: {
        type: InputType.CHECKBOX,
        label: t('common.agree.label'),
        name: 'agree',
        value: false,
        schema: yup.boolean().oneOf([true], VALIDATION_ERROR.REQUIRED).required(VALIDATION_ERROR.REQUIRED),
      },
    };
  }
}
