import React from 'react';
import _ from 'lodash';
import { FormikProvider, Form, useFormik } from 'formik';
import cn from 'classnames';
import * as yup from 'yup';

import { useAppSelector, useTranslation, useAppDispatch } from 'client/hooks';
import { setPopup } from 'client/redux/ui';
import { selectUserOnboarding } from 'client/redux/user/selectors';
import { getTaxDeclaration } from 'utils/onboarding/accreditation-finalization/tax-declaration';
import { StepInput } from 'utils/onboarding/stage';
import { Onboarding } from 'models/user/onboarding';
import { InputType } from 'utils/onboarding/input-types';
import { onboarding as onBoardingApi } from 'client/redux/api/onboarding';
import { Screen } from './index';
import { initialValues, validationSchema } from 'utils/onboarding';
import { EnvelopeType } from 'api/utils/docuSign/types';

import TaxInfoFieldArray from './TaxInfoFieldArray';
import Button from 'client/components/common/Button';
import css from './TaxDeclare.module.scss';

interface Props {
  setScreen: (val: Screen) => void;
  setDocumentType: (type: EnvelopeType) => void;
}

const TaxDeclareForm = ({ setScreen, setDocumentType }: Props) => {
  const onBoarding = useAppSelector(selectUserOnboarding);
  const taxDeclarationDetailsData = getTaxDeclaration(onBoarding).steps as Array<StepInput>;
  const taxDeclarationFormStepData = [taxDeclarationDetailsData[0]];
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();
  const [taxDeclaration, taxDeclarationState] = onBoardingApi.endpoints.setTaxDeclaration.useMutation();

  const formikContext = useFormik<Onboarding['form']>({
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    initialValues: initialValues(taxDeclarationFormStepData as Array<StepInput>),
    validate: async (values: Onboarding['form']) => {
      const errors: Record<string, string> = {};
      const schema = validationSchema(taxDeclarationFormStepData as Array<StepInput>);

      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const { inner } = error as yup.ValidationError;
        inner.forEach((e) => {
          if (e.path && e.type) _.set(errors, e.path, e.errors.join(', '));
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      await taxDeclaration({ ...values, intent: 'tax_declaration' });
    },
  });

  React.useEffect(() => {
    if (taxDeclarationState.data?.success) {
      const taxCountryDetails = formikContext.values.taxCountryDetails as Array<{ country: string; taxId: string }>;
      if (taxCountryDetails) {
        const isIncludeUS = taxCountryDetails.some((el) => el.country === 'US');

        if (isIncludeUS) {
          setDocumentType('w-9');
        } else {
          setDocumentType('w-8ben');
        }
        setScreen('taxDocument');
      }
    }
  }, [taxDeclarationState]);

  const closeClickHandler = () => {
    if (formikContext.dirty) {
      const result = confirm(translate('onboardingFinalization.taxDeclaration.closeWindowConfirm'));
      if (result) {
        dispatch(setPopup(null));
      }
    } else {
      dispatch(setPopup(null));
    }
  };

  const renderFields = (input: StepInput) => {
    switch (input.type) {
      case InputType.ARRAY:
        return <TaxInfoFieldArray name={input.name} items={formikContext.values[`${input.name}`]} />;
      default:
        return null;
    }
  };

  return (
    <FormikProvider value={formikContext}>
      <Form autoComplete="off">
        <div className={css.closeBtnMobile} onClick={closeClickHandler}></div>
        <div className={css.formWrapper}>
          <div className={css.closeBtn} onClick={closeClickHandler}></div>
          <div className={css.formWrapperI}>
            <div className={css.contentWrapper}>
              <h1>{translate('onboardingFinalization.taxDeclaration.title')}</h1>
              <h3>{translate('onboardingFinalization.taxDeclaration.subtitle')}</h3>
              <div className={css.form}>
                {taxDeclarationDetailsData.map((item, index) => {
                  return <div key={index}>{renderFields(item)}</div>;
                })}
              </div>
              <div className={css.submitWrapper}>
                <Button type="submit" disabled={false} className={css.submitButton}>
                  <span className={cn(css.btnText, taxDeclarationState.isLoading && css.loading)}>
                    {translate('onboardingFinalization.taxDeclaration.continueBtn')}
                  </span>
                  <div className={cn(css.loaderWrapper, taxDeclarationState.isLoading && css.loading)}>
                    <div className={css.loader}></div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default TaxDeclareForm;
