import cookies from 'js-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, signInWithCustomToken, updateEmail } from 'firebase/auth';

import { ROUTE as AUTH_ROUTE, Params as AuthParams } from 'api/auth/user/sign-in/params';
import { SignInSignUpResult as AuthResult } from 'api/auth/user/types';
import { ROUTE as SESSION_IN_ROUTE } from 'api/auth/session-in/params';
import { app as firebaseApp } from 'src/firebase-client';
import { ApiResponse } from 'src/types/api-response';
import { CSRF_TOKEN } from 'api/constants';
import { api } from 'utils/api';
import reduxApi from '../../api';

export const signIn = createAsyncThunk<ApiResponse<AuthResult>, AuthParams, { rejectValue: string }>(
  'user/signIn',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.sendRequest<AuthResult>({ url: AUTH_ROUTE, method: 'POST', data });

      if (response.success) {
        const firebaseAuth = getAuth(firebaseApp);

        if (firebaseAuth.currentUser && response.data.provider === 'emailAndPassword')
          updateEmail(firebaseAuth.currentUser, response.data.email);

        await signInWithCustomToken(firebaseAuth, response.data.token);

        const idToken = await firebaseAuth.currentUser?.getIdToken(/* forceRefresh */ true);
        const csrfToken = cookies.get(CSRF_TOKEN);

        if (idToken && csrfToken) {
          await api.sendRequest({ url: SESSION_IN_ROUTE, method: 'POST', data: { idToken, csrfToken } });

          dispatch(reduxApi.util.invalidateTags(['MANDATE']));

          return response;
        }
      } else {
        throw new Error(response.error.message as string);
      }
      throw new Error('SignInError');
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
