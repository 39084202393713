import * as yup from 'yup';
import countries from 'utils/countries.json';
import { Onboarding } from 'models/user/onboarding';
import { filterDependencies, configureStep, getStageCurrentStepName, getStageStep } from '../stage';
import { InputType } from '../input-types';
import { VALIDATION_ERROR } from 'endpoints/index';

/**
 * Returns accreditation finalization form fields
 */
export function getTaxDeclaration(data: Onboarding) {
  const t = (path: string) => `onboardingFinalization.taxDeclaration.${path}`;
  return {
    steps: filterDependencies(
      [
        configureStep(
          {
            type: InputType.ARRAY,
            name: 'taxCountryDetails',
            label: t('taxCountryDetails'),
            explanation: '',
            defaultValue: [
              { country: data.form.countryOfResidence ? (data.form.countryOfResidence as string[])[0] : '', taxId: '' },
            ],
            shape: {
              country: {
                type: InputType.AUTO_COMPLETE,
                name: 'country',
                label: '',
                explanation: '',
                defaultValue: [],
                options: countries,
                schema: yup.string().required(VALIDATION_ERROR.TAXID_REQUIRED),
              },
              taxId: {
                type: InputType.TEXT,
                name: 'taxId',
                label: '',
                explanation: '',
                schema: yup.string().required(VALIDATION_ERROR.TAXCOUNTRY_REQUIRED),
              },
            },
            schema: yup.array().min(1, VALIDATION_ERROR.REQUIRED).required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'taxDeclarationId',
            label: t('taxDeclarationId.label'),
            schema: yup.string().required(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'taxDeclarationStatus',
            label: t('taxDeclarationStatus.label'),
            schema: yup.string().required(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.DATE,
            name: 'taxDeclarationDate',
            label: t('taxDeclarationDate.label'),
            schema: yup.date().typeError(`${VALIDATION_ERROR.REQUIRED}`).required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
      ],
      data,
    ),
  };
}

export function getCurrentStep(data: Onboarding) {
  const currentStepName = getStageCurrentStepName(getTaxDeclaration(data).steps);
  return currentStepName ? getStep(data, currentStepName) : null;
}

export function getStep(data: Onboarding, targetStep: string) {
  return getStageStep(getTaxDeclaration(data).steps, targetStep);
}
