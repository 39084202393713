import React from 'react';
import cn from 'classnames';
import Select, { components, InputProps } from 'react-select';
import { useField } from 'formik';
import { useTranslation, useResponsive } from 'client/hooks';

import Icon from '../../../../../Icon';
import Error from '../../../../../Error';

import css from './Select.module.scss';

interface Props {
  name: string;
  multiple?: boolean;
  options: Array<{ label: string; value: string }>;
  label: string;
  stringValue?: boolean;
}

const CustomInput = (props: InputProps<{ label: string; value: string }>) => {
  const inputProps = { ...props };

  return <components.Input {...inputProps} autoComplete="some-new-options" />;
};

const SelectField = ({ name, multiple = false, options, label, stringValue = false }: Props) => {
  const [isMobile] = useResponsive(['MOBILE']);
  const [isExtraSmallMobile] = useResponsive('MOBILE_EXTRA_SMALL');
  const fontSize = React.useMemo(() => {
    if (isExtraSmallMobile) return '16px';
    if (isMobile) return '18px';
    return '22px';
  }, [isMobile, isExtraSmallMobile]);
  const [field, meta, helpers] = useField(name);
  const errorMsg = !!(meta.touched && meta.error) ? meta.error : '';
  const { translate } = useTranslation();

  return (
    <div className={css.selectWrapper}>
      {multiple ? (
        <Select
          options={options}
          id={name}
          instanceId={name}
          value={field.value ? field.value.map((val: string) => options.find((option) => option.value === val)) : null}
          isMulti
          onChange={(e) => {
            const values = e.map((val) => {
              return val.value;
            });
            helpers.setValue(values);
          }}
          placeholder={translate(label)}
          components={{
            DropdownIndicator: () => <Icon type="down-white-arrow" className={css.dropdownIndicator} />,
            Input: CustomInput,
          }}
          menuPlacement={'auto'}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              flexWrap: 'nowrap',
              height: '59px',
              border: state.isFocused ? 'none' : 'none',
              borderColor: state.isFocused ? '#transparent' : '#transparent',
              boxShadow: 'none',
              borderBottom:
                errorMsg && meta.touched
                  ? 'solid 1px rgba(252, 68, 79, 1)'
                  : state.isFocused
                  ? `1px solid rgb(104, 164, 255)`
                  : `solid 1px rgba(255, 255, 255, 0.4)`,
              backgroundColor: `rgba(255, 255, 255, 0.07)`,
              borderRadius: '0px',
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
            }),
            valueContainer: (baseStyles, state) => ({
              ...baseStyles,
              padding: '0 20px 0 24px',
              fontSize,
              letterSpacing: '-0.25px',
              fontWeight: 400,
              color: 'rgba(255, 255, 255, 0.87)',
            }),
            singleValue: (baseStyles, state) => ({
              ...baseStyles,
              fontSize,
              letterSpacing: '-0.25px',
              fontWeight: 400,
              color: 'rgba(255, 255, 255, 0.87)',
            }),
            input: (baseStyles, state) => ({
              ...baseStyles,
              color: '#fff',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            menu: (baseStyles, state) => ({
              ...baseStyles,
              height: '150px',
              backgroundColor: 'rgba(17, 17, 17, 0.97)',
              overflow: 'auto',
            }),
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              height: '150px',
              backgroundColor: '#transparent',
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isFocused || state.isSelected ? '#444' : '#transparent',
              fontSize,
              letterSpacing: '-0.25px',
              fontWeight: 400,
              color: '#fff',
            }),
            noOptionsMessage: (baseStyles, state) => ({
              ...baseStyles,
              fontSize,
              letterSpacing: '-0.25px',
              fontWeight: 400,
              color: '#fff',
            }),
          }}
        />
      ) : (
        <Select
          options={options}
          id={name}
          instanceId={name}
          value={
            stringValue
              ? options.filter((option) => option.value === field.value)
              : options.filter((option) => option.value === field.value[0])
          }
          isMulti={false}
          onChange={(e) => {
            if (stringValue) {
              helpers.setValue(e?.value);
            } else {
              helpers.setValue([e?.value]);
            }
          }}
          components={{
            DropdownIndicator: () => <Icon type="down-white-arrow" className={css.dropdownIndicator} />,
            Input: CustomInput,
          }}
          placeholder={translate(label)}
          menuPlacement={'auto'}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              flexWrap: 'nowrap',
              height: '59px',
              border: state.isFocused ? 'none' : 'none',
              borderColor: state.isFocused ? '#transparent' : '#transparent',
              boxShadow: 'none',
              borderBottom:
                errorMsg && meta.touched
                  ? 'solid 1px rgba(252, 68, 79, 1)'
                  : state.isFocused
                  ? `1px solid rgb(104, 164, 255)`
                  : `solid 1px rgba(255, 255, 255, 0.4)`,
              backgroundColor: `rgba(255, 255, 255, 0.07)`,
              borderRadius: '0px',
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
            }),
            valueContainer: (baseStyles, state) => ({
              ...baseStyles,
              padding: '0 20px 0 24px',
              fontSize,
              letterSpacing: '-0.25px',
              fontWeight: 400,
              color: 'rgba(255, 255, 255, 0.87)',
            }),
            singleValue: (baseStyles, state) => ({
              ...baseStyles,
              fontSize,
              letterSpacing: '-0.25px',
              fontWeight: 400,
              color: 'rgba(255, 255, 255, 0.87)',
            }),
            input: (baseStyles, state) => ({
              ...baseStyles,
              color: '#fff',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            menu: (baseStyles, state) => ({
              ...baseStyles,
              height: '150px',
              backgroundColor: 'rgba(17, 17, 17, 0.97)',
              overflow: 'auto',
            }),
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              height: '150px',
              backgroundColor: '#transparent',
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isFocused || state.isSelected ? '#444' : '#transparent',
              fontSize,
              letterSpacing: '-0.25px',
              fontWeight: 400,
              color: '#fff',
            }),
            noOptionsMessage: (baseStyles, state) => ({
              ...baseStyles,
              fontSize,
              letterSpacing: '-0.25px',
              fontWeight: 400,
              color: '#fff',
            }),
          }}
        />
      )}

      {errorMsg && meta.touched && <Error name={name} error={errorMsg} />}
    </div>
  );
};

export default SelectField;
