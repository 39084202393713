import React from 'react';
import cn from 'classnames';

import css from './IconButton.module.scss';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
  children?: React.ReactNode;
}

function IconButton(props: Props) {
  const { className = '', children, type = 'button', ...rest } = props;

  return (
    <button className={cn(css.iconButton, className)} type={type as HTMLButtonElement['type']} {...rest}>
      <div className={css.icon}>{children}</div>
    </button>
  );
}

export { IconButton };
export default IconButton;
