import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Loader } from 'client/components/common';
import { CONFIG } from 'constants/index';
import { MAIN_PAGE_ROUTE, SAVING_LIST_PAGE_ROUTE } from 'client/constants/routes';
import { favoriteList as favoriteListApi } from 'client/redux/api/favorite-list';
import { useAnalytics, useAppSelector, useTranslation } from 'client/hooks';

import { selectUser } from 'client/redux/user/selectors';
import { Item } from 'client/components/pages/UserProfile/Item';
import ConfirmationDialog from 'client/components/common/ConfirmationDialog';
import ContentWrap from 'client/components/common/mobile/ContentWrap';
import { ControlPanel, Header } from 'client/components/common/mobile';

import css from './SavingList.module.scss';

const SavingList: React.FC<unknown> = () => {
  const user = useAppSelector(selectUser);
  const analytics = useAnalytics();
  const { data, isLoading } = favoriteListApi.endpoints.getFavoriteList.useQuery(undefined, {
    skip: !user.auth.isLoggedIn,
  });
  const favoriteCompanies = React.useMemo(() => (data?.success ? data.data : []), [data]);
  const [deleteCompany, deleteCompanyResult] = favoriteListApi.endpoints.deleteFromFavoriteList.useMutation();
  const { translate: _t } = useTranslation();
  const [confirmation, setConfirmation] = useState({ show: false, data: { id: '' } });

  React.useEffect(() => {
    if (user.isGAEnabled) {
      analytics.gtag.event('page_view', {
        page_title: `Investor Profile - My Library`,
        page_location: `${CONFIG.domainUrl}${SAVING_LIST_PAGE_ROUTE}`,
      });
    }
  }, [user.isGAEnabled]);

  const onCompanyDeleteClick = (id: string) => {
    setConfirmation({ show: true, data: { id } });
  };

  const handleCancel = () => {
    setConfirmation({ show: false, data: { id: '' } });
  };

  const handleConfirm = async () => {
    await deleteCompany({ companyId: confirmation.data.id });
  };

  if (!user.auth.isLoggedIn) {
    return <Redirect to={MAIN_PAGE_ROUTE} />;
  }

  const renderItems = () => {
    if (favoriteCompanies.length === 0) {
      return (
        <div className={css.noItems}>
          <h6 className={css.title}>{_t('savingList.heading')}</h6>
          <p className={css.message}>{_t('savingList.message')}</p>
          <Link to={MAIN_PAGE_ROUTE} className={css.button}>
            {_t('savingList.homeBtn')}
          </Link>
        </div>
      );
    }

    return (
      <div className={css.items}>
        {favoriteCompanies.map((company) => (
          <Item data={company} key={company._id} onDeleteBtnClick={onCompanyDeleteClick} />
        ))}
      </div>
    );
  };

  return (
    <div className={css.savingList} style={{ pointerEvents: deleteCompanyResult.isLoading ? 'none' : undefined }}>
      <Header className={css.header} />
      <ControlPanel className={css.controlPanel} />
      <ContentWrap>
        {favoriteCompanies.length !== 0 && (
          <div className={css.title}>
            {_t('userProfile.favoriteListTitle').replace('{name}', (user.name ?? '').split(' ')[0])}
          </div>
        )}
        {isLoading ? <Loader position="relative" /> : renderItems()}
      </ContentWrap>

      {confirmation.show && (
        <ConfirmationDialog
          message={_t('userProfile.confirmRemovingLabel')}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default SavingList;
