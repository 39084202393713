import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, getRedirectResult } from 'firebase/auth';

import { app as firebaseApp } from 'src/firebase-client';

import { signIn } from './sign-in';

export const checkRedirectSignIn = createAsyncThunk<boolean, undefined, { rejectValue: string }>(
  'user/checkSignInRedirect',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const user = await getUser();

      if (user) {
        await dispatch(
          signIn({
            provider: user.provider,
            email: user.email || '',
            pictureUrl: user.photoURL ?? '',
            name: user.displayName ?? '',
          }),
        );

        return true;
      }
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }

    return false;
  },
);

async function getUser() {
  const user = JSON.parse(decodeURIComponent(new URLSearchParams(window.location.search).get('user') ?? '{}'));

  if (Object.keys(user).length) {
    return user as { displayName?: string; email?: string; photoURL?: string; provider: 'linkedin' };
  }

  const firebaseAuth = getAuth(firebaseApp);
  const redirectResult = await getRedirectResult(firebaseAuth);

  if (redirectResult?.user) {
    return {
      ...redirectResult.user,
      provider: 'google',
    } as const;
  }

  return null;
}
