import * as yup from 'yup';
import { DistributiveOmit } from 'react-redux';

import { Onboarding, InputValue } from 'models/user/onboarding';
import { Input, InputType } from './input-types';

export type StepInput<I extends keyof Onboarding['form'] = keyof Onboarding['form']> = DistributiveOmit<
  Input,
  'name'
> & {
  name: I;
  dependsOn?: {
    field: keyof Onboarding['form'];
    value: InputValue;
  };
};

export type StageStep<I extends keyof Onboarding['form']> =
  | StepInput<I>
  | {
      type: InputType.FORM;
      name: string;
      inputs: StepInput<I>[];
      dependsOn?: StepInput<I>['dependsOn'];
    };

export function configureStep<I extends keyof Onboarding['form']>(step: StageStep<I>, data: Onboarding): StageStep<I> {
  switch (step.type) {
    case InputType.FORM: {
      return {
        ...step,
        inputs: step.inputs.map((input) => {
          const value = data.form[input.name];
          return { ...input, value };
        }),
      };
    }

    case InputType.ARRAY: {
      const value = data.form[step.name];
      return {
        ...step,
        value,
        schema: step.schema.of(
          yup.object().shape(
            Object.entries(step.shape).reduce((acc, [key, input]) => {
              if (input.type === InputType.ARRAY) {
                throw new Error('Nested arrays are not supported');
              }

              acc[key] = input.schema;
              return acc;
            }, {} as Record<string, yup.AnySchema>),
          ),
        ),
      };
    }

    default: {
      const value = data.form[step.name];
      return { ...step, value };
    }
  }
}

export function filterDependencies<I extends keyof Onboarding['form']>(steps: StageStep<I>[], data: Onboarding) {
  return steps.reduce((acc, step) => {
    if (step.type === InputType.FORM) {
      const filtered = filterDependencies(step.inputs, data);
      if (filtered.length) {
        acc.push({ ...step, inputs: filtered as StepInput<I>[] });
      }
    } else {
      if (step.dependsOn) {
        const { field, value: targetValue } = step.dependsOn;
        if (typeof targetValue === 'boolean') {
          if (targetValue === true) {
            if (['true', true].includes(data.form[field] as boolean)) {
              acc.push(step);
            }
          } else {
            if (['false', false].includes(data.form[field] as boolean)) {
              acc.push(step);
            }
          }
        } else if (data.form[field] === targetValue) {
          acc.push(step);
        }
      } else {
        acc.push(step);
      }
    }
    return acc;
  }, [] as StageStep<I>[]);
}

export const SKIPPABLE_TOKEN = 'SKIPPED';

export function skippableSchema(schema: yup.AnySchema) {
  return yup
    .mixed()
    .test('skippable', 'Test', async (value, ctx) => {
      const isSkipped = value === SKIPPABLE_TOKEN;

      if (isSkipped) {
        ctx.parent[ctx.path] = value;
        return true;
      } else {
        try {
          await schema.validate(value, { context: ctx });
          return true;
        } catch (err) {
          throw err;
        }
      }
    })
    .transform((value) => (value === SKIPPABLE_TOKEN ? value : schema.cast(value)));
}

export function getStageCurrentStepName(steps: StageStep<keyof Onboarding['form']>[]) {
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];
    const isRequiredAndEmpty = (step.type === InputType.FORM ? step.inputs : [step]).some((input) => {
      const schemaDescription = input.schema.describe();
      const isRequired = schemaDescription.tests?.some((test) => test.name === 'required');
      const isSkippable = schemaDescription.tests?.some((test) => test.name === 'skippable');
      return (isRequired || isSkippable) && (input.value === undefined || input.value === null);
    });

    if (isRequiredAndEmpty) {
      return step.name;
    }
  }

  return null;
}

export function getStageStep(steps: StageStep<keyof Onboarding['form']>[], targetStep: string) {
  for (let j = 0; j < steps.length; j++) {
    const step = steps[j];

    if (step.name === targetStep) {
      const prevStep = j > 0 ? steps[j - 1] : null;
      const nextStep = j < steps.length - 1 ? steps[j + 1] : null;

      return {
        prev: prevStep,
        current: step,
        next: nextStep,
      };
    }
  }

  return null;
}

export function getSchemaByInputName<I extends keyof Onboarding['form']>(
  steps: StageStep<keyof Onboarding['form']>[],
  inputName: I,
): yup.AnySchema | null {
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];

    if (step.type === InputType.FORM) {
      const schema = getSchemaByInputName(step.inputs, inputName);
      if (schema) return schema;
    } else {
      if (step.name === inputName) {
        return step.schema;
      }
    }
  }

  return null;
}
