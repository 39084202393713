import React from 'react';

export const FullScreen = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 24 24">
    <path
      fill="#fff"
      d="M21 8.5c-.6 0-1-.4-1-1V4h-3.5c-.6 0-1-.4-1-1s.4-1 1-1H21c.6 0 1 .4 1 1v4.5c0 .6-.4 1-1 1zM21 22h-4.5c-.6 0-1-.4-1-1s.4-1 1-1H20v-3.5c0-.6.4-1 1-1s1 .4 1 1V21c0 .6-.4 1-1 1zM7.5 22H3c-.6 0-1-.4-1-1v-4.5c0-.6.4-1 1-1s1 .4 1 1V20h3.5c.6 0 1 .4 1 1s-.4 1-1 1zM3 8.5c-.6 0-1-.4-1-1V3c0-.6.4-1 1-1h4.5c.6 0 1 .4 1 1s-.4 1-1 1H4v3.5c0 .6-.4 1-1 1z"
    />
  </svg>
);

export const Muted = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 24 24">
    <path
      fill="#fff"
      d="m21.707 20.293-18-18a.999.999 0 1 0-1.414 1.414L6.32 7.734C6.121 8.073 6 8.504 6 9v6c0 1.103.897 2 2 2h3.586l4.707 4.707A1 1 0 0 0 18 21v-1.586l2.293 2.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414zM18 3v11l-7-7h.59l4.7-4.71c.29-.28.72-.37 1.09-.21.38.15.62.52.62.92z"
    />
  </svg>
);

export const UnMuted = () => (
  <svg width="480px" height="449px" viewBox="0 0 480 449" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="volume" transform="translate(0.000000, 0.000000)" fill="#FFFFFF" fillRule="nonzero">
        <path
          d="M278.944,1.58668837 C273.376,-1.06931163 266.816,-0.365311626 262.016,3.50668837 L106.368,128.018688 L32,128.018688 C14.368,128.018688 0,142.386688 0,160.018688 L0,288.018688 C0,305.682688 14.368,320.018688 32,320.018688 L106.368,320.018688 L261.984,444.530688 C264.896,446.834688 268.448,448.018688 272,448.018688 C274.368,448.018688 276.736,447.474688 278.944,446.418688 C284.48,443.762688 288,438.162688 288,432.018688 L288,16.0186884 C288,9.87468837 284.48,4.27468837 278.944,1.58668837 L278.944,1.58668837 Z"
          id="Path"
        ></path>
        <path
          d="M368.992,110.866688 C362.688,104.658688 352.576,104.754688 346.368,110.994688 C340.16,117.298688 340.224,127.410688 346.496,133.650688 C370.688,157.522688 384,189.618688 384,224.018688 C384,258.418688 370.688,290.514688 346.496,314.386688 C340.224,320.562688 340.16,330.706688 346.368,337.010688 C349.504,340.178688 353.632,341.746688 357.728,341.746688 C361.792,341.746688 365.856,340.210688 368.992,337.106688 C399.328,307.250688 416,267.058688 416,224.018688 C416,180.978688 399.328,140.786688 368.992,110.866688 Z"
          id="Path"
        ></path>
        <path
          d="M414.144,65.7786884 C407.84,59.5386884 397.728,59.6026884 391.488,65.8746884 C385.28,72.1466884 385.344,82.2906884 391.584,88.4986884 C427.968,124.562688 448,172.690688 448,224.018688 C448,275.346688 427.968,323.442688 391.584,359.506688 C385.344,365.746688 385.28,375.890688 391.488,382.162688 C394.656,385.298688 398.752,386.866688 402.848,386.866688 C406.912,386.866688 411.008,385.330688 414.144,382.226688 C456.64,340.146688 480,283.954688 480,224.018688 C480,164.082688 456.64,107.890688 414.144,65.7786884 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
);
