import * as yup from 'yup';

import * as routes from 'api/routes';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = { companyId: string };
export type Result = void;

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape({
  companyId: yup.string().required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.COMPANY_WEBSITE_UPDATE_RATING;
