import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { VideoJSPlayer } from 'src/routes/client/components/common';

import VideoListItem from './VideoListItem';

import css from './VideoPlaylist.module.scss';

interface Props {
  className?: string;
  videos: { title: string; file: string }[];
}

export const VideoPlaylist: React.FC<Props> = ({ videos }) => {
  const [currentVideo, setCurrentVideo] = React.useState<{ file: string; title: string }>({
    file: videos[0].file,
    title: videos[0].title,
  });
  const [readyVideos, setReadyVideos] = React.useState<string[]>([]);
  const [isMainPlayerReady, setIsMainPlayerReady] = React.useState(false);

  return (
    <div className={css.playlist}>
      <div className={cn(css.mainPlayerWrap, !isMainPlayerReady && css.isLoading)}>
        <div className={css.mainPlayer}>
          <VideoJSPlayer
            id={'mandate-content-player'}
            videoSRC={currentVideo.file}
            isDefaultControls
            onReady={() => setIsMainPlayerReady(true)}
            playButtonClassName={css.mainPlayerPlayIcon}
          />
        </div>
        <p className={css.currentTitle}>{currentVideo.title}</p>
      </div>
      <div className={css.listWrap}>
        <div className={css.list}>
          {_.map(videos, (vid, index) => {
            return (
              <div
                key={vid.file}
                className={cn(css.listItem, !readyVideos.includes(`${vid.title}-${index}`) && css.isLoading)}
                onClick={() => setCurrentVideo(vid)}
              >
                <VideoListItem
                  videoSRC={vid.file}
                  id={`${vid.title}-${index}`}
                  isPlaying={vid.file === currentVideo.file}
                  onReady={(id) => setReadyVideos((state) => [...state, id])}
                />
                <p className={css.listTitle}>{vid.title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
