import React from 'react';
import gsap from 'gsap';
import cn from 'classnames';

import { Icon } from 'client/components/common';
import { useAppDispatch, useAppSelector } from 'client/hooks';
import { favoriteList as favListApi } from 'client/redux/api/favorite-list';
import { companies as companiesApi } from 'client/redux/api/companies';
import { selectAuth } from 'client/redux/user/selectors';
import { setPopup } from 'client/redux/ui';

import css from './CompanyActions.module.scss';

interface Props {
  className?: string;
  companyId: string;
  isInFavoriteList: boolean;
  isAlreadyVoted: boolean;
}

function CompanyActions(props: Props) {
  const { companyId, className = '', isAlreadyVoted, isInFavoriteList } = props;
  const [isBookmarkStateChanged, setIsBookmarkStateChanged] = React.useState(false);
  const labelRef = React.useRef<HTMLDivElement>(null);
  const bgRef = React.useRef<HTMLDivElement>(null);
  const iconRef = React.useRef<HTMLDivElement>(null);
  const tlRef = React.useRef<gsap.core.Timeline | null>(null);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const [updateRating] = companiesApi.endpoints.updateRating.useMutation();
  const [addToBookmarkList] = favListApi.endpoints.addToFavoriteList.useMutation();
  const [removeFromBookmarkList] = favListApi.endpoints.deleteFromFavoriteList.useMutation();

  const onBookmarkBtnClick = () => {
    if (!auth.isLoggedIn) {
      dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in' } }));
      return;
    }

    setIsBookmarkStateChanged(true);

    if (isInFavoriteList) {
      removeFromBookmarkList({ companyId });
    } else {
      addToBookmarkList({ companyId });
    }
  };

  const onUpvoteBtnClick = () => {
    if (!auth.isLoggedIn) {
      dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in' } }));
      return;
    }

    if (!isAlreadyVoted) {
      updateRating({ companyId });
    }
  };

  React.useEffect(() => {
    setIsBookmarkStateChanged(false);
    tlRef.current?.progress(0).kill();
  }, [companyId]);

  React.useEffect(() => {
    if (!isBookmarkStateChanged || !labelRef.current) return;

    const tl = gsap.timeline();
    tlRef.current = tl;

    // tl.to(iconRef.current, { duration: 0.3, opacity: 1, ease: 'none' });
    tl.to(bgRef.current, { duration: 0.3, width: labelRef.current.offsetWidth + 42, ease: 'power2.inOut' }, 0);
    tl.to(labelRef.current, { duration: 0.3, opacity: 1, ease: 'none' }, 0.15);
    tl.to(labelRef.current, { duration: 0.3, opacity: 0, ease: 'none' }, 2);
    tl.to(bgRef.current, { duration: 0.3, width: 32, ease: 'power2.inOut' }, '-=0.15');
    // tl.to(iconRef.current, { duration: 0.3, opacity: 0.6, ease: 'none' });

    return () => {
      tl.kill();
    };
  }, [isInFavoriteList, isBookmarkStateChanged]);

  return (
    <div className={cn(css.companyActions, className)}>
      <div className={cn(css.saveBtn, isInFavoriteList && css.highlighted)} onClick={onBookmarkBtnClick}>
        <div className={css.saveBtnBg} ref={bgRef} />
        <div className={css.saveBtnLabel} ref={labelRef}>
          <span>{isInFavoriteList ? 'Added' : 'Removed'}</span>
        </div>
        <Icon type="bookmark-unfilled" className={css.saveBtnIcon} containerRef={iconRef} />
      </div>
    </div>
  );
}

export { CompanyActions };
export default CompanyActions;
