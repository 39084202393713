import { useField } from 'formik';
import cn from 'classnames';
import React from 'react';

import Error from '../../Error';

import css from './Checkbox.module.scss';

interface Props {
  className?: string;
  name: string;
  label?: string;
}

const Checkbox: React.FC<Props> = (props) => {
  const { className = '', name, label } = props;
  const [field, meta, helpers] = useField(name);
  const isError = meta.error?.[0] && meta.touched;
  const errorMsg = !!(meta.touched && meta.error) ? meta.error : '';

  return (
    <label className={cn(css.checkbox, isError && css.withError, className)}>
      <div className={css.main}>
        <input type="checkbox" {...field} checked={field.value} />
        <span className={css.checkMark}></span>
        {label && <span className={css.labelText} dangerouslySetInnerHTML={{ __html: label }}></span>}
      </div>
      {errorMsg && meta.touched && <Error className={css.checkBoxError} name={name} error={errorMsg} />}
    </label>
  );
};

export default Checkbox;
