import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import topArr from 'client/assets/top-alignment.svg';
import css from './BackToTop.module.scss';

const btnText = 'Back To Top';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const visibleButton = () => {
    if (window.scrollY > window.innerHeight / 3 && !isVisible) {
      setIsVisible(true);
    }

    if (window.scrollY < window.innerHeight / 3 && isVisible) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', visibleButton);

      return function cleanupListener() {
        window.removeEventListener('scroll', visibleButton);
      };
    }
  }, [isVisible]);

  const clickOnBtnListener = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button className={cn(css.backToTop, isVisible && css.visible)} onClick={clickOnBtnListener}>
      <span className={css.blur}></span>
      <span className={css.inner}>
        <img src={topArr} alt="arrow to top" />
        {btnText}
      </span>
    </button>
  );
};

export default BackToTop;
