import React from 'react';
import { Link } from 'react-router-dom';

import { ADD_YOUR_COMPANY_PAGE_ROUTE, MAIN_PAGE_ROUTE } from 'client/constants/routes';
import { useTranslation } from 'client/hooks';

import css from './AddYourCompanyBtnLink.module.scss';

type Props = {
  from?: string;
};

const AddYourCompanyBtnLink: React.FC<Props> = ({ from = MAIN_PAGE_ROUTE.replace(':id?', '') }) => {
  const { translate } = useTranslation();

  return (
    <div className={css.wrapper}>
      <Link to={{ pathname: ADD_YOUR_COMPANY_PAGE_ROUTE, state: { from } }} className={css.button}>
        <div className={css.inner}>{translate('commonButtons.addYourCompany')}</div>
        <div className={css.buttonBlur}></div>
      </Link>
      <div className={css.wrapperBlur}></div>
    </div>
  );
};

export default AddYourCompanyBtnLink;
