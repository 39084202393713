import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Params as ChangePasswordParams,
  paramsSchema as changePasswordParamsSchema,
} from 'api/users/website/change-password/params';
import { Params as UpdateParams, paramsSchema as updateParamsSchema } from 'api/users/website/update/params';
import { Button } from 'client/components/common/mobile/Button';
import { ProfileEditPopUpData, setPopup } from 'client/redux/ui';
import { TextField } from 'client/components/common/form';
import { changePassword, update } from 'client/redux/user/actions';
import { selectUserId } from 'client/redux/user/selectors';
import { useAppDispatch, useAppSelector, useTranslation } from 'client/hooks';
import css from './ProfileEdit.module.scss';

interface Props {
  data: ProfileEditPopUpData;
}

const ProfileEdit: React.FC<Props> = (props) => {
  const { data } = props;

  const { translate: _t } = useTranslation('userProfile');
  const userId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();
  const handleClose = () => dispatch(setPopup(null));
  const isEditName = data.view === 'edit-name';

  const formikContext = useFormik<UpdateParams | ChangePasswordParams>({
    enableReinitialize: true,
    validationSchema: isEditName ? updateParamsSchema : changePasswordParamsSchema,
    initialValues: isEditName ? { name: data.value } : { oldPassword: '', newPassword: '', confirmNewPassword: '' },
    onSubmit: async (values) => {
      const errors: Record<string, string> = {};

      if ('newPassword' in values) {
        const result = await dispatch(changePassword({ ...values, id: userId }));

        if (result.meta.requestStatus === 'fulfilled') {
          handleClose();
        } else {
          errors.confirmNewPassword = 'Failed to update password.';
        }
      } else {
        const result = await dispatch(update({ ...values, id: userId }));
        if (result.meta.requestStatus === 'fulfilled') {
          handleClose();
        } else {
          errors.name = 'Failed to update name.';
        }
      }

      if (Object.keys(errors).length > 0) {
        formikContext.setErrors(errors);
      }
    },
  });

  const renderChangePasswordForm = () => {
    return (
      <FormikProvider value={formikContext}>
        <Form>
          <TextField className={css.field} name="oldPassword" label={_t('form.labels.oldPassword')} />
          <TextField className={css.field} name="newPassword" label={_t('form.labels.newPassword')} />
          <TextField className={css.field} name="confirmNewPassword" label={_t('form.labels.confirmNewPassword')} />

          <Button size="x-large-block" disabled={!formikContext.dirty || formikContext.isSubmitting} type="submit">
            {_t('form.submitBtnLabel')}
          </Button>
        </Form>
      </FormikProvider>
    );
  };

  const renderChangeNameForm = () => {
    return (
      <FormikProvider value={formikContext}>
        <Form>
          <TextField className={css.field} name="name" label={_t('form.labels.name')} />
          <Button size="x-large-block" disabled={!formikContext.dirty || formikContext.isSubmitting} type="submit">
            {_t('form.submitBtnLabel')}
          </Button>
        </Form>
      </FormikProvider>
    );
  };

  const renderForm = () => {
    switch (data.view) {
      case 'edit-name':
        return renderChangeNameForm();
      case 'edit-password':
        return renderChangePasswordForm();
      default:
        return renderChangeNameForm();
    }
  };

  return <div className={css.root}>{renderForm()}</div>;
};

export default ProfileEdit;
