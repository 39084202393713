import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ROUTE as CHANGE_PASSWORD_API_ROUTE,
  Params as ChangePasswordParams,
  Result as ChangePasswordResult,
} from 'api/users/website/change-password/params';
import { ApiResponse } from 'src/types/api-response';

import { api } from 'utils/api';

export const changePassword = createAsyncThunk<
  ApiResponse<ChangePasswordResult>,
  ChangePasswordParams & { id: string },
  { rejectValue: string }
>('user/changePassword', async (data, { rejectWithValue }) => {
  try {
    const { id, ...payload } = data;
    const response = await api.sendRequest<ChangePasswordResult>({
      url: CHANGE_PASSWORD_API_ROUTE.replace(':id', id),
      method: 'POST',
      data: payload,
    });

    if (response.success) {
      return response;
    }

    //@ts-expect-error TODO: fix correct response error
    throw new Error(response.error.message);
  } catch (e) {
    console.info('e', (e as Error).message || e);
    return rejectWithValue((e as Error).message);
  }
});
