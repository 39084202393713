import * as yup from 'yup';

import * as routes from 'api/routes';
import { IModel, TARGET_VALUES, TargetValues } from 'models/other/interface';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = { target: TargetValues };
export type Result = IModel | null;

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape({
  target: yup.mixed<TargetValues>().oneOf(Object.values(TARGET_VALUES)).required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.STATIC_PAGES_GET;
