import { Onboarding } from 'models/user/onboarding';
import * as accreditationQuestionnaire from './accreditation-questionnaire';
import * as accreditationFinalization from './accreditation-finalization';
import { StageStep, StepInput } from './stage';
import { InputType } from './input-types';

export function getProgress(onboarding: Onboarding) {
  // questionnaire:
  const personalDetailsStage = accreditationQuestionnaire.personalDetails.getPersonalDetails(onboarding);
  const questionnaireStage = accreditationQuestionnaire.questionnaire.getQuestionnaire(onboarding);
  const ndaStage = accreditationQuestionnaire.nda.getNDA(onboarding);
  // finalization:
  const contactDetails = accreditationFinalization.contactDetails.getContactDetails(onboarding);
  const bankDetails = accreditationFinalization.bankDetails.getBankDetails(onboarding);
  const certification = accreditationFinalization.certification.getCertification(onboarding);
  const taxDeclaration = accreditationFinalization.taxDeclaration.getTaxDeclaration(onboarding);
  const kyc = accreditationFinalization.kyc.getKYC(onboarding);

  return {
    personalDetails: getStageProgress(personalDetailsStage.steps, false),
    questionnaire: getStageProgress(questionnaireStage.steps, false),
    nda: getStageProgress(ndaStage.steps, false),
    contactDetails: getStageProgress(contactDetails.steps, false),
    bankDetails: getStageProgress(bankDetails.steps, false),
    certification: getStageProgress(certification.steps, false),
    taxDeclaration: getStageProgress(taxDeclaration.steps, false),
    kyc: getStageProgress(kyc.steps, false),
  };

  function getStageProgress(steps: StageStep<keyof Onboarding['form']>[], allFieldsNeeded: boolean) {
    const flattenedStageSteps: StepInput<keyof Onboarding['form']>[] = [];

    steps.forEach((step) => {
      if (step.type === InputType.FORM) flattenedStageSteps.push(...step.inputs);
      else flattenedStageSteps.push(step);
    });

    if (allFieldsNeeded) {
      const totalCompletedSteps = flattenedStageSteps.filter((step) => {
        const value = onboarding.form[step.name];

        return value !== undefined && value !== null;
      });

      return Math.min(flattenedStageSteps.length > 0 ? totalCompletedSteps.length / flattenedStageSteps.length : 1, 1);
    } else {
      const totalRequiredSteps = flattenedStageSteps.filter((step) => {
        const schemaDescription = step.schema.describe();
        const isRequired = schemaDescription.tests?.some((test) => test.name === 'required');

        return isRequired;
      });

      const totalCompletedRequiredSteps = totalRequiredSteps.filter((step) => {
        const value = onboarding.form[step.name];

        if (step.name === 'kycStatus') {
          return value !== undefined && value !== null && value !== 'init';
        }

        if (step.name === 'taxDeclarationStatus') {
          return value !== undefined && value !== null && value !== 'in-progress';
        }

        if (step.name === 'signatureStatus') {
          return value !== undefined && value !== null && value !== 'in-progress';
        }

        return value !== undefined && value !== null;
      });

      return Math.min(
        totalRequiredSteps.length > 0 ? totalCompletedRequiredSteps.length / totalRequiredSteps.length : 1,
        1,
      );
    }
  }
}
