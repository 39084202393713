import ReactDOM from 'react-dom';
import cn from 'classnames';
import React from 'react';

import { setPopup } from 'client/redux/ui';
import { useAppDispatch } from 'client/hooks';

import css from './ModalWrapper.module.scss';

interface Props extends React.PropsWithChildren {
  className?: string;
  contentClassName?: string;
  title: string;
  onClose?: () => void;
}

export const ModalWrapper: React.FC<Props> = ({ title, contentClassName, className = '', onClose, children }) => {
  const dispatch = useAppDispatch();
  const onCloseBtnClick = React.useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      dispatch(setPopup(null));
    }
  }, [dispatch]);

  return (
    <div className={cn(css.modalWrapper, className)}>
      <div className={cn(css.content, contentClassName)}>
        <div className={css.title}>{title}</div>
        <button className={css.closeButton} onClick={onCloseBtnClick}></button>
        {children}
      </div>
    </div>
  );
};
