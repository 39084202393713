import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Model as SettingsModel } from 'models/settings';
import { Version as OnboardingVersion } from 'models/user/onboarding';
import * as actions from './actions';

type Settings = Omit<SettingsModel, '_id'> & {
  lang: 'he' | 'en';
  isBusy: boolean;
};

const initialState: Settings = {
  lang: 'en',
  texts: {},
  onboardingVersion: OnboardingVersion.SHORTENED,
  isBusy: false,
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.texts = action.payload.texts;
      state.onboardingVersion = action.payload.onboardingVersion;
    },
  },
});

export const { init } = settings.actions;
export default settings.reducer;
