import * as yup from 'yup';

import { Onboarding } from 'models/user/onboarding';
import { filterDependencies, configureStep, getStageCurrentStepName, getStageStep } from '../stage';
import { InputType } from '../input-types';
import { VALIDATION_ERROR } from 'endpoints/index';

/**
 * Returns accreditation finalization form fields
 */
export function getCertification(data: Onboarding) {
  const t = (path: string) => `onboardingFinalization.accreditationCertification.${path}`;
  return {
    steps: filterDependencies(
      [
        configureStep(
          {
            type: InputType.MEDIA,
            name: 'certificationId',
            label: t('certificationId'),
            explanation: '',
            schema: yup
              .mixed()
              .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value) => {
                return value && typeof value === 'object'
                  ? ['application/pdf', 'image/jpeg', 'image/jpg'].includes(value.mimetype || value.type)
                  : true;
              })
              .test('size', VALIDATION_ERROR.INVALID_SIZE, (value) => {
                return value && typeof value === 'object' ? value.size < 1024 * 1024 * 3 : true;
              })
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
      ],
      data,
    ),
  };
}

export function getCurrentStep(data: Onboarding) {
  const currentStepName = getStageCurrentStepName(getCertification(data).steps);
  return currentStepName ? getStep(data, currentStepName) : null;
}

export function getStep(data: Onboarding, targetStep: string) {
  return getStageStep(getCertification(data).steps, targetStep);
}
