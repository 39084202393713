import React from 'react';
import cn from 'classnames';

import { useTranslation, useAppSelector } from 'client/hooks';
import { selectUserOnboarding } from 'client/redux/user/selectors';
import { STAGES } from 'client/types';

import { Screen } from '../MainWrapper';
import css from './StepsIndicator.module.scss';

interface Props {
  activeQuestion: number;
  questionsLength: number;
  screen: Screen;
  stage: STAGES;
}

const StepsIndicator = ({ activeQuestion, questionsLength, screen, stage }: Props) => {
  const { translate } = useTranslation();
  const stages: STAGES[] = ['personalDetails', 'questionnaire', 'nda'];
  const activeStageIndex = stages.indexOf(stage);
  const onBoarding = useAppSelector(selectUserOnboarding);
  const signatureStatus = onBoarding.form.signatureStatus;

  // const completedPercents = () => {
  //   const stepQuestionsCount = questionsCounts[currentStep];
  //   if (currentQuestion === 0) return 0;

  //   return (100 * currentQuestion) / stepQuestionsCount;
  // };

  React.useEffect(() => {
    // const stepQuestionsCount = questionsCounts[currentStep];
    const bar = document.getElementById('bar');
    let val = 0;
    let pct = 0;
    if (bar) {
      const r = bar.getAttribute('r');
      const c = Math.PI * (Number(r) * 2);

      if (activeQuestion === 0) {
        val = 0;
      } else {
        val = (100 * activeQuestion) / questionsLength;
      }

      pct = ((100 - val) / 100) * c;

      bar.style.strokeDashoffset = `${pct}px`;
    }
  }, [activeQuestion, questionsLength]);

  return (
    <div className={css.stepsIndicator}>
      {stages.map((item, i) => {
        const isActive = stage === item && screen !== 'welcome' && screen !== 'thanks';
        const isShowProgress = item === stage && stage !== 'nda' && screen !== 'welcome' && screen !== 'thanks';

        return (
          <div className={css.stepWrapper} key={`step${i}`}>
            <div
              className={cn(
                css.step,
                isActive && css.active,
                (i < activeStageIndex || signatureStatus === 'completed') && css.completed,
              )}
            >
              <span className={css.stepNumber}>{i + 1}</span>
              {isShowProgress && (
                <div className={cn(css.propgressBarSvg, isShowProgress && css.show)}>
                  <svg className={css.svg} width="55" height="55" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <circle
                      r="26.5"
                      cx="27.5"
                      cy="27.5"
                      fill="transparent"
                      strokeDasharray="166.5"
                      strokeDashoffset="0"
                    ></circle>
                    <circle
                      id="bar"
                      r="26.5"
                      cx="27.5"
                      cy="27.5"
                      fill="transparent"
                      strokeDasharray="166.5"
                      strokeDashoffset="166.5"
                      className={css.bar}
                    ></circle>
                  </svg>
                </div>
              )}
            </div>
            <div className={css.texts}>
              <p className={css.title}>{translate(`questionnaire.stepsIndicator.${i}.title`)}</p>
              <p className={css.status}>
                {i < activeStageIndex || signatureStatus === 'completed'
                  ? translate(`questionnaire.stepsIndicator.${i}.completed`)
                  : `~ ${translate(`questionnaire.stepsIndicator.${i}.default`)}`}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StepsIndicator;
