import { ReviewResult, ReviewStatus } from './types';

export const getReviewStatus = (reviewStatus: ReviewStatus, reviewResult: ReviewResult): ReviewStatus | 'rejected' => {
  if (reviewStatus === 'init') {
    return 'init';
  }

  if (reviewStatus === 'pending') {
    return 'pending';
  }

  if (reviewStatus === 'completed' && reviewResult?.reviewAnswer === 'RED') {
    return 'rejected';
  }

  if (reviewStatus === 'completed' && reviewResult?.reviewAnswer === 'GREEN') {
    return 'completed';
  }

  return reviewStatus;
};
