import { FlattenMaps } from 'mongoose';

import * as routes from 'api/routes';
import { IModel } from 'models/category/interface';

export type Params = { isVisible?: boolean };
export type Result = Array<FlattenMaps<IModel>> | null;

export const ROUTE = routes.CATEGORY_GET_ALL;
export const WEBSITE_ROUTE = routes.CATEGORY_WEBSITE_GET_ALL;
