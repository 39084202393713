import { useEffect, useState } from 'react';

export const useOnScreen = (ref: React.RefObject<HTMLElement>, threshold?: number) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer =
    typeof window !== 'undefined'
      ? new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), { threshold: threshold || 0 })
      : null;

  useEffect(() => {
    if (observer && ref.current) {
      observer.observe(ref.current);
      // // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
  }, [ref.current]);

  return isIntersecting;
};
