import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory } from 'react-router-dom';
import noop from 'lodash/noop';
import cn from 'classnames';

import * as routes from 'client/constants/routes';
import { Scroller } from 'client/utils/scroller';
import { MainPageWebsiteSliderItem } from 'api/main_page/public/get/params';
import { Header, ControlPanel, ContentWrap, ScreenSlider } from 'client/components/common/mobile';
import { main as mainPageApi } from 'client/redux/api/main';
import { useAnalytics, useResponsive } from 'client/hooks';
import { useAppSelector } from 'client/hooks/redux';
import { selectUserGAStatus } from 'client/redux/user/selectors';
import { CONFIG } from 'constants/index';

import { Company } from '../MainMobile/Companies/Company';

import css from './Mandates.module.scss';

interface Props {
  className?: string;
}

const DEFAULT_MANDATES: MainPageWebsiteSliderItem[] = [];

const Mandates = (props: Props) => {
  const { className = '' } = props;
  const analytics = useAnalytics();
  const [isMobile] = useResponsive(['MOBILE']);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const scrollerRef = React.useRef<Scroller>(new Scroller());
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const userGAStatus = useAppSelector(selectUserGAStatus);

  const { data: mainPageData, isFetching: isMainPageFetching } = mainPageApi.endpoints.getMainPageSliders.useQuery({
    type: ['investNowSlider'],
  });
  const mandates =
    mainPageData?.success && mainPageData.data.investNowSlider ? mainPageData.data.investNowSlider : DEFAULT_MANDATES;
  const total = mandates?.length ?? 0;
  const page = Number(urlParams.get('page')) || 1;
  const isBusy = isMainPageFetching;

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: 'MandatesPlaylistPage',
        page_location: `${CONFIG.domainUrl}${routes.MANDATES_PAGE_ROUTE}?${urlParams}`,
      });
    }
  }, [history.location, userGAStatus]);

  React.useEffect(() => {
    if (!contentRef.current) {
      console.log('Unable to initialize scroller: container is null');
      return;
    }

    const scroller = scrollerRef.current;

    scroller.init({ container: contentRef.current });
    document.getElementById('INDWrap')?.classList.add('hide');

    if (document.getElementsByClassName('intercom-lightweight-app-launcher')[0]) {
      document.getElementsByClassName('intercom-lightweight-app-launcher')[0]?.classList.add('hideIntercom');
    }

    if (document.getElementsByClassName('intercom-with-namespace-52k34s')[0]) {
      document.getElementsByClassName('intercom-with-namespace-52k34s')[0]?.classList.add('hideIntercom');
    }

    return () => {
      scroller.destroy();
      document.getElementById('INDWrap')?.classList.remove('hide');

      if (document.getElementsByClassName('intercom-lightweight-app-launcher')[0]) {
        document.getElementsByClassName('intercom-lightweight-app-launcher')[0]?.classList.remove('hideIntercom');
      }

      if (document.getElementsByClassName('intercom-with-namespace-52k34s')[0]) {
        document.getElementsByClassName('intercom-with-namespace-52k34s')[0]?.classList.remove('hideIntercom');
      }
    };
  }, [urlParams.get('page')]);

  const onSliderPageChange = React.useCallback(
    (page: number) => {
      const urlParams = new URLSearchParams(history.location.search);
      analytics.gtag.event('MobileSwipe-Mandates', {
        PageID: String(page),
      });
      urlParams.set('page', String(page));
      history.push(`${routes.MANDATES_PAGE_ROUTE}?${urlParams.toString()}`);
    },
    [history],
  );

  const renderHelmet = () => {
    const seo = {
      title: SEO_OG_TITLE,
      description: SEO_OG_DESCRIPTION,
    };
    const og = {
      title: SEO_OG_TITLE,
      description: SEO_OG_DESCRIPTION,
    };

    return (
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={og.title} />
        <meta property="og:description" content={og.description} />
      </Helmet>
    );
  };

  if (isMobile === false) {
    urlParams.delete('page');
    return <Redirect to={routes.ROOT + '?' + urlParams.toString()} />;
  }

  if (!urlParams.get('page')) {
    urlParams.set('page', '1');
    return <Redirect to={routes.MANDATES_PAGE_ROUTE + '?' + urlParams.toString()} />;
  }

  return (
    <>
      {renderHelmet()}
      <div className={cn(css.mandates, css.sliderActive, className)} ref={containerRef}>
        <Header className={css.header} id="mandates" />
        <ControlPanel className={css.controlPanel} />
        <ContentWrap className={css.content} containerRef={contentRef}>
          <ScreenSlider
            className={css.companies}
            items={mandates}
            page={page}
            total={total}
            scroller={scrollerRef.current}
            active
            busy={isBusy}
            containerRef={containerRef}
            slide={Company}
            noResults={NoResults}
            onPageChange={onSliderPageChange}
            onDeactivate={noop}
          />
        </ContentWrap>
      </div>
    </>
  );
};

const SEO_OG_TITLE = `Catalyst Investors' Club - Exclusive Opportunities to Invest in Startups`;
const SEO_OG_DESCRIPTION = `Discover the most disruptive startups, find exclusive investment opportunities, and join Catalyst Investors' Club to invest alongside reputable lead investors.`;

const NoResults = () => {
  return (
    <div className={css.noResults}>
      <p className={css.noResultsText}>Sorry, nothing to show here. Please check back later.</p>
    </div>
  );
};

export default Mandates;
