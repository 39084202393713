// import { thousandsSeparator } from 'utils/thousand-separator';

const CURRENCY_MARK = '$';

export const prettifyValue = (v: number | string) => {
  return `${CURRENCY_MARK}${thousandsSeparator(+v as number)}`;
};

function thousandsSeparator(num: number) {
  const arr = `${num}`.includes('.') ? `${Math.round((num + Number.EPSILON) * 100) / 100}`.split('.') : [`${num}`];
  const b = parseInt(`${arr[0]}`, 10).toLocaleString('en-US', { minimumFractionDigits: 0 });
  return arr.length === 2 ? `${b}.${arr[1].slice(0, 2)}` : b;
}

export const linkTemplate = (link: string, label: string) => {
  return `<a href="${link}" target="_blank" rel="norefferer noopener">${label}</a>`;
};
