export class StickyCards {
  private element: HTMLDivElement;
  private items: HTMLCollection;
  private scrolling: boolean;
  private scrollingListener: boolean | (() => void);
  private marginY: number;
  private cardHeight: number;
  private cardTop: number;

  constructor() {
    this.element = document.getElementsByClassName('cards')[0] as HTMLDivElement;
    this.items = document.getElementsByClassName('card');
    this.scrollingListener = false;
    this.scrolling = false;
    this.marginY = 20;
    this.cardHeight = document.getElementsByClassName('card')[0].getBoundingClientRect().height;
    this.cardTop = Math.floor(
      parseFloat(getComputedStyle(document.getElementsByClassName('card')[0]).getPropertyValue('top')),
    );

    this.init(this);
  }

  init(element: any) {
    const observer = new IntersectionObserver(this.stackCardsCallback.bind(this), { threshold: [0, 1] });
    observer.observe(element.element);

    for (let i = 0; i < this.items.length; i++) {
      (this.items[i] as HTMLElement).style.transform = 'translateY(' + element.marginY * i + 'px)';
    }
  }

  stackCardsCallback(entry: any) {
    if (entry[0].isIntersecting) {
      if (this.scrollingListener) return;
      this.stackCardsInitEvent(this);
    } else {
      if (!this.scrollingListener) return;
      window.removeEventListener('scroll', this.scrollingListener as any);
      this.scrollingListener = false;
    }
  }

  stackCardsInitEvent(element: any) {
    element.scrollingListener = this.stackCardsScrolling.bind(element);
    window.addEventListener('scroll', element.scrollingListener);
  }

  stackCardsScrolling() {
    if (this.scrolling) return;
    this.scrolling = true;
    window.requestAnimationFrame(this.animateStackCards.bind(this));
  }

  animateStackCards() {
    const top = this.element.getBoundingClientRect().top;

    for (let i = 0; i < this.items.length; i++) {
      const scrolling = this.cardTop - top - i * (this.cardHeight + this.marginY);

      if (scrolling > 0) {
        // card is fixed - we can scale it down
        this.items[i].setAttribute(
          'style',
          'transform: translateY(' +
            this.marginY * i +
            'px) scale(' +
            (this.cardHeight - scrolling * 0.05) / this.cardHeight +
            ');',
        );
      }
    }

    this.scrolling = false;
  }
}
