import React from 'react';
import cn from 'classnames';

import css from './ContentWrap.module.scss';

interface Props extends React.PropsWithChildren {
  className?: string;
}

export const ContentWrap = ({ children, className }: Props) => {
  return <div className={cn(css.contentWrap, className)}>{children}</div>;
};

export default ContentWrap;
