import React from 'react';
import { useLocation } from 'react-router';
import { STAGES } from 'client/types';

import { CONFIG } from 'constants/index';
import { useAnalytics } from 'client/hooks';

import PersonalDetails from './PersonalDetails';
import Questionnaire from './Questionnaire';
import NDA from './NDA';

import css from './Stages.module.scss';

interface Props {
  setQuestion: (val: number) => void;
  setLength: (val: number) => void;
  stage: STAGES;
  setStage: (val: STAGES) => void;
  setScreen: () => void;
}

const Stages = ({ setQuestion, setLength, stage, setStage, setScreen }: Props) => {
  const location = useLocation();
  const analytics = useAnalytics();

  React.useEffect(() => {
    switch (stage) {
      case 'personalDetails':
        analytics.gtag.event('page_view', {
          page_title: `Questionnaire - Personal details`,
          page_location: `${CONFIG.domainUrl}${location.pathname}`,
        });
        break;
      case 'questionnaire':
        analytics.gtag.event('page_view', {
          page_title: `Questionnaire - Questionnaire`,
          page_location: `${CONFIG.domainUrl}${location.pathname}`,
        });
        break;
      case 'nda':
        analytics.gtag.event('page_view', {
          page_title: `Questionnaire - NDA`,
          page_location: `${CONFIG.domainUrl}${location.pathname}`,
        });
        break;
      default:
        break;
    }
  }, [stage]);

  const renderStage = (stage: STAGES) => {
    switch (stage) {
      case 'personalDetails':
        return (
          <PersonalDetails
            key="personalDetails"
            setStage={() => setStage('questionnaire')}
            setActiveQuestion={setQuestion}
            setQuestionsLength={setLength}
          />
        );
      case 'questionnaire':
        return (
          <Questionnaire
            key="questionnaire"
            setActiveQuestion={setQuestion}
            setQuestionsLength={setLength}
            setStage={() => setStage('nda')}
          />
        );
      case 'nda':
        return <NDA setScreen={setScreen} />;
      default:
        break;
    }
  };
  return <div className={css.stageWrapper}>{renderStage(stage)}</div>;
};

export default Stages;
