import { createAsyncThunk } from '@reduxjs/toolkit';

import { ROUTE, Params, Result } from 'api/docusign/get-envelope/params';
import { ApiResponse } from 'src/types/api-response';

import { api } from 'utils/api';

export const getEnvelopeUrl = createAsyncThunk<ApiResponse<Result>, Params, { rejectValue: string }>(
  'user/getEnvelopeUrl',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.sendRequest<Result>({
        url: ROUTE,
        method: 'GET',
        data,
      });

      if (response.success) {
        return response;
      }

      //@ts-expect-error TODO: fix correct response error
      throw new Error(response.error.message);
    } catch (e) {
      console.info('e', (e as Error).message || e);
      return rejectWithValue((e as Error).message);
    }
  },
);
