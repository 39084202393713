import React from 'react';
import _ from 'lodash';
import { EnvelopeType } from 'api/utils/docuSign/types';
import { useAppDispatch } from 'client/hooks';
import { setPopup } from 'client/redux/ui';

import TaxDeclareForm from './TaxDeclareForm';
import TaxDocument from './TaxDocument';

import css from './TaxDeclare.module.scss';

export type Screen = 'form' | 'taxDocument';

const TaxDeclare = () => {
  const [screen, setScreen] = React.useState<Screen>('form');
  const [docType, setDocType] = React.useState<EnvelopeType>('w-8ben');
  const dispatch = useAppDispatch();

  const renderScreen = (screen: Screen) => {
    switch (screen) {
      case 'form':
        return <TaxDeclareForm setScreen={setScreen} setDocumentType={setDocumentType} />;
      case 'taxDocument':
        return <TaxDocument docType={docType} />;
      default:
        return null;
    }
  };

  const setDocumentType = (type: EnvelopeType) => {
    setDocType(type);
  };

  const clickOnCloseBtn = () => {
    dispatch(setPopup(null));
  };

  return (
    <>
      {screen === 'taxDocument' && <div className={css.taxDocCloseBtn} onClick={clickOnCloseBtn}></div>}
      {renderScreen(screen)}
      <div className={css.blurredBlock}></div>
    </>
  );
};

export default TaxDeclare;
