import { Result as Mandate } from 'api/mandate/website/get/params';
import graphImg from './assets/graph.png';

export const mandate: Omit<
  NonNullable<Required<Mandate['mandate']>>,
  'prevRounds' | 'recruitmentTotalAmount' | 'valuation'
> & {
  prevRounds: string;
  recruitmentTotalAmount: string;
  valuation: string;
} = {
  _id: '1',
  about: 'Register to view',
  avatarVideo: 'avatarVideo',
  categories: [
    {
      name: 'Register to view',
      slug: 'register_to_view',
      _id: '1',
    },
  ],
  clip: 'clip',
  companyId: '1',
  companySize: 'Register to view',
  investmentStatusColor: '#25e061,',
  investmentStatusTitle: ' Open Investment',
  country: 'Register to view',
  founded: 2024,
  investmentOffersDeadline: new Date().toUTCString(),
  investmentStatus: 'OPEN_FOR_INVESTMENTS',
  isProgressBarVisible: false,
  isVisible: true,
  keyInformation: `
  <h3>Register to view</h3>
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, nunc</p>
  <br />
  <br />
  <img src="${graphImg}" alt="graph" />
  `,
  dealTerms: `<b>Empathetic AI-powered robotic</b> 
  <p>Companion for the elderly; one of the only AIs that has lived with humans over sustained periods of up to 3 years; developed advanced empathetic interactions driving 20+ daily interactions on average Research at Duke University shows ElliQ has 96% efficacy in decreasing loneliness and improving health Commercially launched in 2022 with New York State contract, renewed in Q1 2023 Companion for the elderly; one of the only AIs that has lived with humans over sustained periods of up to 3 years; developed advanced empathetic interactions driving 20+ daily interactions on average Research at Duke University shows ElliQ has 96% efficacy in decreasing loneliness and improving health Commercially launched in 2022 with New York State contract, renewed in Q1 2023</p>`,
  leadInvestorInfo: [
    {
      name: 'Register to view',
      description: 'Register to view',
      logo: '',
    },
  ],
  legalName: 'Register to view',
  logo: 'logo',
  name: 'Register to view',
  prevRounds: 'Register to view',
  progressBarPercentage: 0,
  raised: 'Register to view',
  raisedFieldLabel: 'raised',
  recruitmentTotalAmount: 'Register to view',
  seriesFunding: 'Register to view',
  investmentTerms: '',
  shortDescription: 'Register to view',
  socials: {
    facebookUrl: 'facebookUrl',
    crunchbaseUrl: 'crunchbaseUrl',
    instagramUrl: 'instagramUrl',
    linkedInUrl: 'linkedInUrl',
    tiktok: 'tiktokUrl',
    twitter: 'twitterUrl',
  },
  sourceOfInformation: 'Register to view',
  team: [
    {
      avatar: 'Register to view',
      description: 'Register to view',
      name: 'Register to view',
      role: 'Register to view',
    },
  ],
  termsOfDeal: 'Register to view',
  valuation: 'Register to view',
  videoGallery: [
    {
      title: 'Register to view',
      file: 'Register to view',
    },
  ],
  documents: [
    {
      title: 'Register to view',
      file: '#',
    },
    {
      title: 'Register to view',
      file: '#',
    },
    {
      title: 'Register to view',
      file: '#',
    },
  ],
  websiteLink: 'websiteLink',
};
