import gsap from 'gsap';
import WheelIndicator, { WheelIndicatorEvent } from 'wheel-indicator';
import { Dispatcher } from 'client/utils/dispatcher';

type ScrollerEventMap = {
  wheelScroll: number;
  wheelSwipe: WheelIndicatorEvent['direction'];
  touchScroll: number;
  touch: TouchEvent;
};

export class Scroller {
  private dispatcher: Dispatcher<ScrollerEventMap>;
  private container: HTMLElement | null = null;
  private abortController: AbortController | null = null;
  private wheelIndicator: WheelIndicator | null = null;

  private touchStartPos: { x: number; y: number } | null = null;

  constructor() {
    this.dispatcher = new Dispatcher();
  }

  init(params: { container: HTMLElement }) {
    if (this.container) {
      return;
    }

    this.container = params.container;

    this.abortController = new AbortController();
    this.wheelIndicator = new WheelIndicator({
      elem: this.container,
      callback: this.onWheelIndicatorEvent,
    });

    this.container.addEventListener('wheel', this.onWheelEvent, { passive: true, signal: this.abortController.signal });
    this.container.addEventListener('touchstart', this.onTouchEvent, { signal: this.abortController.signal });
    this.container.addEventListener('touchmove', this.onTouchEvent, { signal: this.abortController.signal });
    this.container.addEventListener('touchend', this.onTouchEvent, { signal: this.abortController.signal });
  }

  subscribe: typeof this.dispatcher.subscribe = (event, listenr, key) => {
    return this.dispatcher.subscribe(event, listenr, key);
  };

  unsubscribe: typeof this.dispatcher.unsubscribe = (event, listenr) => {
    this.dispatcher.unsubscribe(event, listenr);
  };

  triggerEvent: typeof this.dispatcher.triggerEvent = (event, data) => {
    this.dispatcher.triggerEvent(event, data);
  };

  destroy: typeof this.dispatcher.destroy = () => {
    this.wheelIndicator?.destroy();
    this.abortController?.abort();
    this.dispatcher.destroy();
    this.container = null;
  };

  private onWheelIndicatorEvent = (e: WheelIndicatorEvent) => {
    this.triggerEvent('wheelSwipe', e.direction);
  };

  private onWheelEvent = (event: WheelEvent) => {
    this.triggerEvent('wheelScroll', event.deltaY);
  };

  private onTouchEvent = (event: TouchEvent) => {
    switch (event.type) {
      case 'touchstart':
        this.touchStartPos = { x: event.touches[0].clientX, y: event.touches[0].clientY };
        break;

      case 'touchmove':
        event.preventDefault();

        if (!this.touchStartPos) {
          return;
        }

        const deltaY = event.touches[0].clientY - this.touchStartPos.y;

        this.triggerEvent('touchScroll', -deltaY);
        this.touchStartPos = { x: event.touches[0].clientY, y: event.touches[0].clientY };
        break;

      case 'touchend':
        this.touchStartPos = null;
        break;
    }

    this.triggerEvent('touch', event);
  };
}

export function scrollTo(target: HTMLDivElement | null, value: number, params?: gsap.TweenVars) {
  if (!target) return;

  gsap.to(target, {
    duration: 0.25,
    ease: 'power4.out',
    ...params,
    y: -value,
  });
}

export default Scroller;
