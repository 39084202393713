import React from 'react';
import cn from 'classnames';
import Lottie from 'lottie-react';

import { useAppDispatch } from 'client/hooks';

import loaderAnimation from 'utils/docusign-loader-anim.json';
import { getEnvelopeUrl } from 'client/redux/user/actions';
import { EnvelopeType } from 'api/utils/docuSign/types';
import { CONFIG } from 'constants/index';

import css from './Envelope.module.scss';

const CONTAINER_ID = 'docusign-websdk-container';

interface Props {
  type: EnvelopeType;
  className?: string;
  onUIReady?: (envelopeId: string) => void;
  onSignComplete?: (envelopeId: string) => void;
  loaderText?: string;
}

const Envelope: React.FC<Props> = ({ type, onSignComplete, onUIReady, className = '', loaderText = '' }) => {
  const dispatch = useAppDispatch();
  const docusignInstance = React.useRef<any>(null);
  const [uiState, setUiState] = React.useState<'loading' | 'sign-document' | 'success' | 'error'>('loading');

  const renderAdditionalContent = () => {
    switch (uiState) {
      case 'error':
        // TODO: change it when design will be ready
        return <p>Error occurred</p>;
      case 'loading':
        return (
          <div className={css.loaderWrapper}>
            <div className={css.loader}>
              <Lottie animationData={loaderAnimation} loop={true} />
            </div>
            <div className={css.text}>{loaderText}</div>
          </div>
        );
      case 'success':
        return <p>Success</p>;
      case 'sign-document':
      default:
        return null;
    }
  };

  React.useEffect(() => {
    let url = '';

    init();

    async function getEnvelope() {
      const result = await dispatch(getEnvelopeUrl({ type })).unwrap();

      return result.success ? result.data : null;
    }

    async function init() {
      try {
        const result = await getEnvelope();
        url = result?.url || '';

        if (url) {
          launchWebSdk(url, result?.envelopeId || '');
        } else {
          console.info('there is no url');
        }
      } catch (e) {
        console.info('e', e);
        setUiState('error');
      }
    }

    function launchWebSdk(url: string, envelopeId: string) {
      (window as any).DocuSign.loadDocuSign(CONFIG.dsIntegrationKey)
        .then((docusign: any) => {
          docusignInstance.current = docusign.signing({
            url,
            displayFormat: 'focused',
            style: {
              /** High-level variables that mirror our existing branding APIs. Reusing the branding name here for familiarity. */
              branding: {
                primaryButton: {
                  /** Background color of primary button */
                  backgroundColor: '#0757d3',
                  /** Text color of primary button */
                  color: '#fff',
                },
              },

              /** High-level components we allow specific overrides for */
              signingNavigationButton: {
                finishText: 'Finish',
                backgroundColor: '#0757d3',
                color: '#fff',
                position: 'bottom-center',
              },
            },
          });

          if (docusignInstance.current) {
            docusignInstance.current.on('ready', (event: any) => {
              setUiState('sign-document');
              onUIReady?.(envelopeId);
            });

            docusignInstance.current.on('sessionEnd', (event: any) => {
              /** The event here denotes what caused the sessionEnd to trigger, such as signing_complete, ttl_expired etc../ **/
              console.log('sessionend', event);
              setUiState('success');

              if (event.sessionEndType === 'signing_complete') {
                onSignComplete?.(envelopeId);
              }
            });

            docusignInstance.current.mount(`#${CONTAINER_ID}`);

            //@ts-expect-error test
            window.docusign = docusign;
          }
        })
        .catch((ex: Error) => {
          console.info('ex', ex);
        });
    }

    return () => {
      docusignInstance.current?.off();
    };
  }, []);

  return (
    <div className={cn(css.envelopeWrapper, className)}>
      <div className={css.contentWrapper}>
        <div className={cn(css.envelopeContent, uiState === 'sign-document' && css.visible)} id={CONTAINER_ID}></div>
        {renderAdditionalContent()}
      </div>
    </div>
  );
};

export default Envelope;
