import React from 'react';
import cn from 'classnames';

import { useTranslation } from 'client/hooks';
import Icon from 'client/components/common/Icon';

import css from './SuccessMessages.module.scss';

type Props = {
  onCloseClick: () => unknown;
  onTryAgainClick: () => unknown;
  email?: string;
};

const SuccessEmailSend: React.FC<Props> = ({ onCloseClick, onTryAgainClick, email }) => {
  const { translate: _t } = useTranslation('popups');

  return (
    <div className={cn(css.success, css.emailSendSuccess)}>
      <h6 className={css.title}>{_t('login.successMessages.successEmailSend.title')}</h6>
      <p className={css.info}>
        {_t('login.successMessages.successEmailSend.info')}: {email}
      </p>
      <div className={css.bottom}>
        <p className={css.text}>{_t('login.successMessages.successEmailSend.bottomP1')}</p>
        <p className={css.text}>
          {_t('login.successMessages.successEmailSend.bottomP2')}
          <span className={css.tryAgainAction} onClick={onTryAgainClick}>
            {' '}
            {_t('login.successMessages.successEmailSend.tryAgainLabel')}
          </span>
        </p>
      </div>

      <button onClick={onCloseClick} className={css.closeButton}>
        <Icon type="close" />
      </button>
    </div>
  );
};

export default SuccessEmailSend;
