export const renameFile = (file: File, name: string) => {
  const blob = file.slice(0, file.size, file.type);
  const regularFilesType = file.type.split('/')[1];
  const OFFICE_DOCS_MIME_TYPE_MAP: Record<string, string> = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.msword': 'doc',
  };

  return new File([blob], `${name}.${OFFICE_DOCS_MIME_TYPE_MAP[file.type] ?? regularFilesType}`, { type: file.type });
};
