import * as yup from 'yup';

import * as routes from 'api/routes';
import { IModel } from 'models/company_claim/interface';
import { VALIDATION_ERROR } from 'endpoints/index';

export type Params = Omit<IModel, '_id'>;
export type Result = void;

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape(
  {
    fullName: yup.string().required(VALIDATION_ERROR.REQUIRED),
    companyName: yup.string().required(VALIDATION_ERROR.REQUIRED),
    email: yup.string().email(VALIDATION_ERROR.INVALID_EMAIL).required(VALIDATION_ERROR.REQUIRED),
    videoUrl: yup
      .string()
      .matches(
        /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}$/,
        VALIDATION_ERROR.INVALID_URL,
      )
      .required(VALIDATION_ERROR.REQUIRED),
    isNewsletterEnabled: yup.boolean().required(VALIDATION_ERROR.REQUIRED),
    websiteUrl: yup
      .string()
      .optional()
      .when('websiteUrl', {
        is: (websiteUrl: string) => Boolean(websiteUrl),
        then: yup
          .string()
          .matches(
            /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
            VALIDATION_ERROR.INVALID_URL,
          ),
      }),
    phone: yup
      .string()
      .optional()
      .when('phone', {
        is: (phone: string) => Boolean(phone),
        then: yup.string().min(8).required(VALIDATION_ERROR.REQUIRED),
      }),
  },
  [
    ['phone', 'phone'],
    ['websiteUrl', 'websiteUrl'],
  ],
);

export const paramsSchemaClientForm = paramsSchema.shape({
  checkbox2: yup.boolean().oneOf([true], VALIDATION_ERROR.REQUIRED).required(VALIDATION_ERROR.REQUIRED),
});

export const ROUTE = routes.COMPANY_CLAIM_CREATE;
