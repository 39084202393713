import React from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import cn from 'classnames';
import * as yup from 'yup';
import { useTranslation } from 'client/hooks';
import { Onboarding, InputValue } from 'models/user/onboarding';
import { StepInput } from 'utils/onboarding/stage';
import { InputType } from 'utils/onboarding/input-types';

import { initialValues, validationSchema } from 'utils/onboarding';

import DatePicker from './DatePicker';
import TextField from './TextField';
import SelectField from './Select';
import ContinueButton from 'client/components/common/PopUpManager/AccreditationQuestionnaire/ContinueButton';

import css from './Form.module.scss';

interface Props {
  fields: Array<StepInput>;
  name: string;
  onSubmitHandler: (value: Record<string, InputValue>) => void;
}
const FormWrapper = ({ fields, name, onSubmitHandler }: Props) => {
  const { translate } = useTranslation();
  const [isSubmiting, setIsSubmiting] = React.useState(false);
  const formikContext = useFormik<Onboarding['form']>({
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    initialValues: initialValues(fields),
    validate: async (values: Onboarding['form']) => {
      const errors: Record<string, string> = {};
      const schema = validationSchema(fields);

      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const { inner } = error as yup.ValidationError;
        inner.forEach((e) => {
          if (e.path && e.type) errors[e.path] = e.errors.join(', ');
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      setIsSubmiting(true);
      onSubmitHandler(values);
    },
  });

  const field = (item: StepInput) => {
    switch (item.type) {
      case InputType.TEXT:
        return <TextField name={item.name} label={item.label} />;
      case InputType.AUTO_COMPLETE:
        return <SelectField name={item.name} options={item.options} multiple={item.multiple} label={item.label} />;
      case InputType.DATE:
        return <DatePicker name={item.name} label={item.label} />;
      default:
        break;
    }
  };

  const fieldsRender = () => {
    return fields.map((item) => {
      return (
        <div key={item.name} className={cn(css.fieldWrapper, item.shortWidth && css.short)}>
          {field(item)}
        </div>
      );
    });
  };

  return (
    <FormikProvider value={formikContext}>
      <Form autoComplete="off">
        <div className={css.formWrapper}>
          <div className={css.formWrapperI}>
            <h1>{translate(`questionnaire.formsTitles.${name}`)}</h1>
            {fieldsRender()}
          </div>
          <div className={css.btnWrapper}>
            <ContinueButton disabled={isSubmiting} />
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default FormWrapper;
