/**
 * Common dispatcher with predefined events (predefined to avoid strings for event name
 * or multiple imports in case of using constants for ones)
 */

interface AbstractEventMap {
  [event: string]: unknown;
}

export class Dispatcher<T extends AbstractEventMap> {
  private subscribers: Partial<{ [key in keyof T]: { listener: (data: T[key]) => unknown; key?: string }[] }>;

  constructor() {
    this.subscribers = {};
  }

  subscribe = <K extends keyof T>(event: K, listener: (data: T[K]) => unknown, key?: string) => {
    if (!this.subscribers[event]) {
      this.subscribers[event] = [];
    }

    this.subscribers[event]?.push({ listener, key });

    return () => {
      this.unsubscribe(event, listener);
    };
  };

  unsubscribe = <K extends keyof T>(event: K, subject: ((data: T[K]) => unknown) | string) => {
    if (!this.subscribers[event]) {
      return;
    }

    if (subject instanceof Function) {
      const index = this.subscribers[event]?.findIndex((item) => item.listener === subject);

      if (index !== undefined && index >= 0) {
        this.subscribers[event]?.splice(index, 1);
      }
    } else {
      this.subscribers[event] = this.subscribers[event]?.filter((item) => item.key !== subject);
    }
  };

  triggerEvent = <K extends keyof T>(event: K, data: T[K]) => {
    this.subscribers[event]?.forEach((subscriber) => {
      subscriber.listener(data);
    });
  };

  destroy = () => {
    this.subscribers = {};
  };
}
