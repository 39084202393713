import { matchPath } from 'react-router-dom';

import * as routes from 'client/constants/routes';

function getMatchedRoute(pathname: string) {
  return Object.values(routes).find((route) => matchPath(pathname, { path: route, exact: true }));
}

export { getMatchedRoute };
export default getMatchedRoute;
