import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { Company as PaginationCompanyData } from 'api/companies/public/paginate/params';
import { Result as GetCompanyData } from 'api/companies/public/get/params';
import * as routes from 'client/constants/routes';
import { getMatchedRoute } from 'client/utils/get-matched-route';
import { categories as categoriesApi } from 'client/redux/api/categories';
import { companies as companiesApi } from 'client/redux/api/companies';
import { useAppSelector } from 'client/hooks';

const FETCH_SIZE = 100;

function useCompanyData() {
  const location = useLocation();
  const route = getMatchedRoute(location.pathname);

  const urlParams = new URLSearchParams(location.search);
  const page = Number(urlParams.get('page'));
  const search = urlParams.get('search') || '';
  const category = urlParams.get('category') || '';
  const offset = Math.floor((page - 1) / FETCH_SIZE) * FETCH_SIZE;

  const { data: categoriesRes } = categoriesApi.endpoints.getAllCategories.useQuery({});
  const categories = categoriesRes?.success ? categoriesRes.data : [];

  const paginationSelector = companiesApi.endpoints.paginateCompanies.select({ search, offset, limit: FETCH_SIZE });
  const paginationRes = useAppSelector(paginationSelector);

  const paginationByCategorySelector = companiesApi.endpoints.getCompanyWithCategoryOrder.select({
    search,
    filter: { categoryId: category },
    offset,
    limit: FETCH_SIZE,
  });
  const paginationByCategoryRes = useAppSelector(paginationByCategorySelector);

  const companyRouteMatch = useRouteMatch<{ id: string }>(routes.COMPANY_PAGE_ROUTE);
  const companySelector = companiesApi.endpoints.getCompany.select({ id: companyRouteMatch?.params.id ?? '-' });
  const companyRes = useAppSelector(companySelector);

  const [result, setResult] = React.useState<PaginationCompanyData | GetCompanyData | null>(getCompanyData());

  React.useEffect(() => {
    const res = getCompanyData();

    if (res && res._id !== result?._id) setResult(res);
    if (route === routes.MAIN_PAGE_ROUTE && !res) setResult(null);
  }, [paginationRes, paginationByCategoryRes, companyRes, categories, result, route]);

  function getCompanyData() {
    switch (route) {
      case routes.MAIN_PAGE_ROUTE: {
        const paginationCompanies = paginationRes.data?.success ? paginationRes.data.data.items : [];
        const paginationByCategoryCompanies = paginationByCategoryRes.data?.success
          ? paginationByCategoryRes.data.data.items
          : [];
        const company = category ? paginationByCategoryCompanies[page - 1 - offset] : paginationCompanies[page - 1];

        if (category) {
          const categoryData = categories?.find((item) => item._id.toString() === category);

          if (company) {
            return {
              ...company,
              categories: categoryData
                ? [{ _id: categoryData._id.toString(), name: categoryData.name, slug: categoryData.slug }]
                : [],
            };
          }
        }

        return company;
      }

      case routes.COMPANY_PAGE_ROUTE: {
        return companyRes.data?.success ? companyRes.data.data : null;
      }

      default: {
        return null;
      }
    }
  }

  return result;
}

export { useCompanyData };
export default useCompanyData;
