import React from 'react';
import cn from 'classnames';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { FormikProvider, Form, useFormik } from 'formik';

import { paramsSchema, Params } from 'api/auth/user/restore-password/params';
import { useAppDispatch, useTranslation } from 'client/hooks';
import { restorePassword } from 'client/redux/user/actions';
import { TextField } from 'client/components/common/form';
import { Loader, Icon } from 'client/components/common';
import { ERROR } from 'endpoints/endpoint';
import { setPopup } from 'client/redux/ui';

import css from './EmailProvider.module.scss';

type Props = {
  onSuccess: () => unknown;
};

const API_ERRORS_TRANSLATIONS: Record<string, string> = {
  [ERROR.ACCESS_DENIED]: 'invalidToken',
  [ERROR.NEW_PASSWORD_IS_SAME]: 'newPassIsSameAsOld',
};

const ResetPassword: React.FC<Props> = ({ onSuccess }) => {
  const dispatch = useAppDispatch();
  const [error, setError] = React.useState('');
  const { translate: _t } = useTranslation('popups');
  const history = useHistory();
  const queryParams = queryString.parse(location.search, { arrayFormat: 'comma' });

  const formikContext = useFormik<Params>({
    enableReinitialize: true,
    validationSchema: paramsSchema,
    initialValues: {
      token: queryParams.token as string,
      userId: queryParams.userId as string,
      password: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      setError('');

      const result = await dispatch(restorePassword(values));

      if (result.meta.requestStatus === 'rejected') {
        setError(API_ERRORS_TRANSLATIONS[result.payload as string] ?? (result.payload as string));
      } else {
        resetParams();
        onSuccess();
      }
    },
  });

  const resetParams = () => {
    history.replace({ search: '' });
  };

  const onCloseBtnClick = () => {
    dispatch(setPopup(null));
  };

  return (
    <>
      <button className={css.closeButton} onClick={onCloseBtnClick}>
        <Icon type="close" />
      </button>
      <FormikProvider value={formikContext}>
        <h6 className={css.formTitle}>{_t('login.emailFormsTitles.resetPassword')}</h6>
        <Form className={cn(css.form, css.resetPasswordForm)}>
          <div className={css.fields}>
            <div className={css.field}>
              <TextField
                name="password"
                type="password"
                label={() => (
                  <>
                    {_t('login.emailFormsLabels.password')}{' '}
                    <span className={css.hint}>{_t('login.emailFormsLabels.passwordHint')}</span>
                  </>
                )}
                autoComplete="new-password"
              />
            </div>
            <div className={css.field}>
              <TextField
                name="confirmPassword"
                type="password"
                label={_t('login.emailFormsLabels.confirmPassword')}
                autoComplete="new-password"
              />
            </div>
          </div>

          {error && <div className={css.responseError}>{_t(`login.errorLabels.${error}`)}</div>}
          {formikContext.isSubmitting && <Loader position="relative" />}

          <button type="submit" className={css.submitButton}>
            {_t('login.emailFormsButtons.resetPassword')}
          </button>
        </Form>
      </FormikProvider>
    </>
  );
};

export default ResetPassword;
