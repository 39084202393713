import * as yup from 'yup';

import countries from 'utils/countries.json';
import { VALIDATION_ERROR } from 'endpoints/index';
import { Onboarding } from 'models/user/onboarding';

import { filterDependencies, configureStep, getStageCurrentStepName, getStageStep, skippableSchema } from '../stage';
import { InputType, constants } from '../input-types';

/**
 * Returns accreditation questionnaire personal details form fields
 */
export function getPersonalDetails(data: Onboarding) {
  const t = (path: string) => `questionnaire.accreditationQuestionnaire.${path}`;

  return {
    steps: filterDependencies(
      [
        configureStep(
          {
            type: InputType.FORM,
            name: 'personalDetails',
            inputs: [
              {
                type: InputType.TEXT,
                name: 'firstName',
                defaultValue: '',
                label: t('personalDetails.firstName.label'),
                shortWidth: true,
                schema: yup
                  .string()
                  .typeError(`${VALIDATION_ERROR.REQUIRED}`)
                  .min(constants.TEXT_MIN_LENGTH)
                  .max(constants.TEXT_MAX_LENGTH)
                  .required(VALIDATION_ERROR.REQUIRED),
              },
              {
                type: InputType.TEXT,
                name: 'lastName',
                defaultValue: '',
                label: t('personalDetails.lastName.label'),
                shortWidth: true,
                schema: yup
                  .string()
                  .typeError(`${VALIDATION_ERROR.REQUIRED}`)
                  .min(constants.TEXT_MIN_LENGTH)
                  .max(constants.TEXT_MAX_LENGTH)
                  .required(VALIDATION_ERROR.REQUIRED),
              },
              {
                type: InputType.DATE,
                name: 'dateOfBirth',
                shortWidth: true,
                defaultValue: '',
                label: t('personalDetails.dateOfBirth.label'),
                schema: yup.date().typeError(`${VALIDATION_ERROR.REQUIRED}`).required(VALIDATION_ERROR.REQUIRED),
              },
              {
                type: InputType.AUTO_COMPLETE,
                name: 'countryOfBirth',
                shortWidth: true,
                defaultValue: [],
                label: t('personalDetails.countryOfBirth.label'),
                options: countries,
                schema: yup
                  .array()
                  .of(yup.string())
                  .min(1, VALIDATION_ERROR.REQUIRED)
                  .required(VALIDATION_ERROR.REQUIRED),
              },
              {
                type: InputType.AUTO_COMPLETE,
                name: 'countryOfResidence',
                defaultValue: [],
                label: t('personalDetails.countryOfResidence.label'),
                options: countries,
                schema: yup
                  .array()
                  .of(yup.string())
                  .min(1, VALIDATION_ERROR.REQUIRED)
                  .required(VALIDATION_ERROR.REQUIRED),
              },
            ],
          },
          data,
        ),
        configureStep(
          {
            type: InputType.CHECKBOX,
            name: 'hasNonResidentalCitizenship',
            label: t('personalDetails.hasNonResidentalCitizenship.label'),
            schema: yup.boolean().required(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.AUTO_COMPLETE,
            name: 'nonResidentalCitizenships',
            defaultValue: [],
            label: t('personalDetails.nonResidentalCitizenships.label'),
            options: countries,
            multiple: true,
            schema: yup.array().of(yup.string()).min(1, 'Required').required(),
            dependsOn: {
              field: 'hasNonResidentalCitizenship',
              value: true,
            },
          },
          data,
        ),
      ],
      data,
    ),
  };
}

export function getCurrentStep(data: Onboarding) {
  const currentStepName = getStageCurrentStepName(getPersonalDetails(data).steps);
  return currentStepName ? getStep(data, currentStepName) : null;
}

export function getStep(data: Onboarding, targetStep: string) {
  return getStageStep(getPersonalDetails(data).steps, targetStep);
}
