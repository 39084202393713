import React, { forwardRef } from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import { useTranslation, useResponsive } from 'client/hooks';

import DatePicker from 'react-datepicker';

import Error from '../../../../../Error';

import css from './DatePicker.module.scss';

interface Props {
  name: string;
  label: string;
}

type CustomInputProps = {
  value?: string;
  onClick?: () => void;
  placehlder: string;
  isFocused: boolean;
};

// eslint-disable-next-line react/display-name
const CustomField = React.forwardRef<HTMLSpanElement, CustomInputProps>((props, ref) => {
  const { value, onClick, placehlder, isFocused } = props;

  return (
    <span
      className={cn(css.customInput, !value && css.placeholder, isFocused && css.focused)}
      onClick={() => onClick?.()}
      ref={ref}
    >
      {value ? value : placehlder}
    </span>
  );
});

const DateP = ({ name, label }: Props) => {
  const [isMobile] = useResponsive(['MOBILE']);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [field, meta, helpers] = useField(name);
  const errorMsg = !!(meta.touched && meta.error) ? meta.error : '';
  const { translate } = useTranslation();

  return (
    <div className={cn(css.datepicker, errorMsg && meta.touched && css.error)}>
      <DatePicker
        selected={field.value ? new Date(field.value) : null}
        onChange={(date) => {
          if (date) {
            const selectedDate = new Date(date);
            const selectedDateMilliseconds = selectedDate.getTime();
            const dateNow = Date.now();

            if (selectedDateMilliseconds > dateNow) {
              alert('Future dates cannot be selected');
              return;
            }

            helpers.setValue(date?.toISOString());
          }
        }}
        placeholderText={translate(label)}
        showIcon
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
            <path
              fill="#ffffff"
              d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"
            />
          </svg>
        }
        showMonthDropdown
        showYearDropdown
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        maxDate={new Date(new Date().getFullYear(), 11, 31)}
        customInput={isMobile ? <CustomField placehlder={translate(label)} isFocused={isFocused} /> : null}
        onCalendarOpen={() => setIsFocused(true)}
        onCalendarClose={() => setIsFocused(false)}
        // onKeyDown={(e) => e.preventDefault}
        // onFocus={(e) => (e.target.readOnly = true)}
      />
      {errorMsg && meta.touched && <Error name={name} error={errorMsg} />}
    </div>
  );
};

export default DateP;
