import produce from 'immer';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'utils/api';
import {
  ROUTE as UPDATE_USER_API_ROUTE,
  Params as UpdateUserParams,
  Result as UpdateUserResult,
} from 'api/users/website/update/params';
import { renameFile } from 'utils/index';
import { ApiResponse } from 'src/types/api-response';

export const update = createAsyncThunk<
  ApiResponse<UpdateUserResult>,
  UpdateUserParams & { id: string },
  { rejectValue: string }
>('user/update', async (data, { rejectWithValue }) => {
  try {
    const { id, ...rest } = data;
    const payload = produce(rest, (draft) => {
      if (draft.pictureUrl) {
        draft.pictureUrl = isFile(draft.pictureUrl)
          ? renameFile(draft.pictureUrl as File, 'pictureUrl')
          : draft.pictureUrl;
      }
    });
    const response = await api.sendRequest<UpdateUserResult>({
      url: UPDATE_USER_API_ROUTE.replace(':id', id),
      method: 'POST',
      data: payload,
      contentType: 'form-data',
    });

    if (response.success) {
      return response;
    } else {
      //@ts-expect-error TODO: fix correct response error
      throw new Error(response.code);
    }
  } catch (e) {
    console.info('user/update error', e);
    return rejectWithValue((e as Error).message);
  }
});

const isFile = (data?: File | string) => {
  if (!data) return false;

  return data !== undefined && typeof data !== 'string';
};
