import qs from 'qs';
import { WEBSITE_ROUTE as GET_ROUTE, Params as GetParams, Result as GetResult } from 'api/categories/get-all/params';
import { ApiResponse } from 'src/types/api-response';

import api from '..';

export const categories = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategories: builder.query<ApiResponse<GetResult>, GetParams>({
      query: (params) => ({
        method: 'GET',
        url: `${GET_ROUTE}?${qs.stringify(params)}`,
      }),
      providesTags: (result) =>
        result?.success ? [{ type: 'CATEGORIES', id: 'ALL' }] : [{ type: 'CATEGORIES', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
