import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { MainPageWebsiteSliderItem } from 'api/main_page/public/get/params';

import { IModel } from 'models/fake_mandates_slides/interface';
import { /* LINK_TYPE, */ SIZE_TYPE } from 'client/types';
import { COMPANY_PAGE_ROUTE, MANDATE_PAGE_ROUTE } from 'client/constants/routes';
// import LinkButton from '../LinkButton';
import { imgixUrl } from 'client/utils/imgix-url';

import { useAppSelector, useAppDispatch } from 'client/hooks';

import { selectAuth, selectUserRole } from 'client/redux/user/selectors';
import { setPopup } from 'client/redux/ui';

import css from './FakeMandateSlide.module.scss';
import PlayIcon from '../PlayIcon';

type Props = {
  className?: string;
  isUpvoteDisabled?: boolean;
  size?: SIZE_TYPE;
  buttonSize?: SIZE_TYPE;
  item: IModel;
  isMandateLink?: boolean;
  buttonLabel: string;
};

const FakeMandateSlide: React.FC<Props> = ({
  item,
  size = SIZE_TYPE.SM,
  buttonSize = SIZE_TYPE.SM,
  className = '',
  buttonLabel = '',
}) => {
  const { isLoggedIn } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);
  const clickOnItem = React.useRef(false);

  React.useEffect(() => {
    if (isLoggedIn && userRole === 'VIEWER' && clickOnItem.current) {
      clickOnItem.current = false;
      _.defer(() => {
        dispatch(
          setPopup({
            type: 'join-as-investor',
            data: { link: item.redirectUrl, isExternalLink: true },
          }),
        );
      }, 0);
    }
  }, [isLoggedIn]);

  const clickOnLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const link = e.currentTarget.href;
    clickOnItem.current = true;
    if (userRole === 'VIEWER' && !isLoggedIn) {
      e.preventDefault();
      dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in', redirectUrl: link } }));
    }

    if (userRole === 'VIEWER' && isLoggedIn) {
      e.preventDefault();

      dispatch(setPopup({ type: 'join-as-investor', data: { link: link, isExternalLink: true } }));
    }
  };

  return (
    <div className={cn(css.fakeMandateSlide, css[size], className)}>
      <a href={item.redirectUrl} className={css.slideInner} onClick={(e) => clickOnLink(e)}>
        <img src={imgixUrl(item.image, { auto: 'compress' })} alt={item.name} className={css.mainImage} />
        <div className={css.bottom}>
          <h6>{item.name}</h6>
          <p>{item.description}</p>
          <span className={css.button}>
            <PlayIcon size={size === SIZE_TYPE.L ? SIZE_TYPE.M : size} className={css.iconPlay} />
            <span>{buttonLabel}</span>
          </span>
        </div>
      </a>
    </div>
  );
};

export default FakeMandateSlide;
