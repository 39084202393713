import React from 'react';
import cn from 'classnames';
import { SlideDown } from 'react-slidedown';

import { CONFIG } from 'constants/index';
import { FAQ_PAGE_ROUTE } from 'client/constants/routes';
import { useResponsive, useTranslation, useAppSelector, useAnalytics } from 'client/hooks';
import { selectUserGAStatus } from 'client/redux/user/selectors';
import { faq as faqApi } from 'client/redux/api/faq';
import { LOCALE } from 'client/types';
import { ControlPanel, Header } from 'client/components/common/mobile';
import { HelmetComponent } from 'client/components/common';

import css from './Faq.module.scss';

const searchRegExp = /&#x27;/gi;

const FAQ = () => {
  const [isMobile] = useResponsive(['MOBILE']);
  const { translate } = useTranslation();
  const [activeFaq, setActiveFaq] = React.useState<null | number>(null);
  const userGAStatus = useAppSelector(selectUserGAStatus);
  const analytics = useAnalytics();
  const { data, isFetching } = faqApi.endpoints.getFaq.useQuery();
  const items = data?.success && data.data ? data.data : [];

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `FAQ`,
        page_location: `${CONFIG.domainUrl}${FAQ_PAGE_ROUTE}`,
      });
    }
  }, [userGAStatus]);

  const clickOnFaq = (e: React.MouseEvent<HTMLDivElement>) => {
    const { index } = e.currentTarget.dataset;
    if (index) {
      if (Number(index) === activeFaq) {
        setActiveFaq(null);
      } else {
        setActiveFaq(Number(index));
      }
    }
  };

  return (
    <>
      <HelmetComponent
        title="Frequently Asked Questions - Catalyst Investors' Club"
        description="Join Catalyst Investors' Club to invest alongside reputable lead investors. Find out more about the club."
        ogTitle="Frequently Asked Questions - Catalyst Investors' Club"
        ogDescription="Join Catalyst Investors' Club to invest alongside reputable lead investors. Find out more about the club."
      ></HelmetComponent>
      <div className={css.faqWrapper}>
        {isMobile ? <Header className={css.pageHeader} /> : null}
        {isMobile ? <ControlPanel className={css.controlPanel} /> : null}

        <div className={css.contentWrapper}>
          <div className={css.title}>{translate('faqPage.title')}</div>
          <div className={css.faqs}>
            {items.map((i, index) => {
              const itemData = i.value.find((locale) => locale.locale === LOCALE.EN);
              if (itemData) {
                return (
                  <div className={cn(css.faq, index === activeFaq && css.active)} key={index}>
                    <div className={css.name} data-index={index} onClick={clickOnFaq}>
                      <span>{itemData.question}</span>{' '}
                      <div className={css.btn} data-index={index} onClick={clickOnFaq}></div>
                    </div>
                    <SlideDown className={css.descriptionWrapper}>
                      {index === activeFaq ? (
                        <div
                          className={css.description}
                          dangerouslySetInnerHTML={{ __html: itemData.answer.replace(searchRegExp, `'`) }}
                        ></div>
                      ) : null}
                    </SlideDown>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
