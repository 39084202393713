import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { MainPageWebsiteSliderItem } from 'api/main_page/public/get/params';
import { Company } from 'api/companies/public/paginate/params';

import { imgixUrl } from 'client/utils/imgix-url';
import { useAnalytics, useTranslation } from 'client/hooks';
import { COMPANY_PAGE_ROUTE } from 'client/constants/routes';
import { VideoJSPlayer, Icon, Button } from 'client/components/common';
import { videoStatistics as videoStatisticsApi } from 'client/redux/api/video-statistics';

import { SLIDES_PER_GROUP } from '../CompaniesSlider';

import css from './CompanyVideoSlide.module.scss';

interface Props {
  data: MainPageWebsiteSliderItem | Company;
  activeId: string;
  setActiveSlide: (v: string) => void;
  index: number;
  companiesLength: number;
  isEnd?: boolean;
}

type LsData = {
  [key: string]: {
    t: number;
    persents: number;
  };
};

const IMGIX_PARAMS = {
  'max-w': 422,
  fit: 'fill',
  auto: 'compress',
  q: 85,
};

const CompanyVideoSlide: React.FC<Props> = ({ data, activeId, setActiveSlide, index, companiesLength, isEnd }) => {
  const { translate } = useTranslation();
  const [lsData, setLsData] = React.useState<LsData | null>(null);
  const analytics = useAnalytics();
  const [updateVideoStatistics] = videoStatisticsApi.endpoints.updateVideoStatistics.useMutation();

  // const onRatingUpBtnClick = (id: string) => {
  //   console.log('click');
  // };

  const onClickSlide = (e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget.dataset;

    if (id) {
      setActiveSlide(id);
    }
  };

  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget.dataset;

    if (id) {
      setActiveSlide(id);
    }
  };

  const onMouseLeave = () => {
    setActiveSlide('');
  };

  React.useEffect(() => {
    const lsData = window.localStorage.getItem('dvid');
    if (lsData) {
      const parsetData = lsData ? JSON.parse(lsData) : null;
      setLsData(parsetData);
    }
  }, [activeId]);

  const saveVideoPosition = (t: number, persents: number, id: string) => {
    const lsData = window.localStorage.getItem('dvid');
    const parsetData = lsData ? JSON.parse(lsData) : null;
    const dataForSaving = parsetData ? { ...parsetData, [id]: { t, persents } } : { [id]: { t, persents } };
    window.localStorage.setItem('dvid', JSON.stringify(dataForSaving));

    setLsData(dataForSaving);
  };

  const onStatisticUpdate = (
    st: { fileName: string; progress: number; duration: string },
    data: MainPageWebsiteSliderItem | Company,
  ) => {
    const { fileName, duration, progress } = st;
    const videoID = decodeURIComponent(fileName).split('/')?.[4]?.split('.')?.[0] ?? 'N/A';

    analytics.gtag.event('video_play', {
      VideoProgress: `${progress}`,
      CompanyID: data._id,
      CompanyName: data.name,
      VideoID: videoID,
      TotalVideoLength: `${duration}`,
    });

    updateVideoStatistics({ fileName, progress });
  };

  const renderPlayer = () => {
    const lsItem = lsData ? lsData[activeId] : null;
    const progress = lsItem ? lsItem.persents : 0;
    const startTime = lsItem ? lsItem.t : 0;
    return (
      data._id === activeId && (
        <VideoJSPlayer
          id={`${data._id}-${Math.random().toString(36).substring(2)}`}
          videoSRC={data.avatarVideo}
          cover={imgixUrl(data.mainImage, IMGIX_PARAMS) || ''}
          getPosition={(v) => saveVideoPosition(v.pauseTime, v.persents, data._id)}
          startFrom={progress === 100 ? 0 : startTime}
          onStatisticUpdate={(st) => onStatisticUpdate(st, data)}
          smallButtons
          isCenteredPlayBtn
        />
      )
    );
  };

  const renderProgress = () => {
    const lsItem = lsData ? lsData[data._id] : null;
    const isActive = activeId === data._id;
    const persents = lsItem ? lsItem?.persents : 0;
    const progress = isActive && persents === 100 ? 0 : persents;
    return (
      <div className={css.progress}>
        <div className={css.progressInner} style={{ width: `${progress}%` }}></div>
      </div>
    );
  };

  const slideClass = () => {
    //Check if the number of companies is a multiple of Slides per screen
    if (companiesLength % 4 === 0) {
      //first element || fifth element || each 9 element
      if (index === 1 || (index - 1) % SLIDES_PER_GROUP === 0) {
        return css.firstOnScreen;
      }
      if (index % SLIDES_PER_GROUP === 0) {
        return css.lastOnScreen;
      }
      return css.normal;
    }
    //Check if the number of companies less then Slides per screen
    if (companiesLength < 4) {
      if (index === 1) {
        return css.firstOnScreen;
      }
      return css.normal;
    }
    //Check if the number of companies less then Slides per screen
    if (companiesLength % 4 !== 0) {
      if (isEnd) {
        if (companiesLength % 4 === 1 || companiesLength % 4 === 2 || companiesLength % 4 === 3) {
          if (index === companiesLength - 3) {
            return css.firstOnScreen;
          }
        }

        if (index === companiesLength) {
          return css.lastOnScreen;
        }
        return css.normal;
      } else {
        //first element || fifth element || each 9 element
        if (index === 1 || (index - 1) % SLIDES_PER_GROUP === 0) {
          return css.firstOnScreen;
        }
        if (index % SLIDES_PER_GROUP === 0) {
          return css.lastOnScreen;
        }
        return css.normal;
      }
    }
  };

  return (
    <div
      className={cn(css.slideWrapper, data._id === activeId && css.active, slideClass())}
      onMouseLeave={onMouseLeave}
    >
      <div className={css.mediaWrapper} onClick={onClickSlide} data-id={data._id} onMouseEnter={onMouseEnter}>
        <div
          className={css.previewImage}
          style={{ backgroundImage: `url(${imgixUrl(data.mainImage, IMGIX_PARAMS)})` }}
        ></div>
        <div className={css.videoBlock}>{renderPlayer()}</div>
        {renderProgress()}
      </div>
      <Link to={COMPANY_PAGE_ROUTE.replace(':id', data._id.toString())}>
        <div className={css.bottomInfo}>
          <div className={css.info}>
            <div className={css.name}>{data.name}</div>
            <div className={css.description}>{data.subTitle}</div>
          </div>
          <div className={css.btns}>
            {data._id === activeId ? (
              <Button className={css.seeMoreBtn}>
                <Icon type="eye" className={css.iconEye} />
                {translate('commonButtons.seeMore')}
              </Button>
            ) : (
              <></>
              // <div className={css.ratingBtn}>
              //   <span>{data.rating}</span>
              //   <RatingUpButton id={data._id} />
              // </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CompanyVideoSlide;
