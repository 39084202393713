import React from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import { IModel } from 'models/contact_us/interface';
import { Params, paramsSchema } from 'api/contact_us/create/params';
import { contactUs as contactUsApi } from 'client/redux/api/contact-us';
import { useTranslation } from 'client/hooks';
import { REG_EXP } from 'client/constants';
import { TextField, TextArea, Select } from 'client/components/common/form';
import css from './FormWrapper.module.scss';

const intialValues: Omit<IModel, '_id'> = {
  name: '',
  email: '',
  phone: '',
  contactingAs: '',
  message: '',
};

const selectOptions = [
  { label: 'Startup', value: 'Startup' },
  { label: 'Investor', value: 'Investor' },
  { label: 'Press', value: 'Press' },
  { label: 'Other', value: 'Other' },
];

interface Props {
  onSucces: () => void;
}

const FormWrapper = ({ onSucces }: Props) => {
  const { translate } = useTranslation();
  const [contactUs, contactUsState] = contactUsApi.endpoints.contactUs.useMutation();

  const formikContext = useFormik<Params>({
    enableReinitialize: true,
    validationSchema: paramsSchema,
    initialValues: intialValues,
    onSubmit: async (values) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await contactUs(values).unwrap();
    },
  });

  React.useEffect(() => {
    if (contactUsState.data?.success) {
      formikContext.resetForm();
      onSucces();
    }
  }, [contactUsState]);

  return (
    <FormikProvider value={formikContext}>
      <Form className={css.formWrapper}>
        <div className={css.fieldWrapper}>
          <TextField
            name="name"
            label={translate('contactUsPage.leftCol.form.nameLabel')}
            valuePattern={REG_EXP.UNICODELETTERS}
          />
        </div>
        <div className={css.fieldWrapper}>
          <TextField
            name="email"
            valuePattern={REG_EXP.EMAIL}
            label={translate('contactUsPage.leftCol.form.emailLabel')}
          />
        </div>
        <div className={css.fieldWrapper}>
          <TextField
            name="phone"
            label={translate('contactUsPage.leftCol.form.phoneNumberLabel')}
            valuePattern={REG_EXP.NUMBERSPLUSMINUS}
          />
        </div>
        <div className={css.fieldWrapper}>
          <Select
            name="contactingAs"
            options={selectOptions}
            label={translate('contactUsPage.leftCol.form.contactingLabel')}
            placeholder={translate('contactUsPage.leftCol.form.selectPlaceholder')}
            noOptionsText={translate('contactUsPage.leftCol.form.noOptionsText')}
          />
        </div>
        <div className={css.fieldWrapper}>
          <TextArea
            name="message"
            label={translate('contactUsPage.leftCol.form.messageLabel')}
            placeholder={translate('contactUsPage.leftCol.form.messagePlaceholder')}
          />
        </div>
        <div className={css.buttonWrapper}>
          <button type="submit" className={css.submitBtn}>
            {translate('contactUsPage.leftCol.form.submitBtn')}
          </button>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default FormWrapper;
