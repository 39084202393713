import React from 'react';
import { useResponsive, useTranslation } from 'client/hooks';
import { useHistory } from 'react-router';

import { MAIN_PAGE_ROUTE } from 'client/constants/routes';

import MobileButton from 'client/components/common/mobile/Button';
import { ControlPanel, Header } from 'client/components/common/mobile';
import Icon from 'client/components/common/Icon';
import { HelmetComponent } from 'client/components/common';

import backgroundSrc from 'client/assets/claim-company/background.jpg';

import css from './Thanks.module.scss';

const Thanks = () => {
  const { translate } = useTranslation();
  const [isMobile] = useResponsive(['MOBILE']);
  const history = useHistory();

  const onFinishBtnClick = () => {
    history.push(MAIN_PAGE_ROUTE.replace(':id?', ''));
  };

  return (
    <>
      <HelmetComponent
        title="Add your company - Catalyst Investors' Club"
        description="Are you a startup founder? Add your company to Catalyst Investors' Club and get access to reputable lead investors."
        ogTitle="Add your company to Catalyst Investors' Club"
        ogDescription="Are you a startup founder? Add your company to Catalyst Investors' Club and get access to reputable lead investors."
      ></HelmetComponent>
      <div className={css.claimCompanyThanks}>
        {isMobile ? <Header className={css.pageHeader} /> : null}
        {isMobile ? <ControlPanel className={css.controlPanel} /> : null}
        <div className={css.info}>
          <div className={css.tankYou}>
            <div className={css.iconWrapper}>
              <Icon className={css.icon} type="check-rounded" color="#0757d3" secondaryColor="rgb(104,164, 255)" />
            </div>
            <div className={css.title}>{translate('claimCompany.thankyou.title')}</div>
            <div className={css.subtitle}>{translate('claimCompany.thankyou.subTitle')}</div>
            <div className={css.buttonWrapper}>
              {isMobile ? (
                <MobileButton size="x-large-block" onClick={onFinishBtnClick}>
                  {translate('claimCompany.thankyou.finishButton')}
                </MobileButton>
              ) : (
                <button className={css.btnDesktop} onClick={onFinishBtnClick}>
                  {translate('claimCompany.thankyou.finishButton')}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className={css.coverImage} style={{ backgroundImage: `url(${backgroundSrc})` }}></div>
      </div>
    </>
  );
};

export default Thanks;
