import React from 'react';
import cn from 'classnames';
import { IModel } from 'models/company/interface';
import { imgixUrl } from 'client/utils/imgix-url';

import defaultMemberPhoto from 'client/assets/default-avatar.svg';
import css from './Founder.module.scss';

interface Props {
  personInfo: IModel['team'][number];
  className?: string;
}

const IMGIX_PARAMS = {
  'max-w': 200,
  fit: 'fill',
  auto: 'compress',
  q: 85,
};

const Founder = ({ personInfo, className = '' }: Props) => {
  return (
    <div key={personInfo.name} className={cn(className, css.teamMemberInfo)}>
      <div
        className={css.photo}
        style={{
          backgroundImage: `url(${personInfo.avatar ? imgixUrl(personInfo.avatar, IMGIX_PARAMS) : defaultMemberPhoto})`,
        }}
      ></div>
      <div className={css.info}>
        <p className={css.name}>{personInfo.name}</p>
        <p className={css.position}>{personInfo.role}</p>
      </div>
    </div>
  );
};

export default Founder;
