import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { CONFIG } from 'constants/index';
import { ROOT } from 'client/constants/routes';
import { useAnalytics, useTranslation } from 'client/hooks';
import { useAppSelector } from 'client/hooks/redux';
import { selectUserGAStatus } from 'client/redux/user/selectors';

import icon from 'client/assets/403/403-icon.svg';
import bg from 'client/assets/403/403-bg.jpeg';

import css from './PageError.module.scss';

type Props = {
  className?: string;
  message?: string;
};

export function PageError(props: Props) {
  const { className = '' } = props;
  const t = useTranslation().createPrefixedTranslationFunc('mandate.pageError');
  const location = useLocation();
  const analytics = useAnalytics();
  const userGAStatus = useAppSelector(selectUserGAStatus);

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `403`,
        page_location: `${CONFIG.domainUrl}${location.pathname}`,
      });
    }
  }, [userGAStatus]);

  return (
    <div className={cn(css.pageError, className)} style={{ backgroundImage: `url(${bg})` }}>
      <img src={icon} alt="icon no access" className={css.icon} />
      <h6 className={css.title}>{t('title')}</h6>
      <p className={css.message}>{t('message')}</p>
      <p className={css.supportText} dangerouslySetInnerHTML={{ __html: t('supportText') }}></p>
      <Link to={ROOT} className={css.link}>
        {t('buttonLabel')}
      </Link>
    </div>
  );
}

export default PageError;
