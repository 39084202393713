import qs from 'qs';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ROUTE as GET_VOTED_FAVORITE_API_ROUTE,
  Params,
  Result,
} from 'api/users/website/get-favorite-voted-info/params';
import { ApiResponse } from 'src/types/api-response';

import { api } from 'utils/api';

export const getUserData = createAsyncThunk<ApiResponse<Result>, Params, { rejectValue: string }>(
  'user/getUserData',
  async (data, { rejectWithValue }) => {
    try {
      const queryParams = qs.stringify(data);
      const response = await api.sendRequest<Result>({
        url: `${GET_VOTED_FAVORITE_API_ROUTE}?${queryParams}`,
        method: 'GET',
      });

      if (response.success) {
        return response;
      }

      throw new Error(response.error.message as string);
    } catch (e) {
      console.info('e', (e as Error).message || e);
      return rejectWithValue((e as Error).message);
    }
  },
);
