import * as yup from 'yup';

import { Params as PaginationParams, Result as PaginationResult } from 'models/pagination';
import { VALIDATION_ERROR } from 'endpoints/index';
import * as routes from 'api/routes';

import { Company } from '../paginate/params';

export type Params = PaginationParams<Company>;
export type Result = PaginationResult<Company>;

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape({
  limit: yup.number().min(1).required(VALIDATION_ERROR.REQUIRED),
  offset: yup.number().min(0).required(VALIDATION_ERROR.REQUIRED),
  search: yup.string().optional(),
  sort: yup.object().optional(),
  filter: yup
    .object()
    .shape({
      categoryId: yup.string().optional(),
    })
    .optional(),
});

export const ROUTE = routes.COMPANY_WEBSITE_PAGINATE_WITH_CATEGORY_ORDER;
