import React from 'react';
import { useTranslation, useAppDispatch, useAnalytics } from 'client/hooks';
import { setPopup } from 'client/redux/ui/index';

import { LinkInfoItem } from 'models/company_news/interface';
import { Button, NewsItem, Icon } from 'client/components/common';
import css from './News.module.scss';

interface Props {
  news: Array<LinkInfoItem> | undefined;
}

const News: React.FC<Props> = ({ news }) => {
  const { translate } = useTranslation();
  const analytics = useAnalytics();
  const dispatch = useAppDispatch();
  return (
    <div className={css.news}>
      <div className={css.newsTitle}>{translate('companyPage.newsTitle')}</div>
      <div className={css.newsWrapper}>
        {news &&
          news.slice(0, 4).map((item, index) => {
            if (!item.success) return;
            return <NewsItem info={item} key={`${item.title}${index}`} />;
          })}
      </div>
      {news && news?.length > 4 && (
        <Button
          onClick={() => {
            analytics.gtag.event('news_view_all');
            dispatch(setPopup({ type: 'news', data: news }));
          }}
          size="small"
          className={css.btn}
        >
          {translate('commonButtons.viewAll')}
          <Icon type="left-white-arrow" />
        </Button>
      )}
    </div>
  );
};

export default News;
