import React from 'react';
import cn from 'classnames';

import { useAppSelector, useTranslation, useAppDispatch } from 'client/hooks';
import { setPopup } from 'client/redux/ui';
import { ContentWrap, Icon } from 'client/components/common';
import { selectUserAuthProvider, selectUserName, selectUserPictureUrl } from 'client/redux/user/selectors';
import defaultAvatar from 'client/assets/default-avatar.svg';

import { AvatarUploader } from './AvatarUploader';
import InvestorPages from './InvestorPages';

import css from './UserProfile.module.scss';

interface Props {
  className?: string;
}

export const InvestorProfile: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const userName = useAppSelector(selectUserName);
  const avatar = useAppSelector(selectUserPictureUrl);
  const dispatch = useAppDispatch();
  const authProvider = useAppSelector(selectUserAuthProvider);
  const { translate: _t } = useTranslation('userProfile');

  const onEditNameClick = () => {
    dispatch(setPopup({ type: 'profile-edit', data: { view: 'edit-name', value: userName } }));
  };

  const onEditPasswordClick = () => {
    dispatch(setPopup({ type: 'profile-edit', data: { view: 'edit-password' } }));
  };

  return (
    <div className={cn(css.investorProfile, className)}>
      <div className={css.blurSection} style={{ backgroundImage: `url(${avatar || defaultAvatar})` }}></div>
      <div className={css.header}>
        <ContentWrap className={css.contentWrap}>
          <AvatarUploader className={css.avatar} imageSrc={avatar || defaultAvatar} />
          <div className={css.info}>
            <div className={css.infoItem}>
              <div className={css.welcomeMsg}>Hi,</div>
              <div className={css.userName}>
                {userName}{' '}
                <button onClick={onEditNameClick} className={css.editNameBtn}>
                  <Icon className={css.icon} type="edit" />
                </button>
              </div>
            </div>

            <div className={css.editSection}>
              {authProvider === 'emailAndPassword' && (
                <button onClick={onEditPasswordClick} className={css.editInfoBtn}>
                  {_t('editPasswordLabel')}
                </button>
              )}
            </div>
          </div>
        </ContentWrap>
      </div>
      <div className={css.innerPages}>
        <InvestorPages />
      </div>
    </div>
  );
};

export default InvestorProfile;
