import React from 'react';
import cn from 'classnames';

import { ContentWrap } from 'client/components/common/mobile';
import css from './CompanyMobile.module.scss';

interface Props {
  className?: string;
}

function Splash(props: Props) {
  const { className = '' } = props;

  return (
    <div className={cn(css.splash, className)}>
      <div className={css.splashBg} />
      <div className={css.contentWrap}>
        <ContentWrap className={css.content}>
          <div className={css.splashVideoSlider} />
          <div className={css.splashVideoSliderPagination} />
          <div className={css.splashInfo}>
            <div className={css.splashTitleWrap}>
              <div className={css.splashTitle}></div>
              <div className={css.splashActions}>
                <i />
                <i />
              </div>
            </div>
            <div className={css.splashDescription}>
              <div className={css.splashDescriptionLine} />
              <div className={css.splashDescriptionLine} />
            </div>
          </div>
          <div className={css.splashInfoDetails}>
            <div className={css.splashInfoDetailsRow}>
              <div className={css.splashInfoDetailsRowTitle}></div>
              <div className={css.splashInfoDetailsRowValue}></div>
            </div>
            <div className={css.splashInfoDetailsRow}>
              <div className={css.splashInfoDetailsRowTitle}></div>
              <div className={css.splashInfoDetailsRowValue}></div>
            </div>
            <div className={css.splashInfoDetailsRow}>
              <div className={css.splashInfoDetailsRowTitle}></div>
              <div className={css.splashInfoDetailsRowValue}></div>
            </div>
            <div className={css.splashInfoDetailsRow}>
              <div className={css.splashInfoDetailsRowTitle}></div>
              <div className={css.splashInfoDetailsRowValue}></div>
            </div>
            <div className={css.splashInfoDetailsRow}>
              <div className={css.splashInfoDetailsRowTitle}></div>
              <div className={css.splashInfoDetailsRowValue}></div>
            </div>
          </div>
        </ContentWrap>
      </div>
    </div>
  );
}

export { Splash };
export default Splash;
