import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Swiper as SwiperType, Pagination } from 'swiper';
import cn from 'classnames';

import * as routes from 'client/constants/routes';
import { imgixUrl } from 'client/utils/imgix-url';
import { Company } from 'api/companies/public/paginate/params';
import css from './CompaniesSlider.module.scss';

SwiperCore.use([EffectFade, Pagination]);

interface Props {
  className?: string;
  companies: Company[];
}

function CompaniesSlider(props: Props) {
  const { companies, className = '' } = props;
  const [swiperInstance, setSwiperInstance] = React.useState<SwiperType | null>(null);

  return (
    <div className={cn(css.companiesSlider, className)}>
      <div className={css.companiesSliderItems}>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          allowTouchMove={true}
          noSwiping={false}
          pagination={{ el: `.${css.pagination}`, clickable: true }}
        >
          {companies.map((company, index) => (
            <SwiperSlide key={`companyVideo_${index}`}>
              {() => {
                return (
                  <div className={css.companiesSliderItem}>
                    <Link
                      to={`${routes.COMPANY_PAGE_ROUTE.replace(':id', company._id)}`}
                      className={css.companiesSliderItemLink}
                    >
                      <div
                        className={css.companiesSliderItemPreview}
                        style={{
                          backgroundImage: `url(${imgixUrl(company.decorImage || company.mainImage, {
                            auto: 'compress',
                            q: 90,
                            'max-w': 1000,
                            fit: 'fill',
                          })})`,
                        }}
                      />
                    </Link>
                    <div className={css.companiesSliderItemInfo}>
                      <div className={css.companiesSliderItemInfoTitleWrap}>
                        <div className={css.companiesSliderItemInfoTitle}>{company.name}</div>
                        <div className={css.companiesSliderItemInfoDescription}>{company.subTitle}</div>
                      </div>
                      {/* <div className={css.companiesSliderItemInfoVotes}>
                        <span>{company.rating ?? '-'}</span>
                        <div className={css.companiesSliderItemInfoVotesIcon} />
                      </div> */}
                    </div>
                  </div>
                );
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={css.pagination} />
    </div>
  );
}

export { CompaniesSlider };
export default CompaniesSlider;
