import React from 'react';
import { useTranslation } from 'client/hooks';

import css from './LoaderSkeleton.module.scss';
const LoaderSkeleton = () => {
  const { translate } = useTranslation();
  return (
    <>
      <div className={css.contentWrapper}>
        <div className={css.videoWrapper}></div>
        <div className={css.companyInfoWrapper}>
          <div className={css.infoLeft}>
            <div className={css.title}>
              <span>Test</span>
            </div>
            <div className={css.description}>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <div className={css.generalInfo}>
              <div className={css.row}>
                <div className={css.colLeft}>{translate('companyPage.name')}</div>
                <div className={css.colRight}>&nbsp;</div>
              </div>
              <div className={css.row}>
                <div className={css.colLeft}>{translate('companyPage.country')}</div>
                <div className={css.colRight}>&nbsp;</div>
              </div>
              <div className={css.row}>
                <div className={css.colLeft}>{translate('companyPage.website')}</div>
                <div className={css.colRight}>&nbsp;</div>
              </div>
              <div className={css.row}>
                <div className={css.colLeft}>{translate('companyPage.founded')}</div>
                <div className={css.colRight}>&nbsp;</div>
              </div>
              <div className={css.row}>
                <div className={css.colLeft}>{translate('companyPage.social')}</div>
                <div className={css.colRight}>
                  <div className={css.socials}>
                    {Array.from({ length: 4 }).map((_, index) => {
                      return <div className={css.social} key={index}></div>;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.infoRight}>
            <div className={css.actionBtns}>
              <div className={css.addToMyListBtn}></div>
              <div className={css.upvoteBtn}></div>
            </div>
            <div className={css.founders}>
              <div className={css.foundersTitle}>
                <span>{translate('companyPage.foundersTitle')}</span>
              </div>
              {Array.from({ length: 3 }).map((_, index) => {
                return (
                  <div key={index} className={css.teamMemberInfo}>
                    <div className={css.photo}></div>
                    <div className={css.info}>
                      <p className={css.name}>&nbsp;</p>
                      <p className={css.position}>&nbsp;</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={css.categories}>
              <div className={css.categoriesTitle}>
                <span>{translate('companyPage.categoriesTitle')}</span>
              </div>
              <div className={css.categoriesWrapper}>
                {Array.from({ length: 4 }).map((_, index) => {
                  return <div className={css.category} key={index}></div>;
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={css.news}>
          <div className={css.newsTitle}>
            <span>{translate('companyPage.newsTitle')}</span>
          </div>
          <div className={css.newsWrapper}>
            {Array.from({ length: 4 }).map((_, index) => {
              return (
                <div className={css.newsItem} key={index}>
                  <div className={css.image}></div>
                  <div className={css.content}>
                    <div className={css.title}>
                      <span>News ・ Nov , 2023</span>
                    </div>
                    <div className={css.description}>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={css.sliderWrapper}></div>
    </>
  );
};

export default LoaderSkeleton;
