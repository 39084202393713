import cn from 'classnames';
import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';

import { ERROR } from 'endpoints/endpoint';
import { Button } from 'client/components/common';
import { TextField } from 'client/components/common/form';
import { ModalWrapper } from 'client/components/common/ModalWrapper';
import {
  Params as ChangePasswordParams,
  paramsSchema as changePasswordParamsSchema,
} from 'api/users/website/change-password/params';
import { changePassword, update } from 'client/redux/user/actions';
import { setPopup } from 'client/redux/ui';
import { selectUserId, selectUserName } from 'client/redux/user/selectors';
import { useAppDispatch, useAppSelector, useTranslation } from 'client/hooks';
import { Params as UpdateParams, paramsSchema as updateParamsSchema } from 'api/users/website/update/params';

import css from './EditInfoPopUp.module.scss';
interface Props {
  className?: string;
  view: 'edit-name' | 'edit-password';
}

export const EditInfoPopUp: React.FC<Props> = (props) => {
  const { className = '', view } = props;
  const { translate: _t } = useTranslation('userProfile');
  const { translate: errorsTexts } = useTranslation();
  const userName = useAppSelector(selectUserName);
  const userId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();

  const formikContext = useFormik<UpdateParams | ChangePasswordParams>({
    enableReinitialize: true,
    validationSchema: view === 'edit-name' ? updateParamsSchema : changePasswordParamsSchema,
    initialValues:
      view === 'edit-name' ? { name: userName } : { oldPassword: '', newPassword: '', confirmNewPassword: '' },
    onSubmit: async (values, formikHelpers) => {
      if ('newPassword' in values) {
        const result = await dispatch(changePassword({ ...values, id: userId }));

        if (result.meta.requestStatus === 'fulfilled') {
          dispatch(setPopup(null));
        } else {
          if (result.payload === ERROR.ACCESS_DENIED) {
            formikHelpers.setErrors({ oldPassword: errorsTexts('errors.oldPassIncorrect') });
          }
        }
      } else {
        const result = await dispatch(update({ ...values, id: userId }));
        if (result.meta.requestStatus === 'fulfilled') {
          dispatch(setPopup(null));
        } else {
          console.info('result', result);
        }
      }
    },
  });

  const renderChangePasswordForm = () => {
    return (
      <FormikProvider value={formikContext}>
        <Form>
          <TextField
            autoComplete="current-password"
            className={css.field}
            name="oldPassword"
            label={_t('form.labels.oldPassword')}
          />
          <TextField
            autoComplete="new-password"
            className={css.field}
            name="newPassword"
            label={_t('form.labels.newPassword')}
          />
          <TextField
            autoComplete="new-password"
            className={css.field}
            name="confirmNewPassword"
            label={_t('form.labels.confirmNewPassword')}
          />

          <Button disabled={!formikContext.dirty || formikContext.isSubmitting} type="submit">
            {_t('form.submitBtnLabel')}
          </Button>
        </Form>
      </FormikProvider>
    );
  };

  const renderChangeNameForm = () => {
    return (
      <FormikProvider value={formikContext}>
        <Form>
          <TextField className={css.field} name="name" label={_t('form.labels.name')} />
          <Button disabled={!formikContext.dirty || formikContext.isSubmitting} type="submit">
            {_t('form.submitBtnLabel')}
          </Button>
        </Form>
      </FormikProvider>
    );
  };

  const renderForm = () => {
    switch (view) {
      case 'edit-name':
        return renderChangeNameForm();
      case 'edit-password':
        return renderChangePasswordForm();
      default:
        return renderChangeNameForm();
    }
  };

  return (
    <ModalWrapper
      className={css.modal}
      contentClassName={cn(css.content, css[view])}
      title={view === 'edit-name' ? _t(`form.titles.name`) : _t(`form.titles.password`)}
    >
      <div className={cn(css.editInfoPopUp, className)}>{renderForm()}</div>
    </ModalWrapper>
  );
};
