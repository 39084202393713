import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from 'client/hooks';
import { selectPopup } from 'client/redux/ui/selectors';
import { transitionClassNames } from 'client/utils/css-transition-classnames';
import { setPopup } from 'client/redux/ui';
import { ContentWrap } from 'client/components/common/mobile/ContentWrap';
import { usePrev } from 'client/hooks/prev';

import closeBtnIconUrl from 'client/assets/main/close-btn.svg';

import Founders from './Founders';
import News from './News';
import ProfileEdit from './ProfileEdit';
import Login from '../../Login';
import JoinAsInvestor from 'client/components/common/PopUpManager/JoinAsInvestor';
import AccreditationQuestionnaire from 'client/components/common/PopUpManager/AccreditationQuestionnaire';
import BankDetails from 'client/components/common/PopUpManager/AccreditationFinalization/BankDetails';
import AccreditationCertification from 'client/components/common/PopUpManager/AccreditationFinalization/AccreditationCertification';
import TaxDeclare from 'client/components/common/PopUpManager/AccreditationFinalization/TaxDeclare';
import ContactDetails from 'client/components/common/PopUpManager/AccreditationFinalization/ContactDetails';
import QuestionnaireAnswers from 'client/components/common/PopUpManager/QuestionnaireAnswers';
import KYC from 'client/components/common/PopUpManager/KYC';

import css from './PopUpManager.module.scss';

interface Props {
  className?: string;
}

function PopUpManager(props: Props) {
  const { className = '' } = props;
  const popup = useAppSelector(selectPopup);
  const dispatch = useAppDispatch();
  const [title, setTitle] = React.useState<string | null>(getHeaderTitle(popup?.type));
  const [isHeaderVisible, setIsHeaderVisible] = React.useState(getIsHeaderVisible(popup?.type));
  const prevTitle = usePrev(getHeaderTitle(popup?.type));
  const prevIsHeaderVisible = usePrev(getIsHeaderVisible(popup?.type));

  React.useEffect(() => {
    const nextTitle = getHeaderTitle(popup?.type);

    if ((nextTitle && nextTitle !== prevTitle) || (!prevTitle && nextTitle)) {
      setTitle(nextTitle);
    }
  }, [popup?.type, prevTitle]);

  React.useEffect(() => {
    let nextIsHeaderVisible = getIsHeaderVisible(popup?.type);

    if (nextIsHeaderVisible && !prevIsHeaderVisible) {
      nextIsHeaderVisible = prevIsHeaderVisible;
    }

    setIsHeaderVisible(nextIsHeaderVisible);
  }, [popup?.type, prevIsHeaderVisible]);

  const onCloseClick = () => {
    dispatch(setPopup(null));
  };

  const renderContent = () => {
    switch (popup?.type) {
      case 'founders':
        return <Founders data={popup.data} />;

      case 'news':
        return <News data={popup.data} />;

      case 'profile-edit':
        return <ProfileEdit data={popup.data} />;

      case 'auth':
        return <Login {...popup.data} />;

      case 'join-as-investor':
        return <JoinAsInvestor data={popup.data} />;

      case 'accreditation-questionnaire':
        return <AccreditationQuestionnaire />;

      case 'bank-details':
        return <BankDetails />;

      case 'accrediatation-certification':
        return <AccreditationCertification />;

      case 'tax-declare':
        return <TaxDeclare />;

      case 'contact-details':
        return <ContactDetails />;

      case 'questionnaire-answers':
        return <QuestionnaireAnswers />;

      case 'kyc':
        return <KYC />;

      default:
        return null;
    }
  };

  return (
    <CSSTransition in={!!popup} timeout={650} classNames={containerTransitionClassNames} mountOnEnter unmountOnExit>
      <div className={cn(css.popUpManager, className)}>
        <div className={css.bg} />
        <ContentWrap className={cn(css.contentWrap)}>
          {isHeaderVisible && (
            <div className={css.header}>
              <p className={css.title}>{title}</p>
              <button className={css.closeBtn} type="button" onClick={onCloseClick}>
                <img src={closeBtnIconUrl} alt="close" />
              </button>
            </div>
          )}

          <SwitchTransition mode="out-in">
            <CSSTransition
              key={popup?.type}
              classNames={contentTransitionClassNames}
              timeout={300}
              appear
              unmountOnExit
            >
              <div className={cn(css.content, popup?.type === 'accreditation-questionnaire' && css.questionnaire)}>
                {renderContent()}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </ContentWrap>
      </div>
    </CSSTransition>
  );
}

function getHeaderTitle(type?: string) {
  switch (type) {
    case 'founders':
      return 'Founders';

    case 'news':
      return 'Recent News & Activity';

    case 'profile-edit':
      return 'Edit';

    default:
      return null;
  }
}

function getIsHeaderVisible(type?: string) {
  switch (type) {
    case 'founders':
      return true;

    case 'news':
      return true;

    case 'profile-edit':
      return true;

    case 'auth':
      return false;

    case 'join-as-investor':
      return false;

    case 'accreditation-questionnaire':
      return false;

    case 'bank-details':
      return false;

    case 'accrediatation-certification':
      return false;

    case 'tax-declare':
      return false;

    case 'contact-details':
      return false;

    case 'questionnaire-answers':
      return false;

    case 'kyc':
      return false;

    default:
      return true;
  }
}

const containerTransitionClassNames = transitionClassNames(css, 'containerTransition');
const contentTransitionClassNames = transitionClassNames(css, 'contentTransition');

export { PopUpManager };
export default PopUpManager;
