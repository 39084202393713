import qs from 'qs';
import { serialize } from 'object-to-formdata';

import { ApiResponse } from 'src/types/api-response';
import { API_HOST } from 'api/routes';
import { AppCheckTokenResult, getToken } from 'firebase/app-check';
import { getAppCheck } from 'src/firebase-client';
import { set } from 'lodash';

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
interface RequestParams {
  url: string;
  method: RequestMethod;
  data?: Record<string, unknown> | void;
  contentType?: 'json' | 'form-data';
}

export type ApiRequest<Params extends Record<string, unknown> | void, Result> = (params: Params) => {
  args: {
    method: RequestMethod;
    url: string;
    body?: Params;
  };
  invoke: () => Promise<ApiResponse<Result>>;
};

export const createApi = (host: string) => ({
  async sendRequest<R>(params: RequestParams) {
    const { url, contentType = 'json', method, data } = params;
    const urlParams = method === 'GET' && data ? `?${qs.stringify(data)}` : '';
    const targetUrl = `${host}${url}${urlParams}`;

    let responseData: any = null;
    let appCheckTokenResponse: AppCheckTokenResult | null = null;

    try {
      const appCheck = getAppCheck();

      if (appCheck) appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
      else throw new Error('appCheck is null');
    } catch (err) {
      console.info('AppCheck getToken error', err);
    }

    if (contentType === 'form-data') {
      responseData = await makeXMLHttpRequest(method, targetUrl, data, appCheckTokenResponse?.token ?? '');
    } else {
      const headers = { 'Content-Type': 'application/json; charset=utf-8' };
      // Include the App Check token with requests to your server.
      set(headers, 'X-Firebase-AppCheck', appCheckTokenResponse?.token ?? '');

      const response = await fetch(targetUrl, {
        method,
        mode: 'cors',
        credentials: 'include',
        headers,
        ...(method !== 'GET' && { body: JSON.stringify(data) }),
      });
      responseData = await response.json();
    }

    return responseData as ApiResponse<R>;
  },
});

export const makeXMLHttpRequest = <Params extends Record<string, unknown> | void, Result>(
  method = 'POST',
  url: string,
  data: Params,
  appCheckToken?: string,
): Promise<ApiResponse<Result>> =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    const formData = serialize(data, {
      indices: true,
    });

    request.onload = () => {
      try {
        const response = JSON.parse(request.response);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    };
    request.open(method ?? 'POST', url);
    request.setRequestHeader('X-Firebase-AppCheck', appCheckToken ?? '');
    request.send(method === 'GET' ? undefined : formData);
  });

export const api = createApi(API_HOST);
