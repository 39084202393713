import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import SwiperCore, { Virtual, Mousewheel, Navigation } from 'swiper';
import SwiperClass from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'client/hooks';
import { MainPageWebsiteSliderItem } from 'api/main_page/public/get/params';
import { DoubleImageSlide, Loader } from 'client/components/common';
import { SIZE_TYPE } from 'client/types';

import css from './InvestNow.module.scss';

// install Virtual module
SwiperCore.use([Virtual, Mousewheel, Navigation]);
const SLIDES_PER_GROUP = 4;

type Props = {
  title: string;
  items?: MainPageWebsiteSliderItem[];
  isLoading: boolean;
};

const InvestNow: React.FC<Props> = ({ items, isLoading, title }) => {
  const { translate } = useTranslation();
  const sliderRef = React.useRef<SwiperClass>();
  const [isHideNext, setIsHideNext] = React.useState<boolean>(false);
  const [isHidePrev, setIsHidePrev] = React.useState<boolean>(true);

  return (
    <section className={css.investNow}>
      {/* <h4 className={css.title}>{translate('mainPageDesktop.slidersTitles.investNow')}</h4> */}
      <h4 className={css.title}>{title}</h4>
      {isLoading ? (
        <Loader position="absolute" />
      ) : (
        <>
          {items && items.length > 3 ? (
            <div className={css.sliderWrapper}>
              <Swiper
                spaceBetween={0}
                slidesPerView={4}
                mousewheel={false}
                onSwiper={(swiper) => (sliderRef.current = swiper)}
                onSlideChange={(s) => {
                  if (items) {
                    if (s.activeIndex === 0) {
                      setIsHidePrev(true);
                    } else {
                      setIsHidePrev(false);
                    }
                    if (s.activeIndex + SLIDES_PER_GROUP >= items.length) {
                      setIsHideNext(true);
                    } else {
                      setIsHideNext(false);
                    }
                  }
                }}
                slidesPerGroup={SLIDES_PER_GROUP}
              >
                {_.map(items, (i, index) => (
                  <SwiperSlide key={index}>
                    <DoubleImageSlide
                      key={i._id}
                      item={i}
                      isUpvoteDisabled
                      className={css.slide}
                      isMandateLink
                      buttonLabel={translate('mainPageDesktop.watchButton')}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              {items && items.length > SLIDES_PER_GROUP && (
                <>
                  <div
                    className={cn(css.navBtn, css.next, isHideNext && css.hide)}
                    onClick={() => sliderRef.current?.slideNext()}
                  >
                    <div className={css.arrow}></div>
                  </div>

                  <div
                    className={cn(css.navBtn, css.prev, isHidePrev && css.hide)}
                    onClick={() => sliderRef.current?.slidePrev()}
                  >
                    <div className={css.arrow}></div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={css.grid}>
              {_.map(items, (i) => (
                <DoubleImageSlide
                  key={i._id}
                  item={i}
                  isUpvoteDisabled
                  size={SIZE_TYPE.L}
                  buttonSize={SIZE_TYPE.M}
                  className={css.slide}
                  isMandateLink
                  buttonLabel={translate('mainPageDesktop.watchButton')}
                />
              ))}
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default InvestNow;
