import * as yup from 'yup';

import * as routes from 'api/routes';
import { VALIDATION_ERROR } from 'endpoints/index';
import { IModel } from 'models/offer/interface';

export type Params = { companyId?: string; fundId?: string };
export type Result = { _id: string; status: IModel['status'] } | undefined;

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape(
  {
    fundId: yup.string().when(['companyId'], {
      is: (companyId: string) => !companyId,
      then: yup.string().length(24, VALIDATION_ERROR.INVALID_VALUE).required(VALIDATION_ERROR.REQUIRED),
    }),
    companyId: yup.string().when(['fundId'], {
      is: (fundId: string) => !fundId,
      then: yup.string().length(24, VALIDATION_ERROR.INVALID_VALUE).required(VALIDATION_ERROR.REQUIRED),
    }),
  },
  [['companyId', 'fundId']],
);

export const ROUTE = routes.OFFER_GET_WEBSITE;
