import React from 'react';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Swiper as SwiperType, Pagination } from 'swiper';

import { IModel } from 'models/company/interface';

import { videoStatistics as videoStatisticsApi } from 'client/redux/api/video-statistics';
import { VideoJSPlayer } from 'client/components/common/mobile';
import { useAnalytics } from 'client/hooks';

import css from './VideoSlider.module.scss';

SwiperCore.use([EffectFade, Pagination]);

interface Props {
  companyId: string;
  companyName: string;
  videos: IModel['videos'];
  className?: string;
}

function VideoSlider(props: Props) {
  const { companyId, videos, className = '' } = props;
  const analytics = useAnalytics();
  const [swiperInstance, setSwiperInstance] = React.useState<SwiperType | null>(null);
  const [updateVideoStatistics] = videoStatisticsApi.endpoints.updateVideoStatistics.useMutation();

  const onVideoCompleted = React.useCallback(() => {
    if (swiperInstance) {
      if (swiperInstance.activeIndex === videos.length - 1) {
        swiperInstance.slideTo(0);
      } else {
        swiperInstance.slideNext();
      }
    }
  }, [swiperInstance, videos]);

  const onStatisticUpdate = async (st: { fileName: string; progress: number; duration: string }) => {
    const { fileName, progress, duration } = st;
    const videoID = decodeURIComponent(fileName).split('/')?.[4]?.split('.')?.[0] ?? 'N/A';

    analytics.gtag.event('video_play', {
      VideoProgress: `${progress}`,
      CompanyID: props.companyId,
      CompanyName: props.companyName,
      VideoID: videoID,
      TotalVideoLength: `${duration}`,
    });

    updateVideoStatistics({ fileName, progress });
  };

  return (
    <div className={cn(css.videoSlider, className)}>
      <div className={css.videoSliderItems}>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          allowTouchMove={true}
          noSwiping={false}
          pagination={{ el: `.${css.pagination}`, clickable: true }}
        >
          {videos.map((video, index) => (
            <SwiperSlide key={`companyVideo_${index}`}>
              {({ isActive }) => {
                return (
                  <div className={css.videoSliderItem}>
                    {isActive && (
                      <VideoJSPlayer
                        className={css.videoPlayer}
                        id={`player_${companyId}_${index}`}
                        videoSRC={video}
                        onComplete={onVideoCompleted}
                        onStatisticUpdate={(st) => onStatisticUpdate(st)}
                      />
                    )}
                  </div>
                );
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={css.pagination} />
    </div>
  );
}

export { VideoSlider };
export default VideoSlider;
