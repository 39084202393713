import React from 'react';

import { useAppSelector, useAppDispatch } from 'client/hooks';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { selectUserOnboarding } from 'client/redux/user/selectors';
import { getCurrentStep, getQuestionnaire } from 'utils/onboarding/accreditation-questionnaire/questionnaire';
import { InputType } from 'utils/onboarding/input-types';
import { StageStep, StepInput } from 'utils/onboarding/stage';
import { transitionClassNames } from 'client/utils/css-transition-classnames';
import { InputValue, Onboarding } from 'models/user/onboarding';

import { getFullData } from 'utils/onboarding/full-data';
import { getStep } from 'utils/onboarding/accreditation-questionnaire/questionnaire';
import MultipleChoice from '../../QuestionsTypes/MultipleChoice';
import SingleChoice from '../../QuestionsTypes/SingleChoice';
import LongTextField from '../../QuestionsTypes/LongTextField';
import YesNo from '../../QuestionsTypes/YesNo';
import NavigationButtons from '../../NavigationButtons';

import { onboarding as onboardingApi } from 'client/redux/api/onboarding';

import css from './Questionnaire.module.scss';

interface Props {
  setActiveQuestion: (val: number) => void;
  setQuestionsLength: (val: number) => void;
  setStage: () => void;
}

const Questionnaire = ({ setActiveQuestion, setQuestionsLength, setStage }: Props) => {
  const onBoarding = useAppSelector(selectUserOnboarding);

  const currentStepStart = getCurrentStep(onBoarding);
  const [currentStep, setCurrentStep] = React.useState(currentStepStart);

  const [quesstionare, quesstionareState] = onboardingApi.endpoints.setQuesstionare.useMutation();

  const onSubmitHandler = (value: Record<string, InputValue>) => {
    quesstionare({ ...value, intent: 'questionnaire' });
  };

  const onBackHandler = () => {
    if (currentStep?.prev) {
      const targetStep = getStep(onBoarding, currentStep.prev.name);
      setCurrentStep(targetStep);
    }
  };

  const renderQuestion = (item: ReturnType<typeof getStep>, isSkippable: boolean) => {
    if (item?.current.type === InputType.FORM) {
      return 'FORM';
    }

    switch (item?.current.type) {
      case InputType.TEXT:
        return <div>Text field {item.current.name}</div>;

      case InputType.TEXT_AREA:
        return (
          <div>
            <LongTextField info={item.current} onSubmitHandler={onSubmitHandler} isSkippable={isSkippable} />
          </div>
        );

      case InputType.CHECKBOX:
        return (
          <div>
            <YesNo info={item.current} onSubmitHandler={onSubmitHandler} isSkippable={isSkippable} />
          </div>
        );

      case InputType.SELECT:
        if (item.current.multiple)
          return (
            <div>
              <MultipleChoice info={item.current} onSubmitHandler={onSubmitHandler} isSkippable={isSkippable} />
            </div>
          );
        return (
          <div>
            <SingleChoice info={item.current} onSubmitHandler={onSubmitHandler} isSkippable={isSkippable} />
          </div>
        );

      case InputType.AUTO_COMPLETE:
        return <div>Autocomplete field {item.current.name}</div>;

      case InputType.DATE:
        return <div>Date field {item.current.name}</div>;

      default:
        return null;
    }
  };

  const isSkippable = <I extends keyof Onboarding['form']>() => {
    const step = currentStep?.current as StageStep<I>;
    if (!step) return false;

    return step?.type === InputType.FORM ? step.inputs.some(isInputRequired) : isInputRequired(step);

    function isInputRequired(input: StepInput<I>) {
      const schemaDescription = input.schema.describe();
      return schemaDescription?.tests?.some((test) => test.name === 'required');
    }
  };

  React.useEffect(() => {
    const questionnaire = getQuestionnaire(onBoarding);
    const names = questionnaire.steps.map((item) => item.name);
    const name = currentStep?.current.name;
    if (names.length > 0 && name) {
      const activeStageIndex = names.indexOf(name);
      const questionsLength = questionnaire.steps.length;

      setActiveQuestion(activeStageIndex);
      setQuestionsLength(questionsLength);
    }
  }, [currentStep]);

  React.useEffect(() => {
    if (quesstionareState.data?.success) {
      const fullData = getFullData(onBoarding);

      if (currentStep) {
        const updatedCurrentStep = getStep(fullData, currentStep.current.name);

        if (updatedCurrentStep?.next) {
          const nextStep = getStep(fullData, updatedCurrentStep.next.name);

          setCurrentStep(nextStep);
        } else {
          setStage();
        }
      }
    }
  }, [quesstionareState]);

  if (!currentStep) return null;

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={currentStep.current.name}
          timeout={300}
          classNames={contentTransitionClassNames}
          appear
          mountOnEnter
          unmountOnExit
        >
          {renderQuestion(currentStep, !isSkippable())}
        </CSSTransition>
      </SwitchTransition>
      <NavigationButtons
        clickOnBack={onBackHandler}
        isHiddenBack={currentStep && !currentStep.prev}
        isSkippable={!isSkippable()}
        clickOnSkip={onSubmitHandler}
        currentStep={currentStep}
      />
    </>
  );
};

const contentTransitionClassNames = transitionClassNames(css, 'questionnaireTransition');
export default Questionnaire;
