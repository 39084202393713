import React from 'react';
import { useHistory } from 'react-router';
import { setPopup } from 'client/redux/ui';
import { RouteChildrenProps } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useTranslation } from 'client/hooks';
import {
  selectAuth,
  selectUserAuthProvider,
  selectUserName,
  selectUserOnboarding,
  selectUserPictureUrl,
} from 'client/redux/user/selectors';
import { MAIN_PAGE_ROUTE } from 'client/constants/routes';
import { Button, ControlPanel, Header } from 'client/components/common/mobile';
import { AvatarUploader } from 'client/components/pages/UserProfile/AvatarUploader';
import Icon from '../../Icon';
import defaultAvatar from 'client/assets/default-avatar.svg';
import { signOut } from 'client/redux/user/actions';
import InvestorPages from 'client/components/pages/UserProfile/InvestorPages';

import css from './Profile.module.scss';

const Profile: React.FC<RouteChildrenProps> = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const authProvider = useAppSelector(selectUserAuthProvider);
  const userName = useAppSelector(selectUserName);
  const avatar = useAppSelector(selectUserPictureUrl);
  const onboarding = useAppSelector(selectUserOnboarding);
  const history = useHistory();
  const [isLogoutClicked, setIsLogoutClicked] = React.useState(false);
  const { translate: _t } = useTranslation('userProfile');

  React.useEffect(() => {
    if (isLogoutClicked && !auth.isLoggedIn) {
      history.push(MAIN_PAGE_ROUTE);
    }
  }, [auth, isLogoutClicked]);

  const onEditNameClick = () => {
    dispatch(setPopup({ type: 'profile-edit', data: { view: 'edit-name', value: userName } }));
  };

  const onLogoutBtnClick = () => {
    dispatch(signOut());
    setIsLogoutClicked(true);
    (window as any).Intercom('shutdown');
  };

  const onEditPasswordClick = () => {
    dispatch(setPopup({ type: 'profile-edit', data: { view: 'edit-password' } }));
  };

  return (
    <div className={css.profile}>
      <Header className={css.header} />
      <ControlPanel className={css.controlPanel} />
      <div className={css.blurSection} style={avatar ? { backgroundImage: `url(${avatar})` } : {}}></div>

      <div className={css.top}>
        <AvatarUploader imageSrc={avatar || defaultAvatar} />

        <div className={css.field}>
          <div className={css.fieldValue}>
            {`Hi, ${userName}`}
            <button onClick={onEditNameClick} className={css.editNameBtn}>
              <Icon className={css.icon} type="edit" />
            </button>
          </div>
        </div>

        <div className={css.editSection}>
          {authProvider === 'emailAndPassword' && (
            <button onClick={onEditPasswordClick} className={css.editInfoBtn}>
              {_t('editPasswordLabel')}
            </button>
          )}
          <button disabled={auth.isInProgress} className={css.logoutBtn} onClick={onLogoutBtnClick}>
            {_t('logoutBtnLabel')}
          </button>
        </div>
      </div>

      {onboarding.agreement.agree && <InvestorPages />}
    </div>
  );
};

export default Profile;
