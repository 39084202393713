import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import css from './DonutChart.module.scss';

interface Props {
  className?: string;
  thickness?: number;
  values: { progress: number; color: string; tooltip?: string }[];
  enableTooltips?: boolean;
}

export function DonutChart(props: Props) {
  const { values, className = '', thickness = 0.35, enableTooltips = false } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const [tooltip, setTooltip] = React.useState<{ x: number | string; y: number | string; tooltip: string }>({
    x: '50%',
    y: '70%',
    tooltip: '',
  });

  React.useEffect(() => {
    if (!containerRef.current) return;
    const svgNS = 'http://www.w3.org/2000/svg';
    const container = containerRef.current;
    const width = container.offsetHeight;
    const height = container.offsetHeight;
    const radius = Math.min(width, height) / 2;
    const innerRadius = radius * (1 - Math.min(1, Math.max(thickness, 0)));
    const outerRadius = radius * 1;

    const svgElement = document.createElementNS(svgNS, 'svg');
    svgElement.setAttribute('width', width.toString());
    svgElement.setAttribute('height', height.toString());
    svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
    svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');

    const bgPathElement = document.createElementNS(svgNS, 'path');
    bgPathElement.setAttribute('d', getArcPath(0, 1));
    bgPathElement.setAttribute('fill', 'rgba(255, 255, 255, 0.1');
    svgElement.appendChild(bgPathElement);

    values.forEach((value, index) => {
      const pathElement = document.createElementNS(svgNS, 'path');
      const start = values.slice(0, index).reduce((acc, value) => acc + value.progress, 0);
      pathElement.setAttribute('d', getArcPath(start, start + value.progress));
      pathElement.setAttribute('fill', value.color);
      if (value.tooltip) {
        pathElement.setAttribute('data-tooltip', value.tooltip);
        pathElement.addEventListener('mouseenter', (el) => onPathMouseEnter(el));
        pathElement.addEventListener('onclick', (el) => onPathClick(el));
      }
      svgElement.appendChild(pathElement);
    });

    container.appendChild(svgElement);

    /**
     * @param {number} start progress, percentage (0-1)
     * @param {number} target progress, percentage (0-1)
     */
    function getArcPath(start: number, target: number) {
      // A rx ry x-axis-rotation large-arc-flag sweep-flag x y

      const maxAngle = 359.999;
      const rad = Math.PI / 180;
      const correctionAngle = -90;
      const startAngle = (Math.min(1, Math.max(start, 0)) * maxAngle + correctionAngle) * rad;
      const targetAngle = (Math.min(1, Math.max(target, 0)) * maxAngle + correctionAngle) * rad;
      const centerX = width / 2;
      const centerY = height / 2;
      const largeArcFlag = Math.abs(targetAngle - startAngle) > Math.PI ? 1 : 0;

      return `
      M ${centerX + Math.cos(startAngle) * innerRadius} ${centerY + Math.sin(startAngle) * innerRadius}
      L ${centerX + Math.cos(startAngle) * outerRadius} ${centerY + Math.sin(startAngle) * outerRadius}
      A ${outerRadius} ${outerRadius}, 0, ${largeArcFlag}, 1, ${centerX + Math.cos(targetAngle) * outerRadius} ${
        centerY + Math.sin(targetAngle) * outerRadius
      }
      L ${centerX + Math.cos(targetAngle) * innerRadius} ${centerY + Math.sin(targetAngle) * innerRadius}
      A ${innerRadius} ${innerRadius}, 0, ${largeArcFlag}, 0, ${centerX + Math.cos(startAngle) * innerRadius} ${
        centerY + Math.sin(startAngle) * innerRadius
      }
      Z
      `;
    }
  }, []);

  const onPathClick = (el: any) => {
    const { target } = el;

    if (tooltipRef?.current) {
      const { width: tooltipW, height: tooltipH } = tooltipRef?.current.getBoundingClientRect();
      const { width, height, top, left } = target.getBoundingClientRect();

      setTooltip({
        x: left + width / 2 - tooltipW / 2,
        y: tooltipH + 10 > height / 2 ? top - tooltipH : top + height / 2 - tooltipH,
        tooltip: target.dataset.tooltip,
      });
    }
  };

  const onPathMouseEnter = (el: any) => {
    const { target } = el;

    if (tooltipRef?.current) {
      const { width: tooltipW, height: tooltipH } = tooltipRef?.current.getBoundingClientRect();
      const { width, height, top, left } = target.getBoundingClientRect();

      setTooltip({
        x: left + width / 2 - tooltipW / 2,
        y: tooltipH + 10 > height / 2 ? top - tooltipH : top + height / 2 - tooltipH,
        tooltip: target.dataset.tooltip,
      });
    }
  };

  const onMouseLeave = () => {
    setTooltip({ x: tooltip.x, y: tooltip.y, tooltip: '' });
  };

  return (
    <div className={cn(css.donutChartWrap)}>
      <div className={cn(css.donutChart, className)} ref={containerRef} onMouseLeave={onMouseLeave} />

      {enableTooltips && (
        <div
          className={css.tooltip}
          style={{
            top: tooltip.y,
            left: tooltip.x,
            zIndex: tooltip.tooltip.length > 0 ? 10 : -10,
            display: tooltip.tooltip.length > 0 ? 'block' : 'none',
          }}
          ref={tooltipRef}
        >
          {tooltip.tooltip ? tooltip.tooltip : ''}
        </div>
      )}
    </div>
  );
}

export default DonutChart;
