import qs from 'qs';

import {
  ROUTE as CREATE_OFFER_ROUTE,
  Params as CreateParams,
  Result as CreateResult,
} from 'api/offers/website/create/params';
import {
  ROUTE as PAGINATE_OFFER_ROUTE,
  Params as PaginateParams,
  Result as PaginateResult,
} from 'api/offers/website/paginate/params';
import {
  ROUTE as GET_OFFER_ROUTE,
  Params as GetOfferParams,
  Result as GetOfferResult,
} from 'api/offers/website/get/params';
import { ApiResponse } from 'types/api-response';

import api from '..';

export const offer = api.injectEndpoints({
  endpoints: (builder) => ({
    createOffer: builder.mutation<ApiResponse<CreateResult>, CreateParams>({
      query: (params) => ({
        method: 'POST',
        url: CREATE_OFFER_ROUTE,
        body: params,
      }),
      invalidatesTags: () => [{ type: 'OFFER', id: 'ALL' }],
    }),
    paginateOffers: builder.query<ApiResponse<PaginateResult>, PaginateParams>({
      query: (params) => ({
        method: 'GET',
        url: `${PAGINATE_OFFER_ROUTE}?${qs.stringify(params)}`,
      }),
      providesTags: (result) =>
        result?.success
          ? [
              ...result.data.items.map(({ _id }) => ({ type: 'OFFER', id: _id.toString() } as const)),
              { type: 'OFFER', id: 'ALL' },
            ]
          : [{ type: 'OFFER', id: 'ALL' }],
    }),
    getOffer: builder.query<ApiResponse<GetOfferResult>, GetOfferParams>({
      query: (params) => ({
        method: 'GET',
        url: `${GET_OFFER_ROUTE}?${qs.stringify(params)}`,
      }),
      providesTags: (result) => (result?.success ? [{ type: 'OFFER', id: 'ALL' }] : [{ type: 'OFFER', id: 'ALL' }]),
    }),
  }),
  overrideExisting: false,
});
