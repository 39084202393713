import React from 'react';
import cn from 'classnames';
import SwiperCore, { Mousewheel, Navigation, Autoplay } from 'swiper';
import SwiperClass from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import sliderImg1 from 'client/assets/contact-us/slider-img1.jpg';
import sliderImg2 from 'client/assets/contact-us/slider-img2.jpg';
import sliderImg3 from 'client/assets/contact-us/slider-img3.jpg';
import sliderImg4 from 'client/assets/contact-us/slider-img4.jpg';
import sliderImg5 from 'client/assets/contact-us/slider-img5.jpg';

import css from './Slider.module.scss';

SwiperCore.use([Mousewheel, Navigation, Autoplay]);

const images = [sliderImg1, sliderImg2, sliderImg3, sliderImg4, sliderImg5];

const Slider = () => {
  const sliderRef = React.useRef<SwiperClass>();
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  return (
    <div className={css.sliderWrapper}>
      <Swiper
        className={css.swiper}
        spaceBetween={0}
        slidesPerView={1}
        mousewheel={false}
        speed={1500}
        onSwiper={(swiper) => (sliderRef.current = swiper)}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop
        onSlideChange={(s) => {
          setActiveIndex(s.realIndex);
        }}
      >
        {images.map((slideContent, index) => (
          <SwiperSlide className={cn(css.swiperSlide)} key={index}>
            <div className={css.slideInner} style={{ backgroundImage: `url("${slideContent}")` }}></div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={css.pagination}>
        {images.map((item, index) => {
          return (
            <div
              className={cn(css.paginationItem, index === activeIndex && css.active)}
              onClick={() => sliderRef.current?.slideTo(index + 1)}
              key={index}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default Slider;
