type BaseValue = string | number | boolean | Date | null | { [key: string]: InputValue } | File;
export type InputValue = BaseValue | BaseValue[] | { [key: string]: InputValue };

export enum Version {
  SHORTENED = '0.0.1',
  FULL = '1.0.0',
}

export type CountryName = string;
export enum CountryGroup {
  EU = 'eu',
  UK = 'uk',
  IL = 'il',
  US = 'us',
  OTHER = 'other',
}
export type Residency = `${CountryGroup}_${CountryName}`;

export interface AgreementBase<T> {
  residency: Residency;
  agree: T;
}

interface AgreementEu<T> extends AgreementBase<T> {
  residency: `${CountryGroup.EU}_${CountryName}`;
}

interface AgreementUk<T> extends AgreementBase<T> {
  residency: `${CountryGroup.UK}_${CountryName}`;
}

interface AgreementIl<T> extends AgreementBase<T> {
  residency: `${CountryGroup.IL}_${CountryName}`;
}

interface AgreementUs<T> extends AgreementBase<T> {
  residency: `${CountryGroup.US}_${CountryName}`;
}

interface AgreementOther<T> extends AgreementBase<T> {
  residency: `${CountryGroup.OTHER}_${CountryName}`;
}

type Agreement<T> = AgreementEu<T> | AgreementOther<T> | AgreementUk<T> | AgreementIl<T> | AgreementUs<T>;

type OnboardingForm<T> = {
  // PERSONAL DETAILS:
  firstName: T;
  lastName: T;
  dateOfBirth: T;
  countryOfBirth: T;
  countryOfResidence: T;
  hasNonResidentalCitizenship: T;
  nonResidentalCitizenships: T;

  // QUESTIONNAIRE:
  // 1. Current or prior occupation.
  occupation: T;
  // 2. What applies to investor.
  // Eligible investors must have a minimum level of experience in the capital market either personally
  // or must have executed the number of trades
  appliesTo: T;
  // 3. Experience with the following investment either professionally or as an investor
  experience: T;
  // 4. Explanation of personal and/or professional experience in the capital market
  experienceExplanation: T;
  // 5. Having an educational background relevant to the finance / Investment industry
  financeEducation: T;
  // 6. Educational background relevant to private equity
  privateEquity: T;
  // 7. What best describe investment objectives
  objectiveDescription: T;
  // 8. Financial markets are inherently unpredictable.
  // Can investor bear the total loss of your anticipated investment
  totalLossAcceptance: T;
  // 9. Portion of total liquid financial assets would be represented by
  // investment offered by Catalyst Investors' Club
  investmentPortionOfTotal: T;
  // 10. How long can investor's liquid assets (realizable in less than one month)
  // support his ongoing personal expenses
  liquidAssetsDuration: T;
  // 11. Time horizon for investment goals with Catalyst Investors' Club
  timeHorizon: T;
  // 12.Does investor have any liquidity needs that would restrict his willingness and/or
  // ability to invest in a security that may be liquid for 7 years or more
  longTermInvestmentLiquidityNeeds: T;
  // 13. Investing...
  // as an individual or a family trust vehicle / on behalf of a family office / on behalf of a corporation
  investingAs: T;
  // 14. To comply with Anti Money Laundering regulations, source of funds to fund this investment
  sourceOfFunds: T;
  // 15. To comply with regulation, source of savings / investments to be used for Catalyst Investors' Club investments
  sourceOfSavings: T;
  // 16. Have investor ever been entrusted with a prominent public function / role (PEP) in any country
  prominentPublicRole: T;
  // 17. Relation to a person who holds a prominent public function / role (PEP) in any country
  prominentPublicRoleRelation: T;
  // 18. US relation
  USRelation: T;

  // NDA:
  signatureId: T;
  signatureStatus: T; // "in-progress" | "completed"
  signatureDate: T;

  // CONTACT DETAILS:
  city: T;
  addressLine_1: T;
  addressLine_2: T;
  region: T;
  postalCode: T;
  country: T;
  phone: T;
  photoId: T;

  // BANK INFORMATION:
  bankName: T;
  bankCountry: T;
  bankCity: T;
  bankPostalCode: T;
  bankAddressLine_1: T;
  bankAddressLine_2: T;
  bankAccountName: T;
  bankAccountNumber: T;
  bankSwiftCode: T;

  // ACCREDITATION CERTIFICATION:
  certificationId: T;

  // TAX DECLARATION:
  taxCountryDetails: T;
  taxDeclarationId: T;
  taxDeclarationStatus: T; // "in-progress" | "completed"
  taxDeclarationDate: T;

  // KYC:
  kycId: T;
  kycStatus: T; // "rejected" | "completed" | "init" | "pending"
};

export interface Onboarding<T = InputValue> {
  agreement: Agreement<T>;
  form: OnboardingForm<T>;
  version: Version;
}
