import { WEBSITE_ROUTE as GET_ROUTE, Params as GetParams, Result as GetResult } from 'api/categories/get/params';
import { ApiResponse } from 'src/types/api-response';

import api from '..';

export const category = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query<ApiResponse<GetResult>, GetParams>({
      query: (params) => ({
        method: 'GET',
        url: GET_ROUTE.replace(':id', params.id),
      }),
      providesTags: (result) =>
        result?.success ? [{ type: 'CATEGORY', id: 'ALL' }] : [{ type: 'CATEGORY', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
