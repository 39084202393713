import {
  ROUTE as CREATE_CONTACT,
  Params as CreateParams,
  Result as CreateResult,
} from 'api/hubspot/website/create/params';
import {
  ROUTE as UPDATE_CONTACT,
  Params as UpdateParams,
  Result as UpdateResult,
} from 'api/hubspot/website/update/params';
import { ApiResponse } from 'types/api-response';

import api from '..';

export const hubspot = api.injectEndpoints({
  endpoints: (builder) => ({
    createContact: builder.mutation<ApiResponse<CreateResult>, CreateParams>({
      query: (params) => ({
        method: 'POST',
        url: CREATE_CONTACT,
        body: params,
      }),
      extraOptions: { maxRetries: 3 },
    }),
    updateContact: builder.mutation<ApiResponse<UpdateResult>, UpdateParams>({
      query: (params) => ({
        method: 'POST',
        url: UPDATE_CONTACT,
        body: params,
      }),
      extraOptions: { maxRetries: 3 },
    }),
  }),
  overrideExisting: false,
});
