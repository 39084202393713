import React from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import * as yup from 'yup';

import { useTranslation } from 'client/hooks';

import { InputValue } from 'models/user/onboarding';
import { SelectInput } from 'utils/onboarding/input-types';

import Title from '../../Title';
import ContinueButton from '../../ContinueButton';
import ExplanationSubtitle from '../../ExplanationSubtitle';

import css from './MultipleChoice.module.scss';

interface Props {
  info: SelectInput;
  onSubmitHandler: (value: Record<string, InputValue>) => void;
  isSkippable?: boolean;
}

const MultipleChoice = ({ info, onSubmitHandler, isSkippable = false }: Props) => {
  const { translate } = useTranslation();
  const [shouldRender, setShouldRender] = React.useState(false);
  const [isSubmiting, setIsSubmiting] = React.useState(false);
  const initialValues = {
    [`${info.name}`]: (info.defaultValue as Array<string>) || (info.value as Array<string>) || [],
  };

  const formikContext = useFormik<Record<string, Array<string>>>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({ [`${info.name}`]: info.schema }),
    onSubmit: (values) => {
      setIsSubmiting(true);
      onSubmitHandler({ [`${info.name}`]: values[`${info.name}`] });
    },
  });

  React.useEffect(() => {
    setShouldRender(true);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value } = e.currentTarget;
    const lastOptionValue = info.options[info.options.length - 1].value;

    // check none value checkbox
    if (lastOptionValue === value) {
      if (checked) {
        formikContext.setFieldValue(info.name, [value]);
      } else {
        formikContext.setFieldValue(
          info.name,
          formikContext.values[`${info.name}`].filter((v: string) => v !== name),
        );
      }
      return;
    }

    if (checked && lastOptionValue) {
      if (formikContext.values[`${info.name}`].includes(lastOptionValue)) {
        formikContext.setFieldValue(info.name, [value]);
      } else {
        formikContext.setFieldValue(info.name, [...formikContext.values[`${info.name}`], value]);
      }
    } else {
      formikContext.setFieldValue(
        info.name,
        formikContext.values[`${info.name}`].filter((v: string) => v !== value),
      );
    }
  };

  if (!shouldRender) return null;

  return (
    <FormikProvider value={formikContext}>
      <Form className={css.container}>
        <div className={css.containerI}>
          {isSkippable && <p className={css.nonMandatory}>{translate('questionnaire.nonMandatoryText')}</p>}
          <Title text={translate(info.label)} size="m" />
          {info.explanation && <ExplanationSubtitle text={translate(info.explanation)} />}
          <div className={css.fieldWrapper}>
            {info.options.map((item) => {
              return (
                <label key={item.value}>
                  <input
                    name={info.name}
                    type="checkbox"
                    value={item.value}
                    checked={
                      formikContext.values[`${info.name}`]
                        ? formikContext.values[`${info.name}`].includes(item.value)
                        : false
                    }
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <div className={css.answer}>
                    <div className={css.letter}>{item.value}</div>
                    <div className={css.text}>{translate(item.label)}</div>
                  </div>
                </label>
              );
            })}
          </div>
        </div>
        <div className={css.continueBtnWrapper}>
          <ContinueButton disabled={formikContext.values[`${info.name}`]?.length === 0 || isSubmiting} />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default MultipleChoice;
