import React from 'react';
import cn from 'classnames';

import { IModel } from 'models/company/interface';
import { imgixUrl } from 'client/utils/imgix-url';
import noAvatarImageUrl from 'client/assets/default-avatar.svg';
import css from './Founder.module.scss';

interface Props {
  className?: string;
  data: IModel['team'][number];
}

function Founder(props: Props) {
  const { data, className = '' } = props;

  return (
    <div className={cn(css.founder, className)}>
      <div
        className={css.founderPhoto}
        style={{
          backgroundImage: `url(${
            data.avatar
              ? imgixUrl(data.avatar, {
                  q: 85,
                  auto: 'compress',
                  fit: 'fill',
                  'max-w': 200,
                })
              : noAvatarImageUrl
          })`,
        }}
      />
      <div className={css.founderInfo}>
        <p className={css.founderName}>{data.name}</p>
        <p className={css.founderRole}>{data.role}</p>
      </div>
    </div>
  );
}

export { Founder };
export default Founder;
