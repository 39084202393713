import { USER_ROLE, USER_STATUS, UserRole, UserStatus } from 'models/user/interface';

export const statusTranslationMap: Record<UserStatus, string> = {
  [USER_STATUS.APPROVED]: 'Approved',
  [USER_STATUS.NOT_APPROVED]: 'Disapproved',
  [USER_STATUS.WAITING_FOR_APPROVE]: 'Pending approval',
  [USER_STATUS.NONE]: 'None',
} as const;

export const roleTranslationMap: Record<UserRole, string> = {
  [USER_ROLE.VIEWER]: 'Viewer',
  [USER_ROLE.INVESTOR_LEVEL_1]: 'Level 1',
  [USER_ROLE.INVESTOR_LEVEL_2]: 'Level 2',
  [USER_ROLE.INVESTOR_LEVEL_3]: 'Level 3',
} as const;

export const statusTranslation = (role: string, status?: string) => {
  if (!status || status === 'NONE') {
    return `${roleTranslationMap[role as UserRole]}`;
  } else {
    return `${roleTranslationMap[role as UserRole]} - ${statusTranslationMap[status as UserStatus]}`;
  }
};
