import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Swiper as SwiperType, Mousewheel, Navigation } from 'swiper';
import SwiperClass from 'swiper';
import { useTranslation } from 'client/hooks';
import { MainPageWebsiteSliderItem } from 'api/main_page/public/get/params';
import { DoubleImageSlide, Loader } from 'client/components/common';

import css from './TopSlider.module.scss';

SwiperCore.use([EffectFade, Mousewheel, Navigation]);
const SLIDES_PER_GROUP = 4;

type Props = {
  items?: MainPageWebsiteSliderItem[];
  isLoading: boolean;
};

const TopSlider: React.FC<Props> = ({ items, isLoading }) => {
  const { translate } = useTranslation();
  const sliderRef = React.useRef<SwiperClass>();
  const [isHideNext, setIsHideNext] = React.useState<boolean>(false);
  const [isHidePrev, setIsHidePrev] = React.useState<boolean>(true);

  return (
    <section className={css.slider}>
      <h4 className={css.title}>{translate('mainPageDesktop.slidersTitles.topWatched')}</h4>
      {isLoading ? (
        <Loader position="absolute" className={css.loader} />
      ) : (
        <div className={css.sliderWrapper}>
          <Swiper
            spaceBetween={0}
            slidesPerView={4}
            mousewheel={false}
            onSwiper={(swiper) => (sliderRef.current = swiper)}
            onSlideChange={(s) => {
              if (items) {
                if (s.activeIndex === 0) {
                  setIsHidePrev(true);
                } else {
                  setIsHidePrev(false);
                }
                if (s.activeIndex + SLIDES_PER_GROUP >= items.length) {
                  setIsHideNext(true);
                } else {
                  setIsHideNext(false);
                }
              }
            }}
            slidesPerGroup={SLIDES_PER_GROUP}
          >
            {_.map(items, (i, index) => (
              <SwiperSlide key={index}>
                <DoubleImageSlide
                  item={i}
                  className={css.slide}
                  buttonLabel={translate('mainPageDesktop.watchButton')}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {items && items.length > SLIDES_PER_GROUP && (
            <>
              <div
                className={cn(css.navBtn, css.next, isHideNext && css.hide)}
                onClick={() => sliderRef.current?.slideNext()}
              >
                <div className={css.arrow}></div>
              </div>

              <div
                className={cn(css.navBtn, css.prev, isHidePrev && css.hide)}
                onClick={() => sliderRef.current?.slidePrev()}
              >
                <div className={css.arrow}></div>
              </div>
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default TopSlider;
