import * as yup from 'yup';
import { InputValue } from 'models/user/onboarding';

const TEXT_MIN_LENGTH = 2;
const TEXT_MAX_LENGTH = 40;
const TEXT_AREA_MIN_LENGTH = 2;
const TEXT_AREA_MAX_LENGTH = 10000;
const URL_MIN_LENGTH = 2;
const URL_MAX_LENGTH = 1000;
const SWIFT_MIN_LENGTH = 8;
const SWIFT_MAX_LENGTH = 11;
const IBAN_MIN_LENGTH = 5;
const IBAN_MAX_LENGTH = 34;

export const constants = {
  TEXT_MIN_LENGTH,
  TEXT_MAX_LENGTH,
  TEXT_AREA_MIN_LENGTH,
  TEXT_AREA_MAX_LENGTH,
  URL_MIN_LENGTH,
  URL_MAX_LENGTH,
  SWIFT_MIN_LENGTH,
  SWIFT_MAX_LENGTH,
  IBAN_MIN_LENGTH,
  IBAN_MAX_LENGTH,
};

export enum InputType {
  TEXT = 'Text',
  TEXT_AREA = 'TextArea',
  DATE = 'Date',
  SELECT = 'Select',
  AUTO_COMPLETE = 'Autocomplete',
  CHECKBOX = 'Checkbox',
  ARRAY = 'Array',
  MEDIA = 'Media',
  FORM = 'Form', // Form is a special type of input that is used to group other inputs
}
interface InputBase {
  type: InputType;
  name: string;
  label: string;
  explanation?: string;
  value?: InputValue;
  defaultValue?: InputValue;
  schema: yup.AnySchema;
  shortWidth?: boolean;
}

export interface TextInput extends InputBase {
  type: InputType.TEXT;
  schema: yup.StringSchema | yup.BaseSchema;
  min?: number;
  max?: number;
}

export interface TextAreaInput extends InputBase {
  type: InputType.TEXT_AREA;
  schema: yup.StringSchema;
  min?: number;
  max?: number;
}

export interface SelectInput extends InputBase {
  type: InputType.SELECT;
  multiple?: boolean;
  options: { value: string; label: string }[];
  schema: yup.ArraySchema<yup.StringSchema>;
  withOther?: boolean;
}

export interface AutoCompleteInput extends InputBase {
  type: InputType.AUTO_COMPLETE;
  multiple?: boolean;
  options: { value: string; label: string }[];
  schema: yup.ArraySchema<yup.StringSchema> | yup.StringSchema;
}

export interface CheckboxInput extends InputBase {
  type: InputType.CHECKBOX;
  schema: yup.BooleanSchema;
}

export interface DateInput extends InputBase {
  type: InputType.DATE;
  schema: yup.DateSchema;
}

export interface ArrayInput extends InputBase {
  type: InputType.ARRAY;
  shape: Record<string, Input>;
  schema: yup.ArraySchema<yup.AnySchema>;
}

export interface MediaInput extends InputBase {
  type: InputType.MEDIA;
}

export type Input =
  | TextInput
  | TextAreaInput
  | SelectInput
  | AutoCompleteInput
  | CheckboxInput
  | DateInput
  | ArrayInput
  | MediaInput;
