import React, { useState } from 'react';

import cn from 'classnames';

import css from './DealTermsExpandable.module.scss';

interface Props {
  info: string;
  className?: string;
}

const DealTermsExpandable = ({ info, className = '' }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const readMoreBtnClickListener = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className={cn(css.infoWrapper, className)}>
      <div className={cn(css.info, isExpanded && css.fullInfo)} dangerouslySetInnerHTML={{ __html: info }}></div>
      <div className={css.readMore} onClick={readMoreBtnClickListener}>
        Read {isExpanded ? 'less...' : 'more...'}
      </div>
    </div>
  );
};

export default DealTermsExpandable;
