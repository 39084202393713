import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from 'client/hooks';
import { selectMenuState } from 'client/redux/ui/selectors';
import { transitionClassNames } from 'client/utils/css-transition-classnames';
import { setMenuState } from 'client/redux/ui';
import { ContentWrap } from 'client/components/common/mobile/ContentWrap';

import closeBtnIconUrl from 'client/assets/main/close-btn.svg';
import Menu from './Menu';
import Categories from './Categories';
import css from './ControlPanel.module.scss';

interface Props {
  className?: string;
}

function ControlPanel(props: Props) {
  const { className = '' } = props;
  const controlPanelState = useAppSelector(selectMenuState);
  const dispatch = useAppDispatch();

  const onCloseClick = () => {
    dispatch(setMenuState('hidden'));
  };

  const renderContent = () => {
    switch (controlPanelState) {
      case 'navigation': {
        return <Menu />;
      }

      case 'categories': {
        return <Categories />;
      }

      default: {
        return null;
      }
    }
  };

  return (
    <CSSTransition
      in={controlPanelState !== 'hidden'}
      timeout={650}
      classNames={containerTransitionClassNames}
      mountOnEnter
      unmountOnExit
    >
      <div className={cn(css.controlPanel, className)}>
        <div className={css.bg} />
        <ContentWrap className={css.contentWrap}>
          <div className={css.closeBtnWrap}>
            <button className={css.closeBtn} type="button" onClick={onCloseClick}>
              <img src={closeBtnIconUrl} alt="close" />
            </button>
          </div>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={controlPanelState}
              classNames={contentTransitionClassNames}
              timeout={300}
              appear
              unmountOnExit
            >
              <div className={css.content}>{renderContent()}</div>
            </CSSTransition>
          </SwitchTransition>
        </ContentWrap>
      </div>
    </CSSTransition>
  );
}

const containerTransitionClassNames = transitionClassNames(css, 'containerTransition');
const contentTransitionClassNames = transitionClassNames(css, 'contentTransition');

export { ControlPanel };
export default ControlPanel;
