import { useEffect } from 'react';

import { selectAuth, selectUser } from 'client/redux/user/selectors';

import { useAppSelector } from './redux';
import { useTranslation } from './translation';

// @ts-expect-error Appcues is provided to window by added script tag in the index.html
const _window: typeof globalThis & { Appcues: any } = globalThis || {};

export const useAppcues = () => {
  const user = useAppSelector(selectUser);
  const auth = useAppSelector(selectAuth);
  const { translate } = useTranslation();
  const role = translate(`roleLabels.${user.role}`);
  const status = translate(`statusLabels.${user.status}`);

  useEffect(() => {
    if (process.env.MODE === 'local') return;

    if (auth.isCompleted && user._id) {
      _window.Appcues.identify(user._id, {
        createdAt: new Date(user.createdAt).getTime(),
        role: status ? `${role} (${status})` : role,
        firstName: user.onboarding.form.firstName || user.name || '',
        email: user.email,
        language: 'English',
        env: process.env.MODE,
      });
    }
  }, [auth.isCompleted, user._id]);

  return null;
};
