import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import cn from 'classnames';

import * as routes from 'client/constants/routes';
import { useAppSelector, useTranslation, useResponsive } from 'client/hooks';
import { getProgress as getOnboardingProgress } from 'utils/onboarding/progress';
import { selectUserOnboarding } from 'client/redux/user/selectors';

import { PageNavigation } from 'client/components/common';
import { Home } from './Home';
import { InvestorProfile } from './InvestorProfile';
import { Investments } from './Investments';
import { Library } from './Library';
import css from './InvestorPages.module.scss';

const InvestorPages = () => {
  const { translate: _t } = useTranslation('userProfile');
  const [isMobile] = useResponsive(['MOBILE']);
  const onboarding = useAppSelector(selectUserOnboarding);
  const onboardingProgress = getOnboardingProgress(onboarding);

  const navigation = isMobile
    ? [
        { label: _t('investor.home'), path: routes.USER_PROFILE_ROUTE },
        {
          label: _t('investor.investor'),
          path: routes.USER_PROFILE_INVESTOR_ROUTE,
          hasPendingAction: Object.values(onboardingProgress).some((p) => p < 1),
        },
        { label: _t('investor.investments'), path: routes.USER_PROFILE_INVESTMENTS_ROUTE },
      ]
    : [
        { label: _t('investor.home'), path: routes.USER_PROFILE_ROUTE },
        {
          label: _t('investor.investor'),
          path: routes.USER_PROFILE_INVESTOR_ROUTE,
          hasPendingAction: Object.values(onboardingProgress).some((p) => p < 1),
        },
        { label: _t('investor.investments'), path: routes.USER_PROFILE_INVESTMENTS_ROUTE },
        { label: _t('investor.library'), path: routes.USER_PROFILE_LIBRARY_ROUTE },
      ];

  return (
    <>
      <PageNavigation navigation={navigation} />
      <div className={css.content}>
        <Switch>
          <Route exact path={routes.USER_PROFILE_ROUTE} component={Home} />
          <Route exact path={routes.USER_PROFILE_INVESTOR_ROUTE} component={InvestorProfile} />
          <Route exact path={routes.USER_PROFILE_INVESTMENTS_ROUTE} component={Investments} />
          {!isMobile && <Route exact path={routes.USER_PROFILE_LIBRARY_ROUTE} component={Library} />}
        </Switch>
      </div>
    </>
  );
};

export default InvestorPages;
