import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';

import * as routes from 'client/constants/routes';
import { categories as categoriesApi } from 'client/redux/api/categories';
import { transitionClassNames } from 'client/utils/css-transition-classnames';
import { useAppDispatch } from 'client/hooks';
import { setMenuState } from 'client/redux/ui';
import css from './ControlPanel.module.scss';

interface Props {
  className?: string;
}

function Categories(props: Props) {
  const { className = '' } = props;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { data, isLoading } = categoriesApi.useGetAllCategoriesQuery({ isVisible: true });
  const items = data?.success ? data.data : [];

  return (
    <div className={cn(css.subContent, className)}>
      <div className={css.subContentTitle}>Categories</div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={isLoading ? 'loading' : 'loaded'}
          timeout={300}
          classNames={contentTransitionClassNames}
          appear
          mountOnEnter
          unmountOnExit
        >
          <div className={css.subContentScrollable}>
            {isLoading ? (
              <div className={css.loading}>Loading...</div>
            ) : (
              items?.map((item) => {
                const urlParams = new URLSearchParams(location.search);
                const currentCategory = urlParams.get('category');

                urlParams.set('category', item._id.toString());
                urlParams.set('page', '1');

                return (
                  <NavLink
                    to={`${routes.MAIN_PAGE_ROUTE}?${urlParams.toString()}`}
                    className={cn(css.subContentItem, currentCategory === item._id.toString() && css.active)}
                    onClick={() => dispatch(setMenuState('hidden'))}
                    key={item._id.toString()}
                  >
                    {item.name}
                  </NavLink>
                );
              })
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

const contentTransitionClassNames = transitionClassNames(css, 'contentTransition');

export { Categories as Menu };
export default Categories;
