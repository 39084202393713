import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { transitionClassNames } from 'client/utils/css-transition-classnames';
import css from './FoundersSlider.module.scss';

interface Props {
  className?: string;
  founders: { name: string; role: string; avatar: string }[];
}

const MAX_ITEMS_VISIBLE = 3;
const fadeTransitionClassNames = transitionClassNames(css, 'transitionFade');

export function FoundersSlider(props: Props) {
  const { founders, className = '' } = props;
  const [foundersList, setFoundersList] = React.useState(founders);
  const [currentSlideArray, setCurrentSlideArray] = React.useState(createSlideArray(foundersList));
  const [currentFounder, setCurrentFounder] = React.useState({
    name: currentSlideArray[0].name,
    role: currentSlideArray[0].role,
  });
  const [isAnimating, setIsAnimating] = React.useState(false);

  React.useEffect(() => {
    if (foundersList.length <= 1) {
      return;
    }

    const timeoutId = setTimeout(function nextSlide() {
      const nextFoundersList = [...foundersList];
      const removedFounder = nextFoundersList.shift();

      nextFoundersList.push(removedFounder!);

      setIsAnimating(true);
      setCurrentFounder({
        name: nextFoundersList[0].name,
        role: nextFoundersList[0].role,
      });

      setTimeout(() => {
        setIsAnimating(false);
        setFoundersList(nextFoundersList);
        setCurrentSlideArray(createSlideArray(nextFoundersList));
      }, parseInt(css.transitionDuration, 10));
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [foundersList]);

  return (
    <div className={cn(css.foundersSlider, isAnimating && css.animated, className)}>
      <div className={css.photos}>
        {currentSlideArray.map((founder, index) => (
          <div className={css.photoWrap} key={`${founder.name}_${index}`}>
            <div className={css.photo} style={{ backgroundImage: `url(${founder.avatar})` }} />
          </div>
        ))}
      </div>
      <SwitchTransition>
        <CSSTransition
          key={`${currentFounder.name}_${currentFounder.role}`}
          timeout={parseInt(css.transitionDuration, 10)}
          classNames={fadeTransitionClassNames}
        >
          <div className={css.nameAndPosition}>
            <p className={css.name}>{currentFounder.name}</p>
            <p className={css.position}>{currentFounder.role}</p>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

function createSlideArray(list: Props['founders']) {
  if (list.length > 1) {
    const result = list.slice(0, MAX_ITEMS_VISIBLE + 1);
    if (result.length < MAX_ITEMS_VISIBLE + 1) {
      result.push(list[0]);
    }
    return result;
  }
  return list;
}

export default FoundersSlider;
