import React from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import { FormikProvider, Form, useFormik } from 'formik';
import _ from 'lodash';

import { useAppSelector, useTranslation, useAppDispatch } from 'client/hooks';
import { selectUserOnboarding } from 'client/redux/user/selectors';
import { getBankDetails } from 'utils/onboarding/accreditation-finalization/bank-details';
import { InputType, Input } from 'utils/onboarding/input-types';
import { StepInput } from 'utils/onboarding/stage';
import { validationSchema, initialValues } from 'utils/onboarding';
import { Onboarding, InputValue } from 'models/user/onboarding';
import { setPopup } from 'client/redux/ui';
import { onboarding as onboardingApi } from 'client/redux/api/onboarding';

import Text from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/Form/TextField';
import Select from 'client/components/common/PopUpManager/AccreditationQuestionnaire/QuestionsTypes/Form/Select';
import Button from 'client/components/common/Button';

import css from './BankDetails.module.scss';

const BankDetails = () => {
  const onBoarding = useAppSelector(selectUserOnboarding);
  const bankDetailsData = getBankDetails(onBoarding);
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();

  //split fields to bank details and account details
  const bankDetailsFields = bankDetailsData.steps.slice(0, 6) as Array<StepInput>;
  const accountDetailsFields = bankDetailsData.steps.slice(6, 9) as Array<StepInput>;

  const [bankDetails, bankDetailsState] = onboardingApi.endpoints.setBankDetails.useMutation();

  const formikContext = useFormik<Onboarding['form']>({
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    initialValues: initialValues([...bankDetailsFields, ...accountDetailsFields]),
    validate: async (values: Onboarding['form']) => {
      const errors: Record<string, string> = {};
      const schema = validationSchema([...bankDetailsFields, ...accountDetailsFields]);

      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const { inner } = error as yup.ValidationError;
        inner.forEach((e) => {
          if (e.path && e.type) errors[e.path] = e.errors.join(', ');
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      const notEmptyValues = {} as Record<keyof Onboarding['form'], InputValue>;
      for (const key in values) {
        if (values[key as keyof Onboarding['form']] && Array.isArray(values[key as keyof Onboarding['form']])) {
          if ((values[key as keyof Onboarding['form']] as Array<any>).length > 0) {
            notEmptyValues[key as keyof Onboarding['form']] = values[key as keyof Onboarding['form']];
          }
        } else if (values[key as keyof Onboarding['form']]) {
          notEmptyValues[key as keyof Onboarding['form']] = values[key as keyof Onboarding['form']];
        }
      }

      const isEmptyValues = _.isEmpty(notEmptyValues);

      if (!isEmptyValues) {
        await bankDetails({ ...notEmptyValues, intent: 'bank_details' });
      }
    },
  });

  React.useEffect(() => {
    if (bankDetailsState.data?.success) {
      dispatch(setPopup(null));
    }
  }, [bankDetailsState]);

  const closeClickHandler = () => {
    if (formikContext.dirty) {
      const result = confirm(translate('onboardingFinalization.bankDetails.closeWindowConfirm'));
      if (result) {
        dispatch(setPopup(null));
      }
    } else {
      dispatch(setPopup(null));
    }
  };

  const renderField = (input: StepInput) => {
    switch (input.type) {
      case InputType.TEXT:
        return <Text name={input.name} label={input.label} withUpperLabel={false} />;
      case InputType.AUTO_COMPLETE:
        return <Select name={input.name} label={input.label} options={input.options} />;
      default:
        return null;
    }
  };

  const bankDetailsRender = () => {
    return bankDetailsFields.map((item) => {
      if (item.name === 'bankAddressLine_1' || item.name === 'bankAddressLine_2') {
        return (
          <div className={cn(css.fieldWrapper, css.long)} key={item.name}>
            {renderField(item)}
          </div>
        );
      }
      return (
        <div className={css.fieldWrapper} key={item.name}>
          {renderField(item)}
        </div>
      );
    });
  };

  const accountDetailsRender = () => {
    return accountDetailsFields.map((item) => {
      if (item.name === 'bankSwiftCode') {
        return (
          <div className={cn(css.fieldWrapper, css.long)} key={item.name}>
            {renderField(item)}
          </div>
        );
      }
      return (
        <div className={css.fieldWrapper} key={item.name}>
          {renderField(item)}
        </div>
      );
    });
  };

  return (
    <FormikProvider value={formikContext}>
      <Form autoComplete="off">
        <div className={css.closeBtnMobile} onClick={closeClickHandler}></div>
        <div className={css.formWrapper}>
          <div className={css.closeBtn} onClick={closeClickHandler}></div>
          <div className={css.formWrapperI}>
            <div className={css.wrapper}>
              <h1>{translate('onboardingFinalization.bankDetails.title')}</h1>
              <div className={css.form}>
                <div className={css.bankDetails}>
                  <h3>{translate('onboardingFinalization.bankDetails.bankDetailsTitle')}</h3>
                  {bankDetailsRender()}
                </div>
                <div className={css.accountDetails}>
                  <h3>{translate('onboardingFinalization.bankDetails.accountDetailsTitle')}</h3>
                  {accountDetailsRender()}
                </div>
                <div className={css.submitWrapper}>
                  <Button type="submit" disabled={!formikContext.dirty} className={css.submitButton}>
                    <span className={cn(css.btnText, bankDetailsState.isLoading && css.loading)}>
                      {translate('onboardingFinalization.submitBtn')}
                    </span>
                    <div className={cn(css.loaderWrapper, bankDetailsState.isLoading && css.loading)}>
                      <div className={css.loader}></div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={css.blurredBlock}></div>
      </Form>
    </FormikProvider>
  );
};

export default BankDetails;
