import * as routes from 'api/routes';
import { IModel } from 'models/company/interface';

export type Params = void;
export type Result = Array<FavoriteCompany>;

export type FavoriteCompany = Pick<
  IModel,
  'name' | 'isVisible' | 'mainImage' | 'shortDescription' | 'rating' | 'subTitle'
> & {
  _id: string;
  createdAt: string;
};

export const ROUTE = routes.USERS_FAVORITE_LIST_GET;
