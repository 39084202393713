import React from 'react';
import { useTranslation } from 'client/hooks';
import { TERMS_OF_USE_PAGE_ROUTE, PRIVACY_POLICY_PAGE_ROUTE } from 'client/constants/routes';

import css from './Login.module.scss';

const TermsAndPolicy: React.FC = () => {
  const { translate: _t } = useTranslation('popups');

  const link = (link: string, label: string) => {
    return `<a href="${link}" target="_blank" rel="norefferer noopener" class="${css.link}">${_t(label)}</a>`;
  };

  const template = _t(`login.termsTemplate`)
    .replace('{terms}', link(TERMS_OF_USE_PAGE_ROUTE, _t('login.termsLabel')))
    .replace('{policy}', link(PRIVACY_POLICY_PAGE_ROUTE, _t('login.policyLabel')));

  return <div className={css.terms} dangerouslySetInnerHTML={{ __html: template }} />;
};

export default TermsAndPolicy;
