import React from 'react';
import { Link } from 'react-router-dom';

import { USER_PROFILE_INVESTOR_ROUTE } from 'client/constants/routes';
import { USER_ROLE } from 'src/models/user/interface';
import { Button, Icon } from 'src/routes/client/components/common';
import { useTranslation, useResponsive } from 'client/hooks';

import css from './OffersPopup.module.scss';

interface Props {
  userRole: string;
  onClose?: () => void;
}

export const SuccessMessage: React.FC<Props> = ({ userRole, onClose }) => {
  const [isMobile] = useResponsive(['MOBILE']);
  const { translate: _t } = useTranslation('popups');

  const subTitle =
    userRole === USER_ROLE.INVESTOR_LEVEL_2
      ? _t('offersPopup.successMessages.investorL2SubTitle')
      : _t('offersPopup.successMessages.investorL3SubTitle');
  const btnLabel =
    userRole === USER_ROLE.INVESTOR_LEVEL_2
      ? _t('offersPopup.successMessages.investorL2BtnLabel')
      : _t('offersPopup.successMessages.investorL3BtnLabel');

  const renderBtnContent = () => {
    return userRole === USER_ROLE.INVESTOR_LEVEL_2 ? (
      <Link to={USER_PROFILE_INVESTOR_ROUTE} className={css.successMessageButton}>
        {btnLabel}
      </Link>
    ) : (
      <Button className={css.successMessageButton} onClick={onClose}>
        {btnLabel}
      </Button>
    );
  };

  return (
    <div className={css.successMessage}>
      <Icon className={css.icon} type="check-rounded" color="#0757d3" secondaryColor="rgb(104,164, 255)" />
      <div className={css.successMessageTitle}>{_t('offersPopup.successMessages.title')}</div>
      <div className={css.successMessageInfo}>{subTitle}</div>
      {renderBtnContent()}
      {!isMobile && (
        <button className={css.closeBtn} onClick={onClose}>
          <Icon type="close" />
        </button>
      )}
    </div>
  );
};
