import React from 'react';
import cn from 'classnames';
import css from './Loader.module.scss';

interface Props {
  position: 'fixed' | 'absolute' | 'relative';
  className?: string;
  spinnerClassname?: string;
}
const Loader: React.FC<Props> = ({ className, spinnerClassname, position = 'absolute' }) => {
  const positionClass = (position: Props['position']) => {
    switch (position) {
      case 'fixed':
        return css.fixed;
      case 'absolute':
        return css.absolute;
      case 'relative':
      default:
        return;
    }
  };
  return (
    <div className={cn(css.loader, className, positionClass(position))}>
      <div className={cn(css.spinner, spinnerClassname)}></div>
    </div>
  );
};

export default Loader;
