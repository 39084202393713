import React from 'react';
import cn from 'classnames';
import { SIZE_TYPE } from 'client/types';
import css from './PlayIcon.module.scss';

type Props = {
  size?: SIZE_TYPE;
  className?: string;
};

const PlayIcon: React.FC<Props> = ({ size = SIZE_TYPE.L, className = '' }) => {
  return <button type="button" className={cn(css.playIcon, css[size], className)}></button>;
};

export default PlayIcon;
