import { useAppSelector } from 'client/hooks';
import { selectUserOnboarding } from 'client/redux/user/selectors';

export const useGetStartScreen = (): 'questionnaire' | 'welcome' | 'thanks' => {
  const onBoarding = useAppSelector(selectUserOnboarding);
  const firstName = onBoarding.form.firstName;
  const lastName = onBoarding.form.lastName;
  const signatureStatus = onBoarding.form.signatureStatus;

  if (signatureStatus === 'completed') {
    return 'thanks';
  }

  if (firstName || lastName) {
    return 'questionnaire';
  }

  return 'welcome';
};
