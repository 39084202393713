import React from 'react';
import cn from 'classnames';

import { useAppSelector, useTranslation, useAppDispatch } from 'client/hooks';
import { setBannerJoinState, setPopup } from 'client/redux/ui';
import { selectAuth } from 'client/redux/user/selectors';
import { selectUI } from 'client/redux/ui/selectors';
import { State as AuthState } from 'client/components/common/Login/state';

import Icon from '../Icon';

import css from './BannerJoin.module.scss';

const BannerJoin = () => {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const { bannerJoin } = useAppSelector(selectUI);

  const onLoginClick = (type: AuthState['authType']) => {
    dispatch(setPopup({ type: 'auth', data: { initialAuthType: type } }));
  };

  return (
    <div className={cn(css.bannerJoin, !auth.isLoggedIn && bannerJoin === 'visible' && css.visible)}>
      <p>
        {translate(`bannerJoin.text`)}
        <span onClick={() => onLoginClick('sign-up')}>{translate(`bannerJoin.buttonLabel`)}</span>
      </p>
      <button className={css.closeButton} onClick={() => dispatch(setBannerJoinState('hidden'))}>
        <Icon type="close" />
      </button>
    </div>
  );
};

export default BannerJoin;
