import React from 'react';
import { useHistory } from 'react-router';
import { useResponsive } from 'client/hooks';
import * as routes from 'client/constants/routes';

const SavingListStub = () => {
  const history = useHistory();
  const [isDesktop] = useResponsive('DESKTOP');

  React.useEffect(() => {
    if (isDesktop) {
      history.push(routes.USER_PROFILE_ROUTE);
    }
  }, [isDesktop]);

  return <div></div>;
};

export default SavingListStub;
