import React from 'react';
import gsap from 'gsap';
import Div100vh from 'react-div-100vh';
import cn from 'classnames';

import { mergeRefs } from 'utils/index';
import { Scroller } from 'client/utils/scroller';
import css from './ScreenSlider.module.scss';

interface Props<T> {
  className?: string;
  items: T[];
  page: number;
  total: number;
  scroller: Scroller;
  active?: boolean;
  busy?: boolean;
  destroyOnReachingTop?: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
  slide: React.FC<{ data: T | null; position?: 'prev' | 'current' | 'next' }>;
  noResults: React.FC;
  onPageChange: (page: number) => unknown;
  onDeactivate: () => unknown;
}

const TRANSITION_DURATION = 0.3;
const DEFAULT_TRANSITION = `transform ${TRANSITION_DURATION}s ease-out`;
const ELASTIC_TRANSITION = `transform ${TRANSITION_DURATION}s cubic-bezier(0.25, 0.1, 0.3, 1.5)`;

export function ScreenSlider<T>(props: Props<T>) {
  const {
    items,
    page,
    total,
    scroller,
    className = '',
    active = false,
    busy = false,
    destroyOnReachingTop = false,
    slide: Slide,
    noResults: NoResults,
    onPageChange,
    onDeactivate,
  } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const prevPage = React.useRef(page);
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(page - 1);

  const prevSlideData = !busy ? items[currentSlideIndex - 1] : null;
  const currentSlideData = !busy ? items[currentSlideIndex] : null;
  const nextSlideData = !busy ? items[currentSlideIndex + 1] : null;

  // Swipe handlers:
  React.useEffect(() => {
    if (!active || !containerRef.current) return;

    let scrolledDistance = 0;
    let touchStartY = 0;
    let touchEndY = 0;

    scroller.subscribe('wheelSwipe', onWheelSwipeEvent);
    scroller.subscribe('touchScroll', onScrollEvent);
    scroller.subscribe('touch', onTouchEvent);

    function onWheelSwipeEvent(direction: 'up' | 'down') {
      if (direction === 'down') {
        nextSlide();
      } else if (page > 1) {
        prevSlide();
      } else {
        if (destroyOnReachingTop) destroySlider();
        onDeactivate();
      }
    }

    function onScrollEvent(deltaY: number) {
      scrolledDistance += deltaY;
      gsap.set(containerRef.current, { y: -scrolledDistance, transition: 'none' });
    }

    function onTouchEvent(e: TouchEvent) {
      switch (e.type) {
        case 'touchstart':
          scrolledDistance = 0;
          touchStartY = e.touches[0].clientY;
          touchEndY = e.touches[0].clientY;
          break;

        case 'touchmove':
          touchEndY = e.touches[0].clientY;
          break;

        case 'touchend': {
          const minDistance = 60;
          const absDistance = Math.abs(scrolledDistance);

          if (touchEndY > touchStartY && absDistance > 1.75 * minDistance) {
            prevSlide();
            return;
          }

          if (touchEndY < touchStartY && absDistance > minDistance) {
            nextSlide();
            return;
          }

          gsap.set(containerRef.current, { y: 0, transition: DEFAULT_TRANSITION });
          break;
        }
      }
    }

    function prevSlide() {
      if (page === 1) {
        if (destroyOnReachingTop) destroySlider();
        onDeactivate();

        gsap.set(containerRef.current, { y: 0, transition: DEFAULT_TRANSITION });
        return;
      }

      onPageChange(page - 1);
    }

    function nextSlide() {
      if (page === total) {
        gsap.set(containerRef.current, { y: 0, transition: ELASTIC_TRANSITION });
        return;
      }

      onPageChange(page + 1);
    }

    function destroySlider() {
      scroller.unsubscribe('wheelSwipe', onWheelSwipeEvent);
      scroller.unsubscribe('touchScroll', onScrollEvent);
      scroller.unsubscribe('touch', onTouchEvent);
    }

    return destroySlider;
  }, [active, page, total, scroller, destroyOnReachingTop, onPageChange, onDeactivate]);

  // Handle page change:
  React.useEffect(() => {
    if (page === prevPage.current || !containerRef.current) return;

    const containerHeight = containerRef.current.offsetHeight;
    const tl = gsap.timeline();
    const targetY = page > prevPage.current ? -containerHeight : containerHeight;

    tl.add(gsap.set(containerRef.current, { y: targetY, transition: DEFAULT_TRANSITION }), 0).add(() => {
      setCurrentSlideIndex(page - 1);
    }, TRANSITION_DURATION);

    prevPage.current = page;

    return () => {
      // TODO: find out why next effect doesn't work: on clicking HOME
      // tl.kill();
    };
  }, [page]);

  // Reset slider position:
  React.useEffect(() => {
    gsap.set(containerRef.current, { y: 0, transition: 'none' });
  }, [currentSlideIndex]);

  return (
    <Div100vh className={cn(css.companies, className)} ref={mergeRefs(props.containerRef, containerRef)}>
      {page > 1 && <Slide data={prevSlideData} position="prev" />}
      {busy === false && total === 0 ? <NoResults /> : <Slide data={currentSlideData} />}
      {page < total && <Slide data={nextSlideData} position="next" />}
    </Div100vh>
  );
}

export default ScreenSlider;
