import merge from 'lodash/merge';
import { Version, CountryGroup, Onboarding } from 'models/user/onboarding';

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
export function getFullData(...partials: (Onboarding | RecursivePartial<Onboarding> | undefined)[]): Onboarding {
  return merge(
    {
      agreement: {
        residency: `${CountryGroup.OTHER}_`,
        agree: null,
        someOtherSpecialField: null,
      },
      form: {
        // PERSONAL DETAILS:
        firstName: null,
        lastName: null,
        email: null,
        dateOfBirth: null,
        countryOfBirth: null,
        countryOfResidence: null,
        hasNonResidentalCitizenship: null,
        nonResidentalCitizenships: null,

        // QUESTIONNAIRE:
        occupation: null,
        appliesTo: null,
        experience: null,
        experienceExplanation: null,
        financeEducation: null,
        privateEquity: null,
        objectiveDescription: null,
        totalLossAcceptance: null,
        investmentPortionOfTotal: null,
        liquidAssetsDuration: null,
        timeHorizon: null,
        longTermInvestmentLiquidityNeeds: null,
        investingAs: null,
        sourceOfFunds: null,
        sourceOfSavings: null,
        prominentPublicRole: null,
        prominentPublicRoleRelation: null,
        USRelation: null,

        // NDA:
        signatureId: null,
        signatureStatus: null,
        signatureDate: null,

        // CONTACT DETAILS:
        city: null,
        postalCode: null,
        addressLine_1: null,
        addressLine_2: null,
        region: null,
        country: null,
        phone: null,
        photoId: null,

        // BANK INFORMATION:
        bankName: null,
        bankCountry: null,
        bankCity: null,
        bankPostalCode: null,
        bankAddressLine_1: null,
        bankAddressLine_2: null,
        bankAccountName: null,
        bankAccountNumber: null,
        bankSwiftCode: null,

        // ACCREDITATION CERTIFICATION:
        certificationId: null,

        // TAX DECLARATION:
        taxCountryDetails: null,
        taxDeclarationId: null,
        taxDeclarationStatus: null,
        taxDeclarationDate: null,

        // KYC:
        kycId: null,
        kycStatus: null,
      },
      version: Version.SHORTENED,
    } as Onboarding,
    ...partials,
  ) as Onboarding;
}
