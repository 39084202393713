import React from 'react';

import { FounderPopUpData } from 'client/redux/ui';
import { Founder } from 'client/components/pages/CompanyMobile/Founder';
import css from './Founders.module.scss';

interface Props {
  data: FounderPopUpData;
}

function Founders(props: Props) {
  const { data } = props;

  return (
    <div className={css.founders}>
      {data.map((item, index) => (
        <Founder className={css.foundersItem} key={index} data={item} />
      ))}
    </div>
  );
}

export { Founders };
export default Founders;
