import React from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import { useTranslation, useAppSelector } from 'client/hooks';
import DOMPurify from 'dompurify';
import { selectUser } from 'client/redux/user/selectors';

import Error from '../../../../../Error';
import css from './TextField.module.scss';

interface Props {
  name: string;
  label: string;
  withUpperLabel?: boolean;
}

const Text = ({ name, label, withUpperLabel = true }: Props) => {
  const [field, meta, helpers] = useField(name);
  const [isFocused, setIsFocused] = React.useState(false);
  const errorMsg = !!(meta.touched && meta.error) ? meta.error : '';
  const { translate } = useTranslation();

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = DOMPurify.sanitize(e.target.value);
    helpers.setValue(value);
  };

  const onFocusHandler = () => {
    if (!isFocused || (field.value && field.value !== '')) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  };

  const fieldProps = {
    ...field,
    value: field.value || '',
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeHandler(event);
    },
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
      onFocusHandler();
    },
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
      onFocusHandler();
    },
  };

  React.useEffect(() => {
    if (field.value) {
      setIsFocused(true);
    }
  }, []);

  React.useEffect(() => {
    if (field.value && !isFocused) {
      setIsFocused(true);
    }
  }, [field.value]);

  return (
    <>
      <div
        className={cn(
          css.inputWrapper,
          withUpperLabel && css.bigTopMargin,
          isFocused && withUpperLabel && css.focusedTopLabel,
          isFocused && !withUpperLabel && css.focused,
          errorMsg && meta.touched && css.error,
        )}
      >
        <input type="text" {...fieldProps} />
        <p className={css.label}>{translate(label)}</p>
      </div>
      {errorMsg && meta.touched && <Error name={name} error={errorMsg} />}
    </>
  );
};

export default Text;
