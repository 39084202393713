import React from 'react';
import cn from 'classnames';

import { getMandateFieldsByUserRoleAndStatus } from 'api/helpers/mandate/get-field-by-role-and-status';
import { Result as Mandate } from 'api/mandate/website/get/params';
import { useAppSelector, useTranslation } from 'client/hooks';
import { selectUser } from 'client/redux/user/selectors';

import lockIconImg from 'client/assets/icons/lock.png';

import css from './RestrictedBlock.module.scss';

export function RestrictedBlock(props: {
  fields: (keyof NonNullable<Mandate['mandate']>)[];
  children: React.ReactNode;
  fallback: React.ReactNode;
  className?: string;
  unlockPopUp?: boolean;
  onUnlockBtnClick: () => void;
}) {
  const { fields, unlockPopUp, className = '' } = props;
  const { role, status } = useAppSelector(selectUser);
  const allowedFields = getMandateFieldsByUserRoleAndStatus(role, status);
  const isForbiden = fields.some((field) => !allowedFields.includes(field));
  const t = useTranslation().createPrefixedTranslationFunc('mandate');

  if (!isForbiden) return <div className={className}>{props.children}</div>;

  const onClickBtnHandler = () => {
    if (status === 'WAITING_FOR_APPROVE') {
      return;
    }
    props.onUnlockBtnClick();
  };

  return (
    <div className={cn(css.restrictedBlock, isForbiden && css.forbiden, unlockPopUp && css.withPopUp)}>
      <div className={css.restrictedBlockOverlay}>
        <div className={css.restrictedBlockOverlayContent}>
          {unlockPopUp && status !== 'WAITING_FOR_APPROVE' && <p>{t('unlockPopUpText')}</p>}
          <div className={css.btnWrapper}>
            <button className={css.restrictedBlockUnlockBtn} onClick={onClickBtnHandler}>
              <img src={lockIconImg} />
              <span>{status === 'WAITING_FOR_APPROVE' ? t('pendingApproval') : t('unlock')}</span>
            </button>
            {status === 'WAITING_FOR_APPROVE' && <div className={css.tooltip}>{t('tooltipText')}</div>}
          </div>
        </div>
      </div>
      <div className={cn(css.restrictedBlockFallback, className)}>{props.fallback}</div>
    </div>
  );
}

export default RestrictedBlock;
