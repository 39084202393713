import React from 'react';

import { useAppDispatch, useTranslation } from 'client/hooks';
import { useAppSelector } from 'client/hooks';

import { onboarding as onboardingApi } from 'client/redux/api/onboarding';
import { hubspot as hubspotApi } from 'client/redux/api/hubspot';

import { selectUserEmail, selectUserOnboarding } from 'client/redux/user/selectors';
import { setStatus } from 'client/redux/user';
import { statusTranslation } from 'utils/hubspot-investor-status';

import Envelope from 'client/components/common/Envelope';

interface Props {
  setScreen: () => void;
}

const NDA: React.FC<Props> = ({ setScreen }) => {
  const dispatch = useAppDispatch();
  const userOnboarding = useAppSelector(selectUserOnboarding);
  const userEmail = useAppSelector(selectUserEmail);
  const [setNDA] = onboardingApi.endpoints.setNDA.useMutation();
  const { translate } = useTranslation();
  const isAlreadySigned =
    Boolean(userOnboarding.form.signatureId) && userOnboarding.form.signatureStatus === 'completed';

  const [updateContact, updateContactResult] = hubspotApi.endpoints.updateContact.useMutation();

  const onSuccess = (envelopeId: string) => {
    setNDA({
      signatureId: envelopeId,
      signatureStatus: 'completed',
      intent: 'nda',
      signatureDate: new Date().toISOString(),
    });
    setScreen();
    updateContact({
      email: userEmail,
      investor_status_level: statusTranslation('INVESTOR_LEVEL_2', 'WAITING_FOR_APPROVE'),
    });
    dispatch(setStatus('WAITING_FOR_APPROVE'));
  };

  const onReady = (envelopeId: string) => {
    setNDA({
      signatureId: envelopeId,
      signatureStatus: 'in-progress',
      intent: 'nda',
      signatureDate: new Date().toISOString(),
    });
  };

  if (isAlreadySigned) {
    <p>NDA is already signed</p>;
  }

  return (
    <Envelope
      type="nda"
      onSignComplete={onSuccess}
      onUIReady={onReady}
      loaderText={translate('questionnaire.accreditationQuestionnaire.nda.loaderTitle')}
    />
  );
};

export default NDA;
