import React from 'react';
import cn from 'classnames';

import PlayIcon from 'src/routes/client/components/common/PlayIcon';

import css from './VideoListItem.module.scss';

interface Props {
  id: string;
  videoSRC: string;
  isPlaying: boolean;
  onReady: (id: string) => void;
}

export const VideoListItem = (props: Props) => {
  const { videoSRC, id, isPlaying, onReady } = props;
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const [isReady, setIsReady] = React.useState(false);
  const [duration, setDuration] = React.useState('');

  React.useEffect(() => {
    const options = {
      autoplay: false,
      controls: false,
      responsive: true,
      fluid: true,
      muted: true,
      experimentalSvgIcons: true,
      sources: [
        {
          src: videoSRC,
          type: 'application/x-mpegURL',
        },
      ],
    };

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');
      videoElement.setAttribute('id', id);

      videoElement.classList.add('vjs-big-play-centered');
      const videoElem = document.getElementById(id);
      (videoElem as any).appendChild(videoElement);

      const player: any = ((playerRef as any).current = (window as any).videojs(videoElement, options, () => {
        // console.log('player is ready');
        handlePlayerEvents(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player: any = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [videoSRC, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player: any = playerRef.current;

    return () => {
      if (player && !(player as any).isDisposed()) {
        (player as any).dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  React.useEffect(() => {
    const player: any = playerRef.current;
  }, [isReady]);

  const handlePlayerEvents = (player: any) => {
    playerRef.current = player;

    setIsReady(true);

    player.on('loadedmetadata', () => {
      const duration = formatDuration(player.duration());
      setDuration(duration);
      onReady(id);
    });

    player.on('play', () => {
      console.info('play');
    });
  };

  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedDuration = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    return formattedDuration;
  };

  return (
    <div data-vjs-player className={cn(css.player, isPlaying && css.isPlaying)}>
      <div ref={videoRef} id={id} />

      <div className={css.cover}>
        <div className={css.duration}>{duration}</div>
        {isPlaying && <div className={css.status}>playing</div>}
        {!isPlaying && <PlayIcon className={cn(css.playButton)} />}
      </div>
    </div>
  );
};

export default VideoListItem;
