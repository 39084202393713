import React from 'react';

import { CONFIG } from 'constants/index';
import { MANDATE_PAGE_ROUTE } from 'client/constants/routes';
import { useAppSelector, useAppDispatch, useResponsive, useTranslation } from 'client/hooks';
import { selectAuth, selectUserRole } from 'client/redux/user/selectors';
import { setPopup } from 'client/redux/ui';

import css from './CompanyBanner.module.scss';

interface Props {
  className?: string;
  companyName: string;
  mandateId: string;
}

const CompanyBanner = (props: Props) => {
  const { className = '', companyName, mandateId } = props;
  const [isMobile] = useResponsive(['MOBILE']);
  const { translate: _t } = useTranslation('companyPage');
  const _companyName = companyName.trim();
  const heading = _t('companyBanner.heading').replace(`{companyName}`, _companyName);
  const text = _t('companyBanner.text').replace(`{companyName}`, _companyName);
  const preferableLabel = _t('companyBanner.preferableButtonLabel').replace(`{companyName}`, _companyName);
  const labelLength = isMobile ? 25 : 50;
  const btnLabel = preferableLabel.length > labelLength ? _t('companyBanner.buttonLabel') : preferableLabel;

  const { isLoggedIn } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);

  const clickOnLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const link = e.currentTarget.href;

    /**
     * redirectMId - used for redirect after login on CompanyMobile
     */
    if (isMobile && mandateId && !isLoggedIn) localStorage.setItem('redirectMId', mandateId);

    if (userRole === 'VIEWER' && !isLoggedIn) {
      e.preventDefault();
      dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in', redirectUrl: link } }));
    }

    if (userRole === 'VIEWER' && isLoggedIn) {
      e.preventDefault();

      dispatch(setPopup({ type: 'join-as-investor', data: { link: link, isExternalLink: true } }));
    }
  };

  return (
    <div className={css.companyBanner}>
      <h6 className={css.heading}>{heading}</h6>
      <p className={css.text}>{text}</p>
      <a
        href={`${CONFIG.domainUrl}${MANDATE_PAGE_ROUTE.replace(':id', mandateId)}`}
        onClick={clickOnLink}
        className={css.link}
      >
        {btnLabel}
      </a>
    </div>
  );
};

export default CompanyBanner;
