import * as yup from 'yup';
import { StepInput } from 'utils/onboarding/stage';
import { Onboarding } from 'models/user/onboarding';

export const validationSchema = (fieldsInfo: Array<StepInput<keyof Onboarding['form']>>) => {
  const schema = fieldsInfo.reduce<Record<string, yup.AnySchema>>((acc, step) => {
    const schema = step.schema;
    if (!schema) throw new Error(`Can not find schema for ${step.name}`);
    acc[step.name] = step.schema;
    return acc;
  }, {});

  return yup.object().shape(schema);
};
