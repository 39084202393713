import { Types } from 'mongoose';
import { ObjectValues } from 'types/object-values';

export interface IModel {
  _id: Types.ObjectId;
  status: OfferStatus;
  notes?: string;
  value: number;
  feePercentage: number;
  isFeesIncluded: boolean;
  fundId?: Types.ObjectId;
  companyId?: Types.ObjectId;
  userId: Types.ObjectId;
  createdAt: Date;
}

export const OFFER_STATUS = {
  NEW: 'NEW',
  UNDER_REVIEW: 'UNDER_REVIEW',
  APPROVED: 'APPROVED',
  DISAPPROVED: 'DISAPPROVED',
} as const;

export type OfferStatus = ObjectValues<typeof OFFER_STATUS>;
