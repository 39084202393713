import React from 'react';
import cn from 'classnames';

import { useAnalytics } from 'client/hooks';

import css from './ExpandableBlock.module.scss';

interface Props {
  content: string;
  className?: string;
  companyId?: string;
  height: number;
}

function ExpandableBlock(props: Props) {
  const { content, height, companyId, className = '' } = props;
  const [currentContent, setCurrentContent] = React.useState(content);
  const [isExpandable, setIsExpandable] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const templateRef = React.useRef<HTMLDivElement>(null);
  const expandEventName = `expand_btn_click_${React.useId()}`;
  const analytics = useAnalytics();

  React.useEffect(() => {
    if (!templateRef.current) return;

    const readMoreToggleBtn = `&nbsp;<button class="${
      css.expandToggleBtn
    }" type="button" onClick="window.dispatchEvent(new Event('${expandEventName}'))">Read ${
      isExpanded ? `less` : `more...`
    }</button>`;

    if (isExpanded === false) {
      const template = templateRef.current;

      template.innerHTML = 'Aa';

      let prevHeight = template.offsetHeight;
      let rowContent = '';
      let processedContent = '';

      template.innerHTML = '';

      const rowMetrics = content.split(' ').reduce<{ height: number; content: string }[]>((acc, word, index, arr) => {
        const prevRowContent = rowContent;

        processedContent += ` ${word}`;
        rowContent += ` ${word}`;
        template.innerHTML = processedContent;

        if (template.offsetHeight > prevHeight) {
          acc.push({ height: prevHeight, content: prevRowContent });

          prevHeight = template.offsetHeight;
          rowContent = `${word}`;
        }

        if (index === arr.length - 1 && template.offsetHeight <= prevHeight) {
          acc.push({ height: prevHeight, content: rowContent });
        }

        return acc;
      }, []);

      const rows = rowMetrics.filter((row) => (row?.height ?? Number.MAX_VALUE) <= height);

      setIsExpandable(rows.length < rowMetrics.length);
      setCurrentContent(rows.map((row) => row?.content ?? '').join(' ') + readMoreToggleBtn);
    } else {
      setCurrentContent(content + readMoreToggleBtn);
    }

    window.addEventListener(expandEventName, onExpandToggle);

    return () => {
      window.removeEventListener(expandEventName, onExpandToggle);
    };
  }, [content, height, isExpanded, expandEventName]);

  const onExpandToggle = () => {
    if (!isExpandable && companyId) {
      analytics.gtag.event('description_read_more', { ID: companyId as string });
    }
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className={cn(css.expandableContainer, isExpandable && css.expandable, className)}>
      <div
        className={css.template}
        ref={templateRef}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          color: 'red',
          visibility: 'hidden',
        }}
      />
      <div className={css.text} dangerouslySetInnerHTML={{ __html: currentContent }} />
    </div>
  );
}

export { ExpandableBlock as ExpandableText };
export default ExpandableBlock;
