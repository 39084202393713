import * as yup from 'yup';
import countries from 'utils/countries.json';
import { Onboarding } from 'models/user/onboarding';
import { filterDependencies, configureStep, getStageCurrentStepName, getStageStep } from '../stage';
import { InputType, constants } from '../input-types';
import { VALIDATION_ERROR } from 'endpoints/index';

/**
 * Returns accreditation finalization form fields
 */
export function getBankDetails(data: Onboarding) {
  const t = (path: string) => `onboardingFinalization.bankDetails.form.${path}`;
  return {
    steps: filterDependencies(
      [
        configureStep(
          {
            type: InputType.AUTO_COMPLETE,
            name: 'bankCountry',
            label: t('bankCountry.label'),
            explanation: '',
            defaultValue: [],
            options: countries,
            schema: yup
              .array()
              .of(yup.string())
              .typeError(VALIDATION_ERROR.REQUIRED)
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'bankName',
            label: t('bankName.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.TEXT_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .test('max', VALIDATION_ERROR.TOO_LONG, (value) => {
                if (value && value.length > constants.TEXT_MAX_LENGTH) {
                  return false;
                }
                return true;
              })
              .typeError(VALIDATION_ERROR.REQUIRED)
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'bankCity',
            label: t('bankCity.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.TEXT_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .test('max', VALIDATION_ERROR.TOO_LONG, (value) => {
                if (value && value.length > constants.TEXT_MAX_LENGTH) {
                  return false;
                }
                return true;
              })
              .typeError(VALIDATION_ERROR.REQUIRED)
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'bankPostalCode',
            label: t('bankPostalCode.label'),
            explanation: '',
            schema: yup
              .string()
              .test('max', VALIDATION_ERROR.TOO_LONG, (value) => {
                if (value && value.length > constants.TEXT_MAX_LENGTH) {
                  return false;
                }
                return true;
              })
              .typeError(VALIDATION_ERROR.REQUIRED)
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'bankAddressLine_1',
            label: t('bankAddressLine_1.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.TEXT_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .typeError(VALIDATION_ERROR.REQUIRED)
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'bankAddressLine_2',
            label: t('bankAddressLine_2.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.TEXT_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .nullable(),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'bankAccountName',
            label: t('bankAccountName.label'),
            explanation: '',
            schema: yup
              .string()
              .test('length', VALIDATION_ERROR.MIN_TWO_WORDS, (value) => {
                if (value && (value.split(' ').length < 2 || value.split(' ').indexOf('') > 0)) {
                  return false;
                }
                return true;
              })
              .typeError(VALIDATION_ERROR.REQUIRED)
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'bankAccountNumber',
            label: t('bankAccountNumber.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.IBAN_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .test('max', VALIDATION_ERROR.MAX_CHARACTERS, (value) => {
                if (value && value.length > constants.IBAN_MAX_LENGTH) {
                  return false;
                }
                return true;
              })
              .matches(/^[a-zA-Z0-9]*$/, VALIDATION_ERROR.INVALID_CHARACTERS)
              .typeError(VALIDATION_ERROR.REQUIRED)
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
        configureStep(
          {
            type: InputType.TEXT,
            name: 'bankSwiftCode',
            label: t('bankSwiftCode.label'),
            explanation: '',
            schema: yup
              .string()
              .test('min', VALIDATION_ERROR.MIN_CHARACTERS, (value) => {
                if (value && value.length < constants.SWIFT_MIN_LENGTH) {
                  return false;
                }
                return true;
              })
              .test('max', VALIDATION_ERROR.MAX_CHARACTERS, (value) => {
                if (value && value.length > constants.SWIFT_MAX_LENGTH) {
                  return false;
                }
                return true;
              })
              .matches(/^[a-zA-Z0-9]*$/, VALIDATION_ERROR.INVALID_CHARACTERS)
              .typeError(VALIDATION_ERROR.REQUIRED)
              .required(VALIDATION_ERROR.REQUIRED),
          },
          data,
        ),
      ],
      data,
    ),
  };
}

export function getCurrentStep(data: Onboarding) {
  const currentStepName = getStageCurrentStepName(getBankDetails(data).steps);
  return currentStepName ? getStep(data, currentStepName) : null;
}

export function getStep(data: Onboarding, targetStep: string) {
  return getStageStep(getBankDetails(data).steps, targetStep);
}
