import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ROUTE as FORGOT_PASS_API_ROUTE,
  Params as ForgotPassParams,
  Result as ForgotPassResult,
} from 'api/auth/user/forgot-password/params';
import { ApiResponse } from 'src/types/api-response';

import { api } from 'utils/api';

export const forgotPassword = createAsyncThunk<
  ApiResponse<ForgotPassResult>,
  ForgotPassParams,
  { rejectValue: string }
>('user/forgotPassword', async (data, { rejectWithValue }) => {
  try {
    const response = await api.sendRequest<ForgotPassResult>({
      url: FORGOT_PASS_API_ROUTE,
      method: 'POST',
      data,
    });

    if (response.success) {
      return response;
    }

    throw new Error(response.error.message as string);
  } catch (e) {
    console.info('e', (e as Error).message || e);
    return rejectWithValue((e as Error).message);
  }
});
