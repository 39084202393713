import React from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import { FormikProvider, Form, useFormik } from 'formik';
import Select from 'react-select';

import { selectUserOnboarding } from 'client/redux/user/selectors';
import { useTranslation, useAppSelector, useResponsive } from 'client/hooks';
import { InputValue } from 'models/user/onboarding';
import { AutoCompleteInput } from 'utils/onboarding/input-types';

import Title from '../../Title';
import Icon from 'client/components/common/Icon';
import ContinueButton from '../../ContinueButton';
import css from './Select.module.scss';

interface Props {
  info: AutoCompleteInput;
  onSubmitHandler: (value: Record<string, InputValue>) => void;
  isSkippable?: boolean;
}
const CustomSelect = ({ info, onSubmitHandler, isSkippable = false }: Props) => {
  const [isMobile] = useResponsive(['MOBILE']);
  const [isExtraSmallMobile] = useResponsive('MOBILE_EXTRA_SMALL');
  const { translate } = useTranslation();
  const [shouldRender, setShouldRender] = React.useState(false);
  const onBoarding = useAppSelector(selectUserOnboarding);

  const fontSize = React.useMemo(() => {
    if (isExtraSmallMobile) return '16px';
    if (isMobile) return '18px';
    return '22px';
  }, [isMobile, isExtraSmallMobile]);
  // This needs only for nonResidentalCitizenships field
  const countryOfResidence = onBoarding.form.countryOfResidence
    ? info.options.find((item) => {
        if (!onBoarding.form.countryOfResidence) return null;
        if ((onBoarding.form.countryOfResidence as string[]).includes(item.value)) {
          return item;
        }
        return null;
      })
    : null;

  // Exclude from options value from countryOfResidence field, needs only for nonResidentalCitizenships field
  const options =
    info.name === 'nonResidentalCitizenships'
      ? info.options.filter((item) => item.value !== countryOfResidence?.value)
      : info.options;

  const titleText =
    info.name === 'nonResidentalCitizenships'
      ? translate(info.label).replace('{country}', countryOfResidence?.label || '')
      : translate(info.label);

  const initialValues = {
    [`${info.name}`]: (info.defaultValue as Array<string>) || (info.value as Array<string>),
  };

  React.useEffect(() => {
    setShouldRender(true);
  }, []);

  const formikContext = useFormik<Record<string, Array<string>>>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({ [`${info.name}`]: info.schema }),
    onSubmit: async (values) => {
      onSubmitHandler({ [`${info.name}`]: values[`${info.name}`] || false });
    },
  });

  if (!shouldRender) return null;

  return (
    <FormikProvider value={formikContext}>
      <Form className={css.container}>
        <div className={css.containerI}>
          {isSkippable && <p className={css.nonMandatory}>{translate('questionnaire.nonMandatoryText')}</p>}
          <Title text={titleText} size="m" />
          <div className={cn(css.select)}>
            {info.multiple ? (
              <Select
                options={options}
                id={info.name}
                instanceId={info.name}
                placeholder={translate('questionnaire.placeholders.select')}
                isMulti={true}
                value={formikContext.values[`${info.name}`].map((val: string) =>
                  options.find((option) => option.value === val),
                )}
                components={{
                  DropdownIndicator: () => <Icon type="down-white-arrow" className={css.dropdownIndicator} />,
                  MultiValueRemove: (props) => {
                    return (
                      <div
                        {...props.innerProps}
                        className={css.multivalueRemove}
                        onClick={(e) => {
                          if (props.innerProps.onClick) {
                            props.innerProps.onClick(e);
                          }
                        }}
                      ></div>
                    );
                  },
                }}
                onChange={(e) => {
                  const values = e.map((val) => {
                    if (val && val.value) {
                      return val.value;
                    }
                  });
                  formikContext.setFieldValue(info.name, values);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: 'auto',
                    minHeight: '59px',
                    border: state.isFocused ? 'none' : 'none',
                    borderColor: state.isFocused ? '#transparent' : '#transparent',
                    boxShadow: 'none',
                    borderBottom: `solid 1px rgba(255, 255, 255, 0.4)`,
                    backgroundColor: `rgba(255, 255, 255, 0.07)`,
                    borderRadius: '0px',
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                  valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '3px 12px 3px 23px',
                    fontSize: fontSize,
                    letterSpacing: '-0.25px',
                    fontWeight: 400,
                    color: 'rgba(255, 255, 255, 0.87)',
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    color: '#fff',
                  }),
                  singleValue: () => ({
                    fontSize: fontSize,
                    letterSpacing: '-0.25px',
                    fontWeight: 400,
                    color: 'rgba(255, 255, 255, 0.87)',
                  }),
                  multiValue: (baseStyles) => ({
                    ...baseStyles,
                    alignItems: 'center',
                    marginLeft: '10px',
                  }),
                  multiValueRemove: (baseStyles, state) => ({
                    ...baseStyles,
                    fill: '#000',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  clearIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    display: 'none',
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '200px',
                    backgroundColor: 'rgba(17, 17, 17, 0.97)',
                    overflow: 'auto',
                  }),
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '200px',
                    backgroundColor: '#transparent',
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '0 12px 0 23px',
                    backgroundColor: state.isFocused || state.isSelected ? '#444' : '#transparent',
                    fontSize: fontSize,
                    letterSpacing: '-0.25px',
                    fontWeight: 400,
                    color: '#fff',
                  }),
                  noOptionsMessage: (baseStyles, state) => ({
                    fontSize: isMobile ? '22px' : '28px',
                    letterSpacing: '-0.31px',
                    fontWeight: 400,
                    color: '#fff',
                    textAlign: 'center',
                  }),
                }}
              />
            ) : (
              <Select
                options={options}
                id={info.name}
                instanceId={info.name}
                placeholder={translate('questionnaire.placeholders.select')}
                components={{
                  DropdownIndicator: () => <Icon type="down-white-arrow" className={css.dropdownIndicator} />,
                }}
                onChange={(e) => {
                  if (e && e.value) {
                    formikContext.setFieldValue(info.name, [e?.value]);
                  }
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '59px',
                    border: state.isFocused ? 'none' : 'none',
                    borderColor: state.isFocused ? '#transparent' : '#transparent',
                    boxShadow: 'none',
                    borderBottom: `solid 1px rgba(255, 255, 255, 0.4)`,
                    backgroundColor: `rgba(255, 255, 255, 0.07)`,
                    borderRadius: '0px',
                  }),
                  valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 12px 0 23px',
                    fontSize,
                    letterSpacing: '-0.25px',
                    fontWeight: 400,
                    color: 'rgba(255, 255, 255, 0.87)',
                  }),
                  input: () => ({
                    color: '#fff',
                  }),
                  singleValue: () => ({
                    fontSize,
                    letterSpacing: '-0.25px',
                    fontWeight: 400,
                    color: 'rgba(255, 255, 255, 0.87)',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '200px',
                    backgroundColor: 'rgba(17, 17, 17, 0.97)',
                    overflow: 'auto',
                  }),
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '200px',
                    backgroundColor: '#transparent',
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '0 12px 0 23px',
                    backgroundColor: state.isFocused && state.isSelected ? '#444' : '#transparent',
                    fontSize: fontSize,
                    letterSpacing: '-0.25px',
                    fontWeight: 400,
                    color: '#fff',
                  }),
                  noOptionsMessage: (baseStyles, state) => ({
                    fontSize: isMobile ? '22px' : '28px',
                    letterSpacing: '-0.31px',
                    fontWeight: 400,
                    color: '#fff',
                    textAlign: 'center',
                  }),
                }}
              />
            )}
          </div>
        </div>
        <div className={css.continueBtnWrapper}>
          <ContinueButton disabled={!formikContext.dirty} />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default CustomSelect;
