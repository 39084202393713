import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { IModel } from 'models/category/interface';
import { categories as categoriesApi } from 'client/redux/api/categories';

import Slider from './Slider';
import { CATEGORY_PAGE_ROUTE } from 'client/constants/routes';

type Category = Omit<IModel, '_id'> & {
  _id: string;
};

const AMOUNT_ON_SCROLL = 1;

const BottomSliders: React.FC = () => {
  const [amount, setAmount] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  const { data: categoriesData, isLoading: isCategoriesLoading } = categoriesApi.endpoints.getAllCategories.useQuery({
    isVisible: true,
  });
  const categories: { items: Category[]; total: number } | undefined = React.useMemo(() => {
    if (categoriesData?.success) {
      setTotal(_.size(categoriesData.data));
      return { items: categoriesData.data as unknown as Category[], total: _.size(categoriesData.data) };
    }
    null;
  }, [categoriesData]);

  React.useEffect(() => {
    if (total !== amount) {
      document.addEventListener('scroll', onScroll);
    }
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [total, amount]);

  const onScroll = () => {
    const uploadingScreenH = 2 * (window.innerHeight / 3);
    if (total !== amount && window.pageYOffset + window.innerHeight + uploadingScreenH >= document.body.scrollHeight) {
      setAmount(amount + AMOUNT_ON_SCROLL);
    }
  };

  return (
    <>
      {categories?.items &&
        _.map(categories?.items.slice(0, amount), (category) => (
          <Slider
            categoryId={category._id}
            title={() => <Link to={CATEGORY_PAGE_ROUTE.replace(':id', category.slug)}>{category.name}</Link>}
            key={category._id}
          />
        ))}
    </>
  );
};

export default BottomSliders;
