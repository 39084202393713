import React from 'react';

import { Company } from 'api/companies/public/paginate/params';
import { companies as companiesApi } from 'client/redux/api/companies';

import CompaniesSlider from 'client/components/common/CompaniesSlider';

type Props = {
  categoryId: string;
  title: () => React.ReactNode;
};

const Slider: React.FC<Props> = ({ categoryId, title }) => {
  const { data, isLoading } = companiesApi.endpoints.getCompanyWithCategoryOrder.useQuery({
    filter: { categoryId: categoryId },
    limit: 100,
    offset: 0,
  });

  const companies = React.useMemo(() => (data?.success ? data.data : null), [data]);

  if (companies?.items && companies?.items.length === 0) {
    return;
  }

  return <CompaniesSlider title={title} companies={companies?.items as Company[]} isLoading={isLoading} />;
};

export default Slider;
