import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Div100vh from 'react-div-100vh';
import cn from 'classnames';
import { Link, Redirect } from 'react-router-dom';

import * as routes from 'client/constants/routes';
import { CONFIG } from 'constants/index';
import { REG_EXP } from 'client/constants';
import { Header, ControlPanel, ContentWrap, Button, CompanyActions } from 'client/components/common/mobile';
import CompanyBanner from 'client/components/common/CompanyBanner';
import { CompanySocials, ExpandableBlock } from 'client/components/common';
import { COMPANY_PAGE_ROUTE, MANDATE_PAGE_ROUTE } from 'client/constants/routes';
import { transitionClassNames } from 'client/utils/css-transition-classnames';
import { companies as companiesApi } from 'client/redux/api/companies';
import { setPopup } from 'client/redux/ui';
import { imgixUrl } from 'client/utils/imgix-url';
import { useAnalytics, useAppDispatch, useAppSelector, useResponsive, useTranslation } from 'client/hooks';
import { selectAuth, selectData, selectUserGAStatus, selectUserRole } from 'client/redux/user/selectors';

import Splash from './Splash';
import VideoSlider from './VideoSlider';
import CompaniesSlider from './CompaniesSlider';
import Founder from './Founder';
import NewsItem from './NewsItem';

import css from './CompanyMobile.module.scss';

const CompanyMobile = () => {
  const dispatch = useAppDispatch();
  const analytics = useAnalytics();
  const location = useLocation();
  const history = useHistory();
  const userData = useAppSelector(selectData);
  const userGAStatus = useAppSelector(selectUserGAStatus);
  const userRole = useAppSelector(selectUserRole);
  const { isLoggedIn } = useAppSelector(selectAuth);
  const match = useRouteMatch<{ id: string }>(COMPANY_PAGE_ROUTE);
  const [isExtraSmallMobile] = useResponsive('MOBILE_EXTRA_SMALL');
  const { translate } = useTranslation();
  const [companyId] = React.useState(match?.params.id); // because during 'exit' transition params.id will be undefined
  const { data: getCompanyRes } = companiesApi.endpoints.getCompany.useQuery({ id: companyId! });
  const data = getCompanyRes?.success ? getCompanyRes.data : null;
  const filledSocialsAmount = React.useMemo(() => {
    return Object.values(data?.socials || {}).filter((s) => !!s).length;
  }, [data]);
  const filteredNews = (data?.news || []).filter(({ success }) => success);
  const isAlreadyVoted = userData.voted.includes(companyId || '');
  const isInFavoriteList = userData.favorite.includes(companyId || '');

  const { data: paginateCompaniesRes } = companiesApi.endpoints.paginateCompanies.useQuery(
    {
      filter: {
        categoryIds: data?.categories.map((cat) => cat._id.toString()).join(','),
      },
      limit: 5,
      offset: 0,
    },
    { skip: !data },
  );

  const relatedCompaniesData = paginateCompaniesRes?.success
    ? paginateCompaniesRes?.data.items.filter((company) => company._id !== data?._id)
    : null;

  React.useEffect(() => {
    /**
     * This is redirect logic to mandate page after click on CompanyBanner
     * redirectMId - setting item on CompanyBanner button click
     */
    const redirectId = window.localStorage.getItem('redirectMId');

    if (isLoggedIn && redirectId) {
      if (userRole === 'VIEWER') {
        _.defer(() => {
          dispatch(
            setPopup({
              type: 'join-as-investor',
              data: { link: MANDATE_PAGE_ROUTE.replace(':id', redirectId), isExternalLink: false },
            }),
          );
        }, 0);
      }

      if (userRole !== 'VIEWER') {
        history.push(MANDATE_PAGE_ROUTE.replace(':id', redirectId));
      }

      localStorage.removeItem('redirectMId');
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (userGAStatus) {
      if (data) {
        analytics.gtag.event('page_view', {
          page_title: `Company - ${data.name}`,
          page_location: `${CONFIG.domainUrl}${location.pathname}`,
        });
      }
    }
  }, [data, userGAStatus]);

  const onViewAllFoundersClick = () => {
    if (data) {
      analytics.gtag.event('key_executives_view_all');
      dispatch(setPopup({ type: 'founders', data: data.team }));
    }
  };

  const onViewAllNewsClick = () => {
    if (data) {
      analytics.gtag.event('news_view_all');
      dispatch(setPopup({ type: 'news', data: filteredNews }));
    }
  };

  const onClaimYourPageClick = () => {
    history.push(routes.ADD_YOUR_COMPANY_PAGE_ROUTE);
  };

  const renderFounders = () => {
    if (!data?.team) return null;

    const maxFounders = 3;
    const teamToRender = data.team.slice(0, maxFounders);

    return (
      <>
        {teamToRender.map((founder, index) => (
          <Founder key={index} data={founder} />
        ))}
        {data.team.length > maxFounders && (
          <Button className={css.viewMoreBtn} size="large" onClick={onViewAllFoundersClick}>
            {translate('commonButtons.viewAll')}
          </Button>
        )}
      </>
    );
  };

  const renderNews = () => {
    if (!data?.team) return null;

    const maxNews = 3;
    const newsToRender = filteredNews.slice(0, maxNews);

    return (
      <>
        {newsToRender.map((item, index) => (
          <NewsItem key={index} data={item} />
        ))}
        {filteredNews.length > maxNews && (
          <Button className={css.viewMoreBtn} size="large" onClick={onViewAllNewsClick}>
            {translate('commonButtons.viewAll')}
          </Button>
        )}
      </>
    );
  };

  const renderIndustries = () => {
    if (!data?.categories) return null;

    return (
      <div className={css.categoriesList}>
        {data.categories.map((industry) => {
          const urlParams = new URLSearchParams(location.search);

          urlParams.set('page', '1');
          urlParams.set('category', industry._id);

          return (
            <Link
              className={css.categoriesItem}
              to={`${routes.MAIN_PAGE_ROUTE}?${urlParams.toString()}`}
              key={industry._id}
            >
              {industry.name}
            </Link>
          );
        })}
      </div>
    );
  };

  const renderSimilarCompanies = () => {
    return <CompaniesSlider className={css.similarCompaniesSlider} companies={relatedCompaniesData || []} />;
  };

  const renderHelmet = () => {
    if (!data) return null;

    const seo = {
      title: `${data.name} - Catalyst Investors' Club`,
      description: `${data.subTitle}. See more about ${data.name} on Catalyst Investors' Club`,
    };
    const og = {
      title: `${data.name}`,
      description: `${data.subTitle}. See more about ${data.name} on Catalyst Investors' Club`,
    };

    return (
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={og.title} />
        <meta property="og:description" content={og.description} />
        {data.mainImage && (
          <meta
            property="og:image"
            content={imgixUrl(data.mainImage, {
              'max-w': 1200,
              height: 630,
              fit: 'fill',
              auto: 'format,compress',
              q: 70,
              crop: 'entropy',
            })}
          />
        )}
      </Helmet>
    );
  };

  if (data?.isVisible === false) {
    return <Redirect to={routes.NOT_FOUND_PAGE_ROUTE} />;
  }

  return (
    <>
      {renderHelmet()}
      <Header className={css.header} id="company" />
      <ControlPanel className={css.controlPanel} />

      <SwitchTransition mode="out-in">
        <CSSTransition
          key={!data ? 'splash' : 'content'}
          timeout={200}
          classNames={splashTransitionClassNames}
          unmountOnExit
        >
          {data ? (
            <div className={css.companyMobile}>
              <Div100vh className={css.contentWrap}>
                <div className={css.bg}>
                  <div className={css.bgImageWrap}>
                    <div
                      className={css.bgImage}
                      style={{
                        backgroundImage: `url(${imgixUrl(data.mainImage, {
                          'max-w': 800,
                          fit: 'fill',
                          auto: 'format,compress',
                          q: 40,
                        })})`,
                      }}
                    />
                  </div>
                </div>
                <ContentWrap className={css.content}>
                  <VideoSlider companyName={data.name} companyId={data._id} videos={data.videos} />
                  <div className={css.info}>
                    <div className={css.actions}>
                      <CompanyActions
                        isAlreadyVoted={isAlreadyVoted}
                        isInFavoriteList={isInFavoriteList}
                        companyId={data._id}
                      />
                    </div>
                    <div className={css.titleWrap}>
                      <p className={css.title}>{data.name}</p>
                    </div>
                    <ExpandableBlock
                      className={css.description}
                      content={data.description}
                      height={135}
                      companyId={data._id}
                    />
                    <div className={css.innerWrap}>
                      <div className={css.details}>
                        <div className={css.detailsRow}>
                          <div className={css.detailsRowTitle}>Country</div>
                          <div className={css.detailsRowValue}>{data.country}</div>
                        </div>
                        <div className={css.detailsRow}>
                          <div className={css.detailsRowTitle}>Website</div>
                          <div className={cn(css.detailsRowValue, css.websiteLink)}>
                            <a
                              href={data.websiteLink}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => analytics.gtag.event('website_link', { URL: data?.websiteLink as string })}
                            >
                              {data?.websiteLink.replace(REG_EXP.WEBSITE, '')}
                            </a>
                          </div>
                        </div>
                        <div className={css.detailsRow}>
                          <div className={css.detailsRowTitle}>Founded</div>
                          <div className={css.detailsRowValue}>{data.founded}</div>
                        </div>
                        <div className={css.detailsRow}>
                          {(!isExtraSmallMobile || (isExtraSmallMobile && filledSocialsAmount < 5)) && (
                            <div className={css.detailsRowTitle}>Social</div>
                          )}
                          <div className={css.detailsRowValue}>
                            <CompanySocials socials={data.socials} />
                          </div>
                        </div>
                        {data.sourceOfInformation && (
                          <div className={css.detailsDisclaimer}>
                            {translate('companyPage.sourceInfo')}&nbsp;
                            <div
                              className={css.sourceInfoWrapper}
                              dangerouslySetInnerHTML={{
                                __html: data.sourceOfInformation,
                              }}
                            ></div>
                          </div>
                        )}
                      </div>

                      {data?.isInvestBannerVisible && data?.mandateId && (
                        <CompanyBanner companyName={data?.name} mandateId={data?.mandateId} />
                      )}

                      <div className={css.withBgWrap}>
                        <div className={css.founders}>
                          <p className={css.innerTitle}>Founders</p>
                          {renderFounders()}
                        </div>
                      </div>
                      {filteredNews.length > 0 && (
                        <div className={css.withBgWrap}>
                          <div className={css.founders}>
                            <p className={css.innerTitle}>Recent News & Activity</p>
                            {renderNews()}
                          </div>
                        </div>
                      )}
                      <div className={css.withBgWrap}>
                        <div className={css.categories}>
                          <p className={css.innerTitle}>Industries</p>
                          {renderIndustries()}
                        </div>
                      </div>
                      <div className={css.claimYourPage}>
                        <Button className={css.claimYourPageBtn} size="large" onClick={onClaimYourPageClick}>
                          {translate('commonButtons.addYourCompany')}
                        </Button>
                      </div>
                      {Number(relatedCompaniesData?.length) > 0 && (
                        <div className={css.similarCompanies}>
                          <p className={css.innerTitle}>Similar Companies</p>
                          {renderSimilarCompanies()}
                        </div>
                      )}
                    </div>
                  </div>
                </ContentWrap>
              </Div100vh>
            </div>
          ) : (
            <div className={css.companyMobile}>
              <Splash className={css.splash} />
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

const splashTransitionClassNames = transitionClassNames(css, 'splashTransition');

export default CompanyMobile;
