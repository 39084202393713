import React from 'react';
import cn from 'classnames';

import css from './Header.module.scss';

interface Props {
  className?: string;
  value: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onClose: () => void;
}

function Search(props: Props) {
  const { value, className = '', onChange, onSubmit, onClose } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  React.useEffect(() => {
    const abortController = new AbortController();

    document.addEventListener(
      'click',
      (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
          if (!value) onClose();
        }
      },
      { signal: abortController.signal },
    );

    return () => {
      abortController.abort();
    };
  }, [value, onClose]);

  return (
    <div className={cn(css.search, className)} ref={containerRef}>
      <form onSubmit={onFormSubmit}>
        <input
          type="text"
          inputMode="search"
          placeholder="Search"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          ref={props.inputRef}
          autoFocus
        />
      </form>
      <div className={css.underline} />
      <button className={css.closeBtn} type="button" onClick={onClose} />
    </div>
  );
}

export { Search };
export default Search;
