import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { FavoriteCompany } from 'api/users/website/get-favorite-companies/params';
import { COMPANY_PAGE_ROUTE } from 'client/constants/routes';
import { Icon } from 'client/components/common';
import { imgixUrl } from 'client/utils/imgix-url';

import css from './Item.module.scss';

interface Props {
  className?: string;
  data: FavoriteCompany;
  onDeleteBtnClick: (id: string) => unknown;
}

export const Item: React.FC<Props> = ({ className = '', data, onDeleteBtnClick }) => {
  const handleDeleteBtnClick = () => {
    onDeleteBtnClick(data._id);
  };

  return (
    <div className={cn(css.item, className)}>
      <Link to={COMPANY_PAGE_ROUTE.replace(':id', data._id)} className={css.content}>
        <div
          className={css.mainImage}
          style={
            data.mainImage
              ? {
                  backgroundImage: `url('${imgixUrl(data.mainImage, {
                    auto: 'compress',
                    q: 90,
                    'max-w': 500,
                    fit: 'fill',
                  })}')`,
                }
              : {}
          }
        />
        <div className={css.info}>
          <div className={css.name}>{data.name}</div>
          <div className={css.description}>{data.subTitle}</div>
        </div>
      </Link>

      <button onClick={handleDeleteBtnClick} className={css.deleteBtn}>
        <Icon className={css.icon} type="trash" />
      </button>
    </div>
  );
};
