import React from 'react';
import cn from 'classnames';
import Select, { components, InputProps } from 'react-select';
import { useField } from 'formik';

import Icon from 'src/routes/client/components/common/Icon';
import Error from '../../Error';

import css from './Select.module.scss';

interface Props {
  name: string;
  options: Array<{ label: string; value: string }>;
  label: string;
  placeholder?: string;
  noOptionsText?: string;
}

const CustomInput = (props: InputProps<{ label: string; value: string }>) => {
  const inputProps = { ...props };

  return <components.Input {...inputProps} autoComplete="some-new-options" />;
};

const FormSelect = ({ name, options, label, placeholder = '', noOptionsText = 'No more options' }: Props) => {
  const [field, meta, helpers] = useField(name);
  const errorMsg = !!(meta.touched && meta.error) ? meta.error : '';
  return (
    <div className={css.selectWrapper}>
      <p className={css.label}>{label}</p>
      <Select
        options={options}
        id={name}
        instanceId={name}
        value={options.filter((option) => option.value === field.value)}
        isMulti={false}
        onChange={(e) => {
          helpers.setValue(e?.value);
        }}
        components={{
          DropdownIndicator: () => <Icon type="down-white-arrow" className={css.dropdownIndicator} />,
          Input: CustomInput,
        }}
        placeholder={placeholder}
        menuPlacement={'auto'}
        classNames={{
          control: (state) => cn(css.control, errorMsg && meta.touched && css.withError),
          placeholder: (state) => cn(css.placeholder),
          valueContainer: (state) => cn(css.valueContainer),
          singleValue: (state) => cn(css.singleValue),
          input: (state) => cn(css.input),
          indicatorSeparator: (state) => cn(css.indicatorSeparator),
          menu: (state) => cn(css.menu),
          menuList: (state) => cn(css.menuList),
          option: (state) => cn(css.option, (state.isFocused || state.isSelected) && css.selected),
          noOptionsMessage: (state) => cn(css.noOptionsMessage),
        }}
        noOptionsMessage={() => noOptionsText}
      />
      {errorMsg && meta.touched && <Error name={name} error={errorMsg} />}
    </div>
  );
};

export default FormSelect;
