import React from 'react';
import cn from 'classnames';
import { FormikProvider, Form, useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'client/hooks';
import _ from 'lodash';

import { InputValue } from 'models/user/onboarding';
import { CheckboxInput } from 'utils/onboarding/input-types';

import Title from '../../Title';
import ExplanationSubtitle from '../../ExplanationSubtitle';
import ContinueButton from '../../ContinueButton';
import css from './YesNo.module.scss';

interface Props {
  info: CheckboxInput;
  onSubmitHandler: (value: Record<string, InputValue>) => void;
  isSkippable?: boolean;
}

const YesNo = ({ info, onSubmitHandler, isSkippable = false }: Props) => {
  const { translate } = useTranslation();
  const [shouldRender, setShouldRender] = React.useState(false);
  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const initialValues = {
    [`${info.name}`]: (info.defaultValue as boolean) || (info.value as boolean),
  };

  React.useEffect(() => {
    setShouldRender(true);
  }, []);

  const formikContext = useFormik<Record<string, boolean | null>>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({ [`${info.name}`]: info.schema }),
    onSubmit: async (values) => {
      setIsSubmiting(true);
      onSubmitHandler({ [`${info.name}`]: values[`${info.name}`] || false });
    },
  });

  const clickOnYesHandler = () => {
    formikContext.setFieldValue(`${info.name}`, true);
  };

  const clickOnNoHandler = () => {
    formikContext.setFieldValue(`${info.name}`, false);
  };

  if (!shouldRender) return null;

  return (
    <FormikProvider value={formikContext}>
      <Form className={css.container}>
        <div className={css.containerI}>
          {isSkippable && <p className={css.nonMandatory}>{translate('questionnaire.nonMandatoryText')}</p>}
          <Title text={translate(info.label)} size="m" />
          {info.explanation && <ExplanationSubtitle text={translate(info.explanation)} />}
          <div className={css.btnsWrapper}>
            <button
              type="button"
              onClick={clickOnYesHandler}
              className={cn(css.button, css.yesBtn, formikContext.values[`${info.name}`] === true && css.active)}
            >
              {translate('questionnaire.labels.yesNo.yes')}
            </button>
            <button
              type="button"
              onClick={clickOnNoHandler}
              className={cn(css.button, css.noBtn, formikContext.values[`${info.name}`] === false && css.active)}
            >
              {translate('questionnaire.labels.yesNo.no')}
            </button>
          </div>
        </div>
        <div className={css.continueBtnWrapper}>
          <ContinueButton disabled={formikContext.values[`${info.name}`] === null || isSubmiting} />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default YesNo;
