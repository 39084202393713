import React from 'react';
import cn from 'classnames';

import css from './Company.module.scss';

interface Props {
  className?: string;
}

function Splash(props: Props) {
  const { className = '' } = props;
  const foundersAmount = 3;

  return (
    <div className={cn(css.splash, className)}>
      <div className={css.splashBg} />
      <div className={css.splashPreview}>
        <div className={css.splashPlayBtn} />
      </div>
      <div className={css.splashInfo}>
        <div className={css.splashTitleWrap}>
          <div className={css.splashTitle}></div>
          <div className={css.splashActions}>
            <i />
            <i />
          </div>
        </div>
        <div className={css.splashDescription}>
          <div className={css.splashDescriptionLine} />
          <div className={css.splashDescriptionLine} />
        </div>
      </div>
      <div className={css.splashHr} />
      <div className={css.splashFounders}>
        {Array.from({ length: foundersAmount }).map((founder, index) => (
          <div className={css.splashFounder} key={index}>
            <div className={css.splashFounderAvatar}></div>
            <div className={css.splashFounderInfo}>
              <div className={css.splashFounderInfoLine} />
              <div className={css.splashFounderInfoLine} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export { Splash };
export default Splash;
