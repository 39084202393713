import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';

import * as routes from 'client/constants/routes';
import Scroller, { scrollTo } from 'client/utils/scroller';
import { Header, ControlPanel, ContentWrap } from 'client/components/common/mobile';

import Hero from './Hero';
import Companies from './Companies';

import css from './MainMobile.module.scss';
interface Props {
  className?: string;
}

const MainMobile: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const companiesRef = React.useRef<HTMLDivElement>(null);
  const scrollerRef = React.useRef<Scroller>(new Scroller());
  const scrollLimitsRef = React.useRef<{ min: number; max: number }>({ min: 0, max: 0 });
  const currentScrollRef = React.useRef(0);
  const mainPageRouteMatch = useRouteMatch({ path: routes.MAIN_PAGE_ROUTE, exact: true });
  const history = useHistory();
  const page = new URLSearchParams(history.location.search).get('page');
  const prevPage = React.useRef(page);
  const [isCompaniesSliderActive, setIsCompaniesSliderActive] = React.useState(!!page);
  const isMainPageRouteMatched = !!mainPageRouteMatch;

  // Scroll limits:
  React.useEffect(() => {
    const containerHeight = containerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const scroller = scrollerRef.current;

    scrollLimitsRef.current.min = 0;
    scrollLimitsRef.current.max = contentHeight - containerHeight;

    if (!contentRef.current) {
      throw new Error('Unable to initialize scroller: containe is null');
    }

    scroller.init({ container: contentRef.current });

    return () => {
      scroller.destroy();

      // accessibility - show on other pages for sure
      const accessibilityContainer = document.getElementById('INDWrap');
      if (accessibilityContainer) {
        accessibilityContainer.classList.remove('hide');
      }
    };
  }, []);

  // Handle content height change:
  React.useEffect(() => {
    const observer = new MutationObserver(() => {
      const contentHeight = contentRef.current?.offsetHeight || 0;
      const containerHeight = containerRef.current?.offsetHeight || 0;

      scrollLimitsRef.current.max = contentHeight - containerHeight;
      if (page) scrollTo(contentRef.current, scrollLimitsRef.current.max, { duration: 0 });
    });

    observer.observe(contentRef.current as Node, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [page]);

  // Custom scrolling:
  React.useEffect(() => {
    const page = new URLSearchParams(history.location.search).get('page');
    if (page || !mainPageRouteMatch || !containerRef.current || !contentRef.current || !companiesRef.current) {
      if (page) setIsCompaniesSliderActive(true);
      return;
    }

    const scroller = scrollerRef.current;
    let scrolledDistance = 0;
    let touchStartY = 0;
    let touchEndY = 0;

    scroller.subscribe('touch', onTouchEvent, MAIN_PAGE_SCROLLER_SUBJECT);
    scroller.subscribe('touchScroll', onScrollEvent, MAIN_PAGE_SCROLLER_SUBJECT);

    function onScrollEvent(deltaY: number) {
      scrolledDistance += deltaY;
      scrollTo(contentRef.current, scrolledDistance, { duration: 0 });
    }

    function onTouchEvent(e: TouchEvent) {
      switch (e.type) {
        case 'touchstart':
          scrolledDistance = 0;
          touchStartY = e.touches[0].clientY;
          touchEndY = e.touches[0].clientY;
          break;

        case 'touchmove':
          touchEndY = e.touches[0].clientY;
          break;

        case 'touchend': {
          const minDistance = 60;
          const absDistance = Math.abs(scrolledDistance);

          if (touchEndY > touchStartY && absDistance > 1.75 * minDistance) {
            // do nothing
          }

          if (touchEndY < touchStartY && absDistance > minDistance) {
            const urlParams = new URLSearchParams(history.location.search);
            urlParams.set('page', '1');
            history.push(`${routes.MAIN_PAGE_ROUTE}?${urlParams.toString()}`);
            return;
          }

          scrollTo(contentRef.current, scrollLimitsRef.current.min, {
            duration: 0.4,
            ease: 'back.out(1.0)',
          });
          break;
        }
      }
    }

    return () => {
      scroller.unsubscribe('touch', MAIN_PAGE_SCROLLER_SUBJECT);
      scroller.unsubscribe('touchScroll', MAIN_PAGE_SCROLLER_SUBJECT);
    };
  }, [mainPageRouteMatch, history, history.location.search]);

  // transition to companies slider:
  React.useEffect(() => {
    if (!isMainPageRouteMatched) return;

    const scrollLimits = scrollLimitsRef.current;

    if (page) {
      const scroller = scrollerRef.current;
      scroller.unsubscribe('wheelScroll', MAIN_PAGE_SCROLLER_SUBJECT);
      scroller.unsubscribe('touchScroll', MAIN_PAGE_SCROLLER_SUBJECT);

      scrollTo(contentRef.current, scrollLimits.max, {
        duration: prevPage.current !== page ? 0.4 : 0,
        ease: 'back.out(1.0)',
        onComplete: () => {
          setIsCompaniesSliderActive(true);
        },
      });

      currentScrollRef.current = scrollLimits.max;
      prevPage.current = page;
    } else {
      scrollTo(contentRef.current, 0, {
        duration: prevPage.current !== page ? 0.4 : 0,
        ease: 'back.out(1.0)',
        onStart: () => {
          setIsCompaniesSliderActive(false);
        },
      });

      currentScrollRef.current = 0;
      prevPage.current = page;
    }
  }, [page, isMainPageRouteMatched]);

  const onDeactivateCompaniesSlider = React.useCallback(() => {
    setIsCompaniesSliderActive(false);
    prevPage.current = '0';
  }, []);

  React.useEffect(() => {
    // hide accessibility
    if (isCompaniesSliderActive) {
      const accessibilityContainer = document.getElementById('INDWrap');
      const startIntercomBtnContainer = document.getElementsByClassName('intercom-lightweight-app-launcher')[0];
      const openedIntercomBtnContainer = document.getElementsByClassName('intercom-with-namespace-52k34s')[0];
      if (accessibilityContainer) {
        accessibilityContainer.classList.add('hide');
      }
      if (startIntercomBtnContainer) {
        startIntercomBtnContainer.classList.add('hideIntercom');
      }
      if (openedIntercomBtnContainer) {
        openedIntercomBtnContainer.classList.add('hideIntercom');
      }
    } else {
      const accessibilityContainer = document.getElementById('INDWrap');
      const startIntercomBtnContainer = document.getElementsByClassName('intercom-lightweight-app-launcher')[0];
      const openedIntercomBtnContainer = document.getElementsByClassName('intercom-with-namespace-52k34s')[0];
      if (accessibilityContainer) {
        accessibilityContainer.classList.remove('hide');
      }
      if (startIntercomBtnContainer) {
        startIntercomBtnContainer.classList.remove('hideIntercom');
      }
      if (openedIntercomBtnContainer) {
        openedIntercomBtnContainer.classList.remove('hideIntercom');
      }
    }
  }, [isCompaniesSliderActive]);

  const renderHelmet = () => {
    const seo = {
      title: `Catalyst Investors' Club - Exclusive Opportunities to Invest in Startups`,
      description: `Discover the most disruptive startups, find exclusive investment opportunities, and join Catalyst Investors' Club to invest alongside reputable lead investors.`,
    };
    const og = {
      title: `Catalyst Investors' Club - Exclusive Opportunities to Invest in Startups`,
      description: `Discover the most disruptive startups, find exclusive investment opportunities, and join Catalyst Investors' Club to invest alongside reputable lead investors.`,
    };

    return (
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta property="og:title" content={og.title} />
        <meta property="og:description" content={og.description} />
      </Helmet>
    );
  };

  return (
    <>
      {renderHelmet()}
      <div className={cn(css.mainMobile, isCompaniesSliderActive && css.sliderActive, className)} ref={containerRef}>
        <Header className={css.header} id="main" />
        <ControlPanel className={css.controlPanel} />
        <ContentWrap className={css.content} containerRef={contentRef}>
          <Hero />
          <Companies
            className={css.companies}
            scroller={scrollerRef.current}
            active={isCompaniesSliderActive}
            containerRef={companiesRef}
            onDeactivate={onDeactivateCompaniesSlider}
          />
        </ContentWrap>
      </div>
    </>
  );
};

const MAIN_PAGE_SCROLLER_SUBJECT = 'mainPageScroller';

export default MainMobile;
