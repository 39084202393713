import React from 'react';
import cn from 'classnames';

import css from './Button.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  size?: 'x-large-block' | 'large' | 'medium' | 'small';
  variant?: 'primary' | 'secondary' | 'blured';
  noPaddings?: boolean;
}

const Button = (props: Props) => {
  const { className = '', size = 'medium', variant = 'primary', noPaddings = false, ...restProps } = props;

  return (
    <button
      className={cn(css.button, css[`${size}-size`], css[`${variant}Variant`], noPaddings && css.noPaddings, className)}
      {...restProps}
    />
  );
};

export { Button };
export default Button;
